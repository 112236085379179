import React, { useState } from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import styled from "styled-components";

import { Text } from "../Typography";
import Blockie from "../Blockie";
import { useGetEns, useGetEnsAvatar } from "../../hooks/useEns";
import { formatEns, toShortAddress } from "../../utils";
import { COLORS } from "../../constants/colors";
import { useUserDetails } from "../../hooks/useUserDetails";

interface Props {
  address: string;
  protocol?: string;
}

const StyledRow = styled(Row)`
  flex-wrap: nowrap;
  align-items: center;
`;

const StyledVoterName = styled(Text).attrs({
  className: "voter-name",
})`
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.primary.grayDark};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 12rem;
`;

export const VoterNameColumn = ({ address }: Props) => {
  const ens = useGetEns(address);
  const ensAvatar = useGetEnsAvatar(address);
  const shortAddress = String(toShortAddress(address));
  const { user } = useUserDetails({ address, suspense: false });

  const [showImage, setShowImage] = useState(true);

  const addDefaultSrc = () => {
    setShowImage(false);
  };

  return (
    <StyledRow justify="start" gutter={16}>
      <Col>
        {user?.pfpUrl && showImage ? (
          <img
            src={user?.pfpUrl}
            height={40}
            width={40}
            style={{ borderRadius: "40px" }}
            alt="displayImg"
            onError={addDefaultSrc}
          />
        ) : ensAvatar && showImage ? (
          <img
            src={ensAvatar}
            height={40}
            width={40}
            style={{ borderRadius: "40px" }}
            alt="displayImg"
            onError={addDefaultSrc}
          />
        ) : (
          <Blockie dimension={40} seed={address} />
        )}
      </Col>
      <Col>
        <StyledVoterName>{user?.username || formatEns(ens) || shortAddress}</StyledVoterName>
      </Col>
    </StyledRow>
  );
};
