import React, { createContext, useState } from "react";
import { InsightsFrameworkFilterParams } from "../types";

interface Props {
  children: React.ReactNode;
}

interface NetworkFilterFilterContextType {
  framework: InsightsFrameworkFilterParams;
  setFrameworkFilter: React.Dispatch<React.SetStateAction<InsightsFrameworkFilterParams>>;
}

export const NetworkFilterFilterContext = createContext({} as NetworkFilterFilterContextType);

export const NetworkFilterFilterProvider = ({ children }: Props) => {
  const [framework, setFrameworkFilter] = useState<InsightsFrameworkFilterParams>("onchain");
  return (
    <NetworkFilterFilterContext.Provider value={{ framework, setFrameworkFilter }}>
      {children}
    </NetworkFilterFilterContext.Provider>
  );
};
