import React, { useCallback, useContext, useEffect, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import styled from "styled-components";
import { useHistory, useLocation } from "react-router";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { useOnboardWallet } from "../hooks/useOnboardWallet";
import { NftSubscriptionContext } from "../reducers/NftSubscription";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { Modal } from "./Modal";
import MintNFT from "../pages/MintNFT";
import { useSiwe } from "../hooks/useSiwe";
import { checkIfUserHasValidSubscription, useHasValidSubscription } from "../hooks/useHasValidSubscription";
import media from "../media-query";
import { COLORS } from "../constants/colors";
import mediaQuery from "../media-query";
import { useIsEmailSignIn } from "../hooks/useIsEmailSignIn";

const StyledPageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: -18px;
  bottom: 0;
  width: 100%;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  ${media.lessThan("991px")`
    width: 100%;
    left: 0px;
    top: 80px;
  `}
`;

const StyledContentOverlay = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  background: linear-gradient(to top, rgba(255, 255, 255, 1), rgb(255 255 255 / 82%));
  z-index: 10;
  width: 100%;
  text-align: center;
  padding-top: 44px;
  ${media.lessThan("991px")`
    height: 600px;
    padding: 44px 12px 0;
  `}
`;

const StyledTitle = styled.div`
  font-family: MATROSKA;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 24px;
  -webkit-letter-spacing: 0em;
  -moz-letter-spacing: 0em;
  -ms-letter-spacing: 0em;
  letter-spacing: 0em;
  text-align: center;
  padding: 10px 0;
`;

const StyledText = styled.p`
  font-size: 15px;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  color: ${COLORS.primary.grayDarkLighter};
`;

const StyledButton = styled.div<{ $isSubButton?: boolean }>`
  width: 100%;
  height: 40px;
  background: ${({ $isSubButton }) =>
    $isSubButton
      ? "linear-gradient(89.89deg, rgb(66, 53, 225) 0.96%, rgb(148, 67, 213) 97.68%)"
      : COLORS.primary.accentDarker};
  border: 1px solid transparent;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  margin: 0px auto;
  margin-top: 16px;
  width: 200px;
  :hover {
    background: #191540;
    border: 1px solid #ffffff;
    ${({ $isSubButton }) => $isSubButton && `background: ${COLORS.primary.accentDarker}; color: #fff;`}
  }
  :focus,
  :active {
    background: #4235e1;
    border: 1px solid #ffffff;
    border-radius: 8px;
  }
  display: inline-block;
  margin-right: ${({ $isSubButton }) => !$isSubButton && "16px"};
  ${mediaQuery.lessThan("991px")`
    margin-right: 0;
  `}
`;

const ButtonsWrapper = styled.div`
  width: auto;
  margin: auto;
  text-align: center;
  margin-bottom: 16px;
`;

const StyledList = styled.ul`
  margin-top: 16px;
  display: inline-block;
  text-align: left;
  list-style: none;
`;

const StyledListItem = styled.li`
  position: relative;
  font-size: 14px;
  font-weight: 600;
  &::before {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    border: 4px solid #4235e1;
    border-radius: 50%;
    left: -22px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

const StyledLink = styled.a`
  margin-bottom: 20px;
  display: block;
`;

const NftGate = ({ children }: { children?: React.ReactNode }) => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const nftModal = Boolean(query.get("nftModal"));
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const { hasValidKey, dispatchNftSubscriptionIsValid } = useContext(NftSubscriptionContext);
  const [isNFTMintingModalOpen, setIsNFTMintingModalOpen] = useState(nftModal ?? false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  const queryClient = useQueryClient();
  const [showAccessInfo, setShowAccessInfo] = useState(false);
  const isEmailSignIn = useIsEmailSignIn();
  const history = useHistory();

  useHasValidSubscription(account);
  useSiwe({
    trigger: !!hasValidKey,
  });

  useEffect(() => {
    if (account && hasValidKey) {
      const lastNotifyForNfy = window.localStorage.getItem(`lastNotifyForNfy${account.toLocaleLowerCase()}`);
      const isNotNotifiedInLast30Days =
        lastNotifyForNfy && parseInt(lastNotifyForNfy, 10) < Date.now() - 1000 * 60 * 60 * 24 * 30;
      const firstNotify = !lastNotifyForNfy;
      if (firstNotify || isNotNotifiedInLast30Days) {
        window.localStorage.setItem(`lastNotifyForNfy${account.toLocaleLowerCase()}`, Date.now().toString());
        setIsNFTMintingModalOpen(true);
        setShowAccessInfo(true);
      }
    }
  }, [account, hasValidKey]);

  const openConnectWalletModal = useCallback(() => {
    wallet?.openWalletModal();
  }, [wallet]);

  const fetchSubscpription = useCallback(async () => {
    try {
      const hasValidKey = await checkIfUserHasValidSubscription(account);
      dispatchNftSubscriptionIsValid({
        type: "NFT_SUBSCRIPTION_IS_VALID",
        data: hasValidKey,
      });
    } catch (e) {
      console.log(e);
    }
  }, [account, dispatchNftSubscriptionIsValid]);

  useEffect(() => {
    if (account) {
      fetchSubscpription();
    }
  }, [account, fetchSubscpription]);

  useEffect(() => {
    console.log("isEmailSignIn", isEmailSignIn);
    if (isEmailSignIn) {
      fetchSubscpription();
    }
  }, [isEmailSignIn, fetchSubscpription]);

  const onTransactionSentHandler = useCallback(() => {
    fetchSubscpription();
    queryClient.removeQueries([`useHasValidSubscription:${account}`]);
  }, [account, fetchSubscpription, queryClient]);

  useEffect(() => {
    window.addEventListener("unlockProtocol.transactionSent", onTransactionSentHandler);

    return () => {
      window.removeEventListener("unlockProtocol.transactionSent", onTransactionSentHandler);
    };
  }, [onTransactionSentHandler]);

  const toggleNftModal = useCallback(() => {
    if (!isNFTMintingModalOpen && isEmailSignIn && !account) {
      history.push("/settings/email");
    }
    setIsNFTMintingModalOpen((current) => !current);
  }, [account, history, isEmailSignIn, isNFTMintingModalOpen]);

  return (
    <>
      {children}
      {!hasValidKey && (
        <>
          <StyledPageOverlay>
            <StyledContentOverlay>
              <StyledTitle>Mint or Reveal Your Access Card</StyledTitle>
              <StyledText>
                Unlock a world of advanced governance information with the Boardroom members card.
              </StyledText>
              <StyledText>Connect your wallet to check eligibility or mint a new card for access.</StyledText>
              <div>
                <StyledList>
                  <StyledListItem>Forum Discussions</StyledListItem>
                  <StyledListItem>Keyword Alerts</StyledListItem>
                </StyledList>
                <StyledList>
                  <StyledListItem>AI Summaries</StyledListItem>
                  <StyledListItem>Additional Wallets</StyledListItem>
                </StyledList>
              </div>
              <ButtonsWrapper>
                {!account && !isEmailSignIn && (
                  <StyledButton onClick={openConnectWalletModal}>Connect Wallet</StyledButton>
                )}
                <StyledButton $isSubButton onClick={toggleNftModal}>
                  Mint Subscription
                </StyledButton>
              </ButtonsWrapper>
              <StyledLink href="https://home.boardroom.io/dashboard" target="_blank">
                Learn More
              </StyledLink>
            </StyledContentOverlay>
          </StyledPageOverlay>
        </>
      )}
      <Modal
        customMinWidth={isMobile ? "0" : undefined}
        customMaxHeight={isMobile ? "650px" : undefined}
        zIndex={20}
        removePaddingBottom
        size="large"
        open={isNFTMintingModalOpen}
        onClose={toggleNftModal}
        closeButtonRight="48px"
        closeButtonTop="40px"
        closeButtonColor="#ffffff"
      >
        <MintNFT hasValidKey={hasValidKey} showAccessInfo={showAccessInfo} onClose={toggleNftModal} />
      </Modal>
    </>
  );
};

export default NftGate;
