import React, { useEffect, useRef } from "react";
import { StyledMoreResourcesCardWrapper } from "../MoreResourcesCard/MoreResourcesCard";

export const GhostSignUpForm = () => {
  const divRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://cdn.jsdelivr.net/ghost/signup-form@~0.1/umd/signup-form.min.js";
    script.setAttribute("data-label-1", "Boardroom Dashboard");
    script.setAttribute("data-background-color", "#ffffff");
    script.setAttribute("data-text-color", "#000000");
    script.setAttribute("data-button-color", "#5d04ad");
    script.setAttribute("data-button-text-color", "#FFFFFF");
    script.setAttribute("data-title", "Boardroom");
    script.setAttribute(
      "data-description",
      "Boardroom is a governance data and services platform helping stakeholders make faster, smarter, and more informed decisions.",
    );
    script.setAttribute(
      "data-icon",
      "https://news.boardroom.io/content/images/size/w192h192/size/w256h256/2024/04/Logo-Icon---Dark.png",
    );
    script.setAttribute("data-site", "https://news.boardroom.io/");
    script.async = true;

    const currentDiv = divRef.current;
    currentDiv?.appendChild(script);

    return () => {
      currentDiv?.removeChild(script);
    };
  }, []);

  return (
    <StyledMoreResourcesCardWrapper>
      <div style={{ height: "400px" }} ref={divRef}></div>
    </StyledMoreResourcesCardWrapper>
  );
};
