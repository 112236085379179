import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import moment from "moment";
import notification from "antd/es/notification";
import { useHistory, useLocation } from "react-router";
import { DelegatePost, GetDelegatorsToAddressDetails, User } from "@boardroom/boardroom-api";
import InfiniteScroll from "react-infinite-scroller";
import { TwitterShareButton } from "react-share";
import CopyToClipboard from "react-copy-to-clipboard";

import { protocols } from "../../constants/protocols";
import ProfileAvatar from "../VoterProfile/ProfileAvatar";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetEns, useGetEnsAvatar } from "../../hooks/useEns";
import { Table } from "../Table";
import DelegateColumn from "../Delegates/DelegateColumn";
import { PowerDelegatedColumn } from "../Delegates/VotePowerColumn";
import DelegationModal from "../Voting/DelegationModal";
import { useMixpanel } from "../../hooks";
import { formatEns, toShortAddress } from "../../utils";
import MessageOnMessageBoard from "../MessageOnMessageBoard/MessageOnMessageBoard";
import { useDelegatorsToAddress } from "../../hooks/useDelegatorsToAddress";
import { useDelegateActivity } from "../../hooks/useDelegatesTable";
import { Modal } from "../Modal";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { DelegateMessageBoardSkeleton, MessagesLoader, TableLoader } from "./DelegateMessageBoardSkeleton";
import {
  CommentsWrapper,
  DelegateDashboardText,
  DelegateDashboardTitle,
  DelegationDate,
  DelegatorsCount,
  DelegatorsTitle,
  EmptyStateWrapper,
  ExploreDelegatesButton,
  MessageInputWrapper,
  MessagesDrawerTitle,
  MessagesDrawerTitleWrapper,
  ProtocolName,
  ProtocolNameWrapper,
  SeeMore,
  ShareText,
  ShareTriggerWrapper,
  ShowAllButton,
  StyledCommentInput,
  StyledCopiedIcon,
  StyledCopiedText,
  StyledCopyIcon,
  StyledCopyText,
  StyledDrawer,
  StyledSelect,
  StyledTab,
  TableFooter,
  TabsWrapper,
  UndelegatedDescription,
  UndelegatedText,
  UndelegatedWrapper,
  Wrapper,
  YouTooltip,
} from "./styles";
import { apiClient } from "../../utils/apiClient";
import { getUuid } from "../../utils/getUuid";
import { useDelegatePosts } from "../../hooks/useDelegatePosts";
import { PostModal } from "../PostModal/PostModal";
import { ThinShareIcon, TwitterOutlinedIcon } from "../icons";
import { Popover } from "../Popover";
import { Pfp } from "../Pfp/Pfp";

const columns = [
  {
    title: "Delegator Name",
    key: "delegator",
    dataIndex: "delegator",
    width: "50%",
    render: function (address: string) {
      return <DelegateColumn delegate={address} />;
    },
  },
  {
    title: "Power Delegated",
    key: "amount",
    dataIndex: "amount",
    sorter: (a: any, b: any) => b.amount - a.amount,
    width: "25%",
    render: function (_: any, rowData: any) {
      return <PowerDelegatedColumn rowData={rowData} />;
    },
  },
  {
    title: "Delegation date",
    key: "timestamp",
    dataIndex: "timestamp",
    sorter: (a: any, b: any) => b.timestamp - a.timestamp,
    width: "25%",
    render: function (timestamp: number) {
      return <DelegationDate>{timestamp ? moment(timestamp * 1000).format("DD MMMM YYYY") : ""}</DelegationDate>;
    },
  },
];

export const DelegatorsTable = ({
  protocol,
  address,
  userDetails,
  ens,
  backgroundColor,
}: {
  protocol: string;
  address: string;
  userDetails?: User;
  ens: string;
  backgroundColor?: string;
}) => {
  const [isDelegatorsModalVisible, setIsDelegatorsModalVisible] = useState(false);
  const { account } = useContext(CurrentAccountContext);
  const { trackClickDelegatorDelegationTab, trackClickSeeMoreDelegatorsDelegationTab } = useMixpanel();
  const history = useHistory();
  const { delegators, hasNextPage, fetchNextPage } = useDelegatorsToAddress({
    address,
    protocol,
  });
  const { data: activityData } = useDelegateActivity({ address, protocol, suspense: false });
  const profileNameValue = userDetails?.username || (ens !== "" && !!ens && formatEns(ens)) || toShortAddress(address);

  const navToDelegator = useCallback(
    (delegator: GetDelegatorsToAddressDetails) => {
      trackClickDelegatorDelegationTab({
        protocol,
        userId: account,
      });
      history.push({ pathname: `/voter/${delegator.delegator}` });
    },
    [history, account, protocol, trackClickDelegatorDelegationTab],
  );

  const seeMoreDelegators = useCallback(() => {
    trackClickSeeMoreDelegatorsDelegationTab({
      protocol,
      userId: account,
    });
    setIsDelegatorsModalVisible(true);
  }, [account, protocol, trackClickSeeMoreDelegatorsDelegationTab]);

  const initialLength = backgroundColor ? 3 : 10;

  return (
    <>
      {!!delegators?.length && (
        <div style={{ padding: backgroundColor ? "" : "0 24px" }}>
          <Table
            inACardContainer={true}
            onRow={(delegator: any) => {
              return {
                onClick: () => {
                  navToDelegator(delegator);
                },
              };
            }}
            rowIsLink={true}
            pagination={false}
            columns={columns}
            dataSource={delegators?.slice(0, initialLength)}
            backgroundColor={backgroundColor ?? "#fafafa"}
            addLetterSpacing
          />
          <Modal
            customMaxHeight="80vh"
            open={isDelegatorsModalVisible}
            onClose={() => setIsDelegatorsModalVisible(false)}
            size="large"
          >
            <Modal.Title>
              <DelegatorsTitle>
                {profileNameValue + "'s"} Delegators{" "}
                {!!activityData?.delegatorCount && <DelegatorsCount>{activityData?.delegatorCount}</DelegatorsCount>}
              </DelegatorsTitle>
            </Modal.Title>
            <InfiniteScroll
              loadMore={() => fetchNextPage()}
              hasMore={hasNextPage}
              initialLoad={false}
              threshold={300}
              useWindow={false}
            >
              <Modal.Body>
                <Table
                  onRow={(delegators: GetDelegatorsToAddressDetails) => {
                    return {
                      onClick: () => {
                        navToDelegator(delegators);
                      },
                    };
                  }}
                  dataSource={delegators}
                  columns={columns}
                  rowIsLink={true}
                  inACardContainer={true}
                  pagination={false}
                  addLetterSpacing
                />
              </Modal.Body>
            </InfiniteScroll>
          </Modal>
          <TableFooter $removePaddingBottom={!!backgroundColor}>
            {delegators && delegators?.length > initialLength && (
              <ShowAllButton onClick={seeMoreDelegators}>SEE MORE</ShowAllButton>
            )}
          </TableFooter>
        </div>
      )}
      {!delegators?.length && (
        <div>
          <EmptyStateWrapper>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <Pfp size="small" dimension={30} address={address} pfpUrl={userDetails?.pfpUrl} />{" "}
              <span style={{ marginLeft: "12px" }}>
                {userDetails?.username || ens || toShortAddress(address)}{" "}
                <span style={{ color: "#191540" }}>has not</span> received delegations yet
              </span>
            </div>
            <img className="empty-image" src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
          </EmptyStateWrapper>
        </div>
      )}
    </>
  );
};

const DelegateMessages = ({
  address,
  protocol,
  userDetails,
  isDelegator,
}: {
  address: string;
  protocol: string;
  userDetails?: User;
  isDelegator?: boolean;
}) => {
  const [sortingValue, setSortingValue] = useState("newest");
  const [messagesDrawerOpen, setMessagesDrawerOpen] = useState(false);
  const [postModalOpen, setPostModalOpen] = useState(false);
  const { account } = useContext(CurrentAccountContext);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const postId = query.get("post");
  const ensAvatar = useGetEnsAvatar(address);
  const ens = useGetEns(address);
  const uuid = getUuid(account);
  const { delegatePosts, refetch } = useDelegatePosts({
    author: address,
    protocol,
  });
  const { trackClickSeeMoreMessagesDelegationTab, trackOpenPostModalFromMessageBoard, trackDeletePostDelegationTab } =
    useMixpanel();
  const postOnUrl = useMemo(() => delegatePosts?.find((post) => post.postId === postId), [delegatePosts, postId]);

  const onClose = useCallback(() => {
    setPostModalOpen(false);
  }, []);
  const onCloseMessagesDrawer = useCallback(() => {
    setMessagesDrawerOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setPostModalOpen(true);
    trackOpenPostModalFromMessageBoard({
      userId: account,
      protocol,
    });
  }, [account, protocol, trackOpenPostModalFromMessageBoard]);

  const seeMoreMessages = useCallback(() => {
    trackClickSeeMoreMessagesDelegationTab({
      protocol,
      userId: account,
    });
    setMessagesDrawerOpen(true);
  }, [account, protocol, trackClickSeeMoreMessagesDelegationTab]);

  const removeDelegatePost = useCallback(
    async (post: DelegatePost) => {
      try {
        if (!uuid) {
          notification.error({
            message: "Error",
            description: "You need to sign in with wallet to delete a post",
          });
          return;
        }
        await apiClient.deleteDelegatePost({
          author: post.author,
          postBody: post.postBody,
          postId: post.postId,
          uuid,
        });
        notification.success({
          message: "Success",
          description: "Your post has been deleted",
          duration: 10,
        });
        refetch();
        trackDeletePostDelegationTab({ userId: account, protocol: post.protocol });
      } catch (error) {
        console.error(error);
        notification.error({
          message: "Error",
          description: "Something went wrong... Please try again later",
        });
      }
    },
    [uuid, refetch, account, trackDeletePostDelegationTab],
  );

  return (
    <div style={{ padding: "0 24px" }}>
      <PostModal
        addressToPostTo={address}
        refetch={refetch}
        onClose={onClose}
        open={postModalOpen}
        protocol={protocol}
      />
      {!!delegatePosts?.length && <DelegatorsTitle>Posts</DelegatorsTitle>}
      {!isDelegator && (
        <MessageInputWrapper onClick={onOpen}>
          <ProfileAvatar
            customImageDimensions={32}
            address={address}
            size="small"
            pfp={userDetails?.pfpUrl || ensAvatar}
          />
          <StyledCommentInput readOnly placeholder="Share your thoughts with the community" />
        </MessageInputWrapper>
      )}

      {!!delegatePosts?.length && (
        <StyledSelect
          value={sortingValue}
          onChange={(value) => setSortingValue(String(value) || "")}
          defaultValue="newest"
        >
          <option style={{ overflow: "visible" }} value="newest">
            Newest
          </option>
          <option style={{ overflow: "visible" }} value="oldest">
            Oldest
          </option>
        </StyledSelect>
      )}
      {!delegatePosts?.length && (
        <EmptyStateWrapper>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
            <Pfp size="small" dimension={30} address={address} pfpUrl={userDetails?.pfpUrl} />{" "}
            <span style={{ marginLeft: "12px" }}>
              {isDelegator ? (
                <span style={{ color: "#4235e1" }}>
                  {userDetails?.username || ens || toShortAddress(address)}{" "}
                  <span style={{ color: "#191540" }}>has not</span>
                </span>
              ) : (
                "You have not"
              )}{" "}
              posted yet
            </span>
          </div>
          <img className="empty-image" src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
        </EmptyStateWrapper>
      )}
      <CommentsWrapper>
        {!!postId && !!postOnUrl && (
          <MessageOnMessageBoard
            removeBorderBottom
            removePost={() => removeDelegatePost(postOnUrl)}
            key={postOnUrl.postId}
            post={postOnUrl}
            isShared
            onMessagesDrawerOpen={seeMoreMessages}
          />
        )}
        {delegatePosts
          ?.filter((post) => {
            if (postId && post.postId === postId) {
              return false;
            } else {
              return true;
            }
          })
          ?.sort((postA, postB) =>
            sortingValue === "newest"
              ? (Number(postB.createdAt) || 0) - (Number(postA.createdAt) || 0)
              : (Number(postA.createdAt) || 0) - (Number(postB.createdAt) || 0),
          )
          ?.slice(0, 2)
          .map((post) => (
            <MessageOnMessageBoard
              removeBorderBottom
              removePost={() => removeDelegatePost(post)}
              key={post.postId}
              post={post}
              onMessagesDrawerOpen={seeMoreMessages}
            />
          ))}
      </CommentsWrapper>
      {(delegatePosts?.length || 0) > 2 && <SeeMore onClick={seeMoreMessages}>SEE MORE</SeeMore>}
      <StyledDrawer
        height="auto"
        zIndex={20}
        width="38%"
        title={
          <MessagesDrawerTitleWrapper>
            <MessagesDrawerTitle>
              Messages <span>{delegatePosts?.length || 0}</span>
            </MessagesDrawerTitle>
          </MessagesDrawerTitleWrapper>
        }
        placement="right"
        visible={messagesDrawerOpen}
        onClose={onCloseMessagesDrawer}
      >
        {!isDelegator && (
          <MessageInputWrapper onClick={onOpen}>
            <ProfileAvatar
              customImageDimensions={32}
              address={address}
              size="small"
              pfp={userDetails?.pfpUrl || ensAvatar}
            />
            <StyledCommentInput placeholder="Share your thoughts with the community" />
          </MessageInputWrapper>
        )}
        {!!delegatePosts?.length && (
          <StyledSelect
            value={sortingValue}
            onChange={(value) => setSortingValue(String(value) || "")}
            defaultValue="newest"
          >
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </StyledSelect>
        )}
        <CommentsWrapper>
          {delegatePosts
            ?.sort((postA, postB) =>
              sortingValue === "newest"
                ? (Number(postB.createdAt) || 0) - (Number(postA.createdAt) || 0)
                : (Number(postA.createdAt) || 0) - (Number(postB.createdAt) || 0),
            )

            .map((post) => (
              <MessageOnMessageBoard
                removeBorderBottom
                removePost={() => removeDelegatePost(post)}
                key={post.postId}
                post={post}
                onMessagesDrawerOpen={seeMoreMessages}
                isDrawer
              />
            ))}
        </CommentsWrapper>
      </StyledDrawer>
    </div>
  );
};

interface Props {
  protocol: string;
  address: string;
  undelegated: boolean;
  isDelegator: boolean;
  updateAddressToUseWithDefinedAddress: (address: string) => void;
  isShare: boolean;
}

const DelegateMessageBoard = ({
  protocol,
  address,
  undelegated,
  isDelegator,
  updateAddressToUseWithDefinedAddress,
}: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const { search } = useLocation();
  const protocolInfo = protocols[protocol];
  const { user: userDetails } = useUserDetails({ address });
  const ens = useGetEns(address);
  const [visible, setVisible] = useState<boolean>(false);
  const [copied, setCopied] = useState(false);
  const { data } = useDelegateActivity({ address, protocol, suspense: false });
  const { delegatePosts } = useDelegatePosts({
    author: address,
    protocol,
    suspense: false,
  });
  const { delegators } = useDelegatorsToAddress({
    address,
    protocol,
    suspense: false,
  });
  const [tab, setTab] = useState("posts");
  const { trackShareDelegationTab } = useMixpanel();

  useEffect(() => {
    setTab("posts");
  }, [protocol]);

  const twitterShareClick = useCallback(() => {
    trackShareDelegationTab({
      userId: account,
      protocol,
    });
  }, [account, protocol, trackShareDelegationTab]);

  const updateAddressFunc = useCallback(
    (address: string) => {
      window.history.pushState(null, "", `/people/${protocol}/${address}${search}`);
      updateAddressToUseWithDefinedAddress(address);
    },
    [protocol, updateAddressToUseWithDefinedAddress, search],
  );

  const handleVisibleChange = useCallback((visible: boolean) => {
    if (!visible) {
      setTimeout(() => {
        setCopied(false);
      }, 100);
    }
  }, []);

  const onCopy = useCallback(() => {
    setCopied(true);
  }, []);

  return (
    <Wrapper>
      {undelegated ? (
        <UndelegatedWrapper>
          <img src={`${process.env.PUBLIC_URL}/assets/VoteHistoryEmptyState.png`} />
          <UndelegatedText>This Project is Undelegated</UndelegatedText>
          <UndelegatedDescription>
            You can delegate to yourself or another user to see delegate stats on this page.
          </UndelegatedDescription>
          <ExploreDelegatesButton to={`/${protocolInfo?.path}/delegates`}>Explore Delegates</ExploreDelegatesButton>
        </UndelegatedWrapper>
      ) : (
        <>
          <DelegateDashboardTitle>
            <div style={{ display: "flex" }}>
              <Pfp address={address} pfpUrl={userDetails?.pfpUrl} size="medium" dimension={40} />
              <ProtocolNameWrapper>
                <ProtocolName style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                  {`${userDetails?.username || ens || toShortAddress(address)}`}{" "}
                  {address?.toLowerCase() === account?.toLowerCase() && <YouTooltip>YOU</YouTooltip>}
                </ProtocolName>
                <DelegateDashboardText>
                  {protocolInfo?.name} Delegate Activity
                  <DelegationModal
                    visible={visible}
                    setVisible={setVisible}
                    address={address}
                    protocolName={protocolInfo?.cname}
                    updateAddressFunc={updateAddressFunc}
                  />
                </DelegateDashboardText>
              </ProtocolNameWrapper>
            </div>
            <Popover
              trigger="hover"
              placement="topRight"
              zIndex={1000}
              onVisibleChange={handleVisibleChange}
              content={
                <div>
                  {!copied ? (
                    <CopyToClipboard text={`https://boardroom.io/people/${protocol}/${address}`} onCopy={onCopy}>
                      <StyledCopyText>
                        <StyledCopyIcon />
                        Copy Link
                      </StyledCopyText>
                    </CopyToClipboard>
                  ) : (
                    <StyledCopiedText>
                      <StyledCopiedIcon />
                      Link Copied!
                    </StyledCopiedText>
                  )}
                  <TwitterShareButton
                    url={`https://boardroom.io/people/${protocol}/${address}`}
                    via={"boardroom_info"}
                    title={`View this delegate profile on ${protocolInfo?.name}`}
                    style={{ display: "flex", alignItems: "center" }}
                    onClick={twitterShareClick}
                  >
                    <TwitterOutlinedIcon width={16} height={16} color="#4235E1" />
                    <ShareText>Tweet</ShareText>
                  </TwitterShareButton>
                </div>
              }
            >
              <ShareTriggerWrapper>
                <ThinShareIcon width={16} height={16} color="#4235E1" />
                <ShareText>Share</ShareText>
              </ShareTriggerWrapper>
            </Popover>
          </DelegateDashboardTitle>
          <TabsWrapper>
            <StyledTab $active={tab === "posts"} onClick={() => setTab("posts")}>
              Posts <span style={{ fontWeight: 200 }}>{delegatePosts?.length}</span>
            </StyledTab>
            {(data?.delegatorCount || 0) > 0 && delegators?.length > 0 && (
              <StyledTab $active={tab === "delegators"} onClick={() => setTab("delegators")}>
                Delegators <span style={{ fontWeight: 200 }}>{data?.delegatorCount}</span>
              </StyledTab>
            )}
          </TabsWrapper>
          {tab === "posts" && (
            <Suspense fallback={<MessagesLoader />}>
              <DelegateMessages
                address={address}
                protocol={protocol}
                userDetails={userDetails}
                isDelegator={isDelegator}
              />
            </Suspense>
          )}
          {tab === "delegators" && (
            <Suspense fallback={<TableLoader />}>
              <DelegatorsTable protocol={protocol} address={address} userDetails={userDetails} ens={ens} />
            </Suspense>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default (props: Props) => (
  <>
    <Suspense fallback={<DelegateMessageBoardSkeleton />}>
      <DelegateMessageBoard {...props} />
    </Suspense>
  </>
);
