import styled, { css } from "styled-components";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Drawer from "antd/es/drawer";
import { Link } from "react-router-dom";

import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";
import { CopyIcon } from "../icons";

export const StyledCommentInput = styled(Input)<{ $removeMarginRight?: boolean }>`
  && {
    margin-left: 4px;
    margin-right: ${({ $removeMarginRight }) => ($removeMarginRight ? "0px" : "20px")};
    border: 1px solid #f0eff8;
    box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
    border-radius: 6px;
    height: 40px;
  }
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
  }
`;

export const StyledSelect = styled(Select)`
  border: none;
  transition: all 0.3s;
  box-shadow: 0;
  height: auto;
  border-radius: 6px;
  width: auto;
  color: #191540;
  margin: 16px 0 0;
  display: table;
  &:placeholder-shown {
    font-weight: 300;
    color: ${COLORS.primary.grayDarkLightest};
  }
  &:focus,
  &:active,
  &:hover {
    box-shadow: 0;
  }
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: none !important;
    padding-left: 0 !important;
    padding-right: 8px !important;
  }
  .ant-select-arrow {
    margin-top: -4px;
    border-radius: 4px;
    width: 0;
    height: 0;
    color: transparent;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 8px solid ${({ disabled }) => (disabled ? COLORS.primary.accentLight : COLORS.primary.accent)};
  }
  ${mediaQuery.lessThan("991px")`
    margin: 0;
  `};
`;

export const CommentsWrapper = styled.div`
  margin: 4px 0 32px;
`;

export const DelegationDate = styled.span`
  font-weight: 300;
  font-size: 14px;
  line-height: 30px;
  color: #191540;
`;

export const DelegatorsTitle = styled.span`
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #191540;
`;

export const DelegatorsCount = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #191540;
`;

export const MessageInputWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  margin-bottom: 8px;
  cursor: pointer;
`;

export const SeeMore = styled.span`
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  text-transform: uppercase;
  color: #4235e1;
  text-align: center;
  display: block;
  padding-bottom: 44px;
  margin-bottom: 40px;
  border-bottom: 1px solid #f0eff8;
`;

export const Wrapper = styled.div`
  margin: 0 0 36px;
  margin-left: 6px;
  min-height: calc(100% - 40px);
`;

export const ProtocolName = styled.span`
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #191540; ;
`;

export const DelegateDashboardText = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;

  color: #7b7893;
  display: flex;
`;

export const ProtocolNameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 8px;
`;

export const DelegateDashboardTitle = styled.div`
  display: flex;
  align-items: end;
  justify-content: space-between;
  margin-bottom: 14px;
  border-bottom: 1px solid #f0eff8;
  position: sticky;
  top: 0;
  padding: 36px 24px 12px;
  background: #fafafa;
  z-index: 1;
  ${mediaQuery.lessThan("991px")`
    padding: 16px 16px 12px;
  `}
`;

export const EditButton = styled.div`
  color: #4235e1;
  cursor: pointer;
`;

export const UndelegatedText = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;

  color: #9e97f3;
  margin: 48px 0 0;
`;

export const UndelegatedDescription = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  color: #7b7893;
  margin: 16px 0 40px;
`;

export const ExploreDelegatesButton = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;

  color: #9e97f3;
  padding: 12px 0;
  background: rgba(240, 239, 248, 0.2);
  border: 1px solid #9e97f3;
  border-radius: 8px;
  max-width: 396px;
  text-align: center;
`;

export const UndelegatedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 396px;
  margin: 120px auto 0;
  img {
    width: 70%;
    margin: 0 auto;
  }
`;

export const TableFooter = styled("div")<{$removePaddingBottom?: boolean;}>`
  margin-top: 52px;
  padding-bottom: 60px;
  text-align: center;
  ${({ $removePaddingBottom }) => $removePaddingBottom && "padding-bottom: 0;"}
`;

export const ShowAllButton = styled("span")`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #4235e1;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

export const ShareText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4235e1;
  margin-left: 6px;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-header {
    border-bottom: 0;
    padding: 24px 16px 0px;
    .ant-drawer-header-title {
      flex-direction: row-reverse;
      
      }
    }
  }
  .ant-drawer-body {
    padding: 24px 16px 16px;
  }
  ${mediaQuery.lessThan("991px")`
  .ant-drawer-body {
    padding: 8px 16px;
  }
  .ant-drawer-content {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  `};
`;

export const MessagesDrawerTitleWrapper = styled.div`
  border-bottom: 1px solid #f0eff8;
  position: relative;
`;

export const MessagesDrawerTitle = styled.h3`
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #191540;
  :after {
    content: "";
    height: 4px;
    width: 8rem;
    position: absolute;
    background: ${COLORS.primary.accent};
    bottom: -2px;
    left: 0;
  }
  span {
    font-weight: 200;
    font-size: 18px;
    line-height: 28px;
  }
`;

export const StyledCopyIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  color: ${COLORS.primary.accent};
  vertical-align: text-bottom;
  margin-right: 8px;
`;

export const StyledCopiedIcon = styled(CopyIcon)`
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  margin-right: 8px;
  color: ${COLORS.primary.gray};
`;

export const StyledCopyText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-bottom: 16px;
  color: ${COLORS.primary.accent};
`;

export const StyledCopiedText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 16px;
  color: ${COLORS.primary.gray};
`;

export const ShareTriggerWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledTab = styled.div<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  cursor: pointer;
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}
  height: 36px;
  margin-bottom: -2px;

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${mediaQuery.lessThan("640px")`
          height: 16px;
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  padding-left: 30px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${mediaQuery.lessThan("991px")`
      height: 42px;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
      padding-bottom: 12px;
    `}
`;

export const EmptyStateWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 16px 20px;
  width: 100%;
  margin-top: 40px;
  span {
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    color: #191540;
  }
  .empty-image {
    height: 100px;
    width: 168px;
    margin: 40px auto 16px;
    display: block;
  }
`;

export const YouTooltip = styled.div`
  display: flex;
  height: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #dbd8fa;
  background: #f0eff8;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 24px;
  color: #4235e1;
  padding: 0 8px;
`;
