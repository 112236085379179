import React, { createContext, useEffect, useState } from "react";
import { useLocation } from "react-router";

interface Props {
  children: React.ReactNode;
}

interface CurrentProjectsFilterContextType {
  projectsFilter: "custom" | "all" | "proxy";
  setProjectsFilter: React.Dispatch<React.SetStateAction<"custom" | "all" | "proxy">>;
}

export const CurrentProjectsFilterContext = createContext({} as CurrentProjectsFilterContextType);

export const CurrentProjectsFilterProvider = (props: Props) => {
  const { pathname } = useLocation();
  const isProxyPage = pathname.startsWith("/proxy");
  const [projectsFilter, setProjectsFilter] = useState<"custom" | "all" | "proxy">("all");

  useEffect(() => {
    if (isProxyPage) {
      setProjectsFilter("proxy");
    } else {
      setProjectsFilter("all");
    }
  }, [isProxyPage]);

  return (
    <CurrentProjectsFilterContext.Provider value={{ projectsFilter, setProjectsFilter }}>
      {props.children}
    </CurrentProjectsFilterContext.Provider>
  );
};
