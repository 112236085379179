import { useInfiniteQuery } from "@tanstack/react-query";
import { getAddress } from "@ethersproject/address";
import { useMemo } from "react";

import { protocols } from "../constants/protocols";
import { apiClient } from "../utils/apiClient";
import { isAddress } from "web3-utils";
import { useCheckProxyConnection } from "./useClerkSignIn";

interface Props {
  address: string;
  protocol?: string;
  limit?: number;
  suspense?: boolean;
}

interface MultipleAddressProps {
  addresses: Array<string>;
  protocol?: string;
  limit?: number;
}

export const useProxyAddress = (address: string) => {
  const proxyConnections = useCheckProxyConnection() || [];
  const proxyVotingMapsFromConnections = proxyConnections?.map(
    (connection: { address: string; proxyAddress: string }) => ({
      [connection?.address.toLowerCase()]: connection?.proxyAddress.toLowerCase(),
    }),
  );
  const proxyVotingMap = Object.assign({}, ...(proxyVotingMapsFromConnections || []));
  const proxyVotingKeys = Object.keys(proxyVotingMap).map((value) => value.toLowerCase());
  const parsedAddress = address.toLowerCase();
  const isAddressProxyVoting = proxyVotingKeys.includes(parsedAddress);
  const proxyAddress = isAddressProxyVoting ? proxyVotingMap[parsedAddress] : null;
  return proxyAddress;
};

export const useVoterVotes = ({ address, protocol, limit, suspense = true }: Props) => {
  const proxyAddress = useProxyAddress(address);
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`votes:${address?.toLowerCase()}:${protocol || "all"}`],
    ({ pageParam: cursor }) => {
      if (!address) {
        return null;
      }
      const normalizedAddress = isAddress(address) ? getAddress(address) : address;
      return apiClient.getVoterVotes(normalizedAddress, {
        limit,
        ...(protocol ? { cname: protocol } : {}),
        ...(cursor ? { cursor } : {}),
      });
    },
    {
      getNextPageParam: (params) => params?.nextCursor,
      suspense,
    },
  );

  const {
    data: proxyVotingData,
    fetchNextPage: fetchNextPageProxy,
    hasNextPage: hasNextPageProxy,
  } = useInfiniteQuery(
    [`votes:${proxyAddress?.toLowerCase()}:${protocol || "all"}`],
    ({ pageParam: cursor }) => {
      if (!proxyAddress) {
        return null;
      }
      const normalizedProxyAddress = isAddress(proxyAddress) ? getAddress(proxyAddress) : proxyAddress;
      return apiClient.getVoterVotes(normalizedProxyAddress, {
        limit,
        ...(protocol ? { cname: protocol } : {}),
        ...(cursor ? { cursor } : {}),
      });
    },
    {
      enabled: !!proxyAddress,
      getNextPageParam: (params) => params?.nextCursor,
      suspense,
    },
  );

  const votes = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d?.data)
      .flat()
      .filter((d) => d?.protocol && protocols[d?.protocol] && protocols[d?.protocol].isEnabled)
      .concat(
        proxyVotingData?.pages
          .flat()
          .map((d) => d?.data)
          .flat()
          .filter((d) => d?.protocol && protocols[d?.protocol] && protocols[d?.protocol].isEnabled),
      );
  }, [data?.pages, proxyVotingData?.pages]);

  const fetchNextPageAll = async () => {
    await fetchNextPage();
    if (proxyAddress) {
      await fetchNextPageProxy();
    }
  };

  return { votes, fetchNextPage: fetchNextPageAll, hasNextPage: hasNextPage || hasNextPageProxy };
};

export const useVoterVotesForMultipleAddresses = ({ addresses, protocol, limit }: MultipleAddressProps) => {
  const proxyAddress = useProxyAddress(addresses[0]);
  const allAddresses = [...addresses, proxyAddress].filter(Boolean) as string[];
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    [`votes:${allAddresses.toLocaleString()}:${protocol ? protocol : "all"}`],
    ({ pageParam: cursor }) =>
      apiClient.getVotesByAddresses(allAddresses, {
        limit,
        ...(protocol ? { cname: protocol } : {}),
        ...(cursor ? { cursor } : {}),
      }),
    {
      getNextPageParam: (params) => params?.nextCursor,
      enabled: allAddresses?.filter(Boolean).length > 0,
    },
  );
  const votes = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d?.data)
      .flat()
      .filter((d) => d?.protocol && protocols[d?.protocol] && protocols[d?.protocol].isEnabled);
  }, [data]);

  return { votes, fetchNextPage, hasNextPage };
};
