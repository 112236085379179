import { useMemo } from "react";
import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { GetDelegatedVotingPowerResponse } from "@boardroom/boardroom-api";
import { getAddress } from "ethers/lib/utils";

import { apiClient } from "../utils/apiClient";
import { isAddress } from "web3-utils";

interface Props {
  address: string;
}

export const useDelegateVotingPowerByAddress = ({ address }: Props) => {
  const normalizedAddress = isAddress(address) ? getAddress(address) : address;
  const { data: delegateVotingPower } = useQuery<GetDelegatedVotingPowerResponse, Error>(
    [`delegateVotingPower:${normalizedAddress}`],
    () => apiClient.getDelegatedVotingPowerByAddress(normalizedAddress, { address: normalizedAddress }),
    {
      enabled: !!address,
      suspense: false
    },
  );
  return { delegateVotingPower: delegateVotingPower?.data };
};

export const useDelegateProtocolsByAddress = ({ address }: Props) => {
  const normalizedAddress = isAddress(address) ? getAddress(address) : address;
  const { data: delegateVotingPower } = useQuery<GetDelegatedVotingPowerResponse, Error>(
    [`delegateVotingPower:${normalizedAddress}`],
    () => apiClient.getDelegatedVotingPowerByAddress(normalizedAddress, { address: normalizedAddress }),
    { enabled: !!normalizedAddress, suspense: false },
  );
  return { protocols: delegateVotingPower?.data.map((data) => ({
    protocol: data.protocol,
    adapter: data.adapter
  })) };
};

interface UseDelegateProtocolsByAddressesProps {
  addresses: string[];
  suspense?: boolean;
  protocol?: string;
}

export const useDelegateProtocolsByAddresses = ({
  addresses,
  suspense = true,
}: UseDelegateProtocolsByAddressesProps) => {
  const { data: delegateVotingPower } = useQuery<GetDelegatedVotingPowerResponse, Error>(
    [`delegateVotingPowerByAddresses:${addresses.toString()}`],
    () => {
      const normalizedAddress = addresses.map(getAddress);
      return apiClient.getDelegatedVotingPowerByAddresses({ addresses: normalizedAddress });
    },
    {
      enabled: addresses.filter((address) => address !== "").length > 0,
      suspense,
    },
  );
  return { protocols: delegateVotingPower?.data.map((data) => data.protocol) };
};

export const useDelegatesByAddresses = ({ addresses, protocol }: UseDelegateProtocolsByAddressesProps) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetDelegatedVotingPowerResponse, Error>(
    [`delegateVotingPowerByAddresses:${addresses.toString()}:${protocol}`],
    ({ pageParam: cursor }) => {
      const normalizedAddress = addresses.map(getAddress);
      return apiClient.getDelegatedVotingPowerByAddresses({ addresses: normalizedAddress, limit: 1000, ...(cursor ? {cursor} : {}), protocol  } as any);
    },
    {
      enabled: addresses.filter((address) => address !== "").length > 0,
      getNextPageParam: (params) => params?.nextCursor,
    },
  );
  const delegates = useMemo(() => {
    const flatData = data?.pages.flat();

    if (flatData) {
      const nonEmptyData = flatData
        .filter((d) => d !== undefined)
        .map((d) => d.data)
        .flat();
      const sortSnapshotDataToBottom = nonEmptyData.sort((a, b) => {
        if (a.adapter === "snapshot" && b.adapter !== "snapshot") {
          return -1;
        }
        if (a.adapter !== "snapshot" && b.adapter === "snapshot") {
          return 1;
        }
        return 0;
      });
      return Array.from(new Map(sortSnapshotDataToBottom.map((item) => [item.address, item])).values());
    } else {
      return flatData;
    }
  }, [data]);

  return { delegates, fetchNextPage, hasNextPage };
};