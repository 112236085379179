import React, { useRef, useContext } from "react";
import Button from "antd/es/button";
import styled from "styled-components";
import { BottomSheet } from "react-spring-bottom-sheet";

import { COLORS } from "../../constants/colors";
import media from "../../media-query";
import { ProjectsIcon, OnchainIcon, SnapshotIcon } from "../icons";
import { useMobileFilters } from "../../reducers/ShowMobileFilter";
import { DelegateFilterContext, DelegateFrameworkFilterContext } from "../../reducers/DelegateFilter";
import { DelegateFilterParams } from "../../types";

const StyledCloseProfileTrigger = styled.div`
  text-align: right;
  color: white;
  padding-right: 40px;
  height: 528px;
  line-height: 80px;
  background: rgba(25, 21, 64, 0.01);
  ${media.greaterThan("640px")`
  display: none;
  `}
`;

const StyledMobileFilter = styled.div`
  height: fit-content;
  width: 100%;
  position: fixed;
  background: white;
  z-index: +200;
  bottom: 0;
  border-radius: 8px 8px 0 0;

  //   box-shadow: 0px -2px 4px rgba(25, 21, 64, 0.15);
  padding: 0px 16px 28px;
  ${media.greaterThan("640px")`
  display: none;
  `}
`;

const StyledButton = styled(Button)`
  border-radius: 8px;
  background: ${COLORS.primary.accent};
  width: 100%;
  color: #fff;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  height: 40px;
  margin-top: 24px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 24px 0 20px;
`;

const FilterText = styled.div`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  color: ${COLORS.primary.grayDark};
`;

const HeadingText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 28px;
  color: ${COLORS.primary.grayDark};
  text-transform: uppercase;
  margin-bottom: 12px;
`;

const RadioContainer = styled.div`
  display: flex;
  width: inherit;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const Radio = styled.input`
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  margin: 0;
  width: 20px;
  height: 20px;
  border: 3px solid ${COLORS.primary.accent};
  border-radius: 50%;
  display: grid;
  place-content: center;
  &:before {
    content: "";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    transform: scale(0);
    transition: transform 120ms ease-in-out;
    box-shadow: inset 1em 1em ${COLORS.primary.accent};
  }
  &:checked:before {
    transform: scale(1);
  }
`;

const StyledLabel = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  display: flex;
`;

//TBD create a separate component for radio according to the design specs.
const sortParams = [
  { name: "Total Votes Cast", value: "votePower" },
  { name: "Vote Power", value: "votesCast" },
  { name: "No. of Proposals Made", value: "proposalsMade" },
  { name: "Addresses Delegating", value: "delegatorCount" },
];

const MobileFilter = () => {
  const { showFilters, setShowFilters } = useMobileFilters();
  const { filter, setFilter } = useContext(DelegateFilterContext);
  const { framework, setFrameworkFilter } = useContext(DelegateFrameworkFilterContext);

  const sheetRef = useRef<HTMLDivElement>(null);
  const closeOverlay = () => {
    setShowFilters(false);
  };
  return (
    <>
      <BottomSheet open={showFilters} onDismiss={closeOverlay} snapPoints={({ minHeight }) => [minHeight]}>
        <StyledCloseProfileTrigger onClick={closeOverlay}></StyledCloseProfileTrigger>
        <StyledMobileFilter ref={sheetRef}>
          <Header>
            <FilterText>Filters</FilterText>
          </Header>
          <HeadingText>Sort by</HeadingText>
          {sortParams.map((item, index) => (
            <RadioContainer key={index}>
              <StyledLabel>{item.name}</StyledLabel>
              <Radio
                type="radio"
                value={filter}
                onChange={() => setFilter(item.value as DelegateFilterParams)}
                checked={filter === item.value}
              />
            </RadioContainer>
          ))}
          <HeadingText>Frameworks</HeadingText>
          <RadioContainer>
            <StyledLabel>
              <ProjectsIcon
                color={COLORS.primary.grayDarkLightest}
                height={14}
                width={14}
                style={{ margin: "auto 12px auto 0" }}
              />
              All
            </StyledLabel>
            <Radio
              type="radio"
              value="allFrameworks"
              onChange={() => setFrameworkFilter("allFrameworks")}
              checked={framework === "allFrameworks"}
            />
          </RadioContainer>
          <RadioContainer>
            <StyledLabel>
              <SnapshotIcon
                color={COLORS.primary.grayDarkLightest}
                height={20}
                width={20}
                style={{ margin: "auto 4px auto 0" }}
              />
              Snapshot
            </StyledLabel>
            <Radio
              type="radio"
              value="snapshot"
              onChange={() => setFrameworkFilter("snapshot")}
              checked={framework === "snapshot"}
            />
          </RadioContainer>
          <RadioContainer>
            <StyledLabel>
              <OnchainIcon
                color={COLORS.secondary.blue}
                height={16}
                width={14}
                style={{ margin: "auto 12px auto 0" }}
              />
              Onchain
            </StyledLabel>
            <Radio
              type="radio"
              value="onchain"
              onChange={() => setFrameworkFilter("onchain")}
              checked={framework === "onchain"}
            />
          </RadioContainer>
          <StyledButton onClick={closeOverlay}>Apply</StyledButton>
        </StyledMobileFilter>
      </BottomSheet>
    </>
  );
};
export default MobileFilter;
