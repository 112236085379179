import React, { Suspense, useCallback, useContext, useMemo, useRef } from "react";
import styled, { css } from "styled-components";
import { Link, useLocation, useParams } from "react-router-dom";
import { TwitterShareButton as TwitterShare } from "react-share";
import ContentLoader from "react-content-loader";
import { useWindowScroll } from "react-use";

import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { SidebarContext } from "./SidebarContext";
import {
  ArrowLeftIcon,
  ArrowLeftThinIcon,
  ExternalLinkIcon,
  CommunicationBoldIcon,
  SquareShareIcon,
  ArrowIcon,
} from "../icons";
import { useMixpanel } from "../../hooks";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { getProtocolCnameFromPath } from "../../constants/protocols";
import TwitterShareButton from "../common/TwitterShareButton";
import { useGetResourceFolders } from "../../hooks/useGetResourceFolders";
import { useProtocol } from "../../hooks/useProtocol";
import { useCurrentWidth } from "react-socks";
import formatValue from "../../utils/formatValue";
import { useDelegatesByProtocol } from "../../hooks/useDelegatesByProtocol";
import { useGetProposalBriefs } from "../../hooks/useGetProposalBriefs";
import ProtocolLocalNav from "./ProtocolLocalNav";
import { SearchDropdownContext } from "../../reducers/ProjectSearchDropdown";
import { useGetCnameFromUrl } from "../../hooks/useGetCnameFromUrl";
import { useProposal } from "../../hooks/useProposal";
import { useGetProfileTagsByProtocolAndTag } from "../../hooks/useProfileTags";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

interface Params {
  protocol: string;
  proposalId: string;
}

const StyledTab = styled(Link)<{ $active?: boolean }>`
  margin-right: 20px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : "rgba(25, 21, 64, 0.4);")};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  display: inline-block;
  min-width: auto;
  line-height: 28px;
  ${media.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.grayDark};
        content: "";
        ${media.lessThan("640px")`
          height: 16px;
          border-bottom: 4px solid ${COLORS.primary.accent};
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

const StyledTabNoLink = styled("span")<{ $active?: boolean }>`
  margin-right: 20px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : "rgba(25, 21, 64, 0.4);")};
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  ${media.lessThan("991px")`
    line-height: 30px;
    font-size: 20px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.grayDark};
        content: "";
        ${media.lessThan("640px")`
        height: 16px;
        border-bottom: 4px solid ${COLORS.primary.accent};
    `}
      `}
  }
`;

const TabsWrapper = styled.div<{ $addPaddingBottom?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${({ $addPaddingBottom }) => $addPaddingBottom && "padding-bottom: 16px;"}
  ${media.lessThan("991px")`
      height: 42px;
      left: 0px;
      top: 8px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
  ${media.lessThan("640px")`
      width: 100%;
      background: #fafafa;
      z-index: +2;
      padding-left: 2px;
      height: 56px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      display: inline-block;
      padding-top: 16px;
      white-space: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    `}
`;

const StyledProtocolOptions = styled.div`
  position: absolute;
  right: 8px;
  display: flex;
  ${media.lessThan("640px")`
      display: none;
    `}
`;

const StyledMobileProtocolOptions = styled.div`
  position: absolute;
  right: 4px;
  z-index: 7;
  display: flex;
  margin-top: 22px;
  ${media.greaterThan("640px")`
  display: none;
  `}
  ${media.lessThan("640px")`
  background: #fafafa;
  margin-top: 18px;
  padding: 0 4px;
  `}
`;

const StyledArrowLeftIcon = styled(ArrowLeftThinIcon)`
  transition: transform 0.3s ease-out;
  width: 20px;
  height: 20px;
`;

const StyledBackToProposals = styled.div`
  display: flex;
  cursor: pointer;
  align-items: center;
  a {
    height: 24px;
    display: flex;
  }
  span {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: ${COLORS.primary.accent};
    display: inline-block;
    margin-left: 8px;
    ${media.lessThan("640px")`
       font-size: 14px;
       margin-left: 8px;
    `}
  }
  ${media.lessThan("640px")`
       width: 100vw;
    `}
  &:hover {
    ${StyledArrowLeftIcon} {
      transform: translateX(-4px);
      color: ${COLORS.primary.accent};
    }
  }
`;

const ShareButton = styled(TwitterShare)`
  span {
    vertical-align: text-top;
    display: inline-block;
    font-size: 12px;
  }
  svg {
    vertical-align: text-bottom;
    margin-right: 20px;
    ${media.lessThan("640px")`
         margin-right: 2px;
    `}
  }
`;

const StyledSpan = styled.span<{ $active: boolean }>`
  font-weight: 200;
  color: inherit;
`;

const DiscussionsLinkWrapper = styled.a`
  margin-right: 56px;
  display: flex;
  align-items: center;
  color: #7b7893;
  :hover {
    color: #4235e1;
  }
`;

const StyledDiscourseText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.primary.accent};
`;

const StyledCommunicationBoldIcon = styled(CommunicationBoldIcon)<{ $marginRight?: string; $marginTop?: string }>`
  margin-right: ${({ $marginRight }) => $marginRight || "12px"};
  margin-top: ${({ $marginTop }) => $marginTop || "0"};
`;

const StyledDiv = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const ProtocolName = styled.h1`
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  color: #191540;
  padding-bottom: 18px;
  width: 100%;
  border-bottom: 1px solid #f0eff8;
  margin-bottom: 12px;
  cursor: pointer;
  ${media.lessThan("991px")`
    margin-bottom: 0;
  `}
`;

const MobileHeader = styled.div<{ $top: string }>`
  display: none;
  ${({ $top }) =>
    $top &&
    css`
      ${media.lessThan("991px")`
  top: ${$top};
  left: 0;
  padding-left: 16px;
  padding-top: 12px;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 14;
  background: #fafafa;
  width: 100%;
  `}
    `}
`;

interface Props {
  hideShareButton?: boolean;
}

const tabArray = [{ name: "Proposals", activeSection: "proposals", showSpan: true, spanValue: "totalProposals" }];

const ProtocolInnerTabNav = ({ hideShareButton = false }: Props) => {
  const { activeSection } = useContext(SidebarContext);
  const { protocol, proposalId: refId } = useParams<Params>();
  const { trackShareProtocolsListPageOnTwitter } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);
  const cname = useGetCnameFromUrl();
  const protocolInfo = useProtocol({ protocol: cname, suspense: false });
  const { y } = useWindowScroll();

  const { delegates } = useDelegatesByProtocol({
    protocol: protocolInfo?.cname || "",
    limit: 24,
    suspense: false,
  });
  const { proposal } = useProposal({ refId, suspense: false });
  const { pathname, search } = useLocation();
  const resourceFolders = useGetResourceFolders({ protocol: protocolInfo?.cname });
  const isMobile = useCurrentWidth() <= 991;
  const tabWrapper = useRef<HTMLDivElement | null>(null);
  const { dispatchSearchDropDownState, searchDropDownState } = useContext(SearchDropdownContext);
  const { profileTags } = useGetProfileTagsByProtocolAndTag({
    protocol: protocolInfo?.cname || "",
    tag: "service-provider",
  });
  const hasServiceProviders = (profileTags?.length || 0) > 0;

  const humanReadableActiveSection: {
    [key: string]: string;
  } = useMemo(() => {
    return {
      voters: "voters",
      contributors: "contributors",
      proposals: "proposals",
      overview: "overview",
      proposal: "this proposal",
      reports: resourceFolders?.[0]?.toLowerCase() || "overview",
      meetings: "meetings",
      discussions: "discussions",
      delegates: "delegates",
      insights: "charts",
    };
  }, [resourceFolders]);

  const fromIndex = tabArray.map((object) => object.activeSection).indexOf(activeSection as string);
  const element = tabArray.splice(fromIndex, 1)[0];
  tabArray.splice(0, 0, element);

  // hide these folders
  const filteredResourceFolders = useMemo(() => {
    const foldersToIgnore = ["Proposal Memos", "Governance Reports", "Overview", "Meetings"];
    if (resourceFolders && resourceFolders.length) {
      return resourceFolders.filter((folder) => !foldersToIgnore.includes(folder));
    }
  }, [resourceFolders]);

  const proposalBriefsData = useGetProposalBriefs(protocolInfo?.path, false);
  const hasProposalBriefs = useMemo(() => {
    return !!proposalBriefsData && !!proposalBriefsData.items && !!proposalBriefsData.items.length;
  }, [proposalBriefsData]);

  const toggleSearchDropdownState = useCallback(() => {
    dispatchSearchDropDownState({ type: "TOGGLE_STATE", data: !searchDropDownState });
  }, [dispatchSearchDropDownState, searchDropDownState]);

  return (
    <>
      <MobileHeader $top={y > 76 ? "0px" : `${76 - y}px`}>
        {!standAloneProtocolCname && (
          <ProtocolName onClick={toggleSearchDropdownState}>
            {protocolInfo?.name} <ArrowIcon width={16} height={16} color="#4235e1" />
          </ProtocolName>
        )}
        <ProtocolLocalNav />
      </MobileHeader>
      <div style={{ marginTop: isMobile ? "96px" : "" }} />
      <>
        <StyledMobileProtocolOptions>
          {activeSection === "discussions" &&
            pathname.split("/")?.length === 3 &&
            !!protocolInfo?.discourseForum?.url && (
              <a href={protocolInfo?.discourseForum?.url} rel="noopener noreferrer" target="_blank">
                <StyledCommunicationBoldIcon
                  $marginTop="2px"
                  $marginRight="24px"
                  width={24}
                  height={24}
                  color={COLORS.primary.accent}
                />
              </a>
            )}
          {!hideShareButton && (
            <ShareButton
              url={window.location.href}
              via={"boardroom_info"}
              onClick={() => {
                trackShareProtocolsListPageOnTwitter({
                  protocol: protocolInfo?.cname || "",
                  userId: `${account}`,
                });
              }}
              title={`Checkout ${humanReadableActiveSection[activeSection || "overview"]} of ${protocol}`}
              hashtags={[protocol || ""]}
            >
              <SquareShareIcon width={20} height={20} color={COLORS.primary.grayDarkLightest} />
            </ShareButton>
          )}
        </StyledMobileProtocolOptions>
        <TabsWrapper
          $addPaddingBottom={
            (activeSection === "proposals" && pathname.split("/").length === 4) ||
            (activeSection === "discussions" && pathname.split("/").length === 4) ||
            (activeSection?.includes("reports") && pathname.split("/").length === 5) ||
            (activeSection === "reports" && pathname.split("/").length === 5) ||
            (activeSection === "meetings" && pathname.split("/").length === 4)
          }
        >
          {!isMobile && activeSection === "proposals" && pathname.split("/").length === 3 && (
            <StyledDiv>
              <StyledTab to={`/${protocol}/proposals`} $active={activeSection === "proposals"}>
                Proposals&nbsp;
                <StyledSpan $active={activeSection === "proposals"}>{protocolInfo?.totalProposals}</StyledSpan>
              </StyledTab>
            </StyledDiv>
          )}
          {(activeSection === "delegates" || activeSection === "voters" || activeSection === "contributors") &&
            pathname.split("/").length === 3 && (
              <StyledDiv style={{ overflowX: "auto" }}>
                {!!delegates?.length && (
                  <StyledTab to={`/${protocol}/delegates`} $active={activeSection === "delegates"}>
                    Delegates&nbsp;
                  </StyledTab>
                )}
                <StyledTab to={`/${protocol}/voters`} $active={activeSection === "voters"}>
                  Voters&nbsp;
                  <StyledSpan $active={activeSection === "voters"}>
                    {formatValue(protocolInfo?.uniqueVoters, 1)}
                  </StyledSpan>
                </StyledTab>
                {hasServiceProviders && (
                  <StyledTab to={`/${protocol}/contributors`} $active={activeSection === "contributors"}>
                    Contributors&nbsp;
                  </StyledTab>
                )}
              </StyledDiv>
            )}
          {isMobile && activeSection === "proposals" && pathname.split("/").length === 3 && (
            <StyledDiv ref={tabWrapper}>
              {tabArray.map((tab) => (
                <StyledTab
                  to={`/${protocol}/${tab.activeSection}`}
                  $active={activeSection === tab.activeSection}
                  key={tab.name}
                >
                  {tab.name}&nbsp;
                  {tab.showSpan && (
                    <StyledSpan $active={activeSection === tab?.activeSection}>
                      {formatValue((protocolInfo as any)?.[tab?.spanValue], 1)}
                    </StyledSpan>
                  )}
                </StyledTab>
              ))}
            </StyledDiv>
          )}

          {activeSection === "proposals" && pathname.split("/").length === 4 && (
            <Link to={`/${protocol}/proposals`}>
              <StyledBackToProposals>
                <StyledArrowLeftIcon />
                <span>Back</span>
              </StyledBackToProposals>
            </Link>
          )}
          {activeSection === "insights" && (
            <div>
              <StyledTab
                to={`/${protocol}/insights`}
                $active={activeSection === "insights" && pathname.split("/").length === 3}
              >
                Power
              </StyledTab>
              {cname !== "lido" && (
                <StyledTab
                  to={`/${protocol}/insights/participation`}
                  $active={activeSection === "insights" && pathname.split("/").length === 4}
                >
                  Participation
                </StyledTab>
              )}
            </div>
          )}
          {(activeSection === "overview" || activeSection === "calendar") && (
            <div>
              <StyledTab to={`/${protocol}/overview`} $active={activeSection === "overview"}>
                About
              </StyledTab>
              <StyledTab to={`/${protocol}/calendar`} $active={activeSection === "calendar"}>
                Calendar&nbsp;
              </StyledTab>
            </div>
          )}
          {((activeSection === "reports" && pathname.split("/").length !== 5) ||
            (activeSection?.includes("reports") &&
              pathname.split("/")[pathname.split("/").length - 2] === "reports")) && (
            <div>
              {filteredResourceFolders?.map((folder, i) => (
                <StyledTab
                  key={i}
                  to={`/${protocol}/reports/${folder}`}
                  $active={pathname.split("/")[pathname.split("/").length - 1].replace(/%20/g, " ") === folder}
                >
                  {folder}
                </StyledTab>
              ))}
              {hasProposalBriefs && (
                <StyledTab to={`/${protocol}/reports/briefs`} $active={pathname.split("/").includes("briefs")}>
                  Proposal Briefs
                </StyledTab>
              )}
            </div>
          )}

          {activeSection === "meetings" && pathname.split("/").length !== 4 && (
            <StyledTab
              to={`/${protocol}/meetings`}
              $active={pathname.split("/")[pathname.split("/").length - 1] === "meetings"}
            >
              Meetings
            </StyledTab>
          )}

          {activeSection === "discussions" && pathname.split("/").length === 3 ? (
            isMobile ? (
              <StyledTabNoLink $active={activeSection === "discussions"}>Discussions</StyledTabNoLink>
            ) : (
              <div>
                <StyledTab
                  to={`/${protocol}/discussions`}
                  $active={activeSection === "discussions" && pathname.split("/").length === 3}
                >
                  Discussions
                </StyledTab>
                {!!protocolInfo?.discourseForum?.url && (
                  <a
                    href={
                      pathname.split("/")?.length === 4
                        ? `${protocolInfo?.discourseForum?.url}/t/${pathname.split("/")[3]}`
                        : protocolInfo?.discourseForum?.url
                    }
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <ExternalLinkIcon width={16} height={16} />
                  </a>
                )}
              </div>
            )
          ) : null}
          {activeSection === "discussions" && pathname.split("/").length === 4 && (
            <Link to={`/${protocol}/discussions`}>
              <StyledBackToProposals>
                <ArrowLeftIcon height={20} width={20} />
                <span>Back to Discussions</span>
              </StyledBackToProposals>
            </Link>
          )}
          {activeSection === "proposal" && (
            <>
              <StyledDiv>
                <StyledTab
                  to={{
                    search: search.replace(/&?executables=true/g, ""),
                  }}
                  $active={activeSection === "proposal" && !search.includes("executables=true")}
                >
                  Proposal
                </StyledTab>
                {!!proposal?.executables && Array.isArray(proposal?.executables) && !!proposal?.executables?.length && (
                  <StyledTab
                    to={{
                      search: search.includes("executables=true")
                        ? search
                        : `${search}${search.includes("?") ? "&" : "?"}executables=true`,
                    }}
                    $active={activeSection === "proposal" && search.includes("executables=true")}
                  >
                    Executable Code
                  </StyledTab>
                )}
              </StyledDiv>
            </>
          )}

          {activeSection?.includes("reports") && !pathname?.includes("briefs") && pathname.split("/").length === 5 && (
            <Link to={`/${protocol}/reports/${pathname.split("/")[pathname.split("/").length - 2]}`}>
              <StyledBackToProposals>
                <StyledArrowLeftIcon />
                <span>Back {`to ${pathname.split("/")[pathname.split("/").length - 2].replace(/%20/g, " ")}`}</span>
              </StyledBackToProposals>
            </Link>
          )}

          {activeSection === "reports" && pathname?.includes("briefs") && pathname.split("/").length === 5 && (
            <Link to={`/${protocol}/reports/briefs`}>
              <StyledBackToProposals>
                <StyledArrowLeftIcon />
                <span>Back to Proposal Briefs</span>
              </StyledBackToProposals>
            </Link>
          )}

          {activeSection === "meetings" && pathname.split("/").length === 4 && (
            <Link to={`/${protocol}/meetings`}>
              <StyledBackToProposals>
                <StyledArrowLeftIcon />
                <span>Back to Meetings</span>
              </StyledBackToProposals>
            </Link>
          )}

          {!hideShareButton && !(activeSection === "proposal") && (
            <StyledProtocolOptions>
              {activeSection === "discussions" && !!protocolInfo?.discourseForum?.url && (
                <DiscussionsLinkWrapper
                  href={
                    pathname.split("/")?.length === 4
                      ? `${protocolInfo?.discourseForum?.url}/t/${pathname.split("/")[3]}`
                      : protocolInfo?.discourseForum?.url
                  }
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <StyledCommunicationBoldIcon width={20} height={20} color={COLORS.primary.accent} />
                  <StyledDiscourseText>Discourse</StyledDiscourseText>
                </DiscussionsLinkWrapper>
              )}

              <TwitterShareButton
                type="minimal"
                removeDefaultColor
                title={`Checkout ${humanReadableActiveSection[activeSection || "overview"]} of ${protocol}`}
                hashtags={[protocol || ""]}
                clickCallBack={() => {
                  trackShareProtocolsListPageOnTwitter({
                    protocol: protocolInfo?.cname || "",
                    userId: `${account}`,
                  });
                }}
              />
            </StyledProtocolOptions>
          )}
        </TabsWrapper>
        {activeSection === "proposal" && !hideShareButton && (
          <TabsWrapper style={{ borderBottom: 0, marginBottom: 0, marginTop: "-12px" }}>
            <Link to={`/${protocol}/proposals`}>
              <StyledBackToProposals>
                <StyledArrowLeftIcon />
                <span>Back to Proposals</span>
              </StyledBackToProposals>
            </Link>
            <StyledProtocolOptions>
              <TwitterShareButton
                type="minimal"
                removeDefaultColor
                title={`Checkout ${humanReadableActiveSection[activeSection || "overview"]} of ${protocol}`}
                hashtags={[protocol || ""]}
                clickCallBack={() => {
                  trackShareProtocolsListPageOnTwitter({
                    protocol: protocolInfo?.cname || "",
                    userId: `${account}`,
                  });
                }}
              />
            </StyledProtocolOptions>
          </TabsWrapper>
        )}
      </>
    </>
  );
};

export default React.memo((props: Props) => {
  const { protocol: protocolRoute } = useParams<Params>();
  const query = new URLSearchParams(useLocation().search);
  const protocolQuery = query.get("protocol") || "";

  const activeProtocol = protocolRoute || protocolQuery || null;
  const location = useLocation();
  const sectionName = useMemo(() => location.pathname.split("/")[2], [location]);
  const protocolName = activeProtocol ? getProtocolCnameFromPath(activeProtocol) : undefined;
  return (
    <Suspense
      fallback={
        <ContentLoader
          speed={2}
          width={"100%"}
          height={40}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
          style={{ background: "white", borderRadius: "8px" }}
        >
          <rect x="0" y="0" rx="5" ry="5" width="203" height="18" />
          <rect x="0" y="28" rx="5" ry="5" width="100%" height="2" />
        </ContentLoader>
      }
    >
      <SidebarContext.Provider value={{ activeProtocol: protocolName, activeSection: sectionName }}>
        <ProtocolInnerTabNav {...props} />
      </SidebarContext.Provider>
    </Suspense>
  );
});
