import { Adapters, GovernanceSDK, NetworkTransportResolver } from "@boardroom/gov-sdk";
import { JsonRpcProvider } from "@ethersproject/providers";

import { protocolInfoList } from "@boardroom/protocol-info";

import { ProtocolDescription } from "../types";
import { exportedProtocols } from "../utils/sdkProtocolImport";

const providerUrl = process.env.REACT_APP_RPC_URL_1;
const providerUrl10 = process.env.REACT_APP_RPC_URL_10;
const providerUrl137 = process.env.REACT_APP_RPC_URL_137;
const providerUrl1284 = process.env.REACT_APP_RPC_URL_1284;
const providerUrl1285 = process.env.REACT_APP_RPC_URL_1285;
const providerUrl42161 = process.env.REACT_APP_RPC_URL_42161;
const providerUrl43114 = process.env.REACT_APP_RPC_URL_43114;
const providerUrl11155420 = process.env.REACT_APP_RPC_URL_11155420;

export const sdk = new GovernanceSDK({
	transports: {
		rpc: new NetworkTransportResolver({
			1: new JsonRpcProvider(providerUrl),
			10: new JsonRpcProvider(providerUrl10),
      137: new JsonRpcProvider(providerUrl137),
			1284: new JsonRpcProvider(providerUrl1284),
			1285: new JsonRpcProvider(providerUrl1285),
			42161: new JsonRpcProvider(providerUrl42161),
      43114: new JsonRpcProvider(providerUrl43114),
      11155420: new JsonRpcProvider(providerUrl11155420),
		}),
	},
	snapshotApiKey: process.env.REACT_APP_SNAPSHOT_API_KEY,
	protocols: exportedProtocols,
});

const sdkProtocolRegistry = sdk.getAllProtocols();

export const protocolList = protocolInfoList
  .filter((protocolInfo) => sdkProtocolRegistry.find((p) => p.cname === protocolInfo.cname))
  .map((protocolInfo) => {
  const protocol = sdkProtocolRegistry.find((p) => p.cname === protocolInfo.cname);

  return Object.assign(protocol || {}, protocolInfo);
});

export const partnerProtocols = ["aave", "optimism", "moonwell", "lido", "compound", "uniswap", "0xgov", "infinex", "synthetix", "m0", 'radicle', 'radworks', 'ampleforth', 'autonolas'];

export const insightsProtocols = ["moonwell", "optimism", "lido"];

export const pinnedProtocols = ["shapeshift", "ens", "sushi", "indexCoop", "banklessvault", "compound", "uniswap", "aave", "optimism", "synthetix", "mstable", "dhedge"];

export const protocols: Record<string, ProtocolDescription> = protocolList.reduce((protocols, protocol: typeof protocolList[0]) => ({
  ...protocols,
  [protocol.cname]: Object.assign(protocol, {
    order: pinnedProtocols.indexOf(protocol.cname) === -1 && partnerProtocols.indexOf(protocol.cname) === -1 ? Infinity : partnerProtocols.indexOf(protocol.cname) === -1 ? pinnedProtocols.indexOf(protocol.cname) + partnerProtocols.length : partnerProtocols.indexOf(protocol.cname),
  }),
}), {});

export const getProtocolCnameFromPath = (path: string) => {
  return protocolList.find((protocol) => protocol.path === path)?.cname || "";
};

export const getProtocolPath = (protocol: string) => {
  return protocolList.find(({ cname }) => cname === protocol)?.path || "";
};

export const aaveAdapterProtocols = ["aave", "dydx"];

export const getProtocolAdapterInstances = ({adapter, protocol}:{adapter: keyof Adapters, protocol: string}) => {
  try {
    const protocolInSdk = sdk?.getProtocol(protocol);
    const adapterInstances = protocolInSdk?.adapterInstances(adapter);
    return adapterInstances;
  } catch (e) {
    console.error(e);
  }
  };

export const frameworkIntanceMapping: Record<string, Record<string, string>> = {
  "0xgov-test": {
    "onchain": "Protocol Update Vote",
    "onchain-secondary": "Treasury Vote",
  },
  "0xgov": {
    "onchain": "Protocol Update Vote",
    "onchain-secondary": "Treasury Vote",
  },
  "m0": {
    "onchain": "Standard Governor",
    "onchain-secondary": "Zero Governor",
    "onchain-tertiary": "Emergency Governor",
  }
};

export const getProtocolInstanceName = (protocol: string, instance: string) => {
  return frameworkIntanceMapping[protocol]?.[instance] || instance;
};

export const protocolTokensLabels: Record<string, {
  token: string;
  stakedToken: string;
}> = {
  "0xgov-test": {
    "token": "ZRX",
    "stakedToken": "wZRX",
  },
  "0xgov": {
    "token": "ZRX",
    "stakedToken": "wZRX",
  }
};
export const protocolOnchainInstances: Record<string, string[]> = {};

const getInstances = () => {
  protocolList.map(async (protocol) => {
    const protocolInSdk = sdk?.getProtocol(protocol.cname || "");
    const adapterFrameworks = protocolInSdk?.adapterInstances("proposals");
    if (adapterFrameworks && protocolInSdk) {
      const promises = adapterFrameworks.map(async (frameworkName: string) => {
        const instance = await protocolInSdk.adapter("proposals", frameworkName).getFramework();
        if (instance !== "snapshot") {
          return frameworkName;
        }
      });

      const resolvedFrameworks = await Promise.all(promises);
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
      const filteredFrameworks = resolvedFrameworks.filter(
        (framework?: string) => framework !== undefined,
      ) as string[];
      protocolOnchainInstances[protocol.cname] = filteredFrameworks;
    }
  });
};

getInstances();


export const getOnchainProposalVoteAdapterName = (protocol: string, instance: string) => {
  if(frameworkIntanceMapping[protocol]) {
    return frameworkIntanceMapping[protocol]?.[instance] || "Onchain";
  }
  return "Onchain";
};


export const getCreateProposalLabels = (protocol: string, label: string, name: string) => {
  const protocolsToMapLabels = ["0xgov-test", "0xgov"]; 
  if (!protocolsToMapLabels.includes(protocol)) {
    return label;
  }
  if (label === "to") {
    return "receiving address";
  }
  if (label === "amount" && name === "uint256") {
    return "amount (Wei)";
  }
  return label;
};
