import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

export const NavLinkContent = styled(Link)<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  padding: 14px 20px;
  text-align: center;
  cursor: pointer;

  h3 {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: #7b7893;
    margin-bottom: 0;
  }
  :hover {
    h3 {
      font-weight: 600;
      color: #4235e1;
    }
  }
  &:active,
  focus {
    h3 {
      font-weight: 600;
      color: #291fa7;
    }
  }
  ${({ $isSelected }) =>
		$isSelected &&
		css`
      h3 {
        color: #191540 !important;
      }
    `}
  span {
    flex-shrink: 0;
  }
`;

export const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: #4235e1;
  border-radius: 50%;
  margin-left: -4px;
  top: 18px;
  position: absolute;
`;

export const WrapperNavLink = styled.div`
  position: relative;
`;

export const LinksWrapper = styled.div<{ $isMainNavCollapsed: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: fixed;
  top: 65px;
  height: 100%;
  background: #f6f6f6;
  left: ${({ $isMainNavCollapsed }) => ($isMainNavCollapsed ? "80px" : "186px")};
  ${standAloneProtocolCname && "left: 0px"};
`;

export const CreateProposalButton = styled(Link)`
  background: #191540;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: white;
  text-align: center;
  padding: 12px 0;
  width: 180px;
  border: none;
  margin 0 auto 0;
  cursor: pointer;
  display: block;
  :hover {
    color: white;
  }
`;

export const CreateProposalButtonWrapper = styled.div`
  position: sticky;
  bottom: 0;
  padding: 0 18px 24px;
  margin-top: auto;
  margin-bottom: 80px;
`;

export const ProtocolIconDiv = styled.div<{ $url: string }>`
  width: 216px;
  height: 88px;
  z-index: 10;
  background-color: #f6f6f6;
  background-size: contain;
  background-position: center;
  background-image: linear-gradient(90.16deg, rgba(25, 21, 64, 0.2) 0.22%, rgba(25, 21, 64, 0) 99.94%),
    url(${({ $url }) => $url});
`;

export const ProtocolBackButton = styled.div`
  background: #ffffff;
  border: 1px solid #4235e1;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 32px;
  height: 32px;
  color: #4235e1;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 24px 16px;
  cursor: pointer;
  :hover {
    background: #4235e1;
    color: white;
  }
  :active,
  :focus {
    background: #291fa7;
    color: white;
  }
`;
