import React, { Suspense, useCallback, useMemo, useRef, useState } from "react";
import ContentLoader from "react-content-loader";
import moment from "moment";
import styled from "styled-components";

import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { COLORS } from "../../constants/colors";
import { ThinCrossIcon, ArrowIcon, ThinChevronUpIcon } from "../icons";
import { protocols } from "../../constants/protocols";
import ProtocolIcon from "../ProtocolIcon";
import { getColorIndexFromString, isDateToday } from "../Calendar/utils";
import {
  BottomNavigationItemWrapper,
  CloseSplitViewWrapper,
  Event,
  EventColoredMarker,
  EventDate,
  EventDateValue,
  EventHour,
  EventMetaInfo,
  EventPadding,
  EventTitle,
  FlexDiv,
  NextProposalHeaderWrapper,
  ProtocolPageLink,
  ProtocolPageLinksWrapper,
  SplitViewBottomNavigationWrapper,
  SplitViewHeaderNavigationWrapper,
  SplitViewHeaderWrapper,
  SplitViewProtocolDropdownToggleWrapper,
} from "../Calendar/ListView/styles";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { useDelegatesByProtocol } from "../../hooks/useDelegatesByProtocol";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { EventRowSuspense } from "../Calendar/ListView/EventRow";
import { NewsArticleTopicDrawer } from "./NewsAtricleDrawer";
import { GhostPost } from "../../hooks/useGetGhostPosts";

interface EventRowProps {
  event: GhostPost;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  lastItemBorderWidth?: number;
  isToday?: boolean;
  newsEvents?: GhostPost[];
}

export const SplitViewContent = ({
  newsArticles,
  currentArticle,
}: {
  newsArticles?: GhostPost[];
  currentArticle: GhostPost;
}) => {
  const protocolCname = currentArticle.protocolCname;
  const [isSplitViewHeaderExpanded, setIsSplitViewHeaderExpanded] = useState(false);
  const { currentRefId, setCurrentRefId } = useCurrentRefId();

  const { setCurrentSplitViewIndex, currentSplitViewIndex } = useCurrentSplitViewIndex();

  const currentTopic = useMemo(() => {
    return newsArticles?.find((topic) => topic.id === currentArticle.id);
  }, [newsArticles, currentArticle]);
  const { delegates } = useDelegatesByProtocol({
    protocol: protocolCname || "",
    limit: 24,
    suspense: false,
  });

  const handleClickNextProposal = useCallback(() => {
    if (newsArticles) {
      if (currentSplitViewIndex) {
        const nextProposal = newsArticles[currentSplitViewIndex + 1];
        setCurrentRefId(nextProposal?.id || "");
        setCurrentSplitViewIndex(currentSplitViewIndex + 1);
      } else {
        const currProposalIndex = newsArticles.findIndex((event) => event.id === currentRefId);
        const nextProposal = newsArticles[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.id || "");
        setCurrentSplitViewIndex(currProposalIndex + 1);
      }
    }
  }, [currentRefId, setCurrentRefId, newsArticles, currentSplitViewIndex, setCurrentSplitViewIndex]);

  const handleClickPreviousProposal = useCallback(() => {
    if (newsArticles) {
      if (currentSplitViewIndex) {
        const previousProposal = newsArticles[currentSplitViewIndex - 1];
        setCurrentRefId(previousProposal?.id || "");
        setCurrentSplitViewIndex(currentSplitViewIndex - 1);
      } else {
        const currProposalIndex = newsArticles.findIndex((event) => event.id === currentRefId);
        const previousProposal = newsArticles[currProposalIndex - 1];
        setCurrentRefId(previousProposal?.id || "");
        setCurrentSplitViewIndex(currProposalIndex - 1);
      }
    }
  }, [currentRefId, setCurrentRefId, newsArticles, setCurrentSplitViewIndex, currentSplitViewIndex]);

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const toggleSplitViewHeader = useCallback(() => {
    setIsSplitViewHeaderExpanded((curr) => !curr);
  }, []);

  return (
    <div>
      <SplitViewHeaderWrapper $isExpanded={isSplitViewHeaderExpanded}>
        <SplitViewHeaderNavigationWrapper>
          <NextProposalHeaderWrapper onClick={handleClickPreviousProposal} $type="previous">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
          <NextProposalHeaderWrapper onClick={handleClickNextProposal} $type="next">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
        </SplitViewHeaderNavigationWrapper>
        <div style={{ width: "100%" }}>
          <SplitViewProtocolDropdownToggleWrapper
            onClick={toggleSplitViewHeader}
            $isExpanded={isSplitViewHeaderExpanded}
          >
            <ProtocolIcon size="small" protocol={protocols[protocolCname]} />
            <span>{protocols[protocolCname]?.name}</span>
            <ArrowIcon width={14} height={14} color="#4235e1" />
          </SplitViewProtocolDropdownToggleWrapper>
        </div>
        <div style={{ width: "112px" }}>
          <CloseSplitViewWrapper onClick={handleClose}>
            <ThinCrossIcon />
          </CloseSplitViewWrapper>
        </div>
      </SplitViewHeaderWrapper>
      {isSplitViewHeaderExpanded && (
        <ProtocolPageLinksWrapper>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/proposals`}>
            Governance
          </ProtocolPageLink>
          {!!newsArticles?.length && (
            <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/discussions`}>
              Discussions
            </ProtocolPageLink>
          )}
          <ProtocolPageLink
            target="_blank"
            to={
              delegates?.length
                ? `/${protocols[protocolCname]?.path}/delegates`
                : `/${protocols[protocolCname]?.path}/voters`
            }
          >
            Members
          </ProtocolPageLink>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/overview`}>
            Overview
          </ProtocolPageLink>
        </ProtocolPageLinksWrapper>
      )}
      <div style={{ marginTop: isSplitViewHeaderExpanded ? "122px" : "64px" }} />
      <div style={{ marginTop: "24px" }} />
      <Suspense
        fallback={
          <div style={{ margin: "24px" }}>
            <ContentLoader
              speed={2}
              width="100%"
              height={402}
              backgroundColor={COLORS.primary.grayLight}
              foregroundColor={COLORS.primary.grayLighter}
            >
              <rect x="0" y="10" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="34" rx="5" ry="5" width="70%" height="12" />

              <rect x="0" y="75" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="99" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="123" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="147" rx="5" ry="5" width="20%" height="12" />

              <rect x="0" y="188" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="212" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="236" rx="5" ry="5" width="40%" height="12" />

              <rect x="0" y="277" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="301" rx="5" ry="5" width="25%" height="12" />

              <rect x="0" y="342" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="366" rx="5" ry="5" width="100%" height="12" />
              <rect x="0" y="390" rx="5" ry="5" width="70%" height="12" />
            </ContentLoader>
          </div>
        }
      >
        {currentTopic && <NewsArticleTopicDrawer topicData={currentTopic} handleClose={handleClose} />}
      </Suspense>
      <div style={{ marginTop: "96px" }} />
      <SplitViewBottomNavigationWrapper>
        <BottomNavigationItemWrapper $type="previous" onClick={handleClickPreviousProposal}>
          <ThinChevronUpIcon />
          <span>Previous Article</span>
        </BottomNavigationItemWrapper>
        <BottomNavigationItemWrapper onClick={handleClickNextProposal}>
          <ThinChevronUpIcon />
          <span>Next Article</span>
        </BottomNavigationItemWrapper>
      </SplitViewBottomNavigationWrapper>
    </div>
  );
};

export const StyledTagInfo = styled.div<{ $color?: string }>`
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ $color }) => ($color ? $color : "#7B7893")};
  display: inline-flex;
  margin-right: 8px;
  align-items: center;
`;

const EventRow = ({ event, isLastItem, isFirstItem, lastItemBorderWidth, newsEvents }: EventRowProps) => {
  const eventRef = useRef<HTMLDivElement | null>(null);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const { width } = useWindowDimensions();

  const eventIndex = newsEvents?.findIndex((eventInArray) => eventInArray.id === event.id);

  const handleClick = useCallback(() => {
    setCurrentRefId(event.id || "");
    setCurrentSplitViewIndex((eventIndex || 0) > -1 ? eventIndex : undefined);
  }, [setCurrentRefId, event.id, setCurrentSplitViewIndex, eventIndex]);

  const isActive = useMemo(() => currentRefId === event.id, [currentRefId, event.id]);

  return (
    <>
      <Event ref={eventRef} key={event.id} onClick={handleClick}>
        <EventColoredMarker
          $isTodayAndIsFirstItem={isDateToday(moment(event.published_at)) && isFirstItem}
          $isTodayAndIsLastItem={isDateToday(moment(event.published_at)) && isLastItem}
          $color={getColorIndexFromString(event.protocolCname)}
        />
        <EventPadding
          $isActive={isActive}
          $lastItem={isLastItem}
          $firstItem={isFirstItem}
          $lastItemBorderBottomWidth={lastItemBorderWidth}
        >
          <EventMetaInfo>
            <ProtocolIcon protocol={protocols[event.protocolCname]} />
            <EventDate>
              <EventHour>{moment(event.published_at).format("HH:mm")}</EventHour>
              <EventDateValue>{moment(event.published_at).format("MMM DD")?.toUpperCase()}</EventDateValue>
            </EventDate>
          </EventMetaInfo>
          <FlexDiv>
            <div className="opacityForArchiving">
              <EventTitle $width={width} $isActive={isActive}>
                {event.title}
              </EventTitle>
              <>
                {event.tags.map((tag) => (
                  <StyledTagInfo $color={getColorIndexFromString(tag.name)} key={tag.id}>
                    {tag.name}
                  </StyledTagInfo>
                ))}
              </>
            </div>
          </FlexDiv>
        </EventPadding>
      </Event>
    </>
  );
};

const SuspendedNewsEventRow = (props: EventRowProps) => {
  return (
    <Suspense fallback={<EventRowSuspense />}>
      <EventRow {...props} />
    </Suspense>
  );
};

export default SuspendedNewsEventRow;
