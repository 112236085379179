import React, { useContext } from "react";
import { useLocation } from "react-router";
import { Circle, LinksWrapper, NavLinkContent, WrapperNavLink } from "./styles";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useHasValidSubscription } from "../../hooks/useHasValidSubscription";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const NavLink = ({ name, path, isSelected }: { name: string; path: string; isSelected: boolean }) => {
  return (
    <WrapperNavLink>
      {isSelected && <Circle />}
      <NavLinkContent to={path} $isSelected={isSelected}>
        <h3>{name}</h3>
      </NavLinkContent>
    </WrapperNavLink>
  );
};

const SettingsNavHub = () => {
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const { isSidebarExpanded } = useIsSidebarExpanded();
  useHasValidSubscription(account);

  return (
    <>
      <LinksWrapper
        style={{ zIndex: "2", paddingTop: "28px", width: "216px" }}
        $isMainNavCollapsed={!isSidebarExpanded}
      >
        <NavLink
          path={"/settings/profile"}
          name="Profile"
          isSelected={pathname.endsWith("/settings/profile") || pathname.endsWith("/settings/delegate")}
        />
        {!!userDetails?.orgMemberships && (
          <NavLink
            name="Bundle"
            path={"/settings/bundle-profile"}
            isSelected={pathname.endsWith("/settings/bundle-profile") || pathname.endsWith("/settings/bundle-feed")}
          />
        )}
        {!standAloneProtocolCname && (
          <NavLink
            name="Preferences"
            path={"/settings/feed"}
            isSelected={pathname.endsWith("/settings/feed") || pathname.includes("notifications")}
          />
        )}
        <NavLink
          path={"/settings/email"}
          isSelected={
            pathname.includes("membership") ||
            pathname.includes("email") ||
            pathname.includes("api") ||
            pathname.includes("billing")
          }
          name="Account"
        />
      </LinksWrapper>
    </>
  );
};

export { SettingsNavHub };
