import Col from "antd/es/col";
import Layout from "antd/es/layout";
import Drawer from "antd/es/drawer";
import Row from "antd/es/row";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useClickAway } from "react-use";
import styled, { css } from "styled-components";

import media from "../../media-query";
import Wallet from "./Wallet";
import InstaSearch from "./InstaSearch";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { COLORS } from "../../constants/colors";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import mediaQuery from "../../media-query";
import {
  LongArrowLeftIcon,
  ThinCrossIcon,
  DropdownArrowDown,
  PaperOutlineIcon,
  CommentIcon,
  ThinSearchIcon,
  OutlinedDevelopersIcon,
} from "../icons";

import { NftGatedRoutes, RoutePaths } from "../../constants/Routes";
import { StyledTab } from "./NavLinks";
import ProtocolIcon from "../ProtocolIcon";
import { getProtocolCnameFromPath, protocols } from "../../constants/protocols";

import { useDelegatesFromAddress } from "../../hooks/useDelegatesFromAddress";
import { useDelegatesForProtocols } from "../../hooks/useDelegateForProtocols";
import { pageHeaderHeight } from "../../constants/styles";
import { CurrentRefIdContext } from "../../reducers/CurrentRefId";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import ProtocolNav from "./ProtocolNav";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";
import { useHasValidSubscription } from "../../hooks/useHasValidSubscription";
import { Modal } from "../Modal";
import MintNFT from "../../pages/MintNFT";
import { useClerkSignIn } from "../../hooks/useClerkSignIn";
import { useProxyAddress } from "../../hooks/useVoterVotes";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const { Header } = Layout;

const HeaderWrapper = styled.div`
  height: 64px;
  background: #fff;
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const DesktopGlobalNav = styled(Header)`
  height: 64px;
  overflow: visible;
  background: #fff;
  z-index: 10;
  padding: 0px;
  position: relative;

  ${media.lessThan("991px")`
    display: none;
  `}
`;

const StyledProtocolLogo = styled.img`
  width: 32px;
  height: 32px;
  transition: transform ease-in-out 0.3s;
  position: absolute;
  left: 0px;
  margin: 16px 0px 16px 16px;
  &:hover {
    transform: scale(1.1);
  }
`;

const StyledWalletWrapper = styled.div<{ $connected?: boolean }>`
  display: flex;
  position: ${({ $connected }) => ($connected ? "static" : "absolute")};
  top: ${({ $connected }) => ($connected ? "0px" : "13px")};
  right: ${({ $connected }) => ($connected ? "0px" : "30px")};
  z-index: 11;
`;

const StyledInstaSearchWrapper = styled("div")<{ $width: number }>`
  display: inline-block;
  width: calc(100% - 170px);
  input {
    margin-top: 12px;
    font-size: 16px;
    padding-left: 24px;
  }
`;

const PortalNavDivider = styled.div`
  height: 2px;
  width: 100%;
  z-index: 2;
  position: relative;
`;

export const StyledDrawer = styled(Drawer)`
  .ant-drawer-content {
    background: #fafafa;
  }
  .ant-drawer-mask {
    opacity: 0.3 !important;
  }
  .ant-drawer-content-wrapper {
    width: calc(33% - 28px) !important;
    ${media.lessThan("991px")`
      width: auto !important;
      max-width: 370px !important;
    `}
  }

  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

const TabsWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  margin: 20px 0px 12px 0px;
  ${mediaQuery.lessThan("991px")`
    width: 100%;
  `}
`;

const SearchIconWrapper = styled.div<{ $active?: boolean }>`
  position: absolute;
  right: 0px;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${COLORS.primary.grayDarkLightest};
  width: 72px;
  height: ${pageHeaderHeight};
  padding: 12px 16px;
  border-left: 1px solid ${COLORS.primary.grayLighter};
  border-right: 1px solid ${COLORS.primary.grayLighter};
  transition: 0.3s all;
  ${({ $active }) =>
    $active &&
    css`
      background: ${COLORS.primary.grayLighter};
    `}
  :hover {
    color: ${COLORS.primary.accent};
    background: ${COLORS.primary.grayLighter};
  }
`;

const PortfolioText = styled.span<{ $isMainNavCollapsedNav: boolean }>`
  color: ${COLORS.primary.accentDark};
  font-size: 12px;
  font-weight: 600;
  padding-right: 4px;
  text-align: left;
  line-height: 78px;
  margin-left: ${({ $isMainNavCollapsedNav }) => ($isMainNavCollapsedNav ? "-20px" : "0px")};
  display: ${({ $isMainNavCollapsedNav }) => ($isMainNavCollapsedNav ? "inline-block" : "none")};
`;

const GoBackButton = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${COLORS.primary.grayLighter};
  color: ${COLORS.primary.accent};
  width: 32px;
  height: 32px;
  border-radius: 16px;
  margin-right: 8px;
  transition: 0.3s all;
  min-width: 32px;
  :hover {
    background: ${COLORS.primary.accent};
    color: white;
  }
  svg {
    width: 24px;
    height: 24px;
  }
`;

const ProtocolDropdownButton = styled.div<{ $isActive?: boolean }>`
  border-radius: 20px;
  border: 2px solid ${COLORS.primary.grayLighter};
  background: #fafafa;
  box-shadow: 0px 2px 6px 0px rgba(25, 21, 64, 0.05);
  display: inline-flex;
  height: 40px;
  padding: 4px 8px;
  align-items: center;
  gap: 12px;
  flex-shrink: 0;
  color: ${COLORS.primary.grayDark}};
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  cursor: pointer;
  transition: 0.3s all;
  :hover {
    color: ${COLORS.primary.accent};
  }
  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${COLORS.primary.grayLighter};
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      svg {
        transform: rotate(180deg);
      }
    `}
  > div {
    display: flex;
    align-items: center;
    gap: 4px;
  }
  :hover {
    border: 1px solid ${COLORS.primary.accent};
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  }
`;

const ProtocolDropdownButtonWrapper = styled.div`
  padding-left: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
`;

const BackButtonWrapper = styled.div<{ $isMainNavCollapsedNav?: boolean; $isDelegations?: boolean }>`
  display: inline-flex;
  margin-left: ${({ $isMainNavCollapsedNav, $isDelegations }) =>
    $isMainNavCollapsedNav ? "-32px" : $isDelegations ? "-12px" : "74px"};
  width: 205px;
  border-right: 1px solid ${COLORS.primary.grayLighter};
  padding-bottom: 8px;
  height: 64px;
  align-items: end;
`;

const MainTabTextWrapper = styled.div<{ $isMainNavCollapsedNav: boolean }>`
  display: inline-block;
  padding-left: ${({ $isMainNavCollapsedNav }) => ($isMainNavCollapsedNav ? "10px" : "86px")};
  padding-right: 40px;
`;

const NavSearchWrapper = styled.div`
  position: absolute;
  top: 64px;
  right: 0px;
  width: calc(100% - 198px);
  left: 198px;
  padding: 10px 24px;
  border: 1px solid ${COLORS.primary.grayLighter};
  background: white;
  ${mediaQuery.greaterThan("1880px")`
    width: calc(100% - 302px);
    left: 302px;
  `}
`;

const StyledThinCrossIcon = styled(ThinCrossIcon)`
  position: absolute;
  right: 28px;
  cursor: pointer;
  top: 24px;
  :hover: {
    color: ${COLORS.primary.accent};
  }
`;

const MainTabsNavs = () => {
  const [globalSearchState, setGlobalSearchState] = useState(false);
  const [protocolNavSearchState, setProtocolNavSearchState] = useState(false);
  useClerkSignIn();

  const { pathname, search } = useLocation();
  const { currentRefId } = useContext(CurrentRefIdContext);
  const { isSidebarExpanded } = useIsSidebarExpanded();
  const isMainNavCollapsedNav = !!currentRefId || !isSidebarExpanded;
  const { width } = useWindowDimensions();

  const protocolCname = getProtocolCnameFromPath(pathname.split("/")[1]);
  const protocol = protocols[protocolCname];
  const query = new URLSearchParams(search);
  const teamWallet = query.get("bundleWallet");
  const { account } = useContext(CurrentAccountContext);
  const searchBoxRef = useRef(null);
  const proxyAddress = useProxyAddress(account);

  const toggleSearchState = useCallback(() => {
    if (protocolNavSearchState) {
      setProtocolNavSearchState(false);
    } else {
      setGlobalSearchState(!globalSearchState);
    }
  }, [globalSearchState, protocolNavSearchState]);

  const resetSearch = useCallback(() => {
    setProtocolNavSearchState(false);
    setGlobalSearchState(false);
  }, []);

  useClickAway(searchBoxRef, () => {
    resetSearch();
  });

  useEffect(() => {
    resetSearch();
  }, [resetSearch, pathname]);

  const { delegationsFromAddress } = useDelegatesFromAddress({
    address: teamWallet || account,
    suspense: false,
    disable: pathname !== RoutePaths.create,
  });
  const { delegateFor } = useDelegatesForProtocols({
    address: teamWallet || account,
    suspense: false,
    disable: pathname !== RoutePaths.create,
  });
  const delegateToSelf = useMemo(
    () =>
      delegationsFromAddress
        ?.filter(
          (delegate) =>
            delegate.address?.toLowerCase() === delegate.addressDelegatedTo?.toLowerCase() &&
            protocols[delegate.protocol],
        )
        .map((delegate) => delegate.protocol),
    [delegationsFromAddress],
  );
  const delegateToOthers = useMemo(
    () =>
      delegationsFromAddress
        ?.filter((delegate) => delegate.address?.toLowerCase() !== delegate.addressDelegatedTo?.toLowerCase())
        .map((delegate) => delegate.protocol),
    [delegationsFromAddress],
  );

  const delegateForProtocols = useMemo(
    () =>
      Array.from(new Set([...delegateFor, ...delegateToSelf])).filter(
        (protocol) => !delegateToOthers.includes(protocol),
      ),
    [delegateFor, delegateToSelf, delegateToOthers],
  );

  const pathnameSplitted = pathname.split("/");

  const isProjectPage =
    pathnameSplitted[2] === "proposals" ||
    pathnameSplitted[2] === "proposal" ||
    pathnameSplitted[2] === "voters" ||
    pathnameSplitted[2] === "overview" ||
    (pathnameSplitted[2] === "calendar" && pathnameSplitted[1] !== "feed") ||
    pathnameSplitted[2] === "delegates" ||
    pathnameSplitted[2] === "whitelist" ||
    pathnameSplitted[2] === "discussions" ||
    pathnameSplitted[2] === "meetings" ||
    pathnameSplitted[2] === "contributors" ||
    pathnameSplitted[2] === "reports" ||
    pathnameSplitted[2] === "meetings" ||
    pathnameSplitted[2] === "insights";

  const toggleSearchDropdownState = useCallback(() => {
    setProtocolNavSearchState(!protocolNavSearchState);
  }, [protocolNavSearchState]);

  const navigateToCreateSection = useCallback(
    (section: string) => {
      const newSearch = new URLSearchParams();
      const query = new URLSearchParams(search);
      const bundleWallet = query.get("bundleWallet");
      if (bundleWallet) {
        newSearch.set("bundleWallet", bundleWallet);
      }
      newSearch.set("type", section);
      return newSearch.toString();
    },
    [search],
  );

  let MainTabText = null;
  let Tabs = null;
  if (pathname === RoutePaths.projects) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Explore</PortfolioText>;
  } else if (isProjectPage) {
    MainTabText = (
      <BackButtonWrapper $isMainNavCollapsedNav={isMainNavCollapsedNav}>
        <GoBackButton to={RoutePaths.projects}>
          <LongArrowLeftIcon />{" "}
        </GoBackButton>
        <PortfolioText $isMainNavCollapsedNav={false} style={{ width: "100%", lineHeight: "34px" }}>
          <span style={{ fontWeight: "300" }}>Explore / </span>Projects
        </PortfolioText>
      </BackButtonWrapper>
    );
    Tabs = (
      <>
        <ProtocolDropdownButtonWrapper ref={searchBoxRef}>
          {protocolNavSearchState && (
            <NavSearchWrapper>
              <ProtocolNav onClose={toggleSearchDropdownState} />
            </NavSearchWrapper>
          )}
          <ProtocolDropdownButton onClick={toggleSearchDropdownState} $isActive={protocolNavSearchState}>
            <div>
              <ProtocolIcon size="standard" protocol={protocol} />
              {protocol?.name}
            </div>
            <DropdownArrowDown color="#4235e1" width={8} height={8} />
          </ProtocolDropdownButton>
        </ProtocolDropdownButtonWrapper>
      </>
    );
  } else if (
    pathname === RoutePaths.feed ||
    pathname === RoutePaths.discuss ||
    pathname === RoutePaths.dailyBriefs ||
    pathname === RoutePaths.news
  ) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Feed</PortfolioText>;

    Tabs = (
      <>
        <StyledTab $active={pathname === RoutePaths.feed} to={RoutePaths.feed}>
          Proposals
        </StyledTab>
        <StyledTab $active={pathname === RoutePaths.discuss} to={RoutePaths.discuss}>
          Discussions
        </StyledTab>
        <StyledTab $active={pathname === RoutePaths.news} to={RoutePaths.news}>
          News
        </StyledTab>
        <StyledTab $active={pathname === RoutePaths.dailyBriefs} to={RoutePaths.dailyBriefs}>
          Briefs
        </StyledTab>
      </>
    );
  } else if (
    pathname === RoutePaths.proxyFeed ||
    pathname === RoutePaths.proxyDiscuss ||
    pathname === RoutePaths.proxyBriefs ||
    pathname === RoutePaths.proxyNews
  ) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Proxy</PortfolioText>;
    Tabs = (
      <>
        {proxyAddress ? (
          <>
            <StyledTab $active={pathname === RoutePaths.proxyFeed} to={RoutePaths.proxyFeed}>
              Proposals
            </StyledTab>
            <StyledTab $active={pathname === RoutePaths.proxyDiscuss} to={RoutePaths.proxyDiscuss}>
              Discussions
            </StyledTab>
            <StyledTab $active={pathname === RoutePaths.proxyNews} to={RoutePaths.proxyNews}>
              News
            </StyledTab>
            <StyledTab $active={pathname === RoutePaths.proxyBriefs} to={RoutePaths.proxyBriefs}>
              Briefs
            </StyledTab>
          </>
        ) : null}
      </>
    );
  } else if (pathname === RoutePaths.delegation) {
    MainTabText = (
      <BackButtonWrapper $isMainNavCollapsedNav={isMainNavCollapsedNav} $isDelegations>
        <PortfolioText $isMainNavCollapsedNav={false} style={{ width: "100%", lineHeight: "34px" }}>
          People
        </PortfolioText>
      </BackButtonWrapper>
    );
  } else if (pathname.includes(RoutePaths.delegation)) {
    MainTabText = (
      <BackButtonWrapper $isMainNavCollapsedNav={isMainNavCollapsedNav} $isDelegations>
        <GoBackButton to={RoutePaths.delegation}>
          <LongArrowLeftIcon />{" "}
        </GoBackButton>
        <PortfolioText $isMainNavCollapsedNav={false} style={{ width: "100%", lineHeight: "34px" }}>
          <span style={{ fontWeight: "300" }}>Explore / </span>People
        </PortfolioText>
      </BackButtonWrapper>
    );
  } else if (pathname.startsWith("/settings")) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Settings</PortfolioText>;
  } else if (pathname.startsWith("/create")) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Feed</PortfolioText>;
    Tabs = (
      <>
        <StyledTab
          $active={search.includes("proposal") || !search}
          to={{
            pathname: RoutePaths.create,
            search: navigateToCreateSection("proposal"),
          }}
        >
          <PaperOutlineIcon />
          Proposal
        </StyledTab>
        {!!delegateForProtocols?.length && (
          <StyledTab
            $active={search.includes("post")}
            to={{
              pathname: RoutePaths.create,
              search: navigateToCreateSection("post"),
            }}
          >
            <CommentIcon />
            Post
          </StyledTab>
        )}
      </>
    );
  } else if (pathname.startsWith("/developers")) {
    MainTabText = <PortfolioText $isMainNavCollapsedNav={isMainNavCollapsedNav}>Platform</PortfolioText>;
    Tabs = (
      <>
        <StyledTab
          $active
          to={{
            pathname: RoutePaths.billing,
            search: search,
          }}
        >
          <OutlinedDevelopersIcon />
          Developers
        </StyledTab>
      </>
    );
  }

  let MainNavlgCol = 14;
  if (isProjectPage || width > 1880) {
    MainNavlgCol = 16;
  }

  const isMainPath = useMemo(() => {
    const mainPaths = [
      RoutePaths.feed,
      RoutePaths.discuss,
      RoutePaths.dailyBriefs,
      RoutePaths.projects,
      RoutePaths.create,
      RoutePaths.proxyFeed,
      RoutePaths.proxyDiscuss,
      RoutePaths.proxyBriefs,
    ];
    return mainPaths.includes(pathname);
  }, [pathname]);

  return (
    <>
      {!isProjectPage && width <= 1880 && (
        <Col lg={2} offset={isMainNavCollapsedNav || isMainPath ? 0 : 2}>
          {MainTabText}
        </Col>
      )}
      <Col lg={MainNavlgCol} style={{ display: !standAloneProtocolCname && globalSearchState ? "flex" : "" }}>
        {isProjectPage && MainTabText}
        {!isProjectPage && width > 1880 && (
          <MainTabTextWrapper $isMainNavCollapsedNav={isMainNavCollapsedNav}>{MainTabText}</MainTabTextWrapper>
        )}
        {!standAloneProtocolCname && globalSearchState ? (
          <StyledInstaSearchWrapper $width={width} ref={searchBoxRef}>
            <InstaSearch />
          </StyledInstaSearchWrapper>
        ) : (
          <TabsWrapper>{Tabs}</TabsWrapper>
        )}
        <SearchIconWrapper $active={globalSearchState} onClick={toggleSearchState}>
          {!protocolNavSearchState && !globalSearchState ? (
            <ThinSearchIcon height={24} width={24} />
          ) : (
            <StyledThinCrossIcon width={16} height={16} />
          )}
        </SearchIconWrapper>
      </Col>
    </>
  );
};

const GlobalNav = () => {
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const standAloneProtocolIcon = useProtocolIcon({ protocolCname: standAloneProtocolCname });
  const [showNavbar, setShowNavbar] = useState(pathname.includes("/discuss"));
  const hasValidKey = useHasValidSubscription(account);
  const isOnGatedPage = useMemo(() => {
    return NftGatedRoutes.map((route) => pathname.includes(route)).includes(true);
  }, [pathname]);

  const [showNftModal, setShowNftModal] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;

  useEffect(() => {
    if (!showNavbar) {
      const shoulLoadNavbarImmediately = pathname.includes("/settings/") || pathname.includes("/your-projects/");
      const timeout = setTimeout(
        () => {
          setShowNavbar(true);
        },
        shoulLoadNavbarImmediately ? 0 : 5000,
      );
      return () => clearTimeout(timeout);
    }
  }, [pathname, setShowNavbar, showNavbar]);

  const toggleNftModal = useCallback(() => {
    setShowNftModal(!showNftModal);
  }, [showNftModal]);

  useEffect(() => {
    if (account && hasValidKey && !isOnGatedPage) {
      const lastNotifyForNfy = window.localStorage.getItem(`lastNotifyForNfy${account.toLocaleLowerCase()}`);
      const isNotNotifiedInLast30Days =
        lastNotifyForNfy && parseInt(lastNotifyForNfy, 10) < Date.now() - 1000 * 60 * 60 * 24 * 30;
      const firstNotify = !lastNotifyForNfy;
      if (firstNotify || isNotNotifiedInLast30Days) {
        window.localStorage.setItem(`lastNotifyForNfy${account.toLocaleLowerCase()}`, Date.now().toString());
        setShowNftModal(true);
      }
    }
  }, [account, hasValidKey, isOnGatedPage]);

  return (
    <HeaderWrapper>
      <Link to={`/${standAloneProtocolCname}/proposals`}>
        <StyledProtocolLogo src={standAloneProtocolIcon.url} alt="protocol logo" />
      </Link>
      <DesktopGlobalNav>
        <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
          {standAloneProtocolCname ? <Col lg={16} /> : <MainTabsNavs />}
          <Col lg={8}>
            <StyledWalletWrapper $connected={!!account}>
              {showNavbar ? <Wallet /> : <></>}
              <Modal
                customMinWidth={isMobile ? "0" : undefined}
                customMaxHeight={isMobile ? "650px" : undefined}
                zIndex={20}
                removePaddingBottom
                size="large"
                open={showNftModal}
                onClose={toggleNftModal}
                closeButtonRight="48px"
                closeButtonTop="40px"
                closeButtonColor="#ffffff"
              >
                <MintNFT hasValidKey={hasValidKey} showAccessInfo={hasValidKey} onClose={toggleNftModal} />
              </Modal>
            </StyledWalletWrapper>
          </Col>
        </Row>
      </DesktopGlobalNav>
      <PortalNavDivider />
    </HeaderWrapper>
  );
};

export default GlobalNav;
