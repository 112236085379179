import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";

interface Content {
  proposalId?: string;
  protocol: string;
  body: string;
  title: string;
  timestamp: number;
  type: string;
}

export const useContentByProtocol = ({ protocol, suspense }: { protocol?: string; suspense?: boolean }) => {
  const { data, isLoading, error } = useQuery<Content[]>({
    queryKey: ["content", protocol],
    enabled: !!protocol,
    suspense,
    queryFn: async () => {
      const { data } = await axios.get(
        `${baseAPIUrl}content/getContentByProtocol/${protocol}?key=${BOARDROOM_API_KEY}`,
      );
      return data?.data?.content?.sort((a: any, b: any) => b.timestamp - a.timestamp);
    },
  });
  return { data, isLoading, error };
};
