import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import ContentLoader from "react-content-loader";
import React, { Suspense, useCallback, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { ErrorBoundary } from "react-error-boundary";
import moment from "moment";

import { useCurrentProtocol } from "../../hooks/useCurrentProtocol";
import { useProposals } from "../../hooks/useProposals";
import { Item } from "../Dropdown/types";
import { ProposalsListSkeleton } from "./ProposalsListSkeleton";
import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { ProposalTimeLineItem } from "../ProposalTimeLineItem";
import { useSdkWithoutSigner } from "../../hooks/useSdkWithoutSigner";
import { EmptyProposal } from "../VoterProfile/YourProposals";
import { dateKeyFormat } from "../../constants/date";
import { protocolOnchainInstances } from "../../constants/protocols";
import { getProposalMainStatus } from "../../utils/getProposalTimeAgoText";

const statusOptions = [
  { name: "Today", value: "today" },
  { name: "Open", value: "open" },
  { name: "Pending", value: "pending" },
  { name: "Closed", value: "closed" },
];

type StatusOptions = "pending" | "today" | "open" | "closed";

const SeeMoreText = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.primary.accent};
  margin-top: 24px;
  margin-bottom: 100px;
  cursor: pointer;
  ${media.lessThan("large")`
    text-align: center;
    margin-bottom: 0;
  `}
`;

const StyledDiv = styled.div`
  text-align: center;
  margin-bottom: 120px;
  margin-top: 20px;
`;

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: ${COLORS.secondary.purple};
  text-transform: capitalize;
`;

const StyledImg = styled("img")`
  margin: 32px 0;
`;

const StyledBadgeWrapper = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 44px;
  justify-content: flex-end;
  ${media.lessThan("1260px")`
    margin-bottom: 16px;
    justify-content: flex-start;
  `}
  ${media.lessThan("991px")`
    margin-bottom: 44px;
    justify-content: flex-end;
  `}
  ${media.lessThan("640px")`
   display: none;
  `}
`;

const Badge = styled(Button)<{ $active: boolean }>`
  border-radius: 40px;
  font-size: 12px;
  line-height: 13px;
  height: 24px;
  color: ${COLORS.secondary.purple};
  border: 1px solid ${COLORS.secondary.purple};
  ${({ $active }) =>
    $active &&
    `color: ${COLORS.primary.accent}; border: 1px solid ${COLORS.primary.accent}; background: rgba(158, 151, 243, 0.1);`};
  .ant-btn-loading-icon {
    display: none;
  }
`;

const FilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 40px;
  ${media.lessThan("1260px")`
    margin-bottom: 16px;
  `}
  ${media.lessThan("991px")`
    margin-bottom: 40px;
  `}
`;

const FilterText = styled.div<{ active: boolean }>`
  font-size: 14px;
  line-height: 16px;
  opacity: 0.3;
  color: ${COLORS.primary.accent};
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:after {
    ${(props) =>
      props.active &&
      css`
        content: "";
        border-bottom: 1.5px solid ${COLORS.primary.accent};
        display: block;
        width: 100%;
        margin-top: 4px;
      `}
  }
  ${({ active }) => active && `color: ${COLORS.primary.accent}; opacity: 1;`};
`;

const StyledRow = styled(Row)`
  ${media.lessThan("1260px")`
  display: flex;
  flex-direction: column;
`}
  ${media.lessThan("991px")`
  display: flex;
  flex-direction: row;
`}
`;

const makeTitleCase = (str: string): string => {
  str.split("");
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const ProposalsList = () => {
  const protocolInfo = useCurrentProtocol();
  const [filters, setFilters] = useState<Partial<{ status: StatusOptions }>>();
  const [framework, setFramework] = useState<string>("all");
  const sdk = useSdkWithoutSigner();

  const protocolInSdk = sdk?.getProtocol(protocolInfo?.cname || "");
  const adapterFramework = protocolInSdk?.adapterInstances("proposals");
  const hasMultipleFramework = adapterFramework?.includes("snapshot") && adapterFramework?.includes("onchain");
  const onChainFrameworks = protocolOnchainInstances[protocolInfo?.cname || ""];

  const { proposals, fetchNextPage, hasNextPage } = useProposals({
    protocol: protocolInfo,
    limit: 8,
    status: filters?.status === "today" || filters?.status === "open" ? "active" : filters?.status,
    adapters: framework === "all" ? undefined : framework === "onchain" ? onChainFrameworks : [framework],
  });

  const utcOffset = moment().utcOffset();
  const currentDate = useMemo(() => moment(), []);
  const dateKey = moment.utc(currentDate).utcOffset(utcOffset).format(dateKeyFormat);

  const proposalsAll = useMemo(() => {
    return proposals
      ?.filter((prop) => {
        if (framework === "onchain") {
          return (
            onChainFrameworks.includes(prop.adapter) ||
            (prop.adapter === "archive" && !["babydogearmy", "ybaby"].includes(prop.protocol))
          );
        } else if (framework === "snapshot") {
          return (
            prop.adapter === "snapshot" ||
            (prop.adapter === "archive" && ["babydogearmy", "ybaby"].includes(prop.protocol))
          );
        } else return prop;
      })
      ?.filter((prop) => {
        const endKey = moment(prop.endTimestamp * 1000).format(dateKeyFormat);
        if (filters?.status === "today") {
          return endKey === dateKey;
        } else if (filters?.status === "open") {
          return getProposalMainStatus(prop.currentState) === "active";
        } else return prop;
      });
  }, [proposals, framework, onChainFrameworks, filters?.status, dateKey]);

  const handleSelectStatusOption = useCallback(
    (item: Item | null) => {
      setFilters({
        ...(item?.value !== "all" ? { status: item?.value as StatusOptions } : {}),
      });
    },
    [setFilters],
  );

  return (
    <>
      <StyledRow align="middle" gutter={24}>
        <Col xs={12} lg={12}>
          <FilterWrapper>
            <FilterText active={filters === undefined} onClick={() => setFilters(undefined)}>
              All
            </FilterText>
            {statusOptions.map((status) => (
              <FilterText
                key={status.value}
                active={filters?.status === status.value}
                onClick={() => handleSelectStatusOption(status)}
              >
                {status.name}
              </FilterText>
            ))}
          </FilterWrapper>
        </Col>
        {hasMultipleFramework && (
          <Col xs={12} lg={12}>
            <StyledBadgeWrapper>
              {hasMultipleFramework && (
                <Badge $active={framework === "all"} onClick={() => setFramework("all")}>
                  All Voting Types
                </Badge>
              )}
              {adapterFramework?.includes("onchain") && (
                <Badge $active={framework === "onchain"} onClick={() => setFramework("onchain")}>
                  Onchain
                </Badge>
              )}
              {adapterFramework?.includes("snapshot") && (
                <Badge $active={framework === "snapshot"} onClick={() => setFramework("snapshot")}>
                  Offchain
                </Badge>
              )}
            </StyledBadgeWrapper>
          </Col>
        )}
      </StyledRow>

      {proposalsAll?.length === 0 && (
        <StyledDiv>
          <StyledImg src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
          {filters?.status && framework !== "all" ? (
            <StyledTitle>
              No {filters?.status}&nbsp;
              {makeTitleCase(framework)} Proposals
            </StyledTitle>
          ) : framework && framework !== "all" ? (
            <StyledTitle>No {makeTitleCase(framework)} Proposals</StyledTitle>
          ) : filters?.status ? (
            <StyledTitle>
              No {filters?.status === "today" ? "" : filters?.status} Proposals{" "}
              {filters?.status === "today" ? "Ending Today" : ""}
            </StyledTitle>
          ) : (
            <StyledTitle>No Proposals Yet</StyledTitle>
          )}
        </StyledDiv>
      )}

      {proposals && proposalsAll?.map((proposal) => <ProposalTimeLineItem proposal={proposal} key={proposal.refId} />)}

      {hasNextPage && <SeeMoreText onClick={() => fetchNextPage()}>see more</SeeMoreText>}
    </>
  );
};

export default () => (
  <ErrorBoundary fallback={<EmptyProposal />}>
    <Suspense
      fallback={
        <>
          <ContentLoader
            speed={2}
            width="100%"
            height={75}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <rect x="640" y="23" rx="5" ry="5" width="7%" height="12" />
            <rect x="510" y="23" rx="5" ry="5" width="15%" height="12" />
          </ContentLoader>
          <ProposalsListSkeleton />
        </>
      }
    >
      <ProposalsList />
    </Suspense>
  </ErrorBoundary>
);
