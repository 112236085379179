import { Adapters, DelegationAdapter, StakingTokenAdapter, VoteAdapter } from "@boardroom/gov-sdk";
import { sdk } from "../constants/protocols";
import { checkAdapterFrameworkToUse } from "../hooks/useVotePowerAdapter";

const chainIds = {
  1: "0x1",
  42161: "0xa4b1",
  10: "0xa",
  5: "0x5",
  1284: "0x504",
  1285: "0x505",
  11155111: "0xaa36a7",
  11155420: "0xaa37dc",
};

export const chainIdsExplorers: {
  [chainId: number]: string;
} = {
  1: "https://etherscan.io",
  42161: "https://arbiscan.io",
  10: "https://optimistic.etherscan.io",
  5: "https://goerli.etherscan.io",
  1284: "https://moonscan.io",
  1285: "https://moonriver.moonscan.io",
};

interface Props {
  protocolCname?: string;
  adapterType: keyof Adapters;
  forcedFramework?: string;
}

export const getConnectedChainId = async ({
  protocolCname,
  adapterType,
  forcedFramework,
}: Props): Promise<string | undefined> => {
  if (!protocolCname) return (chainIds as Record<number, string>)[1];
  const protocolInSdk = sdk.getProtocol(protocolCname);
  const adapterFrameworks = protocolInSdk?.adapterInstances(adapterType);
  const framework =
    !!forcedFramework && adapterFrameworks.includes(forcedFramework)
      ? forcedFramework
      : checkAdapterFrameworkToUse(adapterFrameworks);

  const chainId = await (
    protocolInSdk.adapter(adapterType, framework) as VoteAdapter | DelegationAdapter | StakingTokenAdapter
  ).getChainId();
  return (chainIds as Record<number, string>)[chainId];
};
