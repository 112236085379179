import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { protocolInfoList } from "@boardroom/protocol-info";
import { ProposalDetails, Voter } from "@boardroom/boardroom-api";
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch";
import { useHistory, useLocation } from "react-router-dom";
import { InstantSearch, InfiniteHits } from "react-instantsearch-dom";
import { connectStateResults } from "react-instantsearch-core";
import styled from "styled-components";
import media from "styled-media-query";

import Hit, { Id } from "./Hit";
import SearchBox from "./SearchConnector";
import { ProtocolDescription } from "../../types";
import { COLORS } from "../../constants/colors";
import { SearchItem } from "../SearchItem";
import { useMixpanel } from "../../hooks";
import { Loader } from "../Loader";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetAddress } from "../../hooks/useEns";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { Pfp } from "../Pfp/Pfp";
import { isTeamView as isTeamViewUtil } from "../../utils/teamUtils";

const searchClient = instantMeiliSearch(
  "https://meilisearch-production-83d0.up.railway.app",
  "7d93badeb7c14d59c2d9c2b749c6b70373d2183b922a883d9d18d06cf06ca70d",
  {
    primaryKey: "id",
  },
);

const StyledInstantSearch = styled(InstantSearch)``;

const StyledSearchBox = styled(SearchBox)`
  background: var(--background-primary-nav);
  ${media.lessThan("medium")`
      padding: 0px 0px 20px 5px;
  `}
  input.ais-SearchBox-input {
    height: 2.5rem;
  }
`;

interface InfiniteHitsProps {
  $width: number;
}

const NoResults = styled("div")<InfiniteHitsProps & { $justifyCenter?: boolean }>`
  max-width: 800px;
  padding: 28px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #07032817;
  position: absolute;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.$justifyCenter ? "center" : "start")};
  width: 100%;
  ${({ $width }) => $width <= 1550 && "width: 95%;"}
  ${({ $width }) => $width <= 991 && "width: calc(90% - 40px); margin-left: 5%;"}
`;

const NoResultsText = styled("span")`
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;
  color: ${COLORS.primary.grayDarkLightest};
  margin-left: 12px;
`;

const NoResultsImg = styled("img")`
  width: 20%;
`;

const SearchSectionTitle = styled("span")<{ $removeMarginTop?: boolean }>`
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${COLORS.primary.grayDarkLightest};
  margin: ${(props) => (props.$removeMarginTop ? "0 0 8px" : "32px 0 8px")};
  display: block;
  cursor: text;
`;

const HitWrapper = styled("div")`
  cursor: default;
`;

const StyledHits = styled(InfiniteHits)<InfiniteHitsProps>`
  position: absolute;
  padding: 16px 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 8px #07032817;
  margin-top: 12px;
  max-width: 800px;
  width: 100%;
  ${({ $width }) => $width <= 1550 && "width: 95%;"}

  ${({ $width }) => $width <= 991 && "width: calc(90% - 40px); margin-left: 5%;"}

  .ais-InfiniteHits-loadMore {
    display: none;
  }

  .ais-InfiniteHits-list {
    overflow-y: auto;
    max-height: 500px;
    margin: 0;
    ${media.lessThan("medium")`
      padding-left: 0;
      .ant-ribbon-placement-end.ant-ribbon {
        right: -9px;
        top: 0px;
      }
    `};

  .ais-SearchBox-form {
    width: 100%;
    display: flex;
    ${media.lessThan("medium")`
      margin: auto;
    `}
  }
  .ais-SearchBox-reset {
  }

  .ant-ribbon-wrapper {
    border: 1px solid #c4c8d8;
    padding: 0px 10px;
    border-top: none;
    background: white;
    ${media.lessThan("medium")`
      width: 100%;
    `}
  }

  .ais-InfiniteHits-item,
  .ais-InfiniteResults-item,
  .ais-Hits-item,
  .ais-Results-item {
    width: 100%;
    margin-top: 0px;
    margin-left: 0px;
    padding: 0px;
    box-shadow: none;
    border: none;
  }
`;

const SwitchingBanner = styled.div`
  background: #191540;
  height: 48px;
  width: 100%;
  position: fixed;
  bottom: 0;
  color: white;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    text-decoration-line: underline;
    font-weight: 300;
    margin-left: 12px;
    cursor: pointer;
  }
`;

interface Props {
  onResultSelect?: Function;
}

const UserPfpWrapper = ({ userEns }: { userEns: string }) => {
  const resolvedAddress = useGetAddress(userEns);
  const { user } = useUserDetails({ address: resolvedAddress });
  return <Pfp pfpUrl={user?.pfpUrl} address={resolvedAddress} dimension={40} size="medium" />;
};

const Results = connectStateResults((props: any) => {
  const { width } = useWindowDimensions();
  const [firstProtocolHit, setFirstProtocolHit] = useState({} as any);
  const [firstProposalHit, setFirstProposalHit] = useState({} as any);
  const [firstVoterHit, setFirstVoterHit] = useState({} as any);
  const protocolsInfoList = protocolInfoList;
  const history = useHistory();
  const { trackSelectVoter } = useMixpanel();

  const firstSection = useMemo(() => {
    const firstHit = props.allSearchResults?.hits?.[0];

    const isProposal = !!firstHit?.title;
    const isProtocol = !!firstHit?.name;
    const isVoter = !!firstHit?.address;

    if (isProposal) return "proposal";
    if (isProtocol) return "protocol";
    if (isVoter) return "voter";
  }, [props.allSearchResults?.hits]);

  const filteredHitsLength = useMemo(
    () =>
      props.allSearchResults?.hits.filter((hit: any) => {
        const isProposal = !!hit.title;
        const isProtocol = !!hit.name;
        const isVoter = !!hit.address;

        if (isVoter) {
          const protocolName = hit.protocols[0].protocol;
          const protocolIsEnabled = protocolsInfoList.find((protocol) => protocol.cname === protocolName)?.isEnabled;

          if (!protocolIsEnabled) {
            return false;
          }
          return true;
        }

        if (isProtocol) {
          const protocolName = hit.cname;
          const protocolIsEnabled = protocolsInfoList.find((protocol) => protocol.cname === protocolName)?.isEnabled;

          if (!protocolIsEnabled) {
            return false;
          }
          return true;
        }

        if (isProposal) {
          const protocolName = hit.protocol;
          const protocolIsEnabled = protocolsInfoList.find((protocol) => protocol.cname === protocolName)?.isEnabled;

          if (!protocolIsEnabled) {
            return false;
          }
          return true;
        }
      }).length,
    [props.allSearchResults?.hits, protocolsInfoList],
  );

  if (!props.searching) {
    const proposalHit = props.allSearchResults?.hits.filter((hit: any) => !!hit.title)?.[0];
    const protocolHit = props.allSearchResults?.hits.filter((hit: any) => !!hit.name)?.[0];
    const voterHit = props.allSearchResults?.hits.filter((hit: any) => !!hit.address)?.[0];
    if (proposalHit && proposalHit.title !== firstProposalHit?.title) {
      setFirstProposalHit(proposalHit);
    }
    if (protocolHit && protocolHit.name !== firstProtocolHit?.name) {
      setFirstProtocolHit(protocolHit);
    }
    if (voterHit && voterHit.address !== firstVoterHit?.address) {
      setFirstVoterHit(voterHit);
    }
  }

  const hitComponent = useCallback(
    ({ hit }: { hit: Id & ProtocolDescription & Voter & ProposalDetails }) => {
      return (
        <HitWrapper>
          {firstProtocolHit.name && hit.name === firstProtocolHit.name && (
            <SearchSectionTitle $removeMarginTop={firstSection === "protocol"}>projects</SearchSectionTitle>
          )}
          {firstVoterHit.address && hit.address === firstVoterHit.address && (
            <SearchSectionTitle $removeMarginTop={firstSection === "voter"}>profiles</SearchSectionTitle>
          )}
          {firstProposalHit.title && hit.title === firstProposalHit.title && (
            <SearchSectionTitle $removeMarginTop={firstSection === "proposal"}>proposals</SearchSectionTitle>
          )}
          <Hit key={hit.id} hit={hit} />
        </HitWrapper>
      );
    },
    [firstProtocolHit, firstVoterHit, firstProposalHit, firstSection],
  );

  const selectVoter = useCallback(
    (voterAddress: string) => {
      history.push(`/voter/${voterAddress}`);

      trackSelectVoter({
        protocol: "",
        voterAddress,
        userId: `${voterAddress}`,
        context: "Search Results",
      });
    },
    [trackSelectVoter, history],
  );

  if (props?.searchState?.query?.endsWith(".eth")) {
    return (
      <NoResults $width={width}>
        <HitWrapper style={{ width: "100%" }}>
          <SearchSectionTitle $removeMarginTop>profiles</SearchSectionTitle>
          <SearchItem
            onClick={() => selectVoter(props?.searchState?.query)}
            tagLabel="profile"
            prefix={
              <Suspense fallback={<Loader />}>
                <UserPfpWrapper userEns={props?.searchState?.query} />
              </Suspense>
            }
            title={props?.searchState?.query}
          ></SearchItem>
        </HitWrapper>
      </NoResults>
    );
  }

  if (!filteredHitsLength && !props?.searchState?.query?.endsWith(".eth")) {
    return (
      <NoResults $width={width}>
        <NoResultsImg src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
        <NoResultsText>No results matched your search.</NoResultsText>
      </NoResults>
    );
  }

  return <StyledHits $width={width} hitComponent={hitComponent} />;
});

function InstaSearch(props: Props) {
  const [value, setValue] = useState<string>();
  const [hitState, setHitState] = useState<Boolean>(false);
  const location = useLocation();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const hitOpenStateRef = useRef(hitState);
  const { pathname, search } = useLocation();
  const isTeamView = isTeamViewUtil(pathname, search);

  useEffect(() => {
    setValue(undefined);
  }, [location]);

  const handleClickOutside = (e: Event) => {
    if (hitOpenStateRef.current && wrapperRef.current && !wrapperRef.current.contains(e.target as Node)) {
      setHitState(false);
      e.stopPropagation();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const onSearchStateChange = ({ query }: { query: string }) => {
    setValue(query);
    setHitState(true);
    hitOpenStateRef.current = true;
  };

  const cancelSearch = useCallback(() => {
    setValue(undefined);
  }, []);

  return (
    <>
      <div ref={wrapperRef}>
        <StyledInstantSearch
          indexName="boardroom"
          searchClient={searchClient}
          stalledSearchDelay={500}
          onSearchStateChange={onSearchStateChange}
        >
          <StyledSearchBox />
          {value && hitState && <Results {...props} />}
        </StyledInstantSearch>
      </div>
      {!!(isTeamView && value) && (
        <SwitchingBanner>
          Switching you back to Connected Wallet
          <span onClick={cancelSearch}>Cancel</span>
        </SwitchingBanner>
      )}
    </>
  );
}

export default InstaSearch;
