import React, { useContext } from "react";
import notification from "antd/es/notification";
import Cookies from "universal-cookie";
import Web3Utils from "web3-utils";
import { useSSX } from "@spruceid/ssx-react";
import { getAddress } from "ethers/lib/utils";
import web3 from "web3";
import { useWallets } from "@web3-onboard/react";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import useEvent from "./useEvent";
import { Text } from "../components/Typography";

const cookies = new Cookies();

export const useSiweFunction = () => {
  const { account, dispatchAccount } = useContext(CurrentAccountContext);
  const { dispatchUuid } = useContext(CurrentUuidContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const { ssx } = useSSX();
  const connectedWallets = useWallets();

  const siweFunction = useEvent(async () => {
    if (!account || !web3Instance) {
      return;
    }
    const web3Util = new web3(web3Instance?.provider as any);
    let isSafeWallet = false;
    try {
      const code = await Promise.race([
        web3Util.eth.getCode(getAddress(account)),
        new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), 1000)),
      ]);
      if (code !== "0x") {
        isSafeWallet = true;
      }
    } catch (error) {
      console.error(error);
    }
    if (isSafeWallet && account.toLowerCase() === connectedWallets?.[0]?.accounts?.[0]?.address?.toLowerCase()) {
      notification.error({
        message: "Sign in with ethereum error",
        description: (
          <Text>
            Can&apos;t Sign in with ethereum with Safe,{" "}
            <a
              href="https://docs.boardroom.io/docs/learn-hub-1/yg02nk7qkgfqv-signing-in-as-a-multisig"
              target="_blank"
              rel="noopener"
            >
              checkout our guide on how to sign in as a multisig here
            </a>
          </Text>
        ),
        duration: 15,
      });
      return;
    }
    try {
      const session = (await ssx?.signIn()) as any;
      if (session?.data?.uuid?.length) {
        cookies.set(`uuid:${session?.address?.toLowerCase()}`, session?.data?.uuid, { path: "/" });
        cookies.set("lastUsedAddressForUUID", Web3Utils.toChecksumAddress(session?.address));
        dispatchUuid({ type: "SAVE_UUID", data: session?.data?.uuid });
        if (session?.data?.address !== session?.data?.walletAddress) {
          dispatchAccount({ type: "SAVE_ACCOUNT", data: session?.data?.address });
        }
      }
    } catch (error: any) {
      cookies.remove(`uuid:${account?.toLowerCase()}`, { path: "/" });
      cookies.remove("lastUsedAddressForUUID", { path: "/" });
      dispatchUuid({ type: "SAVE_UUID", data: "" });
      if (error.code === 4001 || error.code === "REJECTED_SIGNATURE" || error.code === "ACTION_REJECTED") {
        notification.error({
          message: "Canceled... You need to sign in with wallet, please try again",
          duration: 10,
        });
      }
    }
  });
  return siweFunction;
};
