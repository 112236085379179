import React, { useCallback, useContext, useState } from "react";
import styled from "styled-components";
import WalletOutlined from "@ant-design/icons/lib/icons/WalletOutlined";
import { UserButton } from "@clerk/clerk-react";
import { useHistory } from "react-router";

import { Modal, NavAvatar } from "../";
import { Button } from "../Button";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import media from "../../media-query";
import { EmailLoginModal } from "../EmailLoginModal/EmailLoginModal";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";

const ConnectedWrapper = styled.span`
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: auto;
  ${media.lessThan("991px")`
    width: 75px;
    display: inline-block;
    padding: 0px 10px;
  `}
`;

export const StyledLabel = styled.span`
  && {
    ${media.lessThan("991px")`
    display: none;
  `}
  }
`;

export const StyledButton = styled(Button)<{ $isFeatureLandingPage?: boolean }>`
  && {
    background: linear-gradient(89.28deg, #4235e1 3.44%, #ee3fd9 175.86%);
    box-shadow: 0px 0px 8px rgba(25, 21, 64, 0.2);
    ${({ $isFeatureLandingPage }) => $isFeatureLandingPage && "border: 1px solid #FFFFFF;"}
    &:hover {
      background: linear-gradient(0deg, #4235e1, #4235e1);
    }
  }
`;

export const StyledLoginWrapper = styled.div`
  display: flex;
`;

const Wallet = ({ isFeatureLandingPage }: { isFeatureLandingPage?: boolean }) => {
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const [isEmailLoginModalOpen, setIsEmailLoginModalOpen] = useState(false);
  const isEmailSignIn = useIsEmailSignIn();
  const history = useHistory();

  const onClose = useCallback(() => {
    setIsEmailLoginModalOpen(false);
  }, []);

  const onOpen = useCallback(() => {
    setIsEmailLoginModalOpen(true);
  }, []);

  return (
    <>
      <Modal customMinHeight="auto" zIndex={20} size="large" open={isEmailLoginModalOpen} onClose={onClose}>
        <EmailLoginModal setIsEmailLoginModalOpen={setIsEmailLoginModalOpen} />
      </Modal>
      {account && wallet ? (
        <>
          <ConnectedWrapper>
            <NavAvatar openWallet={wallet?.openWalletModal} walletReset={wallet?.walletReset} />
          </ConnectedWrapper>
        </>
      ) : isEmailSignIn ? (
        <StyledLoginWrapper
          onClick={() => {
            history.push("/settings/email");
          }}
        >
          <UserButton showName={true} />
        </StyledLoginWrapper>
      ) : (
        <StyledLoginWrapper>
          <StyledButton $isFeatureLandingPage={isFeatureLandingPage} onClick={onOpen} type="primary" size="small">
            <WalletOutlined />
            <StyledLabel>Sign In</StyledLabel>
          </StyledButton>
        </StyledLoginWrapper>
      )}
    </>
  );
};

export default Wallet;
