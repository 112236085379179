import React, { useCallback, useContext, useEffect } from "react";
import axios from "axios";
import styled, { css } from "styled-components";
import { FormattedSubscriptionData } from "@boardroom/boardroom-api/dist/subscriptions";

import loadChargeBee from "../../utils/loadChargeBee";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { baseAPIUrl, BOARDROOM_API_KEY, isProd } from "../../constants/general";

import notification from "antd/es/notification";
import mediaQuery from "../../media-query";
import { CurrentSubscriptionDataContext } from "../../reducers/SubscriptionData";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import Tooltip from "antd/es/tooltip";
import { COLORS } from "../../constants/colors";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";

const StyledWrapper = styled("div")`
  background: linear-gradient(180deg, #191540 52.39%, #4235e1 100%);
  border-radius: 8px;
  color: white;
  text-align: center;
  height: 100%;
  padding: 24px 48px 32px;
`;

const Title = styled("h2")`
  font-family: "MATROSKA";
  font-weight: 900;
  font-size: 18px;
  line-height: 18px;
  color: white;
  margin: 8px 0 20px;
`;

const Paragraph = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`;

const FlexDiv = styled("div")`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 12px;
  ${mediaQuery.lessThan("991px")`
    flex-direction: column;
  `}
`;

export const StyledPlanWrapper = styled("div")<{ $custom?: boolean; $selected?: boolean; $unselected?: boolean }>`
  border: 1px solid ${({ $custom }) => ($custom ? "#F0EFF8" : "#9e97f3")};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 16px;
  width: 50%;
  transition: all 0.3s;
  cursor: pointer;
  :hover {
    border: 1px solid #4235e1;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
  }
  margin-bottom: 24px;
  ${({ $selected }) =>
    $selected &&
    css`
      border: 4px solid #4235e1 !important;
      background: #f0eff8;
      filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.15));
    `}
  ${({ $unselected }) =>
    $unselected &&
    css`
      border: 1px solid #f0eff8;
      background: #fafafa;
      opacity: 0.7;
    `}
  ${mediaQuery.lessThan("991px")`
    width: 100%;
    margin-bottom: 16px;
  `}
`;

export const PlanTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
`;

const PlanDesc = styled("p")`
  margin-bottom: 24px;
  height: 44px;
`;

export const PriceText = styled("span")`
  font-family: "MATROSKA";
  font-weight: 900;
  font-size: 24px;
  line-height: 24px;
`;

export const PlanItems = styled("ul")`
  text-align: left;
  padding-top: 12px;
`;

const PlanUnitsDesc = styled("span")`
  color: ${COLORS.primary.grayDark};
`;

export const PriceDetailsMonthly = styled("span")`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  font-family: Inter;
`;

export const SubscriptionButton = styled("button")<{ $custom?: boolean }>`
  background: ${({ $custom }) =>
    $custom
      ? "#fff"
      : `linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    conic-gradient(
      from 180deg at 50% 131.6%,
      #b7b2f7 0deg,
      #74a5f5 131.25deg,
      #8379ff 219.37deg,
      #ffb3f6 352.5deg,
      #b7b2f7 360deg
    )`};
  ${({ $custom }) =>
    $custom &&
    css`
      color: #191540;
      border: 1px solid #191540;
      transition: all 0.3s;
      :hover {
        border: 1px solid #4235e1;
        color: #4235e1;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      }
      :active {
        border: 1px solid #4235e1;
        color: #fff;
        background: #4235e1;
        box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
      }
    `}
  ${({ $custom }) => !$custom && "border: 0;"}
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
  padding: 12px 0;
  width: 100%;
  cursor: pointer;
`;
const ParagraphContainer = styled("div")`
  max-width: 29rem;
  margin: 0 auto 32px;
`;

const StyledUl = styled.ul`
  text-align: center;
  padding-left: 24px;
  li:not(:last-child) {
    border-right: 1px solid;
  }
  li {
    display: inline;
    padding: 0px 10px;
  }
  ${mediaQuery.lessThan("991px")`
    display: flex;
    flex-direction: column;
    gap: 2px;
    li:not(:last-child) {
      border-right: 0;
    }
  `}
`;

const StyledUlTitle = styled(Paragraph)`
  text-align: center;
  margin-bottom: 8px;
  margin-top: 8px;
`;

const StyledDocsLink = styled.a`
  color: ${COLORS.primary.accentLight};
  &:hover {
    color: white;
  }
`;

export const PlansWrapper = ({ handleCheckout, planType }: { handleCheckout: any; planType?: string }) => {
  let plan = planType || "free";
  if (plan === "free") {
    plan = isProd ? "api-metered-prd-USD-Monthly" : "api-metered-USD-Monthly";
  } else {
    plan = isProd ? "Scale-Plan-USD-Monthly" : "Scale-USD-Monthly";
  }

  return (
    <StyledPlanWrapper>
      <PlanTitle>{planType === "free" ? "Free" : "Scale"}</PlanTitle>
      <PlanDesc>
        {planType === "free"
          ? "Governance data across 350+ DAOs and multiple networks."
          : "High performance, lower price."}
      </PlanDesc>
      <PriceText>
        {planType === "free" ? "$0" : "$299"}
        <PriceDetailsMonthly>/month</PriceDetailsMonthly>
      </PriceText>
      <PlanItems>
        {planType === "free" ? (
          <>
            <Tooltip
              overlayInnerStyle={{ width: "20rem" }}
              placement="topLeft"
              color={"#fff"}
              title={
                <PlanUnitsDesc>
                  ﹡Our plans are based on Compute Units (CU) which measure a request’s computational complexity. Any
                  additional requests beyond the 30k monthly limit will cost $0.01. See our{" "}
                  <a href="https://docs.boardroom.io/docs/api/e3qy596u2rjei-api-pricing" target="_blank">
                    documentation{" "}
                  </a>
                  for a listing of compute units by API endpoint.
                </PlanUnitsDesc>
              }
            >
              <li>30K CU per month*</li>
            </Tooltip>
            <li>Core APIs Included</li>
            <li>350+ DAOs Included</li>
            <li>All Supported Networks</li>
            <li style={{ marginBottom: "22px" }}>24/7 Discord Support</li>
          </>
        ) : (
          <>
            <Tooltip
              overlayInnerStyle={{ width: "20rem" }}
              placement="topLeft"
              color={"#fff"}
              title={
                <PlanUnitsDesc>
                  ﹡Our plans are based on Compute Units (CU) which measure a request’s computational complexity. Any
                  additional requests beyond the 220k monthly limit will cost $0.01. See our{" "}
                  <a href="https://docs.boardroom.io/docs/api/e3qy596u2rjei-api-pricing" target="_blank">
                    documentation{" "}
                  </a>
                  for a listing of compute units by API endpoint.
                </PlanUnitsDesc>
              }
            >
              <li>220K CU per month*</li>
            </Tooltip>
            <li>Core APIs Included</li>
            <li>350+ DAOs Included</li>
            <li>All Supported Networks</li>
            <li>24/7 Real-Time Support</li>
            <li>Webhooks </li>
          </>
        )}
      </PlanItems>
      <SubscriptionButton onClick={() => handleCheckout(plan)}>
        {planType === "free" ? "Get Started" : "Sign Up"}{" "}
      </SubscriptionButton>
    </StyledPlanWrapper>
  );
};

const PaymentGate = () => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const { dispatchSubscriptionData } = useContext(CurrentSubscriptionDataContext);
  const siweFunction = useSiweFunction();
  const wallet = useOnboardWallet();

  useEffect(() => {
    loadChargeBee();
  }, []);

  const updateSubscriptionData = useCallback(
    (data: FormattedSubscriptionData) => {
      if (data) {
        dispatchSubscriptionData({
          type: "SAVE_SUBSCRIPTION_DATA",
          data: data,
        });
      }
    },
    [dispatchSubscriptionData],
  );

  const handleCheckout = useCallback(
    async (plan: string) => {
      if (!account) {
        notification.error({
          message: "Canceled... You need to connect wallet to subscribe, please try again",
          duration: 10,
        });
        await wallet?.openWalletModal();
        return;
      }
      if (!uuid) {
        notification.error({
          message: "Canceled... You need to sign in with wallet to subscribe, please try again",
          duration: 10,
        });
        await siweFunction();
      }

      // @ts-ignore
      const chargeBeeInstance = window.Chargebee.getInstance();
      chargeBeeInstance.openCheckout({
        hostedPage: () => {
          const data = {
            plan,
            address: account,
            uuid,
          };

          // Hit your end point that returns hosted page object as response
          // This sample end point will call checkout new api
          // https://apidocs.chargebee.com/docs/api/hosted_pages#checkout_new_subscription
          // If you want to use paypal, go cardless and plaid, pass embed parameter as false
          return axios
            .post(`${baseAPIUrl}createHostedPageForItems?key=${BOARDROOM_API_KEY}`, data)
            .then((response) => response.data);
        },

        success(hostedPageId: any) {
          axios
            .get(`${baseAPIUrl}getSubscriptionDetailsAndUpdate/${hostedPageId}?key=${BOARDROOM_API_KEY}`)
            .then((response) => updateSubscriptionData(response.data));
        },
        close: () => {
          chargeBeeInstance.closeAll();
        },
      });
    },
    [account, siweFunction, updateSubscriptionData, uuid, wallet],
  );

  return (
    <StyledWrapper>
      <Title>
        GOVERNANCE
        <br />
        API
      </Title>
      <ParagraphContainer>
        <>
          <Paragraph>
            Leverage Boardrooms Governance API to find comprehensive data for any application for 350+ DAOs across
            chains.
          </Paragraph>
          <StyledUlTitle>Access the high-performance API to comprehensively query:</StyledUlTitle>
          <StyledUl>
            <li>Ballots</li>
            <li>Proposals</li>
            <li>Delegates</li>
            <li>Discussions</li>
            <li>Much More</li>
          </StyledUl>
          <StyledDocsLink target="_blank" href="https://home.boardroom.io/developers">
            Learn More
          </StyledDocsLink>
        </>
      </ParagraphContainer>
      <FlexDiv>
        <PlansWrapper handleCheckout={handleCheckout} planType="free" />
        <PlansWrapper handleCheckout={handleCheckout} planType="scale" />
      </FlexDiv>
    </StyledWrapper>
  );
};

export default PaymentGate;
