import React, { useState, useContext, createContext, ReactNode } from "react";
import { useLocation } from "react-router";
import { useWindowDimensions } from "../hooks/useWindowDimensions";
import { RoutePaths } from "../constants/Routes";

interface Props {
  children: ReactNode;
}

interface IsSidebarExpandedContextType {
  isSidebarExpanded: boolean;
  setIsSidebarExpanded: React.Dispatch<boolean>;
}

export const IsSidebarExpandedContext = createContext({} as IsSidebarExpandedContextType);

export const IsSidebarExpandedProvider = ({ children }: Props) => {
  const { width } = useWindowDimensions();
  const { pathname } = useLocation();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(
    ((pathname === RoutePaths.feed ||
      RoutePaths.proxyFeed ||
      RoutePaths.proxyDiscuss ||
      pathname === RoutePaths.discuss) &&
      width >= 1200) ||
      width >= 1880,
  );

  return (
    <IsSidebarExpandedContext.Provider value={{ isSidebarExpanded, setIsSidebarExpanded }}>
      {children}
    </IsSidebarExpandedContext.Provider>
  );
};

export const useIsSidebarExpanded = () => {
  const { isSidebarExpanded, setIsSidebarExpanded } = useContext(IsSidebarExpandedContext);
  return { isSidebarExpanded, setIsSidebarExpanded };
};
