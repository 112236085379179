import Col from "antd/es/col";
import React, { useEffect, useState, useRef, useCallback, useContext, useMemo } from "react";
import Row from "antd/es/row";
import { useHistory, useLocation } from "react-router";

import { MainContainer, BackgroundForMobile } from "../../components";
import { COLORS } from "../../constants/colors";
import { ArrowUpIcon, EyeIcon } from "../../components/icons";
import MetaTags from "../../components/common/MetaTags";
import {
  Container,
  PageMax,
  BackToTopSection,
  BackToTopText,
  StyledRowNoMargins,
  PaddingContainer,
  MobileBgWrapper,
} from "../Dashboard/styles";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { TrackPageView } from "../../components/TrackPageView";
import {
  FeedFooter,
  JoinBannerText,
  MintBanner,
  StickyDiv,
  StyledCol,
  StyledColSplitView,
  StyledTeamTxt,
  TeamFeedText,
  ViewOnlyWrapper,
} from "../DashboardPages/Calendar/Calendar";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useGetTeam } from "../../hooks/useGetTeam";
import SuspendedNewsEventRow, { SplitViewContent } from "../../components/News/News";
import { GhostSignUpForm } from "../../components/News/SignUpForm";
import { EventsWrapper, SplitViewDrawer, Today, TodayWrapper } from "../../components/Calendar/ListView/styles";
import { isTeamView } from "../../utils/teamUtils";
import { WalletDrawerContext } from "../../reducers/WalletDrawer";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { DiscussFiltersContent } from "../../components/ProposalsFilters/ProposalsFilters";
import { CurrentProjectsFilterContext } from "../../reducers/CurrentProjectsFilter";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { savedProtocolsToArray } from "../../utils/savedProtocolsToArray";
import { mainGridOffset, mainGridStrucutre } from "../../constants/styles";
import { useGetProtocolsToAdd } from "../../hooks/useGetProtocolsToAdd";
import { useHasValidSubscription } from "../../hooks/useHasValidSubscription";
import { PortfolioMobileNav } from "../DailyBriefs/DailyBriefs";
import { RoutePaths } from "../../constants/Routes";
import { useGetGhostPosts } from "../../hooks/useGetGhostPosts";
import { useProxyAddress } from "../../hooks/useVoterVotes";
import { useDelegateVotingPowerByAddress } from "../../hooks/useDelegateVotingPowerByAddress";
import InfiniteScroll from "react-infinite-scroller";

function News() {
  const { account } = useContext(CurrentAccountContext);
  const { search, pathname } = useLocation();
  const isProxyPage = pathname.startsWith("/proxy");
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");
  const isOnTeamView = isTeamView(pathname, search);
  const { isWalletDrawerOpen } = useContext(WalletDrawerContext);
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const savedProtocolsArray = savedProtocolsToArray(userDetails?.savedProtocols);
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const history = useHistory();

  const { width } = useWindowDimensions();
  const dashboardSection = useRef<HTMLDivElement>(null);
  const splitViewRef = useRef<HTMLDivElement>(null);
  const protocolsToAdd = useGetProtocolsToAdd();

  const { data: newsArticles, fetchNextPage, hasNextPage } = useGetGhostPosts(account);

  const [showButton, setShowButton] = useState(false);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const hasValidKey = useHasValidSubscription(account);
  const proxyAddress = useProxyAddress(account);
  const { delegateVotingPower } = useDelegateVotingPowerByAddress({
    address: proxyAddress || "",
  });
  const proxyProtocols = useMemo(() => {
    return delegateVotingPower?.map((data) => data.protocol) || [];
  }, [delegateVotingPower]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop > 1500) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    if (dashboardSection.current) {
      dashboardSection.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const filteredNewsArticles = useMemo(() => {
    const events = newsArticles?.filter((article) => {
      if (projectsFilter === "custom" && !teamId && savedProtocolsArray.length) {
        return savedProtocolsArray.includes(article.protocolCname);
      } else if (projectsFilter === "custom" && !savedProtocolsArray.length && !teamSavedProtocolsArray?.length) {
        return protocolsToAdd.includes(article.protocolCname);
      } else if (projectsFilter === "custom" && teamId && teamSavedProtocolsArray?.length) {
        return teamSavedProtocolsArray.includes(article.protocolCname);
      } else if (projectsFilter === "proxy" && isProxyPage && proxyAddress && proxyProtocols?.length) {
        return proxyProtocols.includes(article.protocolCname);
      } else {
        return true;
      }
    });
    return events;
  }, [
    isProxyPage,
    newsArticles,
    projectsFilter,
    protocolsToAdd,
    proxyAddress,
    proxyProtocols,
    savedProtocolsArray,
    teamId,
    teamSavedProtocolsArray,
  ]);

  const currentTopic = useMemo(
    () => newsArticles?.find((topic) => topic?.id === currentRefId),
    [currentRefId, newsArticles],
  );

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  useEffect(() => {
    let timeoutRef: string | number | NodeJS.Timeout | undefined;
    if (!hasValidKey && newsArticles?.length && !(isProxyPage && !!proxyAddress)) {
      timeoutRef = setTimeout(() => {
        setCurrentRefId(newsArticles[0]?.id);
      }, 500);
    }
    return () => {
      clearTimeout(timeoutRef);
    };
  }, [newsArticles, hasValidKey, setCurrentRefId, isProxyPage, proxyAddress]);

  const navigateToProxy = useCallback(() => {
    history.push(`/voter/${proxyAddress}`);
  }, [history, proxyAddress]);

  return (
    <>
      <TrackPageView name={"News"} />
      <MetaTags
        title="News | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <MainContainer $isDashboard>
        <MobileBgWrapper>
          <BackgroundForMobile />
        </MobileBgWrapper>
        <Container
          className="DashboardContainer"
          ref={dashboardSection}
          id="scrollElement"
          isSettings={false}
          $isCalendar={location.pathname === RoutePaths.news}
          $isSplitView={!!currentRefId}
          $width={width}
        >
          <PaddingContainer>
            <PageMax>
              <StyledRowNoMargins justify={"start"} align="middle">
                <Col style={{ position: currentRefId ? "static" : "relative" }} span={24}>
                  <>
                    {teamId && account ? (
                      <StyledTeamTxt>
                        {teamDetails?.orgName} <TeamFeedText>Feed</TeamFeedText>{" "}
                        <ViewOnlyWrapper>
                          <EyeIcon width={20} height={20} /> View Only
                        </ViewOnlyWrapper>
                      </StyledTeamTxt>
                    ) : (
                      <></>
                    )}
                    {account && proxyAddress && isProxyPage ? (
                      <MintBanner onClick={navigateToProxy}>
                        <JoinBannerText className="joinText">Proxy View: {proxyAddress}</JoinBannerText>
                      </MintBanner>
                    ) : (
                      <></>
                    )}
                    <Row style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[48, 48]}>
                      <StyledColSplitView
                        xs={24}
                        lg={{ span: mainGridStrucutre(!!currentRefId), offset: mainGridOffset(!!currentRefId) }}
                        ref={splitViewRef}
                        $width={width}
                        $isSplitView={!!currentRefId}
                      >
                        <PortfolioMobileNav />
                        <StickyDiv
                          $hasMintBanner={account && proxyAddress && isProxyPage}
                          $isTeamView={!!teamId && !!account}
                        >
                          <DiscussFiltersContent />
                        </StickyDiv>
                        <div style={{ marginTop: "20px" }} />
                        <TodayWrapper>
                          <Today>ALL NEWS</Today>
                        </TodayWrapper>
                        <EventsWrapper $isToday>
                          <InfiniteScroll
                            loadMore={() => fetchNextPage()}
                            hasMore={hasNextPage}
                            initialLoad={false}
                            threshold={1000}
                            useWindow={false}
                            getScrollParent={() => splitViewRef?.current}
                          >
                            <>
                              {filteredNewsArticles?.map((topic) => (
                                <SuspendedNewsEventRow
                                  key={topic?.uuid}
                                  newsEvents={filteredNewsArticles}
                                  event={topic}
                                />
                              ))}
                            </>
                          </InfiniteScroll>
                        </EventsWrapper>
                        {currentTopic && (
                          <SplitViewDrawer
                            zIndex={currentRefId ? 16 : -1}
                            visible={!!currentRefId}
                            onClose={handleClose}
                            placement="right"
                            contentWrapperStyle={{ width: "100%" }}
                            destroyOnClose
                            $isTeamView={!!isOnTeamView && !!account}
                            $isWalletDrawerOpen={isWalletDrawerOpen}
                            $width={width}
                            bodyStyle={{ zIndex: 0 }}
                          >
                            <SplitViewContent currentArticle={currentTopic} newsArticles={newsArticles} />
                          </SplitViewDrawer>
                        )}
                      </StyledColSplitView>
                      <StyledCol
                        $hasMintBanner={account && proxyAddress && isProxyPage}
                        $isTeamView={!!teamId && !!account}
                        $isSplitView={!!currentRefId}
                        xs={24}
                        lg={8}
                        style={{ paddingTop: !!teamId && !!account ? "136px" : "76px", zIndex: "8" }}
                      >
                        {!teamId ? (
                          <>
                            <GhostSignUpForm />
                          </>
                        ) : (
                          <></>
                        )}
                        <div style={{ marginTop: "32px" }} />
                        <FeedFooter />
                      </StyledCol>
                    </Row>
                  </>
                  {showButton && (
                    <BackToTopSection onClick={scrollToTop}>
                      <ArrowUpIcon color={COLORS.primary.accent} width={48} height={48} />
                      <BackToTopText>Back to Top</BackToTopText>
                    </BackToTopSection>
                  )}
                </Col>
              </StyledRowNoMargins>
            </PageMax>
          </PaddingContainer>
        </Container>
      </MainContainer>
    </>
  );
}

export default React.memo(News);
