import React, { useContext, useState, useMemo, useEffect } from "react";
import axios from "axios";
import notification from "antd/es/notification";
import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import Web3Utils from "web3-utils";
import { useWallets } from "@web3-onboard/react";
import { useSSX } from "@spruceid/ssx-react";
import { getAddress } from "ethers/lib/utils";
import web3 from "web3";

import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { getUuid } from "../utils/getUuid";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { useIsMultisigSignIn } from "./useIsMultisigSignIn";
import useEvent from "./useEvent";
import { Text } from "../components/Typography";

const cookies = new Cookies();

export const useSiwe = ({ trigger = true, setTrigger }: { trigger?: boolean; setTrigger?: any }) => {
  const [notificationSent, setNotificationSent] = useState(false);
  const [rejectedSignature, setRejectedSignature] = useState(false);
  const { account, dispatchAccount } = useContext(CurrentAccountContext);
  const { dispatchUuid } = useContext(CurrentUuidContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const uuidInCookie = getUuid(account);
  const { pathname, search } = useLocation();
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const connectedWallets = useWallets();
  const { ssx } = useSSX();
  const isMultisigSignIn = useIsMultisigSignIn();

  useEffect(() => {
    if (account.toLowerCase() !== connectedWallets?.[0]?.accounts?.[0]?.address?.toLowerCase() && !isMultisigSignIn) {
      dispatchUuid({ type: "SAVE_UUID", data: "" });
    }
  }, [account, dispatchUuid, connectedWallets, isMultisigSignIn]);

  const siweFunction = useEvent(async () => {
    // use siwe on voter profile page only if connected wallet has the same address as profile
    if (
      (query.get("voter") && !(query.get("voter")?.toLowerCase() === account?.toLowerCase())) ||
      (pathname.includes("voter") && !pathname.toLowerCase().includes(account?.toLowerCase()))
    ) {
      return;
    }
    if (!account || !web3Instance || notificationSent || !trigger) {
      return;
    }
    const web3Util = new web3(web3Instance?.provider as any);
    let isSafeWallet = false;
    try {
      // Using promise.race here because getCode doesn't resolve or reject if it fails and just stays as pending promise
      const code = await Promise.race([
        web3Util.eth.getCode(getAddress(account)),
        new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), 1000)),
      ]);
      if (code !== "0x") {
        isSafeWallet = true;
      }
    } catch (error) {
      console.error(error);
    }
    if (isSafeWallet && account.toLowerCase() === connectedWallets?.[0]?.accounts?.[0]?.address?.toLowerCase()) {
      notification.error({
        message: "Sign in with ethereum error",
        description: (
          <Text>
            Can&apos;t Sign in with ethereum with Safe,{" "}
            <a
              href="https://docs.boardroom.io/docs/learn-hub-1/yg02nk7qkgfqv-signing-in-as-a-multisig"
              target="_blank"
              rel="noopener"
            >
              checkout our guide on how to sign in as a multisig here
            </a>
          </Text>
        ),
        duration: 15,
      });
      setNotificationSent(true);
      return;
    }
    if (!uuidInCookie && !rejectedSignature) {
      setNotificationSent(true);
      try {
        const session = (await ssx?.signIn()) as any;
        if (session?.data?.uuid?.length) {
          cookies.set(`uuid:${session?.address?.toLowerCase()}`, session?.data?.uuid, { path: "/" });
          cookies.set("lastUsedAddressForUUID", Web3Utils.toChecksumAddress(session?.address));
          dispatchUuid({ type: "SAVE_UUID", data: session?.data?.uuid });
          if (session?.data?.address !== session?.data?.walletAddress) {
            dispatchAccount({ type: "SAVE_ACCOUNT", data: session?.data?.address });
          }
        }
      } catch (error: any) {
        cookies.remove(`uuid:${account?.toLowerCase()}`, { path: "/" });
        cookies.remove("lastUsedAddressForUUID", { path: "/" });
        dispatchUuid({ type: "SAVE_UUID", data: "" });
        if (error.code === 4001 || error.code === "REJECTED_SIGNATURE" || error.code === "ACTION_REJECTED") {
          notification.error({
            message: "Canceled... You need to sign in with wallet, please try again",
            duration: 10,
          });
          setRejectedSignature(true);
        }
        setNotificationSent(false);
        if (setTrigger) {
          setTrigger(false);
        }
      }
    } else {
      const meResponse = await axios.post(`${baseAPIUrl}siwe/me?key=${BOARDROOM_API_KEY}`, {
        address: account,
        uuid: uuidInCookie,
      });
      const { success } = meResponse?.data?.data;
      if (!success) {
        cookies.remove(`uuid:${account.toLowerCase()}`, { path: "/" });
        cookies.remove("lastUsedAddressForUUID", { path: "/" });
        dispatchUuid({ type: "SAVE_UUID", data: "" });
        return;
      } else {
        dispatchUuid({ type: "SAVE_UUID", data: uuidInCookie });
      }
    }
  });

  useEffect(() => {
    siweFunction();
  }, [account, trigger, siweFunction]);
};
