import Tooltip from "antd/es/tooltip";
import React, { useContext, useState, useCallback } from "react";
import styled from "styled-components";

import { useMixpanel } from "../../hooks";

import { Button } from "../Button";

import { CurrentAccountContext } from "../../reducers/CurrentAccount";

import { COLORS } from "../../constants/colors";

import DelegationModal from "./DelegationModal";

const StyledButton = styled(Button)<{ showDelegationsLink?: boolean }>`
  && {
    background: white;
    color: ${COLORS.primary.accent};
    border-right: ${({ showDelegationsLink }) => (showDelegationsLink ? "1px solid #f0eff8" : "none")};
    border-top: 1px solid #f0eff8;
    width: ${({ showDelegationsLink }) => (showDelegationsLink ? "50%" : "100%")};
    border-radius: 0px;
    height: 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    transition: all 0.3s;
    padding: 12px 8px;
    :hover {
      background: linear-gradient(90deg, ${COLORS.secondary.purple} 3.54%, ${COLORS.primary.accent} 100%);
      color: #fff;
    }
    :disabled {
      color: ${COLORS.primary.grayDarkLightest};
      opacity: 0.4;
      :hover {
        background: white;
        opacity: 0.4;
        color: ${COLORS.primary.grayDarkLightest};
      }
    }
  }
`;

interface Props {
  protocolName: string;
  address?: string;
  triggerText?: string;
  zIndex?: number;
  showDelegationsLink?: boolean;
}

function CompoundishSetupVoting(props: Props) {
  const { protocolName, triggerText = "Manage Delegation", zIndex } = props;

  const { account } = useContext(CurrentAccountContext);

  const [visible, setVisible] = useState<boolean>(false);

  const { trackStartSetupDelegation } = useMixpanel();

  const openSetupDelegationModal = useCallback(() => {
    setVisible(true);

    trackStartSetupDelegation({
      protocol: protocolName,
      userId: `${account}`,
    });
  }, [account, protocolName, trackStartSetupDelegation]);

  return (
    <>
      <Tooltip title="Setup your account to vote for proposals on this protocol.">
        <StyledButton
          onClick={openSetupDelegationModal}
          type="primary"
          size="small"
          disabled={protocolName === "makerdao"}
        >
          {triggerText}
        </StyledButton>
      </Tooltip>
      <DelegationModal
        address={account}
        visible={visible}
        setVisible={setVisible}
        protocolName={protocolName}
        zIndex={zIndex}
      />
    </>
  );
}

export default CompoundishSetupVoting;
