import React, { Suspense, useContext, useMemo } from "react";
import styled from "styled-components";
import { useCurrentWidth } from "react-socks";
import { useLocation, useParams } from "react-router-dom";
import ContentLoader from "react-content-loader";

import media from "../../media-query";
import { COLORS } from "../../constants/colors";
import { SidebarContext } from "./SidebarContext";
import { getProtocolCnameFromPath } from "../../constants/protocols";
import { SearchDropdownContext } from "../../reducers/ProjectSearchDropdown";
import { NavLinks } from "./NavLinks";

interface Params {
  protocol: string;
}

const TabsWrapper = styled.div<{ $isSearchOpen?: boolean; $isMobile: boolean }>`
  padding-top: 24px;
  display: ${({ $isSearchOpen, $isMobile }) => ($isSearchOpen ? "none" : $isMobile ? "flex" : "none")};
  white-space: nowrap;
  ${media.lessThan("991px")`
      width: 100%;
      padding: 12px 0;
      overflow-x: scroll;
      justify-content: start;
      margin-right: 0px;
    `}
  ${media.lessThan("640px")`
      width: calc(100vw - 16px);
      padding: 12px 0;
      overflow-x: scroll;
      justify-content: start;
      margin-right: 0px;
    `}
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ProtocolLocalNav = () => {
  const { activeSection } = useContext(SidebarContext);
  const { searchDropDownState } = useContext(SearchDropdownContext);
  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth <= 991;

  return (
    <TabsWrapper $isMobile={isMobile} $isSearchOpen={searchDropDownState}>
      {(activeSection === "voters" ||
        activeSection === "discussions" ||
        activeSection === "proposals" ||
        activeSection === "overview" ||
        activeSection === "proposal" ||
        activeSection === "calendar" ||
        activeSection === "whitelist" ||
        activeSection === "delegates" ||
        activeSection === "insights" ||
        activeSection === "contributors" ||
        activeSection === "meetings" ||
        activeSection === "reports" ||
        activeSection === "meetings") && (
        <>
          <NavLinks />
        </>
      )}
    </TabsWrapper>
  );
};

export default React.memo(() => {
  const isMobile = useCurrentWidth() <= 991;
  const { protocol: protocolRoute } = useParams<Params>();
  const query = new URLSearchParams(useLocation().search);
  const protocolQuery = query.get("protocol") || "";
  const activeProtocol = protocolRoute || protocolQuery || null;
  const location = useLocation();
  const sectionName = useMemo(() => location.pathname.split("/")[2], [location]);
  const protocolName = activeProtocol ? getProtocolCnameFromPath(activeProtocol) : undefined;
  return (
    <SidebarContext.Provider value={{ activeProtocol: protocolName, activeSection: sectionName }}>
      <Suspense
        fallback={
          <ContentLoader
            speed={2}
            width={"100%"}
            height={40}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
            style={{ borderRadius: "8px", marginTop: "32px" }}
          >
            <rect x={isMobile ? "0" : "100"} y="0" rx="5" ry="5" width="85" height="18" />
            <rect x={isMobile ? "140" : "240"} y="0" rx="5" ry="5" width="85" height="18" />
            <rect x={isMobile ? "280" : "380"} y="0" rx="5" ry="5" width="85" height="18" />
            <rect x={isMobile ? "420" : "520"} y="0" rx="5" ry="5" width="85" height="18" />
            <rect x={isMobile ? "0" : "100"} y="28" rx="5" ry="5" width={isMobile ? "100%" : "70%"} height="2" />
          </ContentLoader>
        }
      >
        <ProtocolLocalNav />
      </Suspense>
    </SidebarContext.Provider>
  );
});
