import React from "react";
import styled from "styled-components";
import { SignIn, SignOutButton, useUser } from "@clerk/clerk-react";
import WalletOutlined from "@ant-design/icons/WalletOutlined";
import { useLocation } from "react-router";

import { StyledButton, StyledLabel, StyledLoginWrapper } from "../NavBar/Wallet";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { COLORS } from "../../constants/colors";
import { ThinLogOutIcon } from "../icons";
import { RoutePaths } from "../../constants/Routes";

const StyledDiv = styled("div")``;

const StyledTitle = styled("span")`
  font-weight: 600;
  font-size: 16px;
  line-height: 30px;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

const SignInWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
`;

const ConnectWalletWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
`;

export const DividerRow = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const DividerLine = styled("div")`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  flex: 1 1 0%;
  height: 1px;
  background-color: ${COLORS.primary.grayLight};
`;

export const DividerText = styled("span")`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 0px 16px;
`;

export const Button = styled("button")`
  background: none;
  border: 1px solid ${COLORS.primary.grayLight};
  border-radius: 8px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  cursor: pointer;
  margin: 16px auto;
`;

const EmailLoginModal = ({
  setIsEmailLoginModalOpen,
}: {
  setIsEmailLoginModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { isSignedIn } = useUser();
  const wallet = useOnboardWallet();
  const { pathname } = useLocation();

  const onClick = () => {
    setIsEmailLoginModalOpen(false);
    wallet?.openWalletModal();
  };

  return (
    <>
      {isSignedIn ? (
        <>
          <StyledDiv>
            <StyledTitle>Sign in</StyledTitle>
            <ConnectWalletWrapper>
              <StyledLoginWrapper>
                <StyledButton onClick={onClick} type="primary" size="small">
                  <WalletOutlined />
                  <StyledLabel>Connect Wallet</StyledLabel>
                </StyledButton>
              </StyledLoginWrapper>
            </ConnectWalletWrapper>
            <DividerRow>
              <DividerLine />
              <DividerText>or</DividerText>
              <DividerLine />
            </DividerRow>
            <SignOutButton>
              <Button>
                <ThinLogOutIcon width={20} height={20} />
                <span>Logout</span>
              </Button>
            </SignOutButton>
          </StyledDiv>
        </>
      ) : (
        <>
          <StyledDiv>
            <StyledTitle>Sign in</StyledTitle>
            <ConnectWalletWrapper>
              <StyledLoginWrapper>
                <StyledButton onClick={onClick} type="primary" size="small">
                  <WalletOutlined />
                  <StyledLabel>Connect Wallet</StyledLabel>
                </StyledButton>
              </StyledLoginWrapper>
            </ConnectWalletWrapper>
            <DividerRow>
              <DividerLine />
              <DividerText>or</DividerText>
              <DividerLine />
            </DividerRow>
            <SignInWrapper>
              <SignIn
                appearance={{
                  variables: {},
                }}
                afterSignInUrl={pathname}
                afterSignUpUrl={RoutePaths.settingsEmail}
              />
            </SignInWrapper>
          </StyledDiv>
        </>
      )}
    </>
  );
};

export { EmailLoginModal };
