import React, { Suspense, useCallback, useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import Row from "antd/es/row";
import Col from "antd/es/col";
import AntdForm from "antd/es/form";
import message from "antd/es/message";
import FormItem from "antd/lib/form/FormItem";
import Select from "antd/es/select";
import MDEditor from "@uiw/react-md-editor";
import { ProposalCreationCallData } from "@boardroom/gov-sdk";
import notification from "antd/es/notification";
import QuestionCircleOutlined from "@ant-design/icons/QuestionCircleOutlined";

import { Form, ProposalInput } from "../../components";
import { Loader } from "../Loader";
import { useMixpanel } from "../../hooks";
import { useCurrentProtocol } from "../../hooks/useCurrentProtocol";
import { CurrentWeb3Context } from "../../reducers/CurrentWeb3Provider";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { Button } from "../Button";
import { COLORS } from "../../constants/colors";
import AddAnotherCommandButton from "./AddAnotherCommandButton";
import { useSdk } from "../../hooks/useSdk";
import VerifyAddress from "./VerifyAddress";
import ReviewCommand from "./ReviewCommand";
import ConfirmedCommand from "./ConfirmedCommand";
import { identifier } from "../../constants/identifier";
import { ArrowIcon } from "../icons";
import { useIsMultisigSignIn } from "../../hooks/useIsMultisigSignIn";
import { NotificationsContext } from "../../contexts";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";
import { ContentTitle, ContentWrapper } from "../PostModal/PostModal";
import { ReviewInputWrapper } from "./CreateProposalForm";
import { getConnectedChainId } from "../../utils/checkChain";
import { useSetChain } from "@web3-onboard/react";
import { Text } from "./../Typography";
import { StyledFormItem } from "../ProposalInput/ProposalInput";
import { getCreateProposalLabels } from "../../constants/protocols";
import { useIsAccountConnectedWallet } from "../../hooks/useIsAccountConnectedWallet";

const Container = styled.div`
  margin-top: 8px;
`;

const InputLabel = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${COLORS.primary.grayDark};
  margin: 32px 0 12px;
  display: block;
`;

const InnerInputLabel = styled("div")<{ $disabled?: boolean }>`
  font-size: 14px;
  line-height: 18px;
  color: ${({ $disabled }) => ($disabled ? COLORS.primary.gray : COLORS.primary.grayDark)};
  margin: 24px 0 12px;
  display: flex;
  justify-content: space-between;
`;

export const StyledRichMarkdownEditor = styled(MDEditor)<{ $isErrored: boolean }>`
  padding-bottom: 32px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  min-height: 128px;
  border-radius: 4px;
  transition: all 0.3s;
  border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.secondary.purpleLighter}`)};
  &:hover {
    border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.primary.accent}`)};
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.primary.grayDarkLightest};
  }
  .w-md-editor-text-input {
    -webkit-text-fill-color: inherit;
  }
`;

const StyledReviewButton = styled(Button)`
  border-radius: 8px !important;
  margin: 18px 0 4px auto;
  display: block;
  width: 90%;
  &[disabled] {
    background: ${COLORS.secondary.purple};
    color: #fff;
    :hover {
      background: ${COLORS.secondary.purple};
      color: #fff;
    }
  }
`;

const StyledCancelButton = styled(Button)`
  border-radius: 8px !important;
  margin: 18px 0 4px auto;
  width: 90%;
  border: 0 !important;
  font-weight: 500 !important;
  font-size: 14px;
  line-height: 16px;
`;

const ProposalCommandsContainer = styled.div`
  padding-bottom: 40px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  .ant-form-item-with-help .ant-form-item-explain {
    display: none;
  }
`;

const SuffixText = styled("span")`
  font-size: 14px;
  line-height: 18px;
`;

const PrefixContainer = styled("div")`
  font-size: 14px;
  line-height: 18px;
  padding-right: 16px;
  border-right: 1px solid #dbd8fa;
  height: 150%;
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const StyledSelect = styled(Select)`
  transition: all 0.3s;
  box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
  height: 44px;
  border-radius: 6px;
  &:placeholder-shown {
    font-weight: 300;
    color: ${COLORS.primary.grayDarkLightest};
  }
  &:focus,
  &:hover {
    border-color: ${COLORS.primary.accent};
    box-shadow: 0px 4px 7px rgba(37, 26, 171, 0.1);
  }
  .ant-select-selector {
    height: 100% !important;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: 1px solid ${COLORS.primary.grayLight} !important;
    border-radius: 8px !important;
  }

  &.ant-select-status-error .ant-select-selector {
    border-color: ${COLORS.red} !important;
    border-radius: 8px;
  }
`;

const StyledSubmitButton = styled(Button)`
  && {
    background: #948fb9;
    color: white;
    border-radius: 8px !important;
    transition: 0.3s all;
    :hover {
      background: #4235e1;
      color: white;
    }
    :focus,
    :active {
      background: #291fa7;
      color: white;
    }
  }
  &[disabled] {
    border-radius: 8px;
    background: ${COLORS.primary.grayDarkLightest} !important;
    color: #fff;
    :hover {
      color: #fff;
    }
  }
`;

interface CallDataTopic {
  type: string;
  internalType: string;
  name: string;
}

interface ContractFunction {
  name: string;
  type: string;
  stateMutability: string;
  inputs: {
    internalType: string;
    name: string;
    type: string;
  }[];
  outputs: {
    internalType: string;
    name: string;
    type: string;
  }[];
}

interface Props {
  instance?: string;
}

export const CreateOnChainProposalFormContent = ({ protocolCname }: { protocolCname: string }) => {
  const [editorContent, setEditorContent] = useState<string | undefined>("");
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);
  const [verifyAddressState, setVerifyAddressState] = useState("");
  const [contractFunctions, setContractFunctions] = useState<ContractFunction[]>([]);
  const [formValues, setFormValues] = useState({} as any);
  const [callDataTopics, setCallDataTopics] = useState<CallDataTopic[]>([]);
  const [inReview, setInReview] = useState(false);
  const [addingProposalCommand, setAddingProposalCommand] = useState(true);
  const [targets, setTargets] = useState<string[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [signatures, setSignatures] = useState<string[]>([]);
  const [formattedCallData, setFormattedCallData] = useState<ProposalCreationCallData[]>([]);
  const [callDatas, setCallDatas] = useState<string[][]>([]);
  const [requestId, setRequestId] = useState("");

  const history = useHistory();
  const { account } = useContext(CurrentAccountContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const [form] = AntdForm.useForm();
  const { trackCreateProposal } = useMixpanel();
  const sdk = useSdk();
  const protocolInSdk = sdk?.getProtocol(protocolCname || "");
  //the condition check here is temporary
  const adapterInstances = protocolInSdk?.adapterInstances("proposals");
  const createOnChainProposalAdapters = adapterInstances?.filter(
    (instance: string) => protocolInSdk?.hasAdapter("createOnChainProposal", instance) && instance.includes("onchain"),
  );

  const createOnChainProposalAdapter = createOnChainProposalAdapters?.length
    ? protocolInSdk?.adapter("createOnChainProposal", createOnChainProposalAdapters[0])
    : protocolInSdk?.adapter("createOnChainProposal");

  const isMultisigSignIn = useIsMultisigSignIn();
  const isEmailSignIn = useIsEmailSignIn();
  const isAccountConnectedWallet = useIsAccountConnectedWallet();
  const { dispatch } = useContext(NotificationsContext);

  useEffect(() => {
    let newContractFunctions = null;
    const { proposalAddress } = formValues;

    const getContractFunctions = async () => {
      // 42 is the number of characters in a ethereum address (0xcdf7028ceab81fa0c6971208e83fa7872994bee5)
      if (proposalAddress?.length === 42) {
        try {
          newContractFunctions = JSON.parse(
            (await createOnChainProposalAdapter?.getContractFunctions(proposalAddress)) || "",
          );

          if (newContractFunctions) {
            const filteredContractFunctions = newContractFunctions.filter(
              (func: any) => func.stateMutability !== "view",
            );
            setContractFunctions(filteredContractFunctions);
            setVerifyAddressState("verified");
          } else {
            setVerifyAddressState("invalid");
          }
        } catch (error) {
          setVerifyAddressState("invalid");
          console.error(error);
        }
      } else if (proposalAddress?.length > 0) {
        setVerifyAddressState("verifying");
      } else {
        setVerifyAddressState("");
      }
    };

    getContractFunctions();
  }, [formValues, createOnChainProposalAdapter]);

  useEffect(() => {
    const { method } = formValues;
    if (method) {
      const selectedFunction = contractFunctions.find((func) => func.name === method);
      setCallDataTopics(selectedFunction?.inputs || []);
    }
  }, [formValues, contractFunctions]);

  const onSubmit = useCallback(
    async ({ name }: any) => {
      if (isMultisigSignIn) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the multisig wallet to create a proposal." },
        });
        return;
      }
      if (isEmailSignIn && !isAccountConnectedWallet) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the wallet to create a proposal." },
        });
        return;
      }
      if (account && web3Instance && formattedCallData) {
        try {
          const requestId = await createOnChainProposalAdapter?.createOnChainProposal(
            {
              description: `# ${name} <br />${editorContent}`,
              signatures,
              targets,
              values: values.map((value) => Number(value)),
              calldata: formattedCallData,
            },
            identifier,
          );
          setRequestId(requestId || "");
        } catch (error: any) {
          console.error(error);
          notification.error({
            message: "Something went wrong... Please try again",
            duration: 10,
          });
        }
      }
    },
    [
      isMultisigSignIn,
      isEmailSignIn,
      isAccountConnectedWallet,
      account,
      web3Instance,
      formattedCallData,
      dispatch,
      createOnChainProposalAdapter,
      editorContent,
      signatures,
      targets,
      values,
    ],
  );

  useEffect(() => {
    if (requestId) {
      history.push(`/${protocolCname}/proposals`);
      message.success("Proposal created");

      trackCreateProposal({
        protocol: protocolCname || "",
        proposalId: requestId,
        userId: `${account}`,
      });
    }
  }, [account, requestId, history, protocolCname, trackCreateProposal]);

  const isSubmitButtonDisabled = useCallback(() => {
    if (
      formValues.name?.length &&
      editorContent?.length &&
      signatures?.length &&
      targets?.length &&
      formattedCallData?.length &&
      values?.length
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    editorContent?.length,
    formattedCallData?.length,
    formValues.name?.length,
    signatures?.length,
    targets?.length,
    values?.length,
  ]);

  const resetForm = useCallback(() => {
    setInReview(false);
    setVerifyAddressState("");
    setAddingProposalCommand(false);
    setContractFunctions([]);
    form.resetFields(["proposalAddress", "proposalValue", "method", ...callDataTopics.map((_, i) => `topic_${i}`)]);
  }, [callDataTopics, form]);

  const ConfirmProposalCommand = useCallback(() => {
    const callDataValues = callDataTopics.map((_, i) => formValues[`topic_${i}`]);
    if (!formValues.proposalAddress || !formValues.proposalValue || !formValues.method) {
      if (!formValues.proposalValue) {
        notification.error({
          message: "Error",
          description: <Text>Please enter the Target value</Text>,
        });
      } else if (!formValues.method) {
        notification.error({
          message: "Error",
          description: <Text>Please select the Target method</Text>,
        });
      }
      return;
    }
    if (formValues.proposalAddress) setTargets([...targets, formValues.proposalAddress]);
    if (formValues.proposalValue) setValues([...values, formValues.proposalValue]);
    if (formValues.method) setSignatures([...signatures, formValues.method]);
    const newFormattedCallData = { type: formValues.method, values: callDataValues };
    setFormattedCallData((current) => [...(current || []), ...[newFormattedCallData]]);
    setCallDatas([...callDatas, [...callDataValues]]);
    setInReview(false);
    setVerifyAddressState("");
    form.resetFields(["proposalAddress", "proposalValue", "method", ...callDataTopics.map((_, i) => `topic_${i}`)]);
    setAddingProposalCommand(false);
    setContractFunctions([]);
  }, [callDataTopics, callDatas, form, formValues, signatures, targets, values]);

  const checkFormAndSetReview = useCallback(() => {
    form.validateFields().then(
      () => {
        setInReview(true);
        return;
      },
      () => {
        console.log("invalid form");
      },
    );
  }, [form]);

  return (
    <Form
      name="create-proposal-form"
      form={form}
      onValuesChange={(_, values) => setFormValues(values)}
      onSubmit={onSubmit}
    >
      <ContentWrapper style={{ marginBottom: "20px" }}>
        <ContentTitle>2. PROPOSAL BASICS</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Proposal Title</InputLabel>
        <ProposalInput name="name" label="Proposal Title" isRequired />
        <InputLabel>Proposal Description</InputLabel>
        <StyledRichMarkdownEditor
          onChange={(value) => setEditorContent(value)}
          height={150}
          $isErrored={
            isEditorEverFocused &&
            !(Array.from(editorContent?.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g) || []).length > 0)
          }
          onFocus={() => setIsEditorEverFocused(true)}
          placeholder="Your proposal description"
        />
      </ContentWrapper>
      <ContentWrapper $isDeactivated={!formValues?.name || !editorContent} style={{ marginBottom: "20px" }}>
        <ContentTitle>3. ACTIONS</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Proposal Commands</InputLabel>

        <ProposalCommandsContainer>
          {!inReview && addingProposalCommand && (
            <>
              <InnerInputLabel>
                {protocolCname === "0xgov" || protocolCname === "0xgov-test" ? "Token Address" : "Target Address"}
              </InnerInputLabel>
              <ProposalInput
                suffix={<VerifyAddress state={verifyAddressState} />}
                name="proposalAddress"
                label="i.e. 0x..."
                isRequired
              />
              <InnerInputLabel $disabled={verifyAddressState !== "verified"}>Target Value (Wei)</InnerInputLabel>
              <ProposalInput
                suffix={<SuffixText>Wei</SuffixText>}
                name="proposalValue"
                label="00"
                disabled={verifyAddressState !== "verified"}
                isRequired
              />
              <InnerInputLabel
                style={{ display: "inline-flex", gap: "4px" }}
                $disabled={verifyAddressState !== "verified"}
              >
                Method{" "}
                <a href="https://docs.boardroom.io/" target="_blank">
                  <QuestionCircleOutlined
                    style={{
                      color: COLORS.primary.accent,
                      width: "16px",
                      height: "16px",
                      alignSelf: "center",
                    }}
                  />
                </a>
              </InnerInputLabel>
              <StyledFormItem name="method" required rules={[{ required: true, message: "" }]}>
                <StyledSelect
                  placeholder="choose method"
                  disabled={verifyAddressState !== "verified"}
                  suffixIcon={<ArrowIcon width={14} height={14} color="#4235e1" />}
                >
                  {contractFunctions.map((func) => (
                    <option key={func.name} value={func.name}>
                      {func.name}
                    </option>
                  ))}
                </StyledSelect>
              </StyledFormItem>
              {callDataTopics.length ? (
                <InnerInputLabel $disabled={verifyAddressState !== "verified"}>Call Data</InnerInputLabel>
              ) : null}
              {callDataTopics.map((topic, i) => (
                <ProposalInput
                  prefix={
                    <PrefixContainer>
                      <span>{topic.type.charAt(0).toUpperCase() + topic.type.slice(1)}</span>
                    </PrefixContainer>
                  }
                  disabled={verifyAddressState !== "verified"}
                  key={topic.name}
                  addMarginBottom
                  name={`topic_${i}`}
                  label={getCreateProposalLabels(protocolCname, topic.name, topic.type)}
                  isRequired
                />
              ))}
            </>
          )}
          {inReview && (
            <ReviewCommand
              target={formValues.proposalAddress}
              value={formValues.proposalValue}
              method={formValues.method}
              callDataTopicValues={callDataTopics.map((_, i) => formValues[`topic_${i}`])}
              goBack={() => setInReview(false)}
              confirmCommand={ConfirmProposalCommand}
            />
          )}
          {addingProposalCommand && (
            <Row>
              {!inReview && (
                <>
                  <Col span={12}>
                    {formattedCallData.length > 0 && (
                      <StyledCancelButton type="secondary" onClick={resetForm}>
                        Cancel
                      </StyledCancelButton>
                    )}
                  </Col>
                  <Col span={12}>
                    <StyledReviewButton onClick={checkFormAndSetReview} disabled={verifyAddressState !== "verified"}>
                      Review
                    </StyledReviewButton>
                  </Col>
                </>
              )}
            </Row>
          )}
          {formattedCallData.map((_, i) => (
            <ConfirmedCommand
              key={i}
              target={targets[i]}
              value={values[i]}
              method={signatures[i]}
              callDataTopicValues={callDatas[i]}
              deleteThisCommandFunction={() => {
                setSignatures([...signatures.slice(0, i), ...signatures.slice(i + 1)]);
                setValues([...values.slice(0, i), ...values.slice(i + 1)]);
                setFormattedCallData([...formattedCallData?.slice(0, i), ...formattedCallData?.slice(i + 1)]);
                setTargets([...targets.slice(0, i), ...targets.slice(i + 1)]);
                setCallDatas([...callDatas.slice(0, i), ...callDatas.slice(i + 1)]);
              }}
            />
          ))}
        </ProposalCommandsContainer>
        <AddAnotherCommandButton
          removeSpacing
          addAnotherCommandFunction={() => {
            if (signatures.length < 10) setAddingProposalCommand(true);
          }}
          disabled={inReview || addingProposalCommand || signatures.length >= 10}
          text="Add another command"
        />
      </ContentWrapper>
      <ContentWrapper
        $isDeactivated={
          !formValues?.name ||
          !editorContent ||
          !signatures?.length ||
          !targets?.length ||
          !formattedCallData?.length ||
          !values?.length
        }
        style={{ marginBottom: "20px" }}
      >
        <ContentTitle>4. REVIEW</ContentTitle>
        <InputLabel style={{ marginTop: "20px" }}>Confirm Proposal Details</InputLabel>
        <ReviewInputWrapper>
          <FormItem style={{ marginBottom: 0 }} rules={[{ required: true, message: "" }]}>
            <input name="reviewed" required type="checkbox" />
          </FormItem>
          <span>I have reviewed the contents of my proposal and am ready to publish.</span>
        </ReviewInputWrapper>
      </ContentWrapper>
      <FormItem shouldUpdate>
        <StyledSubmitButton type="primary" htmlType="submit" block disabled={isSubmitButtonDisabled()}>
          Create Proposal
        </StyledSubmitButton>
      </FormItem>
    </Form>
  );
};

const CreateOnChainProposalForm = ({ instance }: Props) => {
  const [editorContent, setEditorContent] = useState<string | undefined>("");
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);
  const [verifyAddressState, setVerifyAddressState] = useState("");
  const [contractFunctions, setContractFunctions] = useState<ContractFunction[]>([]);
  const [formValues, setFormValues] = useState({} as any);
  const [callDataTopics, setCallDataTopics] = useState<CallDataTopic[]>([]);
  const [inReview, setInReview] = useState(false);
  const [addingProposalCommand, setAddingProposalCommand] = useState(true);
  const [targets, setTargets] = useState<string[]>([]);
  const [values, setValues] = useState<string[]>([]);
  const [signatures, setSignatures] = useState<string[]>([]);
  const [formattedCallData, setFormattedCallData] = useState<ProposalCreationCallData[]>([]);
  const [callDatas, setCallDatas] = useState<string[][]>([]);
  const [requestId, setRequestId] = useState("");

  const protocolInfo = useCurrentProtocol();
  const history = useHistory();
  const { account } = useContext(CurrentAccountContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const [form] = AntdForm.useForm();
  const containerElement = useRef<HTMLDivElement>(null);
  const { trackCreateProposal } = useMixpanel();
  const sdk = useSdk();
  const protocolInSdk = sdk?.getProtocol(protocolInfo?.cname || "");
  //the condition check here is temporary
  const createOnChainProposalAdapter = protocolInSdk?.adapter("createOnChainProposal", instance || "onchain");
  const isMultisigSignIn = useIsMultisigSignIn();
  const isEmailSignIn = useIsEmailSignIn();
  const { dispatch } = useContext(NotificationsContext);
  const [{ connectedChain }, setChain] = useSetChain();

  useEffect(() => {
    let newContractFunctions = null;
    const { proposalAddress } = formValues;

    const getContractFunctions = async () => {
      if (proposalAddress?.length === 42) {
        try {
          newContractFunctions = JSON.parse(
            (await createOnChainProposalAdapter?.getContractFunctions(proposalAddress)) || "",
          );
          if (newContractFunctions) {
            const filteredContractFunctions = newContractFunctions.filter(
              (func: any) => func.stateMutability !== "view",
            );
            setContractFunctions(filteredContractFunctions);
            setVerifyAddressState("verified");
          } else {
            setVerifyAddressState("invalid");
          }
        } catch (error) {
          setVerifyAddressState("invalid");
          console.error(error);
        }
      } else if (proposalAddress?.length > 0) {
        setVerifyAddressState("verifying");
      } else {
        setVerifyAddressState("");
      }
    };

    getContractFunctions();
  }, [formValues, createOnChainProposalAdapter]);

  useEffect(() => {
    const { method } = formValues;
    if (method) {
      const selectedFunction = contractFunctions.find((func) => func.name === method);
      setCallDataTopics(selectedFunction?.inputs || []);
    }
  }, [formValues, contractFunctions]);

  const onSubmit = useCallback(
    async ({ name }: any) => {
      if (isMultisigSignIn) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the multisig wallet to create a proposal." },
        });
        return;
      }
      if (isEmailSignIn) {
        dispatch({
          type: "onError",
          payload: { message: "Please connect the wallet to create a proposal." },
        });
        return;
      }

      const chainIdForProtocol = await getConnectedChainId({
        protocolCname: protocolInfo?.cname,
        adapterType: "createOnChainProposal",
      });
      if (chainIdForProtocol && chainIdForProtocol !== connectedChain?.id) {
        await setChain({ chainId: chainIdForProtocol });
        notification.success({
          message: "Pushed a request to your wallet... Please switch networks to delegate",
          placement: "topLeft",
          duration: 6,
        });
      }

      if (account && web3Instance && formattedCallData) {
        try {
          const requestId = await createOnChainProposalAdapter?.createOnChainProposal(
            {
              description: `# ${name} \n ${editorContent}`,
              signatures,
              targets,
              values: values.map((value) => Number(value)),
              calldata: formattedCallData,
            },
            identifier,
          );
          setRequestId(requestId || "");
        } catch (error: any) {
          console.error(error);
          notification.error({
            message: "Something went wrong... Please try again",
            duration: 10,
          });
        }
      }
    },
    [
      isMultisigSignIn,
      isEmailSignIn,
      protocolInfo?.cname,
      connectedChain?.id,
      account,
      web3Instance,
      formattedCallData,
      dispatch,
      setChain,
      createOnChainProposalAdapter,
      editorContent,
      signatures,
      targets,
      values,
    ],
  );

  useEffect(() => {
    if (requestId) {
      history.push(`/${protocolInfo?.path}/proposals`);
      message.success("Proposal created");

      trackCreateProposal({
        protocol: protocolInfo?.cname || "",
        proposalId: requestId,
        userId: `${account}`,
      });
    }
  }, [account, requestId, history, protocolInfo, trackCreateProposal]);

  const isSubmitButtonDisabled = useCallback(() => {
    if (
      formValues.name?.length &&
      editorContent?.length &&
      signatures?.length &&
      targets?.length &&
      formattedCallData?.length &&
      values?.length
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    editorContent?.length,
    formattedCallData?.length,
    formValues.name?.length,
    signatures?.length,
    targets?.length,
    values?.length,
  ]);

  const checkFormAndSetReview = useCallback(() => {
    form.validateFields().then(
      () => {
        setInReview(true);
        return;
      },
      () => {
        console.log("invalid form");
      },
    );
  }, [form]);

  const ConfirmProposalCommand = useCallback(() => {
    const callDataValues = callDataTopics.map((_, i) => formValues[`topic_${i}`]);
    if (!formValues.proposalAddress || !formValues.proposalValue || !formValues.method) {
      if (!formValues.proposalValue) {
        notification.error({
          message: "Error",
          description: <Text>Please enter the Target value</Text>,
        });
      } else if (!formValues.method) {
        notification.error({
          message: "Error",
          description: <Text>Please select the Target method</Text>,
        });
      }
      return;
    }
    if (formValues.proposalAddress) setTargets([...targets, formValues.proposalAddress]);
    if (formValues.proposalValue) setValues([...values, formValues.proposalValue]);
    if (formValues.method) setSignatures([...signatures, formValues.method]);
    const newFormattedCallData = { type: formValues.method, values: callDataValues };
    setFormattedCallData((current) => [...(current || []), ...[newFormattedCallData]]);
    setCallDatas([...callDatas, [...callDataValues]]);
    setInReview(false);
    setVerifyAddressState("");
    form.resetFields(["proposalAddress", "proposalValue", "method", ...callDataTopics.map((_, i) => `topic_${i}`)]);
    setAddingProposalCommand(false);
    setContractFunctions([]);
  }, [callDataTopics, callDatas, form, formValues, signatures, targets, values]);

  const resetForm = useCallback(() => {
    setInReview(false);
    setVerifyAddressState("");
    setAddingProposalCommand(false);
    setContractFunctions([]);
    form.resetFields(["proposalAddress", "proposalValue", "method", ...callDataTopics.map((_, i) => `topic_${i}`)]);
  }, [callDataTopics, form]);

  return (
    <Container ref={containerElement}>
      <Form
        name="create-proposal-form"
        form={form}
        onValuesChange={(_, values) => setFormValues(values)}
        onSubmit={onSubmit}
      >
        <Row>
          <Col span={24}>
            <InputLabel>Proposal Title</InputLabel>
            <ProposalInput name="name" label="Proposal Title" isRequired />
            <InputLabel>Proposal Description</InputLabel>
            <StyledRichMarkdownEditor
              onChange={(value) => setEditorContent(value)}
              height={150}
              $isErrored={
                isEditorEverFocused &&
                !(Array.from(editorContent?.matchAll(/[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g) || []).length > 0)
              }
              onFocus={() => setIsEditorEverFocused(true)}
              placeholder="Your proposal description"
            />
            <ProposalCommandsContainer>
              <InputLabel>Proposal Commands</InputLabel>
              {!inReview && addingProposalCommand && (
                <>
                  <InnerInputLabel>
                    {protocolInfo?.cname === "0xgov" || protocolInfo?.cname === "0xgov-test"
                      ? "Token Address"
                      : "Target Address"}
                  </InnerInputLabel>
                  <ProposalInput
                    suffix={<VerifyAddress state={verifyAddressState} />}
                    name="proposalAddress"
                    label="i.e. 0x..."
                    isRequired
                  />
                  <InnerInputLabel $disabled={verifyAddressState !== "verified"}>Target Value (Wei)</InnerInputLabel>
                  <ProposalInput
                    suffix={<SuffixText>Wei</SuffixText>}
                    name="proposalValue"
                    label="00"
                    disabled={verifyAddressState !== "verified"}
                    isRequired
                  />
                  <InnerInputLabel
                    style={{ display: "inline-flex", gap: "4px" }}
                    $disabled={verifyAddressState !== "verified"}
                  >
                    Method{" "}
                    <a href="https://docs.boardroom.io/" target="_blank">
                      <QuestionCircleOutlined
                        style={{
                          color: COLORS.primary.accent,
                          width: "16px",
                          height: "16px",
                          alignSelf: "center",
                        }}
                      />{" "}
                    </a>
                  </InnerInputLabel>
                  <StyledFormItem name="method" required rules={[{ required: true, message: "" }]}>
                    <StyledSelect
                      placeholder="choose method"
                      disabled={verifyAddressState !== "verified"}
                      suffixIcon={<ArrowIcon width={14} height={14} color="#4235e1" />}
                    >
                      {contractFunctions.map((func) => (
                        <option key={func.name} value={func.name}>
                          {func.name}
                        </option>
                      ))}
                    </StyledSelect>
                  </StyledFormItem>
                  {callDataTopics.length ? (
                    <InnerInputLabel $disabled={verifyAddressState !== "verified"}>Call Data</InnerInputLabel>
                  ) : null}
                  {callDataTopics.map((topic, i) => (
                    <ProposalInput
                      prefix={
                        <PrefixContainer>
                          <span>{topic.type.charAt(0).toUpperCase() + topic.type.slice(1)}</span>
                        </PrefixContainer>
                      }
                      disabled={verifyAddressState !== "verified"}
                      key={topic.name}
                      addMarginBottom
                      name={`topic_${i}`}
                      label={getCreateProposalLabels(protocolInfo?.cname || "", topic.name, topic.type)}
                      isRequired
                    />
                  ))}
                </>
              )}
              {inReview && (
                <ReviewCommand
                  target={formValues.proposalAddress}
                  value={formValues.proposalValue}
                  method={formValues.method}
                  callDataTopicValues={callDataTopics.map((_, i) => formValues[`topic_${i}`])}
                  goBack={() => setInReview(false)}
                  confirmCommand={ConfirmProposalCommand}
                />
              )}
              {addingProposalCommand && (
                <Row>
                  {!inReview && (
                    <>
                      <Col span={12}>
                        {formattedCallData.length > 0 && (
                          <StyledCancelButton type="secondary" onClick={resetForm}>
                            Cancel
                          </StyledCancelButton>
                        )}
                      </Col>
                      <Col span={12}>
                        <StyledReviewButton
                          onClick={checkFormAndSetReview}
                          disabled={verifyAddressState !== "verified"}
                        >
                          Review
                        </StyledReviewButton>
                      </Col>
                    </>
                  )}
                </Row>
              )}
              {formattedCallData.map((_, i) => (
                <ConfirmedCommand
                  key={i}
                  target={targets[i]}
                  value={values[i]}
                  method={signatures[i]}
                  callDataTopicValues={callDatas[i]}
                  deleteThisCommandFunction={() => {
                    setSignatures([...signatures.slice(0, i), ...signatures.slice(i + 1)]);
                    setValues([...values.slice(0, i), ...values.slice(i + 1)]);
                    setFormattedCallData([...formattedCallData?.slice(0, i), ...formattedCallData?.slice(i + 1)]);
                    setTargets([...targets.slice(0, i), ...targets.slice(i + 1)]);
                    setCallDatas([...callDatas.slice(0, i), ...callDatas.slice(i + 1)]);
                  }}
                />
              ))}
            </ProposalCommandsContainer>
            <AddAnotherCommandButton
              addAnotherCommandFunction={() => {
                if (signatures.length < 10) setAddingProposalCommand(true);
              }}
              disabled={inReview || addingProposalCommand || signatures.length >= 10}
              text="Add another command"
            />
            <FormItem shouldUpdate>
              <StyledSubmitButton type="primary" htmlType="submit" block disabled={isSubmitButtonDisabled()}>
                Create Proposal
              </StyledSubmitButton>
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

export default (props: Props) => (
  <Suspense fallback={<Loader pageAlign />}>
    <CreateOnChainProposalForm {...props} />
  </Suspense>
);
