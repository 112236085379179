import { connectSearchBox } from "react-instantsearch-dom";
import React from "react";
import styled from "styled-components";

import { Input } from "../Input";
import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

interface Props {
  currentRefinement: string;
  refine: Function;
}

const StyledSearchInput = styled(Input)<{ $width: number }>`
  padding: 12px 16px;
  background: white;
  border: 0;
  box-shadow: none;
  padding-left: 0;
  height: 48px;
  .ant-input {
    font-size: 18px;
    line-height: 30px;
  }

  ${mediaQuery.lessThan("991px")`
    width: 100%;
    padding: 12px;
  `}
  svg {
    color: ${COLORS.primary.grayDarkLightest};
  }
  :hover {
    svg {
      color: #4235e1;
    }
    box-shadow: none;
  }
  :focus {
    box-shadow: none;
  }
`;

const SearchBox = ({ currentRefinement, refine }: Props) => {
  const { width } = useWindowDimensions();

  return (
    <form noValidate action="" role="search">
      <StyledSearchInput
        placeholder="Search for projects, proposals, profiles, etc"
        value={currentRefinement}
        onChange={(event) => refine(event.currentTarget.value)}
        $width={width}
        autoFocus
      />
    </form>
  );
};

const SearchConnector = connectSearchBox(SearchBox);
export default SearchConnector;
