import axios from "axios";

import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";
import { getUuid } from "./getUuid";

interface Props {
  commentId: string;
  proposalId: string;
  author: string;
  sharedWithOrg?: boolean;
  siweFunction: any;
}

export const deleteComment = async ({ author, commentId, proposalId, sharedWithOrg, siweFunction }: Props) => {
  const uuid = getUuid(author);
  if (!uuid) {
    siweFunction();
    throw new Error("You need to sign in with wallet to delete a comment");
  }
  const parsedData = {
    uuid,
    author,
    commentId,
    proposalId,
  };
  if (sharedWithOrg) {
    return (await axios.post(`${baseAPIUrl}deleteOrgNote?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
  }
  return (await axios.post(`${baseAPIUrl}deleteComment?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
};
