import React, { useContext } from "react";
import { useLocation } from "react-router";
import { Circle, LinksWrapper, NavLinkContent, WrapperNavLink } from "./styles";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { RoutePaths } from "../../constants/Routes";
import { CurrentSubscriptionDataContext } from "../../reducers/SubscriptionData";
import { useGetApiKey } from "../../hooks/useApiKey";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";

const NavLink = ({ name, path, isSelected }: { name: string; path: string; isSelected: boolean }) => {
  return (
    <WrapperNavLink>
      {isSelected && <Circle />}
      <NavLinkContent to={path} $isSelected={isSelected}>
        <h3>{name}</h3>
      </NavLinkContent>
    </WrapperNavLink>
  );
};

const DevelopersNavHub = () => {
  const { pathname } = useLocation();
  const { isSidebarExpanded } = useIsSidebarExpanded();
  const { subscriptionData } = useContext(CurrentSubscriptionDataContext);
  const { account } = useContext(CurrentAccountContext);
  const { apiKey: existingApiKey } = useGetApiKey({ address: account, suspense: false });

  return (
    <>
      <LinksWrapper
        style={{ zIndex: "2", paddingTop: "28px", width: "216px" }}
        $isMainNavCollapsed={!isSidebarExpanded}
      >
        <NavLink path={RoutePaths.billing} name="Billing" isSelected={pathname === RoutePaths.billing} />
        {!!subscriptionData && !!subscriptionData.status && !(subscriptionData?.status === "cancelled") && (
          <>
            <NavLink name="API Key" path={RoutePaths.api} isSelected={pathname === RoutePaths.api} />
          </>
        )}
        {!!subscriptionData &&
          !!subscriptionData.status &&
          !(subscriptionData?.status === "cancelled") &&
          !!existingApiKey && (
            <NavLink name="Webhooks" path={RoutePaths.webhooks} isSelected={pathname === RoutePaths.webhooks} />
          )}
        {!!subscriptionData && !!subscriptionData.status && !(subscriptionData?.status === "cancelled") && (
          <>
            <NavLink name="Usage" path={RoutePaths.usage} isSelected={pathname === RoutePaths.usage} />
          </>
        )}
      </LinksWrapper>
    </>
  );
};

export { DevelopersNavHub };
