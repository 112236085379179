import { useInfiniteQuery } from "@tanstack/react-query";
import { useContext, useMemo } from "react";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { protocols } from "../constants/protocols";
import { CurrentUuidContext } from "../reducers/CurrentUuid";

export interface GhostPostAuthor {
  id: string;
  name: string;
  slug: string;
  profile_image: string;
  cover_image: string;
  url: string;
}

export interface GhostPostTag {
  id: string;
  name: string;
  slug: string;
  description: string;
  visibility: boolean;
  url: string;
}

export interface GhostPost {
  id: string;
  uuid: string;
  title: string;
  slug: string;
  html: string;
  comment_id: string;
  feature_image: string;
  featured: boolean;
  visibility: boolean;
  created_at: string;
  updated_at: string;
  published_at: string;
  custom_excerpt: string;
  authors: GhostPostAuthor[];
  primary_author: GhostPostAuthor;
  tags: GhostPostTag[];
  protocolCname: string;
}

const findCnameMatchForTags = (tags: GhostPostTag[]) => {
  const matchedtag = tags.find((tag) => protocols[tag.name]);
  return matchedtag?.name || "aave";
};

export const useGetGhostPosts = (address?: string) => {
  const { uuid } = useContext(CurrentUuidContext);
  const account = address ? address : undefined;
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ["ghostPosts"],
    async ({ pageParam: page = 1 }) => {
      const resp = await fetch(
        `${baseAPIUrl}content/getGhostBlogPosts/${account}?uuid=${uuid}&key=${BOARDROOM_API_KEY}&page=${page}`,
      );
      const data = await resp.json();
      const formattedData = data.posts.map((post: any) => {
        return {
          ...post,
          protocolCname: findCnameMatchForTags(post.tags),
          tags: post.tags.filter((tag: any) => tag.visibility === "public"),
        };
      });
      return {
        posts: formattedData,
        page: data.pagination.page + 1,
      };
    },
    {
      getNextPageParam: (params) => params?.page,
    },
  );

  return useMemo(
    () => ({
      data: data?.pages.flatMap((page) => page.posts) || [],
      fetchNextPage,
      hasNextPage,
    }),
    [data, fetchNextPage, hasNextPage],
  );
};
