import { UserPostData } from "@boardroom/boardroom-api";
import axios from "axios";
import notification from "antd/es/notification";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { getUuid } from "./getUuid";

interface Props {
  address: string;
  userData: UserPostData & { savedProtocols?: string; bookmarkedProposals?: string };
  gnosisOwnerAddress?: string;
  siweErrorDescription?: string;
  siweFunction: any;
}

export const updateUserDetails = async ({
  address,
  userData,
  gnosisOwnerAddress,
  siweFunction,
  siweErrorDescription,
}: Props) => {
  const uuid = getUuid(address);
  if (!(userData?.uuid || uuid)) {
    notification.error({
      message: "Error",
      description: siweErrorDescription || "You need to sign in with wallet to update your profile settings",
    });
    if (siweFunction) {
      siweFunction();
    }
    throw new Error("SIWE Error");
  }

  const parsedData = {
    address,
    ens: userData?.ens || "",
    username: userData?.username || "",
    twitter: userData?.twitter || "",
    about: userData?.about || "",
    website: userData?.website || "",
    pfpUrl: userData?.pfpUrl || "",
    pfpImageId: userData?.pfpImageId || "",
    pfpThumbnailUrl: userData?.pfpThumbnailUrl || "",
    bannerImageId: userData?.bannerImageId || "",
    bannerUrl: userData?.bannerUrl || "",
    bannerThumbnailUrl: userData?.bannerThumbnailUrl || "",
    email: userData?.email || "",
    notificationPreferences: userData?.notificationPreferences || "",
    associatedAddresses: userData?.associatedAddresses || "",
    type: userData?.type || "",
    uuid: userData?.uuid || uuid,
    github: userData?.github || "",
    discord: userData?.discord || "",
    delegationPitches: userData?.delegationPitches || "",
    savedProtocols: userData?.savedProtocols || "",
    loginEmail: userData?.loginEmail || "",
    orgMemberships: userData?.orgMemberships || "",
    bookmarkedProposals: userData?.bookmarkedProposals || "",
  };
  const response = await axios.post(
    `${baseAPIUrl}updateUserDetails/${address.toLowerCase()}?key=${BOARDROOM_API_KEY}${
      gnosisOwnerAddress ? `&gnosisOwnerAddress=${gnosisOwnerAddress}` : ""
    }`,
    parsedData,
  );

  return response.data;
};
