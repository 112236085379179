import React, { useState, useContext, useEffect, useCallback, useRef, useMemo } from "react";
import { User } from "@boardroom/boardroom-api";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import notification from "antd/es/notification";
import MDEditor from "@uiw/react-md-editor";
import Switch from "antd/es/switch";
import styled from "styled-components";
import { useClickAway } from "react-use";

import { COLORS } from "../../constants/colors";
import { SearchIcon, CircleAddIcon, CircleMinusIcon, TrashIcon, EditIcon } from "../icons";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import ProtocolIcon from "../../components/ProtocolIcon";
import { useProtocols } from "../../hooks/useProtocols";
import { ProtocolDescription } from "../../types";
import { DelegationPitch, useDelegationPitchesByAddress } from "../../hooks/useDelegationPitchesByAuthor";
import { updateDelegationPitch } from "../../utils/updateDelegationPitch";
import { useMixpanel } from "../../hooks";
import { useSiweFunction } from "../../hooks/useSiweFunction";

const ButtonGroup = styled.div<{ justify?: string; marginTop?: string; padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : "24px")};
  display: flex;
  width: inherit;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "64px")};
`;

const MainButtonGroup = styled.div<{ $widthFull?: boolean }>`
  padding: 24px;
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 64px;
  position: fixed;
  bottom: 0;
  width: ${({ $widthFull }) => ($widthFull ? "100%" : "calc(100% - 48px)")};
  background: white;
}
`;

const StyledButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  height: 40px;
  border-radius: 8px;
`;

const StyledCancelButton = styled(StyledButton)`
  color: ${COLORS.primary.accent};
  font-weight: normal;
  border: none;
  width: 200px;
`;

const StyledSaveButton = styled(StyledButton)`
  font-weight: 600;
  color: #fff;
  background: ${COLORS.primary.accent};
  width: 200px;
`;

const StyledPitchButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border-radius: 8px;
`;

const PitchCancelButton = styled(StyledPitchButton)`
  color: #7b7893;
  font-weight: normal;
  border: none;
  width: 112px;
`;

const PitchSaveButton = styled(StyledPitchButton)`
  font-weight: 600;
  color: #fff;
  background: ${COLORS.secondary.purple};
  width: 112px;
`;

const PitchDeleteButton = styled(StyledPitchButton)`
  font-weight: 600;
  color: #fff;
  background: #ff0000;
  width: 112px;
`;

const StyledDiv = styled("div")`
  padding: 0 20px;
`;

const StyledText = styled.div`
  width: 100%;
  text-align: center;
  color: ${COLORS.primary.grayDarkLightest};
  margin-top: 40px;
`;

const StyledHeading = styled.div`
  margin-top: 28px;
`;
const StyledHeadingText = styled.div<{ size: string; color: string }>`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
`;

const StyledSwitch = styled(Switch)`
  float: right;
  &.ant-switch-checked {
    background: #4235e1;
  }
`;

const HelperText = styled.div<{ color: string; margin?: string; fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  line-height: 18px;
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

const InputDiv = styled.div<{ height?: string }>`
  margin-top: 14px;
  display: inline-block;
  border: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 4px 12px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  height: ${({ height }) => (height ? height : "56px")};
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

const StyledInput = styled.input<{ width?: string }>`
  display: block;
  width: ${({ width }) => (width ? width : "100%")};
  outline: none;
  border: none;
`;

const StyledSearchIcon = styled(SearchIcon)`
  color: inherit;
  float: right;
  width: 20px;
  height: 20px;
  margin: auto 0 auto auto;
`;

const StyledInputDiv = styled(InputDiv)`
  display: flex;
`;

const Container = styled.div`
  position: relative;
  z-index: 5;
  height: 260px;
`;

const List = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  margin-top: 10px;
  padding: 10px 4px;
  width: 100%;
  list-style: none;
  background: #fff;
  height: 260px;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(7, 3, 40, 0.09);
  border-radius: 8px;
  text-align: left;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: ${({ isOpen }) => (isOpen ? "all 0.2s" : "none")};
`;

const FlexItem = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  cursor: pointer;
  padding: 8px 2px;
  color: ${COLORS.primary.grayDarkLightest};
  &:hover {
    color: ${COLORS.primary.accent};
    border-radius: 4px;
  }
  &:first-child {
    margin-top: 0px;
  }
`;

const SearchText = styled.div<{ fontSize?: string; color?: string }>`
  font-size: 16px;
  line-height: 18px;
  margin: auto 0;
  fontsize: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
`;

const StyledFlex = styled.div<{ $customGap?: string }>`
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: ${({ $customGap }) => ($customGap ? $customGap : "6px")};
  cursor: pointer;
`;

const StyledFlexDiv = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  cursor: pointer;
`;

const PitchDiv = styled.div<{ showTextBox?: boolean }>`
  border-top: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 10px 0 4px;
  width: 100%;
  position: relative;
  height: ${({ showTextBox }) => (showTextBox ? "auto" : "56px")};
  &:last-of-type {
    border-bottom: 1px solid ${COLORS.secondary.purpleLighter};
  }

  &:hover {
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

const StyledRichMarkdownEditor = styled(MDEditor)<{ $isErrored: boolean }>`
  padding-bottom: 4px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  min-height: 80px;
  border-radius: 4px;
  transition: all 0.3s;
  width: 100%;
  margin-top: 16px;
  border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.secondary.purpleLighter}`)};
  &:hover {
    border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.primary.accent}`)};
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.primary.grayDarkLightest};
  }
  .w-md-editor-text-input {
    -webkit-text-fill-color: inherit;
  }
`;

const SavedDelegationPitchText = styled("p")`
  margin-bottom: 24px;
  margin-top: 12px;
  margin-left: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  max-width: 35rem;
`;

const SureToDeleteTitle = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 12px;
  color: #191540;
  max-width: 35rem;
  display: block;
  margin-left: 28px;
`;

const SureToDeleteText = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 48px;
  margin-top: 12px;
  color: #191540;
  max-width: 35rem;
  margin-left: 28px;
`;

const CancelText = styled.span`
  color: ${COLORS.primary.accent};
  margin-top: 14px;
  font-size: 16px;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;

interface Props {
  onClose: () => void;
  user: User;
  buttonWidthFull?: boolean;
}

const ProtocolWrapper = ({
  protocol,
  handleRemoveProtocol,
}: {
  protocol: ProtocolDescription;
  handleRemoveProtocol: any;
}) => {
  return (
    <StyledInputDiv height="44px" key={protocol.cname}>
      <FlexItem>
        <ProtocolIcon protocol={protocol} size="xsmall" />
        <SearchText>{protocol.name}</SearchText>
        <StyledFlex onClick={() => handleRemoveProtocol(protocol)}>
          <CircleMinusIcon width={16} height={16} color={COLORS.primary.grayDarkLightest} />
          <SearchText fontSize="14px" color={COLORS.primary.grayDarkLightest}>
            Remove
          </SearchText>
        </StyledFlex>
      </FlexItem>
    </StyledInputDiv>
  );
};

const DelegateSettings = ({ onClose, user, buttonWidthFull }: Props) => {
  const { account } = useContext(CurrentAccountContext);
  const [query, setQuery] = useState<string>("");
  // protocols that have been saved in the DB
  const [savedProtocols, setSavedProtocols] = useState<ProtocolDescription[]>([]);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { protocols } = useProtocols();
  const { delegationPitches: savedDelegationPitches, refetch } = useDelegationPitchesByAddress({
    address: user?.address,
  });
  const [showTextBox, setShowTextBox] = useState<any>({});
  const [showDeleteBox, setShowDeleteBox] = useState<any>({});
  const [editorContent, setEditorContent] = useState<{ [T: string]: string }>({});
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);
  const [newProtocols, setNewProtocols] = useState<ProtocolDescription[]>([]);
  const [protocolsToBeRemoved, setProtocolsToBeRemoved] = useState<ProtocolDescription[]>([]);
  const [delegationPitches, setDelegationPitches] = useState<DelegationPitch[]>([]);
  const { trackUpdateDelegationPitch } = useMixpanel();
  const siweFunction = useSiweFunction();

  const [isSelectedProtocolsEdited, setIsSelectedProtocolsEdited] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (savedDelegationPitches) {
      setDelegationPitches(savedDelegationPitches);
    }
  }, [savedDelegationPitches]);

  //initial loading of protocols that have already been saved to the DB
  useEffect(() => {
    if (
      savedDelegationPitches &&
      savedDelegationPitches.length &&
      savedProtocols.length === 0 &&
      !isSelectedProtocolsEdited
    ) {
      const protocolsPitchedDetails = Object.values(protocols)?.filter((protocol) =>
        savedDelegationPitches?.map((delegationPitch) => delegationPitch.protocol).includes(protocol?.cname),
      );
      setSavedProtocols([...protocolsPitchedDetails]);
    }
  }, [user, protocols, savedProtocols, savedDelegationPitches, isSelectedProtocolsEdited]);

  const allCategories = useMemo(
    () =>
      Object.values(protocols)
        .filter((a) => a.isEnabled)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .filter((a) => a.name.toLowerCase().includes(query.toLowerCase())),
    [protocols, query],
  );

  const handleShowTextBox = useCallback(
    (cname: string, value: boolean, savedDelegationPitch?: DelegationPitch) => {
      setShowTextBox({ ...showTextBox, [cname]: value });
      setShowDeleteBox((current: any) => ({ ...current, [cname]: false }));
      if (savedDelegationPitch) {
        setEditorContent((currentValue) => ({ ...currentValue, [cname]: savedDelegationPitch?.delegationPitch }));
      }
    },
    [showTextBox],
  );

  const handleShowDeleteBox = useCallback((cname: string, value: boolean) => {
    setShowDeleteBox((current: any) => ({ ...current, [cname]: value }));
    setShowTextBox((current: any) => ({ ...current, [cname]: false }));
  }, []);

  const handleEditorContent = useCallback(
    (value: string, cname: string) => {
      setEditorContent({ ...editorContent, [cname]: value });
    },
    [editorContent],
  );

  //initial loading of delegate pitches from DB into the content editors
  useEffect(() => {
    savedDelegationPitches?.map((pitch) => {
      setEditorContent((currentValue) => ({ ...currentValue, [pitch.protocol]: pitch.delegationPitch }));
    });
  }, [savedDelegationPitches]);

  const updateProfile = useCallback(() => {
    setLoading(true);
    const newProtocolsWithoutPitch =
      delegationPitches !== undefined
        ? newProtocols?.filter((protocol) => {
            return !delegationPitches?.some((pitch) => pitch.protocol === protocol.cname);
          })
        : [...newProtocols];

    if (!newProtocolsWithoutPitch.length && !protocolsToBeRemoved?.length) {
      notification.info({
        message: "Successful",
        description: "All your delegation selections and pitches have already been saved.",
      });
      return;
    }

    newProtocolsWithoutPitch.map(async (protocol) => {
      try {
        await updateDelegationPitch({
          address: account,
          delegationPitch: "Delegate",
          protocol: protocol.cname,
          trackUpdateDelegationPitch,
          siweFunction,
        });
        notification.success({
          message: "Successful",
          description: `Delegation Pitch Updated for - ${protocols[protocol.cname].name}`,
        });
        handleShowTextBox(protocol.cname, false);
      } catch (error) {
        console.error(error);
      }
    });

    // this will delete these pitches in the database
    protocolsToBeRemoved?.map(async (protocol) => {
      try {
        await updateDelegationPitch({
          address: account,
          delegationPitch: "",
          protocol: protocol.cname,
          trackUpdateDelegationPitch,
          siweFunction,
        });
        notification.success({
          message: "Successful",
          description: `You have removed - ${protocols[protocol.cname].name} from your selections`,
        });
        handleShowTextBox(protocol.cname, false);
      } catch (error) {
        console.error(error);
      }
    });

    setDelegationPitches((current) =>
      [
        ...(current || []),
        ...newProtocolsWithoutPitch.map((protocol) => ({
          address: account,
          delegationPitch: "Delegate",
          protocol: protocol.cname,
        })),
      ].filter((pitch) => !protocolsToBeRemoved?.some((protocol) => protocol.cname === pitch.protocol)),
    );
    setSavedProtocols((prevState) =>
      Array.from(new Set([...prevState, ...newProtocols].map((protocol) => protocols[protocol.cname]))),
    );
    refetch();
    setNewProtocols([]);
    setProtocolsToBeRemoved([]);
  }, [
    account,
    delegationPitches,
    handleShowTextBox,
    newProtocols,
    protocols,
    protocolsToBeRemoved,
    refetch,
    siweFunction,
    trackUpdateDelegationPitch,
  ]);

  const handleAddNewProtocol = useCallback(
    (item: ProtocolDescription) => {
      setNewProtocols((prevState) =>
        Array.from(new Set([...prevState, item].map((protocol) => protocols[protocol.cname]))),
      );
      setProtocolsToBeRemoved((current) => current.filter((protocol) => protocol.cname !== item.cname));
      setIsSelectedProtocolsEdited(true);
      setQuery("");
      setShowSearchInput(false);
    },
    [protocols],
  );

  const handleRemoveProtocol = useCallback((item: ProtocolDescription) => {
    setProtocolsToBeRemoved((prevState) => Array.from(new Set([...prevState, item])));
    setSavedProtocols((current) => current.filter((protocol) => protocol.cname !== item.cname));
    setNewProtocols((current) => current.filter((protocol) => protocol.cname !== item.cname));
    setIsSelectedProtocolsEdited(true);
  }, []);

  const handleUpdateDelegationPitch = useCallback(
    async ({ address, delegationPitch, protocol }: DelegationPitch) => {
      setLoading(true);
      try {
        await updateDelegationPitch({
          address,
          delegationPitch,
          protocol,
          trackUpdateDelegationPitch,
          siweFunction,
        });
        notification.success({
          message: "Successful",
          description: delegationPitch
            ? `Delegation Pitch Updated for - ${protocols[protocol].name}`
            : `Delegation Pitch Deleted for - ${protocols[protocol].name}`,
        });
        if (delegationPitch) {
          setDelegationPitches([
            ...(delegationPitches?.filter((pitch) => pitch.protocol !== protocol) || []),
            {
              address,
              delegationPitch,
              protocol,
            },
          ]);
        } else {
          setDelegationPitches([...(delegationPitches?.filter((pitch) => pitch.protocol !== protocol) || [])]);
          setSavedProtocols((current) => [
            ...(current?.filter((protocolDescription) => protocolDescription.cname !== protocol) || []),
          ]);
          setNewProtocols((current) => [
            ...(current?.filter((protocolDescription) => protocolDescription.cname !== protocol) || []),
          ]);
          setIsSelectedProtocolsEdited(true);
        }
        handleShowTextBox(protocol, false);
        refetch();
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [delegationPitches, handleShowTextBox, protocols, refetch, siweFunction, trackUpdateDelegationPitch],
  );

  useClickAway(containerRef, () => {
    setIsInputFocused(false);
  });

  const allProtocols = useMemo(() => savedProtocols.concat(newProtocols), [newProtocols, savedProtocols]);

  return (
    <>
      <Row style={{ marginBottom: "96px" }} gutter={[8, 8]} justify="end">
        <Col span={24}>
          <StyledDiv>
            <StyledText>
              If you’d like to represent voters on their behalf, you may opt-in to become a <br />
              delegate. You can also choose to delegate to yourself on any project.
            </StyledText>
            <StyledHeading>
              <StyledSwitch size="small" defaultChecked={false} checked={allProtocols.length >= 1} />
              <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                Become a Project Delegate
              </StyledHeadingText>
              {allProtocols.map((protocol) => (
                <ProtocolWrapper protocol={protocol} key={protocol.cname} handleRemoveProtocol={handleRemoveProtocol} />
              ))}
              {!showSearchInput && (
                <StyledFlexDiv>
                  <StyledFlex onClick={() => setShowSearchInput(true)}>
                    <CircleAddIcon width={16} height={16} color={COLORS.primary.accent} />
                    <SearchText fontSize="14px" color={COLORS.primary.accent}>
                      Add Another Project
                    </SearchText>
                  </StyledFlex>
                </StyledFlexDiv>
              )}
              <div ref={containerRef}>
                {showSearchInput && (
                  <>
                    <CancelText onClick={() => setShowSearchInput(false)}>Cancel</CancelText>
                    <StyledInputDiv height="44px">
                      <StyledInput
                        width="85%"
                        placeholder="Search Projects"
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onFocus={() => setIsInputFocused(true)}
                      />
                      <StyledSearchIcon />
                    </StyledInputDiv>
                  </>
                )}
                {showSearchInput && isInputFocused && (
                  <Container>
                    <List isOpen={true}>
                      {allCategories.map((protocol) => (
                        <FlexItem key={protocol.cname} onClick={() => handleAddNewProtocol(protocol)}>
                          <ProtocolIcon protocol={protocol} size="xsmall" />
                          <SearchText>{protocol.name}</SearchText>
                        </FlexItem>
                      ))}
                    </List>
                  </Container>
                )}
              </div>
            </StyledHeading>
            <StyledHeading>
              <StyledSwitch size="small" defaultChecked={false} checked={allProtocols.length >= 1} />
              <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                Add a delegation pitch
              </StyledHeadingText>
              <HelperText color={COLORS.primary.grayDarkLightest} margin="0 0 24px 0">
                Help voters understand why they should delegate their votes to you.
              </HelperText>
              {allProtocols.map((protocol) => {
                const savedDelegationPitch = savedDelegationPitches?.find((pitch) => pitch.protocol === protocol.cname);
                return (
                  <PitchDiv
                    showTextBox={showTextBox[protocol.cname as keyof typeof showTextBox] || !!savedDelegationPitch}
                    key={protocol.cname}
                  >
                    <FlexItem>
                      <ProtocolIcon protocol={protocol} size="xsmall" />
                      <SearchText color={COLORS.primary.grayDark}>{protocol.name}</SearchText>
                      {!showTextBox[protocol.cname as keyof typeof showTextBox] &&
                        !showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                        (!savedDelegationPitch ? (
                          <StyledFlex onClick={() => handleShowTextBox(protocol.cname, true)}>
                            <CircleAddIcon width={16} height={16} color={COLORS.primary.accent} />
                            <SearchText fontSize="14px" color={COLORS.primary.accent}>
                              Add Pitch
                            </SearchText>
                          </StyledFlex>
                        ) : (
                          <StyledFlex $customGap="24px">
                            <TrashIcon
                              onClick={() => handleShowDeleteBox(protocol.cname, true)}
                              width={16}
                              height={16}
                              color="#FF0000"
                            />
                            <EditIcon
                              onClick={() => handleShowTextBox(protocol.cname, true)}
                              width={20}
                              height={20}
                              color="#4235E1"
                            />
                          </StyledFlex>
                        ))}
                    </FlexItem>
                    {!showTextBox[protocol.cname as keyof typeof showTextBox] &&
                      !showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                      savedDelegationPitch && (
                        <SavedDelegationPitchText>{savedDelegationPitch?.delegationPitch}</SavedDelegationPitchText>
                      )}
                    {showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                      !showTextBox[protocol.cname as keyof typeof showTextBox] && (
                        <>
                          <SureToDeleteTitle>Are you sure you want to delete this delegation pitch?</SureToDeleteTitle>
                          <SureToDeleteText>
                            This delegation pitch will be deleted immediately and cannot be recovered once you confirm
                            delete.
                          </SureToDeleteText>
                          <ButtonGroup justify="flex-end" marginTop="8px" padding="8px 0 24px 0">
                            <PitchCancelButton onClick={() => handleShowDeleteBox(protocol.cname, false)}>
                              Cancel
                            </PitchCancelButton>
                            <PitchDeleteButton
                              disabled={loading}
                              onClick={() =>
                                handleUpdateDelegationPitch({
                                  address: account,
                                  delegationPitch: "",
                                  protocol: protocol.cname,
                                })
                              }
                            >
                              {loading ? "Loading" : "Delete"}
                            </PitchDeleteButton>
                          </ButtonGroup>
                        </>
                      )}
                    {showTextBox[protocol.cname as keyof typeof showTextBox] && (
                      <>
                        <StyledRichMarkdownEditor
                          onChange={(value) => handleEditorContent(value as string, protocol.cname)}
                          $isErrored={
                            isEditorEverFocused &&
                            !(
                              Array.from(
                                editorContent[protocol.cname as keyof typeof editorContent]?.matchAll(
                                  /[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g,
                                ) || [],
                              ).length > 0
                            )
                          }
                          onFocus={() => setIsEditorEverFocused(true)}
                          value={editorContent[protocol.cname]}
                          placeholder="Your delegation pitch..."
                        />
                        <ButtonGroup justify="flex-end" marginTop="8px" padding="8px 0 24px 0">
                          <PitchCancelButton
                            onClick={() => handleShowTextBox(protocol.cname, false, savedDelegationPitch)}
                          >
                            Cancel
                          </PitchCancelButton>
                          <PitchSaveButton
                            disabled={loading}
                            onClick={() =>
                              handleUpdateDelegationPitch({
                                address: account,
                                delegationPitch: editorContent[protocol.cname as keyof typeof editorContent],
                                protocol: protocol.cname,
                              })
                            }
                          >
                            {loading ? "Loading" : "Save"}
                          </PitchSaveButton>
                        </ButtonGroup>
                      </>
                    )}
                  </PitchDiv>
                );
              })}
            </StyledHeading>
          </StyledDiv>
        </Col>
      </Row>
      <MainButtonGroup $widthFull={buttonWidthFull}>
        <StyledCancelButton onClick={onClose}>Cancel</StyledCancelButton>
        <StyledSaveButton
          disabled={loading}
          onClick={() => {
            updateProfile();
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          }}
        >
          {loading ? "Loading" : "Submit"}
        </StyledSaveButton>
      </MainButtonGroup>
    </>
  );
};

export default DelegateSettings;
