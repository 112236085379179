import Button from "antd/es/button";
import Modal from "antd/lib/modal/Modal";
import React, { useState, useContext, useCallback, useRef, useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { useClickAway } from "react-use";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import media from "../../media-query";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import InstaSearch from "./InstaSearch";
import { GlobalSearchMobileContext } from "../../reducers/GlobalSearchMobile";
import { useMobileGlobalMenu } from "../../reducers/MobileGlobalMenu";
import { boardroomDiscordLink, boardRoomOrgGithubLink, boardroomTwitterLink } from "../../constants/general";
import { bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9 } from "../../constants/protocolBgImages";
import {
  BoardroomLogo,
  MenuIcon,
  SearchIcon,
  CrossIcon,
  HomeIcon,
  ProjectsListingIcon,
  TwitterIcon,
  DiscordIcon,
  GithubIcon,
  SwitchIcon,
  LogOutIcon,
  ChevronRightIcon,
  SettingsAngularIcon,
  OutlinedDevelopersIcon,
  ThinPlusIcon,
} from "../icons";
import { Pfp } from "../Pfp/Pfp";
import { RoutePaths } from "../../constants/Routes";
import { useProtocolIcon } from "../../hooks/useProtocolIcon";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const Background = styled.div<{
  boxShadow: string;
  isMenuOpen: boolean;
  backgroundNumber: any;
}>`
  background: ${({ isMenuOpen, backgroundNumber }) => (isMenuOpen ? "white" : `url(${backgroundNumber})`)};
  width: 100%;
  height: 72px;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 0;
  z-index: 10;
  margin-top: -1px;
  box-shadow: ${({ boxShadow }) => boxShadow};
  position: ${({ isMenuOpen }) => (isMenuOpen ? "fixed" : "relative")};
  ${media.greaterThan("991px")`
      display: none;
  `};
`;

const StyledMenuIcon = styled(MenuIcon)`
  width: 20px;
  height: 20px;
  color: white;
`;

const ListIconWrapper = styled.div`
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  display: flex;
  margin-left: 16px;
  justify-content: center;
  align-items: center;
`;

const SearchIconWrapper = styled.div<{ display: string }>`
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  width: 40px;
  height: 40px;
  display: ${({ display }) => display};
  justify-content: center;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-left: auto;
  display: flex;
`;

const MenuWrapper = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
  background: white;
  margin-top: 60px;
  z-index: 21;
  position: fixed;
  padding: 64px 0 132px 28px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  ${media.greaterThan("991px")`
      display: none;
  `};
`;

const FlexItem = styled.div`
  display: flex;
  gap: 24px;
  margin-bottom: 48px;
`;

const StyledSpan = styled.span<{ color?: string }>`
  color: ${({ color }) => (color ? color : COLORS.primary.grayDark)};
  font-size: 24px;
  line-height: 28px;
`;

const FooterIcons = styled.div`
  display: flex;
  margin: auto auto 12px;
  gap: 32px;
  justify-content: center;
  width: fit-content;
  height: 20px;
`;

const ConnectButton = styled(Button)`
  background: linear-gradient(89.28deg, #4235e1 3.44%, #ee3fd9 175.86%);
  box-shadow: 0px 0px 8px rgba(25, 21, 64, 0.2);
  border-radius: 40px;
  height: 48px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  line-height: 14px;
  margin-top: auto;
  width: 90%;
  margin-bottom: 24px;
  padding: 16px 0;
`;

const PfpWrapper = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`;

const StyledModal = styled(Modal)`
  .ant-modal-footer {
    display: none;
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
  @media (max-width: 767px) {
    margin: -80px auto;
  }
`;

const DropDownWrapper = styled.div`
  height: 236px;
  background: white;
  width: 100%;
  border: 1px solid ${COLORS.primary.grayLighter};
  box-shadow: 0px 4px 12px rgba(25, 21, 64, 0.2);
  border-radius: 8px;
  margin-top: 22px;
`;

const InfoWrapper = styled.div`
  height: 72px;
  display: flex;
  border-radius: 8px 8px 0 0;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  align-items: center;
  padding: 0 16px;
`;

const StyledText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  color: ${COLORS.primary.grayDark};
  margin: auto 0 auto 8px;
`;

const ActionMenuWrapper = styled.div`
  padding: 32px 20px;
`;

const StyledProtocolLogo = styled.img`
  width: 44px;
  height: 44px;
  transition: transform ease-in-out 0.3s;
  margin-left: 20px;
  &:hover {
    transform: scale(1.1);
  }
`;

const BackgroundForMobile = () => {
  const { account } = useContext(CurrentAccountContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const wallet = useOnboardWallet();
  const { isMenuOpen, setMenuOpen, handleOpen } = useMobileGlobalMenu();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropDownRef = useRef(null);
  const searchBoxMobileRef = useRef(null);
  const history = useHistory();
  const { globalSearchMobileState, dispatchGlobalSearchMobileState } = useContext(GlobalSearchMobileContext);
  const standAloneProtocolIcon = useProtocolIcon({ protocolCname: standAloneProtocolCname });

  const handleClickOnWallet = useCallback(() => {
    setMenuOpen(false);
    if (!account) {
      wallet?.openWalletModal();
    }
  }, [setMenuOpen, account, wallet]);

  const backgroundNumber = useMemo(() => {
    const bgImages = [bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9];

    const index = Math.ceil(Math.random() * 7);
    return bgImages[index];
  }, []);

  useClickAway(dropDownRef, () => {
    setDropdownOpen(false);
  });

  useClickAway(searchBoxMobileRef, () => {
    dispatchGlobalSearchMobileState({ type: "TOGGLE_STATE", data: false });
  });

  let logo = (
    <BoardroomLogo
      color={isMenuOpen ? COLORS.primary.accent : "white"}
      height={24}
      width={24}
      style={{ margin: "8px  0 auto 24px" }}
    />
  );

  if (standAloneProtocolCname) {
    logo = <StyledProtocolLogo src={standAloneProtocolIcon.url} alt="protocol logo" />;
  }

  return (
    <>
      <div>
        <Background
          boxShadow={isMenuOpen ? "0px 4px 4px rgba(25, 21, 64, 0.1)" : "none"}
          isMenuOpen={isMenuOpen}
          backgroundNumber={backgroundNumber}
        >
          {!globalSearchMobileState && <Link to={RoutePaths.feed}>{logo}</Link>}
          {!globalSearchMobileState && (
            <IconWrapper>
              {!isMenuOpen && account && (
                <PfpWrapper onClick={() => setDropdownOpen(true)}>
                  <Pfp size="small" address={account} pfpUrl={userDetails?.pfpUrl} />
                </PfpWrapper>
              )}
              {!standAloneProtocolCname && (
                <SearchIconWrapper
                  display={isMenuOpen ? "none" : "flex"}
                  onClick={() =>
                    dispatchGlobalSearchMobileState({ type: "TOGGLE_STATE", data: !globalSearchMobileState })
                  }
                >
                  <SearchIcon color="white" width={24} height={24} />
                </SearchIconWrapper>
              )}
              {isMenuOpen ? (
                <CrossIcon
                  width={18}
                  height={18}
                  color={COLORS.primary.grayDarkLightest}
                  onClick={handleOpen}
                  style={{ marginRight: "12px" }}
                />
              ) : (
                <ListIconWrapper onClick={handleOpen}>
                  <StyledMenuIcon />
                </ListIconWrapper>
              )}
            </IconWrapper>
          )}
        </Background>
        {isMenuOpen && (
          <MenuWrapper>
            <Link to={RoutePaths.feed} onClick={handleOpen}>
              <FlexItem>
                <HomeIcon color={COLORS.primary.accent} width={24} height={24} />
                <StyledSpan>Home</StyledSpan>
              </FlexItem>
            </Link>
            {!standAloneProtocolCname && (
              <Link to={RoutePaths.projects} onClick={handleOpen}>
                <FlexItem>
                  <ProjectsListingIcon color={COLORS.primary.accent} width={24} height={24} />
                  <StyledSpan>Projects</StyledSpan>
                </FlexItem>
              </Link>
            )}
            <Link to={RoutePaths.settingsProfile} onClick={handleOpen}>
              <FlexItem>
                <SettingsAngularIcon color={COLORS.primary.accent} width={24} height={24} />
                <StyledSpan>Settings</StyledSpan>
              </FlexItem>
            </Link>
            <Link to={RoutePaths.create} onClick={handleOpen}>
              <FlexItem>
                <ThinPlusIcon color={COLORS.primary.accent} width={24} height={24} />
                <StyledSpan>Create</StyledSpan>
              </FlexItem>
            </Link>
            <Link to={RoutePaths.billing} onClick={handleOpen}>
              <FlexItem>
                <OutlinedDevelopersIcon color={COLORS.primary.accent} width={24} height={24} />
                <StyledSpan>Developers</StyledSpan>
              </FlexItem>
            </Link>
            {account ? (
              <ConnectButton onClick={wallet?.walletReset}>Log Out</ConnectButton>
            ) : (
              <ConnectButton onClick={handleClickOnWallet}>Connect Wallet</ConnectButton>
            )}
            <FooterIcons>
              <a href={boardRoomOrgGithubLink} target="_blank">
                <GithubIcon width={20} height={20} color={COLORS.primary.accent} />
              </a>
              <a href={boardroomTwitterLink} target="_blank">
                <TwitterIcon width={20} height={20} color={COLORS.primary.accent} />
              </a>
              <a href={boardroomDiscordLink} target="_blank">
                <DiscordIcon width={20} height={20} color={COLORS.primary.accent} />
              </a>
            </FooterIcons>
          </MenuWrapper>
        )}
        {!standAloneProtocolCname && (
          <StyledModal visible={globalSearchMobileState}>
            <div ref={searchBoxMobileRef} style={{ marginTop: "-32px" }}>
              <InstaSearch />
            </div>
          </StyledModal>
        )}
        <StyledModal visible={isDropdownOpen}>
          <DropDownWrapper ref={dropDownRef}>
            <InfoWrapper onClick={() => history.push({ pathname: `/voter/${account}` })}>
              <Pfp size="medium" address={account} pfpUrl={userDetails?.pfpUrl} />
              <StyledText>My Profile</StyledText>
              <ChevronRightIcon
                width={24}
                height={24}
                color={COLORS.primary.grayDarkLightest}
                style={{ marginLeft: "auto" }}
                onClick={() => setDropdownOpen(false)}
              />
            </InfoWrapper>
            <ActionMenuWrapper>
              <div
                style={{ display: "flex", marginBottom: "32px" }}
                onClick={() => {
                  setDropdownOpen(false);
                  wallet?.openWalletModal();
                }}
              >
                <SwitchIcon color={COLORS.primary.grayDark} width={24} height={24} />
                <span style={{ fontSize: "18px", margin: "auto 20px" }}>Switch Wallets</span>
              </div>
              <div
                style={{ display: "flex" }}
                onClick={() => {
                  setDropdownOpen(false);
                  wallet?.walletReset();
                }}
              >
                <LogOutIcon color={COLORS.primary.grayDark} width={24} height={24} />
                <span style={{ fontSize: "18px", margin: "auto 20px" }}>Log Out</span>
              </div>
            </ActionMenuWrapper>
          </DropDownWrapper>
        </StyledModal>
      </div>
    </>
  );
};
export default BackgroundForMobile;
