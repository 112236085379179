import CaretDownFilled from "@ant-design/icons/CaretDownFilled";
import Col from "antd/es/col";
import Popover from "antd/es/popover";
import Drawer from "antd/es/drawer";
import Row from "antd/es/row";
import React, { useContext, useEffect, Suspense, useCallback, useMemo } from "react";
import Cookies from "universal-cookie";
import Web3Utils, { isAddress } from "web3-utils";
import notification from "antd/es/notification";
import { useSSX } from "@spruceid/ssx-react";
import styled from "styled-components";
import { useLocation, useHistory } from "react-router";
import { Breakpoint, useCurrentWidth } from "react-socks";
import ContentLoader from "react-content-loader";
import { getAddress } from "ethers/lib/utils";
import { Link } from "react-router-dom";
import web3 from "web3";
import { useWallets } from "@web3-onboard/react";

import { Avatar } from "../Avatar";
import { formatEns, toShortAddress } from "../../utils";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useUserDetails } from "../../hooks/useUserDetails";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useGetEns } from "../../hooks/useEns";
import {
  ChevronRightIcon,
  MenuIcon,
  MultisigIcon,
  OrgIcon,
  PersonCircleIcon,
  RocketBoostIcon,
  SettingsAngularIcon,
  SwitchWalletIcon,
  ThinCrossIcon,
  ThinLogOutIcon,
} from "../icons";
import { useIsMultisigSignIn } from "../../hooks/useIsMultisigSignIn";
import { useSiweDelegators } from "../../hooks/useSiweDelegators";
import useEvent from "../../hooks/useEvent";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { CurrentWeb3Context } from "../../reducers/CurrentWeb3Provider";
import { CurrentDaoLoginContext } from "../../reducers/DaoLogin";
import { useGetTeam } from "../../hooks/useGetTeam";
import { useSiwe } from "../../hooks/useSiwe";
import { isTeamView } from "../../utils/teamUtils";
import { Pfp } from "../Pfp/Pfp";
import { WalletDrawerContext } from "../../reducers/WalletDrawer";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { useMultipleAddressesVoter } from "../../hooks/useVoter";
import ProtocolIcon from "../ProtocolIcon";
import { getProtocolCnameFromPath, protocols } from "../../constants/protocols";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { useProposal } from "../../hooks/useProposal";
import { useMultipleAddressVotePower } from "../../hooks/useVotePower";
import formatValue from "../../utils/formatValue";
import { useMixpanel } from "../../hooks";
import { RoutePaths } from "../../constants/Routes";
import { Text } from "../Typography";
import { COLORS } from "../../constants/colors";
import { CollapsedMainNavWidth, ExpandedMainNavWidth, pageHeaderHeight } from "../../constants/styles";
import mediaQuery from "../../media-query";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

interface Props {
  openWallet: () => void;
  walletReset: () => void;
  marginTop?: string;
}

const CaratBreakpointMobile = styled(Breakpoint)`
  display: inline-block;
  svg {
    color: white;
    margin-left: 10px;
  }
`;

const StyledNavContentLarge = styled(Breakpoint)<{ $isPortal?: boolean }>`
  display: contents;
  font-size: 16px;
  color: ${({ $isPortal }) => ($isPortal ? "#4235e1" : "white")};
`;

const ProfileWrapper = styled.div<{ $isDropdownOpen?: boolean }>`
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 12px 0 8px;
  transition: 0.3s all;
  width: 280px;
  border-radius: 8px;
  height: ${pageHeaderHeight};
  .view-profile-username {
    display: flex;
  }
  .menu-icon {
    color: #191540;
    border-left: 1px solid ${COLORS.primary.grayLighter};
    height: 100%;
    padding: 0 24px;
    width: 68px;
  }
  .cross-icon {
    color: #191540;
    border-left: 1px solid ${COLORS.primary.grayLighter};
    height: 100%;
    padding: 0 24px;
    width: 68px;
    background: white;
  }
  :hover {
    .wallet-text {
      color: #4235e1;
    }
    .menu-icon {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    .wallet-text {
      color: #291fa7;
    }
  }
  ${({ $isDropdownOpen }) =>
    $isDropdownOpen &&
    `
    border-right: none;
    border-radius: 4px;
    svg {
      color: #4235e1;
    }
    :hover {
     .view-profile-username {
      display: flex;
      color: #4235e1;
     }
    }
    :active, :focus {
      background: #F0EFF8;
      box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
      border: none;
     .view-profile-username {
      display: flex;
      color: #291FA7;
     }
    }
  `}
`;

const ConnectedWalletWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  text-align: center;
  margin-right: 12px;
  height: 100%;
`;

const ConnectedWalletText = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  color: #7b7893;
`;

const WalletText = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
  max-width: 7.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledDrawer = styled(Drawer)<{ $isSidebarExpanded: boolean }>`
  top: ${pageHeaderHeight};
  height: calc(100% - ${pageHeaderHeight});
  .ant-drawer-content {
    background: #fafafa;
  }
  .ant-drawer-mask {
    opacity: 0.2 !important;
    left: ${({ $isSidebarExpanded }) => ($isSidebarExpanded ? ExpandedMainNavWidth : CollapsedMainNavWidth)};
  }
  .ant-drawer-content-wrapper {
    width: calc(33% - 28px) !important;
    ${mediaQuery.lessThan("991px")`
      width: auto !important;
      max-width: 370px !important;
    `}
  }
  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0 20px 0 12px;
  }

  ${mediaQuery.greaterThan("1600px")`
    .ant-drawer-body {
      max-width: 500px;
    }
  `}
`;

// const DrawerFooter = styled.div`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-top: 1px solid #f0eff8;
//   padding-top: 12px;
// `;

const BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-top: auto;
  border-top: 1px solid #f0eff8;
  position: absolute;
  width: 100%;
  margin-left: -12px;
  padding: 16px 20px 0;
  bottom: 0;
`;

const DrawerFooterItem = styled.div<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 16px;
  color: #191540;
  ${({ $disabled }) => $disabled && "opacity: 0.3; cursor: not-allowed;"}
  svg {
    margin-right: 10px;
  }
  transition: 0.3s all;
  :hover {
    color: #4235e1;
  }
  :active,
  :focus {
    color: #291fa7;
  }
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
`;

const FlexColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const WalletsText = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: #191540;
`;

const ProjectsText = styled.span`
  font-weight: 400;
  color: #7b7893;
`;

const Username = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
`;

const WalletWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0eff8;
  border-radius: 8px;
  padding: 8px;
  height: 80px;
  flex-shrink: 0;
  cursor: pointer;
  transition: 0.3s all;
  .hoverSection {
    display: none;
  }
  :hover {
    border: 1px solid #4235e1;
    .hoverSection {
      display: flex;
    }
    .hoverable {
      color: #4235e1;
    }
    .rocketIcon {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    border: 1px solid #291fa7;
    background: #f0eff8;
    .hoverable {
      color: #4235e1;
    }
    .rocketIcon {
      color: white;
      background: #4235e1;
    }
  }
`;

const WalletWrapperLink = styled(Link)`
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #f0eff8;
  border-radius: 8px;
  padding: 8px;
  height: 80px;
  cursor: pointer;
  transition: 0.3s all;
  :hover {
    border: 1px solid #4235e1;
    .hoverable {
      color: #4235e1;
    }
    .rocketIcon {
      color: #4235e1;
    }
  }
  :active,
  :focus {
    border: 1px solid #291fa7;
    background: #f0eff8;
    .hoverable {
      color: #4235e1;
    }
    .rocketIcon {
      color: white;
      background: #4235e1;
    }
  }
`;

const WalletsWrapper = styled.div<{ $hasUuid?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-top: 28px;
  gap: 8px;
  overflow-y: auto;
  height: calc(100% - ${({ $hasUuid }) => ($hasUuid ? "368px" : "410px")});
`;

const AddWalletText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #7b7893;
`;

const BundleText = styled.span`
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: #7b7893;
  margin-top: 4px;
`;

const RocketIconWrapper = styled.div`
  background: #fafafa;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  padding: 12px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #191540;
  margin-right: 12px;
`;

const BannerDiv = styled.div<{ $bannerUrl?: string }>`
  height: 66px;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  color: #ffffff;
  position: absolute;
  width: 100%;
  margin-left: -12px;
  background-image: url(${({ $bannerUrl }) => $bannerUrl ?? `${process.env.PUBLIC_URL}/assets/LandingGraphic.svg`}),
    linear-gradient(180.61deg, #191540 0.53%, #000000 46.83%, #191540 99.47%);
  background-size: cover;
  background-position: center;
  span {
    margin-left: 16px;
    margin-top: 42px;
    display: flex;
  }
  ${mediaQuery.greaterThan("1600px")`
      max-width: 500px;
  `}
`;

const ActiveProtocolsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const MoreProtocols = styled.div`
  border: 1px solid #7b7893;
  border-radius: 8px;
  padding: 0 4px;
  font-weight: 500;
  font-size: 7px;
  line-height: 30px;
  color: #7b7893;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const VotePowerWrapper = styled.div`
  background: #f0eff8;
  border-radius: 12px;
  padding: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  .vote-power {
    margin-left: 4px;
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 24px;
  background: #f0eff8;
  margin: 0 8px;
`;

const TeamDetailsButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  height: 32px;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  border-radius: 4px;
  gap: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  width: 100%;
  cursor: pointer;
  margin-bottom: 12px;
  margin-top: 12px;
  transition: 0.3s all;
  border: 1px solid transparent;
  svg {
    width: 20px;
    height: 20px;
  }
  :hover {
    border: 1px solid #4235e1;
    color: #4235e1;
  }
  :active,
  :focus {
    background: #4235e1;
    color: white;
  }
`;

const WalletHoverSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: auto;
`;

const ProfileLink = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  background: transparent;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  :hover {
    color: #4235e1;
    font-weight: 500;
  }
  :focus,
  :active {
    background: #291fa7;
    border-radius: 4px;
    color: white;
  }
`;

const ConnectButton = styled.div`
  border: 1px solid #7b7893;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  border-radius: 4px;
  transition: 0.3s all;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  :hover {
    border: 1px solid #4235e1;
    color: #4235e1;
    font-weight: 500;
  }
  :focus,
  :active {
    background: #291fa7;
    color: white;
  }
`;

const StyledChevronIcon = styled(ChevronRightIcon)`
  position: absolute;
  right: 4px;
`;

const cookies = new Cookies();

const WalletContent = ({
  address,
  activeProtocols,
  votePower,
  protocol,
}: {
  address: string;
  activeProtocols?: string[];
  votePower?: number | string | "-";
  protocol?: string;
}) => {
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const { user } = useUserDetails({ address, suspense: false });
  const ens = useGetEns(address, undefined, false);
  const { trackOpenConnectWalletModalFromWalletDrawer } = useMixpanel();

  const onClick = useCallback(() => {
    wallet?.openWalletModal();
    trackOpenConnectWalletModalFromWalletDrawer({
      userId: account,
    });
  }, [account, trackOpenConnectWalletModalFromWalletDrawer, wallet]);

  const parsedVotePower = votePower === "-" ? null : formatValue(votePower, 2);

  return (
    <WalletWrapper style={{ opacity: !!protocol && !parsedVotePower ? 0.5 : 1 }} onClick={onClick}>
      <FlexDiv style={{ width: "100%" }}>
        <Pfp shape="square" address={address} size="medium" dimension={40} pfpUrl={user?.pfpUrl} noShrink />
        <div style={{ marginLeft: "12px" }}>
          <Username className="hoverable" style={{ marginBottom: "8px" }}>
            {formatEns(ens) || toShortAddress(address, true)}
          </Username>
          <FlexDiv>
            {!!protocol && !!parsedVotePower && (
              <>
                <VotePowerWrapper>
                  <ProtocolIcon size="xxsmall" protocol={protocols[protocol]} />{" "}
                  <span className="vote-power">{parsedVotePower}</span>
                </VotePowerWrapper>
                <Divider />
              </>
            )}
            <ActiveProtocolsWrapper>
              {!!activeProtocols?.length &&
                activeProtocols
                  ?.slice(0, !!protocol && !!parsedVotePower ? 3 : 5)
                  ?.map((protocol) => <ProtocolIcon size="xxsmall" protocol={protocols[protocol]} key={protocol} />)}
              {!!activeProtocols?.length && activeProtocols?.length > 5 && (
                <MoreProtocols>+{activeProtocols?.length - 5}</MoreProtocols>
              )}
            </ActiveProtocolsWrapper>
          </FlexDiv>
        </div>
        <WalletHoverSection className="hoverSection">
          <ProfileLink onClick={(e) => e.stopPropagation()} to={`/voter/${address}`}>
            Profile
          </ProfileLink>
          <ConnectButton>Connect</ConnectButton>
        </WalletHoverSection>
      </FlexDiv>
    </WalletWrapper>
  );
};

export const BoostCard = ({ firstLine, secondLine }: { firstLine?: string; secondLine?: string }) => {
  return (
    <WalletWrapperLink to={"/onboard"}>
      <FlexDiv style={{ justifyContent: "start", width: "100%", position: "relative" }}>
        <RocketIconWrapper className="rocketIcon">
          <RocketBoostIcon width={24} height={24} />
        </RocketIconWrapper>
        <FlexColumnDiv>
          <AddWalletText className="hoverable">{firstLine ?? "Add Another Wallet"}</AddWalletText>
          <BundleText>{secondLine ?? "Bundle your wallets or read other address activity"}</BundleText>
        </FlexColumnDiv>
        <StyledChevronIcon color="#7B7893" width={20} height={20} />
      </FlexDiv>
    </WalletWrapperLink>
  );
};

function NavAvatar(props: Props) {
  const { openWallet, walletReset } = props;
  const { account, dispatchAccount } = useContext(CurrentAccountContext);
  const { user } = useUserDetails({ address: account, byPassCache: true });
  const { userDetails, dispatchUserDetails } = useContext(CurrentUserDetailsContext);
  const { dispatchUuid, uuid } = useContext(CurrentUuidContext);
  const { daoLogin } = useContext(CurrentDaoLoginContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const ens = useGetEns(account);
  const width = useCurrentWidth();
  const history = useHistory();
  const orgMemberships = userDetails?.orgMemberships;
  const teamDetails = useGetTeam(orgMemberships || "");
  const isMultisigSignIn = useIsMultisigSignIn();
  const delegators = useSiweDelegators({ address: account });
  const { ssx } = useSSX();
  const { trackOpenWalletDrawer } = useMixpanel();
  const { pathname, search } = useLocation();
  useSiwe({
    trigger: pathname.startsWith("/settings") || pathname.startsWith("/people"),
  });
  const isPortal = pathname !== RoutePaths.feed;
  const isOnTeamView = isTeamView(pathname, search);
  const { isSidebarExpanded } = useIsSidebarExpanded();
  const isEmailSignin = useIsEmailSignIn();

  const hasTeam = !!teamDetails?.orgId;
  const votedProtocols = useMultipleAddressesVoter({
    addresses: hasTeam ? teamDetails?.orgWallets : [account],
    suspense: false,
  });
  const parsedAddress = isAddress(account) ? getAddress(account) : account;
  const activeProtocolsSize = useMemo(
    () => votedProtocols?.data?.votesByAddress[parsedAddress]?.protocols?.length,
    [parsedAddress, votedProtocols?.data?.votesByAddress],
  );
  const { currentRefId } = useCurrentRefId();
  const connectedWallets = useWallets();
  const { proposal } = useProposal({ refId: currentRefId, suspense: false });
  const pathnameSplitted = pathname.split("/");
  const protocolInUrl = pathnameSplitted[2] === "proposal" ? pathnameSplitted[1] : undefined;
  const protocolCnameFromPath = getProtocolCnameFromPath(protocolInUrl || "");
  const { scoresMap } = useMultipleAddressVotePower({
    protocol: proposal?.protocol || protocolCnameFromPath,
    suspense: false,
    addresses: teamDetails?.orgWallets,
  });
  const activeProtocolsForConnectedWallet = useMemo(
    () => votedProtocols?.data?.votesByAddress[parsedAddress]?.protocols?.map((protocol) => protocol.protocol),
    [parsedAddress, votedProtocols?.data?.votesByAddress],
  );

  const isTeamAdmin = teamDetails?.adminAddress?.toLowerCase() === account.toLowerCase();

  const { dispatchIsWalletDrawerOpen, isWalletDrawerOpen } = useContext(WalletDrawerContext);

  const isMobile = width < 991;
  const isMainNavCollapsedNav = !!currentRefId || !isSidebarExpanded;

  useEffect(() => {
    dispatchUserDetails({ type: "SAVE_USER_DETAILS", data: user });
  }, [user, dispatchUserDetails]);

  useEffect(() => {
    dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: false });
  }, [dispatchIsWalletDrawerOpen, pathname, account]);

  const siweFunction = useEvent(async () => {
    if (!account || !web3Instance || !daoLogin) {
      return;
    }
    const web3Util = new web3(web3Instance?.provider as any);
    let isSafeWallet = false;
    try {
      const code = await Promise.race([
        web3Util.eth.getCode(getAddress(account)),
        new Promise((_, reject) => setTimeout(() => reject(new Error("timeout")), 1000)),
      ]);
      if (code !== "0x") {
        isSafeWallet = true;
      }
    } catch (error) {
      console.error(error);
    }
    if (isSafeWallet && account.toLowerCase() === connectedWallets?.[0]?.accounts?.[0]?.address?.toLowerCase()) {
      notification.error({
        message: "Sign in with ethereum error",
        description: (
          <Text>
            Can&apos;t Sign in with ethereum with Safe,{" "}
            <a
              href="https://docs.boardroom.io/docs/learn-hub-1/yg02nk7qkgfqv-signing-in-as-a-multisig"
              target="_blank"
              rel="noopener"
            >
              checkout our guide on how to sign in as a multisig here
            </a>
          </Text>
        ),
        duration: 15,
      });
      return;
    }
    try {
      const session = (await ssx?.signIn()) as any;
      if (session?.data?.uuid?.length) {
        cookies.set(`uuid:${session?.address?.toLowerCase()}`, session?.data?.uuid, { path: "/" });
        cookies.set("lastUsedAddressForUUID", Web3Utils.toChecksumAddress(session?.address));
        dispatchUuid({ type: "SAVE_UUID", data: session?.data?.uuid });
        if (session?.data?.address !== session?.data?.walletAddress) {
          dispatchAccount({ type: "SAVE_ACCOUNT", data: session?.data?.address });
        }
      }
    } catch (error: any) {
      cookies.remove(`uuid:${account?.toLowerCase()}`, { path: "/" });
      cookies.remove("lastUsedAddressForUUID", { path: "/" });
      dispatchUuid({ type: "SAVE_UUID", data: "" });
      if (error.code === 4001 || error.code === "REJECTED_SIGNATURE" || error.code === "ACTION_REJECTED") {
        notification.error({
          message: "Canceled... You need to sign in with wallet, please try again",
          duration: 10,
        });
      }
    }
  });

  const settingsClick = useCallback(() => {
    if (!!isOnTeamView && !isTeamAdmin) return;
    history.push("/settings/profile");
  }, [history, isOnTeamView, isTeamAdmin]);

  const profileWrapperClick = useCallback(() => {
    if (isWalletDrawerOpen) {
      history.push({ pathname: `/voter/${account}` });
    } else {
      dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: !isWalletDrawerOpen });
      trackOpenWalletDrawer({
        userId: account,
      });
    }
  }, [account, dispatchIsWalletDrawerOpen, history, isWalletDrawerOpen, trackOpenWalletDrawer]);

  const closeClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      e.stopPropagation();
      dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: !isWalletDrawerOpen });
    },
    [dispatchIsWalletDrawerOpen, isWalletDrawerOpen],
  );

  return (
    <>
      <StyledDrawer
        placement="right"
        width="33%"
        zIndex={15}
        afterVisibleChange={(visible) => dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: visible })}
        visible={isWalletDrawerOpen}
        onClose={() => dispatchIsWalletDrawerOpen({ type: "TOGGLE_WALLET_DRAWER", data: false })}
        destroyOnClose
        $isSidebarExpanded={!isMainNavCollapsedNav}
      >
        <BannerDiv $bannerUrl={teamDetails?.bannerUrl || userDetails?.bannerUrl}>
          {hasTeam && (
            <div style={{ position: "absolute" }}>
              <Pfp addOutline address={account} size="small" pfpUrl={teamDetails?.pfpUrl} marginTop="42px" noShrink />
            </div>
          )}
          <span style={{ marginLeft: "56px" }}>
            Wallets
            <span style={{ fontWeight: "300", marginTop: 0, marginLeft: "4px" }}>
              {hasTeam ? teamDetails?.orgWallets?.length : 1}
            </span>
          </span>
        </BannerDiv>
        <div style={{ marginTop: "94px" }} />
        <WalletsWrapper $hasUuid={!!uuid}>
          {hasTeam ? (
            <>
              {teamDetails?.orgWallets?.map((wallet) => {
                const parsedAddress = isAddress(wallet) ? getAddress(wallet) : wallet;
                return (
                  <WalletContent
                    address={wallet}
                    key={wallet}
                    protocol={(scoresMap?.size || 0) > 0 ? proposal?.protocol || protocolCnameFromPath : undefined}
                    votePower={scoresMap?.get(parsedAddress)}
                    activeProtocols={votedProtocols?.data?.votesByAddress[parsedAddress]?.protocols?.map(
                      (protocol) => protocol.protocol,
                    )}
                  />
                );
              })}
            </>
          ) : (
            <>
              <WalletWrapper>
                <FlexDiv>
                  <Pfp
                    address={account}
                    size="medium"
                    dimension={40}
                    pfpUrl={userDetails?.pfpUrl}
                    marginTop={props.marginTop}
                    noShrink
                  />
                  <div style={{ marginLeft: "12px" }}>
                    <Username style={{ marginBottom: "8px" }} className="hoverable">
                      {formatEns(ens) || toShortAddress(account, true)}
                    </Username>
                    <ActiveProtocolsWrapper>
                      {!!activeProtocolsForConnectedWallet?.length &&
                        activeProtocolsForConnectedWallet
                          ?.slice(0, 5)
                          ?.map((protocol) => (
                            <ProtocolIcon size="xxsmall" protocol={protocols[protocol]} key={protocol} />
                          ))}
                      {!!activeProtocolsForConnectedWallet?.length && activeProtocolsForConnectedWallet?.length > 5 && (
                        <MoreProtocols>+{activeProtocolsForConnectedWallet?.length - 5}</MoreProtocols>
                      )}
                    </ActiveProtocolsWrapper>
                  </div>
                </FlexDiv>
              </WalletWrapper>
              {!standAloneProtocolCname && <BoostCard />}
            </>
          )}
        </WalletsWrapper>
        {!!userDetails?.orgMemberships && (
          <TeamDetailsButton
            to={{
              search,
              pathname: `/bundle-details/${userDetails?.orgMemberships}`,
            }}
          >
            <OrgIcon /> See Wallet Details
          </TeamDetailsButton>
        )}
        <BottomSection>
          {userDetails?.orgMemberships && isOnTeamView ? (
            <DrawerFooterItem
              onClick={() =>
                history.push({
                  pathname: `/bundle/${userDetails?.orgMemberships}`,
                  search: search || `bundle=${userDetails?.orgMemberships}`,
                })
              }
              key="profile"
            >
              <PersonCircleIcon width={20} height={20} />
              <span>Bundled Profile</span>
            </DrawerFooterItem>
          ) : (
            <DrawerFooterItem onClick={() => history.push({ pathname: `/voter/${account}` })} key="profile">
              <PersonCircleIcon width={20} height={20} />
              <span>My Profile</span>
            </DrawerFooterItem>
          )}
          <DrawerFooterItem onClick={() => openWallet()} key="switchWallet">
            <SwitchWalletIcon width={20} height={20} />
            <span>Switch Wallet</span>
          </DrawerFooterItem>

          {delegators?.length ? (
            <>
              {!isMultisigSignIn && (
                <DrawerFooterItem onClick={siweFunction} key="multisigSignIn">
                  <MultisigIcon width={20} height={20} />
                  <span>Multisig Sign In</span>
                </DrawerFooterItem>
              )}
            </>
          ) : (
            <Popover
              content={
                <a
                  target="_blank"
                  rel="noopener"
                  href="https://docs.boardroom.io/docs/learn-hub-1/yg02nk7qkgfqv-signing-in-as-a-multisig"
                >
                  Checkout our guide on how to sign in as a multisig here
                </a>
              }
              zIndex={1050}
              trigger="hover"
              overlayInnerStyle={{ maxWidth: "20rem" }}
            >
              {!isMultisigSignIn && (
                <DrawerFooterItem
                  key="multisigSignIn"
                  style={{
                    opacity: 0.3,
                    cursor: "not-allowed",
                  }}
                >
                  <MultisigIcon width={20} height={20} />
                  <span>Multisig Sign In</span>
                </DrawerFooterItem>
              )}
            </Popover>
          )}
          <DrawerFooterItem onClick={settingsClick} key="settings" $disabled={!!isOnTeamView && !isTeamAdmin}>
            <SettingsAngularIcon width={20} height={20} />
            <span>Settings</span>
          </DrawerFooterItem>
          <DrawerFooterItem onClick={walletReset} key="logout">
            <ThinLogOutIcon width={20} height={20} />
            <span>Logout</span>
          </DrawerFooterItem>
        </BottomSection>
      </StyledDrawer>
      {isMobile ? (
        <div>
          {isOnTeamView && teamDetails ? (
            <Pfp address={account} size="small" pfpUrl={teamDetails?.pfpUrl} marginTop={props.marginTop} />
          ) : (
            <Pfp address={account} size="small" pfpUrl={userDetails?.pfpUrl} marginTop={props.marginTop} />
          )}
        </div>
      ) : (
        <div onClick={profileWrapperClick}>
          <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
            <CaratBreakpointMobile medium down>
              {isOnTeamView && teamDetails ? (
                <Pfp address={account} size="small" pfpUrl={teamDetails?.pfpUrl} marginTop={props.marginTop} />
              ) : (
                <Pfp address={account} size="small" pfpUrl={userDetails?.pfpUrl} marginTop={props.marginTop} />
              )}
              <CaretDownFilled style={{ color: isPortal ? "#4235e1" : "white" }} />
            </CaratBreakpointMobile>
            <Row style={{ marginRight: "0" }} align="middle" gutter={12} justify="space-between">
              <StyledNavContentLarge large up $isPortal={isPortal}>
                <ProfileWrapper className="profile-wrapper" $isDropdownOpen={isWalletDrawerOpen}>
                  <Pfp
                    address={account}
                    size="medium"
                    dimension={40}
                    pfpUrl={userDetails?.pfpUrl}
                    marginTop={props.marginTop}
                  />
                  {isWalletDrawerOpen ? (
                    <FlexColumnDiv style={{ marginLeft: "12px", alignItems: "start", whiteSpace: "nowrap" }}>
                      <WalletsText>
                        {!!isTeamAdmin && <span style={{ fontWeight: "400", color: "#D225BE" }}>Admin · </span>}
                        <ProjectsText>{activeProtocolsSize || 0} Projects </ProjectsText>
                      </WalletsText>
                      <Username className="view-profile-username">View My Profile</Username>
                    </FlexColumnDiv>
                  ) : (
                    <ConnectedWalletWrapper style={{ marginLeft: "12px" }}>
                      {isEmailSignin ? (
                        <ConnectedWalletText style={{ color: COLORS.primary.accent }}>
                          CONNECTED EMAIL
                        </ConnectedWalletText>
                      ) : (
                        <ConnectedWalletText>CONNECTED WALLET</ConnectedWalletText>
                      )}
                      <WalletText className="wallet-text">
                        {formatEns(ens) || toShortAddress(account, true)}{" "}
                      </WalletText>
                    </ConnectedWalletWrapper>
                  )}
                  {!isWalletDrawerOpen && (
                    <MenuIcon
                      width={20}
                      height={20}
                      className="menu-icon"
                      style={{ right: "0px", position: "absolute" }}
                    />
                  )}
                  {isWalletDrawerOpen && (
                    <ThinCrossIcon
                      onClick={closeClick}
                      width={20}
                      height={20}
                      className="cross-icon"
                      style={{ right: "0px", position: "absolute" }}
                    />
                  )}
                </ProfileWrapper>
              </StyledNavContentLarge>
            </Row>
          </a>
        </div>
      )}
    </>
  );
}

const NavAvatarWrapper = (props: Props) => {
  const { pathname } = useLocation();
  const isPortal = pathname === RoutePaths.feed || pathname.includes("/people");
  return (
    <Suspense
      fallback={
        <Row align="middle" gutter={12} justify="space-between">
          <StyledNavContentLarge large up>
            <Col>
              <Avatar
                size="small"
                src={
                  <ContentLoader
                    speed={2}
                    width="100%"
                    height="100%"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                  >
                    <rect x="0" y="0" rx="50" ry="50" width="30" height="30" />
                  </ContentLoader>
                }
              />
            </Col>
            <Col>
              <CaretDownFilled style={{ color: isPortal ? "#4235e1" : "white" }} />
            </Col>
          </StyledNavContentLarge>
        </Row>
      }
    >
      <NavAvatar {...props} />
    </Suspense>
  );
};

export default React.memo(styled(NavAvatarWrapper)``);
