import axios from "axios";

import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";
import { getUuid } from "./getUuid";

interface Props {
  proposalId: string;
  author: string;
  protocol?: string;
  parentId?: string;
  commentBody?: string;
  sharedWithOrg?: boolean;
  siweFunction: any;
}

export const createComment = async ({ author, proposalId, commentBody, parentId, protocol, sharedWithOrg, siweFunction }: Props) => {
  const uuid = getUuid(author);
  if (!uuid) {
    siweFunction();
    throw new Error("You need to sign in with wallet to submit a comment");
  }
  const parsedData = {
    proposalId,
    author,
    protocol,
    parentId,
    commentBody,
    uuid,
  };
  if (sharedWithOrg) {
    return (await axios.post(`${baseAPIUrl}createOrgNote?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
  }

  return (await axios.post(`${baseAPIUrl}createComment?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
};
