const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

export const RoutePaths = {
  feed: "/feed",
  settingsFeed: "/settings/feed",
  settingsNotifications: "/settings/notifications",
  settingsProfile: "/settings/profile",
  settingsTeamProfile: "/settings/bundle-profile",
  settingsTeamFeed: "/settings/bundle-feed",
  settingsDelegate: "/settings/delegate",
  settingsEmail: "/settings/email",
  subscriptionBilling: "/settings/billing",
  addDao: "/add-a-dao",
  delegation: "/people",
  delegationYourProjects: "/people/your-projects",
  delegationUser: "/people/:protocol/:identifier",
  projects: standAloneProtocolCname ? "/projects" : "/",
  protocols: "/protocols",
  voterProfile: "/voter/:identifier",
  create: "/create",
  updateTags: "/update-tags",
  webhooks: "/developers/webhooks",
  api: "/developers/api",
  billing: "/developers/billing",
  usage: "/developers/usage",
  onboard: "/onboard",
  discuss: "/discuss",
  news: "/news",
  dailyBriefs: "/briefs",
  proxyFeed: "/proxy/feed",
  proxyDiscuss: "/proxy/discuss",
  proxyBriefs: "/proxy/briefs",
  proxyNews: "/proxy/news",
  protocol: {
    overview: "/:protocol"    
  },
  teamProfile: "/bundle/:identifier",
  teamDetails: "/bundle-details/:identifier",
}; 


export const NftGatedRoutes = [
  RoutePaths.dailyBriefs,
  RoutePaths.discuss
];

export const GhostWebsiteUrl = "https://news.boardroom.io";