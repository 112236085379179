import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { useOpenZeppelinProtocols } from "../../hooks/useOpenZeppelinProtocols";
import ProtocolIcon from "../ProtocolIcon";
import { protocols } from "../../constants/protocols";
import { events } from "../../pages/Webhooks";
import { COLORS } from "../../constants/colors";
import { Dropdown } from "../Dropdown";

const Tab = styled.div<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 14px;
  line-height: 36px;
  display: inline-block;
  min-width: auto;
  cursor: pointer;
  height: 36px;
  margin-bottom: 1px;

  &:after {
    ${(props) =>
      props.$active &&
      css`
        display: block;
        border-bottom: 2px solid ${COLORS.primary.grayDark};
        content: "";
      `}
  }
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const FiltersWrapper = styled.div`
  margin-top: -20px;
  margin-left: -20px;
  width: calc(100% + 40px);
  padding: 12px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: sticky;
  top: -20px;
  background: #fff;
  z-index: 2;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const FiltersDropdownsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Filters = ({
  setEvent,
  setProtocol,
  status,
  setStatus,
}: {
  setEvent: (event: string) => void;
  setProtocol: (protocol: string) => void;
  status: string;
  setStatus: (status: string) => void;
}) => {
  const { openZeppelinProtocols } = useOpenZeppelinProtocols();

  const openZeppelinProtocolsItems = useMemo(
    () => [
      {
        name: "All Protocols",
        value: "all",
      },
      ...(openZeppelinProtocols?.map((protocol) => ({
        name: (
          <div>
            <ProtocolIcon protocol={protocols[protocol]} size="xsmall" />
            <span style={{ marginLeft: "8px" }}>{protocols[protocol].name}</span>
          </div>
        ),
        value: protocol,
      })) || []),
    ],
    [openZeppelinProtocols],
  );

  const eventsWithAll = useMemo(() => {
    return [
      {
        name: "All Events",
        value: "all",
      },
      ...events,
    ];
  }, []);

  return (
    <FiltersWrapper>
      <TabsWrapper>
        <Tab $active={status === "all"} onClick={() => setStatus("all")}>
          All
        </Tab>
        <Tab $active={status === "active"} onClick={() => setStatus("active")}>
          Active
        </Tab>
        <Tab $active={status === "inactive"} onClick={() => setStatus("inactive")}>
          Inactive
        </Tab>
      </TabsWrapper>
      <FiltersDropdownsWrapper>
        <div>
          <Dropdown
            buttonStyle={{
              height: "36px",
              border: "none",
              background: "transparent",
              boxShadow: "none",
              padding: "0",
              width: "100%",
            }}
            itemListStyle={{
              width: "100%",
              maxHeight: "15rem",
              overflow: "auto",
              minWidth: "14rem",
            }}
            initialValue={openZeppelinProtocolsItems[0]}
            customArrowIconColor="#4235e1"
            items={openZeppelinProtocolsItems}
            onChange={(e) => {
              setProtocol(e!.value);
            }}
            label={null}
          />
        </div>
        <div>
          <Dropdown
            buttonStyle={{
              height: "36px",
              border: "none",
              background: "transparent",
              boxShadow: "none",
              padding: "0",
            }}
            itemListStyle={{
              width: "100%",
              minWidth: "10rem",
              marginLeft: "-4rem",
            }}
            initialValue={eventsWithAll[0]}
            customArrowIconColor="#4235e1"
            items={eventsWithAll}
            onChange={(e) => {
              setEvent(e!.value);
            }}
            label={null}
          />
        </div>
      </FiltersDropdownsWrapper>
    </FiltersWrapper>
  );
};
