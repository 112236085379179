import { Link } from "react-router-dom";
import styled from "styled-components";
import { COLORS } from "../../constants/colors";
import mediaQuery from "../../media-query";

export const Wrapper = styled.div`
  min-height: calc(100% - 40px);
  width: 100%;
  padding-bottom: 100px;
`;

export const ProjectCardWrapper = styled.div`
  background: #ffffff;
  border: 1px solid #f0eff8;
  border-radius: 8px;
  height: 88px;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  cursor: pointer;
  transition: 0.3s all;
  .showOnHover {
    display: none;
  }
  :hover {
    border: 1px solid #4235e1;
    .hoverable {
      color: #4235e1;
    }
    .hideOnHover {
      display: none;
    }
    .showOnHover {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: 20px;
      gap: 24px;
    }
  }
  :active,
  :focus {
    background: #f0eff8;
    border: 1px solid #291fa7;
  }
`;

export const ProtocolImg = styled.img`
  height: 86px;
  width: 90px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const ProtocolName = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #7b7893;
`;

export const DelegatorCount = styled.span`
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #191540;
`;

export const ProtocolInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 16px;
`;

export const VotePowerWrapper = styled.div`
  display: flex;
  align-items: center;
  right: 16px;
  position: absolute;
  text-align: left;
  width: 80px;
`;

export const VotePowerName = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #191540;
`;

export const VotePowerValue = styled.span`
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  color: #4235e1;
`;

export const ProtocolCardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SectionTitle = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.02em;
  text-transform: capitalize;
  color: #191540;
  margin-bottom: 12px;
  display: block;
`;

export const SectionInfo = styled.span`
  color: #7b7893;
`;

export const ExploreDelegates = styled(Link)`
  border: 1px solid rgba(123, 120, 147, 0.4);
  border-radius: 4px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  right: 16px;
  position: absolute;
  text-align: left;
  width: 120px;
  white-space: nowrap;
  transition: 0.3s all;
  cursor: pointer;
  :hover {
    border: 1px solid #4235e1;
    color: #4235e1;
  }
  :active,
  :focus {
    background: #4235e1;
    color: #ffffff;
  }
`;

export const HoverableInfo = styled.div``;

export const SeeActivity = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  transition: 0.3s all;
  cursor: pointer;
  :hover {
    color: #4235e1;
  }
  :active,
  :focus {
    color: #291fa7;
  }
`;

export const EditDelegation = styled.div`
  border: 1px solid #7b7893;
  border-radius: 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7b7893;
  width: 124px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  transition: 0.3s all;
  cursor: pointer;
  :hover {
    border: 1px solid #4235e1;
    color: #4235e1;
  }
  :active,
  :focus {
    background: #4235e1;
    color: #ffffff;
  }
`;

export const ShareWrapper = styled.div`
  color: #7b7893;
  transition: 0.3s all;
  cursor: pointer;
  display: flex;
  :hover {
    color: #4235e1;
  }
  :active,
  :focus {
    color: #291fa7;
  }
`;

export const YourProjectsTitle = styled.h2`
  color: ${COLORS.primary.grayDark}};
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 0;
  white-space: nowrap;
  display: block;
  &:after {
    height: 8px;
    display: block;
    border-bottom: 4px solid ${COLORS.primary.accent};
    content: "";
    margin-top: 8px;
    ${mediaQuery.lessThan("640px")`
        height: 16px;
        width: calc(100% + 24px);
        font-weight: 400;
    `}
  }
`;

export const HeaderWrapper = styled.div<{ $hasBanner?: boolean }>`
  border-bottom: 1px solid #f0eff8;
  padding-top: ${({ $hasBanner }) => ($hasBanner ? "54px" : "52px")};
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  z-index: 2;
  background: #fafafa;
  ${mediaQuery.lessThan("991px")`
    padding-top: 16px;
  `}
`;

export const ShareDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  justify-content: flex-end;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4235e1;
  cursor: pointer;
  margin-top: 6px;
`;

export const ProjectsFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 168px;
  margin-left: auto;
  .button-wrapper {
    justify-content: flex-end;
  }
`;

export const NewUpdatesText = styled.span`
  color: #d225be;
  font-size: 8px;
  font-weight: 500;
  line-height: 16px;
`;

export const PinkCircle = styled.div`
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #ee3fd9;
  position: absolute;
  top: 0;
  right: 40px;
`;
