import Row from "antd/es/row";
import Col from "antd/es/col";
import Switch from "antd/es/switch";
import notification from "antd/es/notification";
import Message from "antd/es/message";
import React, { useCallback, useContext, useEffect, useState, Suspense } from "react";
import styled, { css } from "styled-components";
import { IKContext, IKUpload } from "imagekitio-react";
import MDEditor from "@uiw/react-md-editor";
import { getAddress } from "ethers/lib/utils";
import { User } from "@boardroom/boardroom-api";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import ContentLoader from "react-content-loader";
import { useMutation } from "@tanstack/react-query";

import { TrackPageView } from "../../../components/TrackPageView";
import { COLORS } from "../../../constants/colors";
import { CameraIcon, SettingsAngularIcon } from "../../../components/icons";
import { CurrentUuidContext } from "../../../reducers/CurrentUuid";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { updateUserDetails } from "../../../utils/updateUserDetails";
import { API_URL, BOARDROOM_API_KEY } from "../../../constants/general";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { CurrentUserDetailsContext } from "../../../reducers/CurrentUserDetails";
import mediaQuery from "../../../media-query";
import UnconnectedWalletState from "../../../components/UnconnectedWalletState";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import { useMixpanel } from "../../../hooks";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { StyledSaveButton, StyledSaveButtonWrapper } from "./SettingsFeed";
import { Loader } from "../../../components/Loader";
import { useSiweFunction } from "../../../hooks/useSiweFunction";
import { RoutePaths } from "../../../constants/Routes";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const StyledRow = styled(Row)`
  margin-bottom: 120px;
  border-radius: 8px;
  padding-bottom: 48px;
`;

const StyledRowContent = styled(Row)`
  margin-left: 0 !important;
  margin-right: 0 !important;
`;

const StyledImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-size: cover;
  background-position: center;
`;

const StyledDiv = styled("div")`
  padding: 0 20px;
  margin-top: -30px;
`;

const InputDiv = styled.div`
  margin-top: 14px;
  display: inline-block;
  border: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 4px 12px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  display: block;
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  color: ${COLORS.primary.grayDarkLightest};
`;

const StyledInput = styled.input<{ width?: string }>`
  display: block;
  width: ${({ width }) => (width ? width : "100%")};
  outline: none;
  border: none;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
`;

const StyledAbout = styled(StyledLabel)`
  position: absolute;
  top: 64px;
  left: 22px;
  ${mediaQuery.lessThan("991px")`
    left: 20px;
    top: 90px;
  `}
`;

const StyledMDEditor = styled(MDEditor)`
  margin-top: 18px;
  .w-md-editor-content {
    border-radius: 7px;
    border: 1px solid ${COLORS.secondary.purpleLighter};
    padding-top: 16px;
    &:hover {
      border: 1px solid ${COLORS.primary.accent};
      label {
        color: ${COLORS.primary.accent};
      }
    }
  }
  &.w-md-editor {
    box-shadow: none;
  }
  .w-md-editor-preview {
    padding-top: 24px;
  }
  .w-md-editor-toolbar {
    border-bottom: none;
    background: transparent;
    margin-bottom: 8px;
    li > button {
      padding: 6px;
      height: auto;
    }
  }
  .w-md-editor-text-input {
    -webkit-text-fill-color: inherit;
  }
`;

const StyledSwitch = styled(Switch)`
  float: right;
  &.ant-switch-checked {
    background: #4235e1;
  }
`;

const StyledPreviewWrapper = styled.div`
  position: absolute;
  right: 14px;
  top: 20px;
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const Label = styled.label`
  margin-left: 4px;
  font-size: 10px;
  line-height: 16px;
  color: #7b7893;
`;

const StyledPreviewLabel = styled(Label)`
  float: right;
  line-height: 22px;
  margin-right: 8px;
`;

const Flex = styled.div`
  display: flex;
`;

const StyledSpan = styled.span`
  font-weight: 500;
  margin-right: 2px;
  color: ${COLORS.secondary.purpleLighter};
`;

const StyledHeading = styled.div`
  margin-top: 28px;
`;
const StyledHeadingText = styled.div<{ size: string; color: string }>`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
`;

const StyledCameraIcon = styled(CameraIcon)`
  color: #fff;
  width: 24px;
  height: 24px;
`;

const IconWrapper = styled.button`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background: transparent;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  &:hover {
    background: #000;
    opacity: 0.2;
    color: #fff;
  }
`;

const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 148px;
`;

const StyledProfileImg = styled("img")`
  border: 2px solid #ffffff;
  background: #000;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

const ProfileImageWrapper = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
  border-radius: 50%;
`;

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${mediaQuery.lessThan("991px")`
      height: 42px;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
  ${mediaQuery.lessThan("640px")`
      width: 100%;
      background: #fafafa;
      z-index: +2;
      padding-left: 2px;
      height: 64px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      display: inline-block;
      padding-top: 16px;
      white-space: nowrap;
    `}
`;

const StyledDivAlt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    overflow-x: auto;
  `}
`;

const StyledTab = styled(Link)<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}

  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${mediaQuery.lessThan("640px")`
          height: 16px;
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

const StyledCol = styled(Col)`
  background: white;
  padding-top: 12px;
  padding-left: 16px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  width: 100%;
`;

export const StyledRowMobile = styled(Row)`
  ${mediaQuery.lessThan("991px")`
    padding: 0 12px;
    margin: 0 !important;
`}
`;

export const SettingsText = styled.div`
  padding: 44px 0;
  font-weight: 600;
  font-size: 28px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: ${COLORS.primary.grayDark};
  svg {
    margin-right: 8px;
  }
  span {
    font-weight: 300;
    margin-left: 4px;
  }
  ${mediaQuery.lessThan("991px")`
    padding: 20px 0;
  `}
`;

export const MainTabsWrapper = styled.div`
  display: none;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    display: flex;
    width: calc(100vw - 16px);
    padding: 12px 0;
    overflow-x: scroll;
    justify-content: start;
    margin-right: 0px;
    `}
`;

export const StyledMainTab = styled(Link)<{ $active?: boolean }>`
  color: ${({ $active }) => ($active ? "white" : "rgba(25, 21, 64, 0.4)")};
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;

  border: 1px solid rgba(123, 120, 147, 0.2);
  border-radius: 20px;
  padding: 0px 12px;
  height: 32px;
  font-size: 16px;
  margin: 0 8px 0 0;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap: 4px;

  ${(props) =>
    props.$active &&
    css`
      background: ${COLORS.primary.accent};
      font-weight: 300;
      border: none;
    `}
  :active {
    color: ${({ $active }) => ($active ? "white" : "rgba(25, 21, 64, 0.4)")};
  }
  ${({ $active }) =>
    !$active &&
    css`
      :hover {
        background: ${COLORS.primary.grayLighter};
        color: #4235e1;
      }
    `}
  ${({ $active }) =>
    $active &&
    css`
      :hover {
        color: ${$active ? "white" : "rgba(25, 21, 64, 0.4)"};
      }
    `}
`;

export const SettingsMainNav = () => {
  const { width } = useWindowDimensions();
  const isMobile = width < 991;
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const { user: userDetails } = useUserDetails({ address: account, suspense: false, byPassCache: true });

  return (
    <>
      {isMobile && (
        <Col style={{ paddingLeft: 0 }} xs={24} lg={16}>
          <MainTabsWrapper>
            <StyledMainTab
              to={RoutePaths.settingsProfile}
              $active={pathname.endsWith(RoutePaths.settingsProfile) || pathname.endsWith(RoutePaths.settingsDelegate)}
            >
              Profile
            </StyledMainTab>
            {!!userDetails?.orgMemberships && (
              <StyledMainTab
                to={RoutePaths.settingsTeamProfile}
                $active={
                  pathname.endsWith(RoutePaths.settingsTeamProfile) || pathname.endsWith(RoutePaths.settingsTeamFeed)
                }
              >
                Bundle
              </StyledMainTab>
            )}
            {!standAloneProtocolCname && (
              <StyledMainTab
                to={"/settings/feed"}
                $active={pathname.endsWith(RoutePaths.settingsFeed) || pathname.includes("notifications")}
              >
                Preferences
              </StyledMainTab>
            )}
            <StyledMainTab
              to={RoutePaths.settingsEmail}
              $active={
                pathname.includes("membership") ||
                pathname.includes("email") ||
                pathname.includes("api") ||
                pathname.includes("billing")
              }
            >
              Account
            </StyledMainTab>
          </MainTabsWrapper>
        </Col>
      )}
    </>
  );
};

const urlEndpoint = "https://ik.imagekit.io/4fqrcsooovf";
const publicKey = "public_/bTVi2IN587BeoLfZIQcNM0GvAU=";
const authenticationEndpoint = `${API_URL}/getImageAuthentication?key=${BOARDROOM_API_KEY}`;

const SettingsProfile = () => {
  const [preview, setPreview] = useState<"edit" | "live" | "preview">("edit");
  const { account } = useContext(CurrentAccountContext);
  const { user: userDetails } = useUserDetails({ address: account, suspense: false, byPassCache: true });
  const [isSaved, setIsSaved] = useState(false);
  const [userState, setUserState] = useState<User>({ address: account });
  const { dispatchUserDetails } = useContext(CurrentUserDetailsContext);
  const [about, setAbout] = useState(userState?.about);
  const normalizedAddress = account !== "" ? getAddress(account) : "";
  const [addressType, setAddressType] = useState(userState?.type || "User");
  const { pathname } = useLocation();
  const wallet = useOnboardWallet();
  const { uuid } = useContext(CurrentUuidContext);
  const { trackUpdateProfileSettingsInProfileSettingsPage } = useMixpanel();
  const { width } = useWindowDimensions();
  const isMobile = width < 991;
  const siweFunction = useSiweFunction();

  useEffect(() => {
    if (userDetails) {
      setUserState(userDetails);
    }
  }, [userDetails, setUserState]);

  const updateUserState = useCallback(
    (userDetails: User) => {
      setUserState(userDetails);
      dispatchUserDetails({ type: "SAVE_USER_DETAILS", data: userDetails });
    },
    [dispatchUserDetails],
  );

  const setUserDefaults = useCallback(
    (user: User) => ({
      address: user?.address || account,
      ens: user?.ens || "",
      twitter: user?.twitter || "",
      username: user?.username || "",
      website: user?.website || "",
      pfpUrl: user?.pfpUrl || "",
      pfpImageId: user?.pfpImageId || "",
      email: user?.email || "",
      loginEmail: user?.loginEmail || "",
      pfpThumbnailUrl: user?.pfpThumbnailUrl || "",
      bannerImageId: user?.bannerImageId || "",
      bannerUrl: user?.bannerUrl || "",
      bannerThumbnailUrl: user?.bannerThumbnailUrl || "",
      notificationPreferences: user?.notificationPreferences || "",
      associatedAddresses: user?.associatedAddresses || "",
      github: user?.github || "",
      discord: user?.discord || "",
      delegationPitches: user?.delegationPitches || "",
      savedProtocols: user?.savedProtocols || "",
      orgMemberships: user?.orgMemberships || "",
      bookmarkedProposals: user?.bookmarkedProposals || "",
    }),
    [account],
  );

  const initialValue = setUserDefaults(userState);

  const [inputState, setInputState] = useState(initialValue);

  useEffect(() => {
    setAbout(userState?.about);
    setAddressType(userState?.type || "User");
    setInputState(setUserDefaults(userState));
  }, [userState, setUserDefaults]);

  const updateProfile = useCallback(async () => {
    const userProfile = {
      ...inputState,
      type: addressType,
      about,
      notificationPreferences: userState?.notificationPreferences,
      address: account,
      uuid,
    };

    try {
      const { data } = await updateUserDetails({
        address: normalizedAddress,
        userData: userProfile,
        gnosisOwnerAddress: undefined,
        siweFunction,
      });

      updateUserState(data);
      notification.success({
        message: "Success",
        duration: 10,
      });
      trackUpdateProfileSettingsInProfileSettingsPage({
        userId: normalizedAddress,
      });
      setIsSaved(true);
    } catch (e) {
      console.error(e);
    }
  }, [
    about,
    account,
    addressType,
    inputState,
    normalizedAddress,
    siweFunction,
    trackUpdateProfileSettingsInProfileSettingsPage,
    updateUserState,
    userState?.notificationPreferences,
    uuid,
  ]);

  const { isLoading, mutate } = useMutation(updateProfile);

  const handleInputChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    setInputState({ ...inputState, [name]: value });
  };

  const handleSubmit = () => {
    mutate();
  };

  const toggleMdEditorView = useCallback((checked: boolean) => {
    if (checked) {
      setPreview("preview");
    } else {
      setPreview("edit");
    }
  }, []);

  const onUploadPfpError = (err: any) => {
    console.log("Error", err);
    Message.error("Sorry, an error occured. Please try again");
  };

  const onUploadPfpSuccess = useCallback(
    async (res: any) => {
      const userProfile = {
        ...userState,
        email: userState?.email || "",
        uuid,
        pfpUrl: res.url,
        pfpImageId: res.fileId,
        pfpThumbnailUrl: res.thumbnailUrl,
      };
      try {
        const { data } = await updateUserDetails({
          address: normalizedAddress,
          userData: userProfile,
          gnosisOwnerAddress: undefined,
          siweFunction,
        });
        updateUserState(data);
        Message.success({ content: "Successfully uploaded profile image", key: "savingPfp", duration: 1.5 });
        trackUpdateProfileSettingsInProfileSettingsPage({
          userId: normalizedAddress,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [
      normalizedAddress,
      siweFunction,
      trackUpdateProfileSettingsInProfileSettingsPage,
      updateUserState,
      userState,
      uuid,
    ],
  );

  const triggerPfpInput = (): void => {
    const element = document.querySelector("#uploadPfp");
    if (element instanceof HTMLElement) {
      element?.click();
    }
  };

  const triggerBannerInput = (): void => {
    const element = document.querySelector("#uploadBanner");
    if (element instanceof HTMLElement) {
      element?.click();
    }
  };

  const onUploadBannerError = (err: any) => {
    console.log("Error", err);
    Message.error("Sorry, an error occured. Please try again");
  };

  const onUploadBannerSuccess = useCallback(
    async (res: any) => {
      const userProfile = {
        ...userState,
        email: userState?.email || "",
        uuid,
        bannerUrl: res.url,
        bannerImageId: res.fileId,
        bannerThumbnailUrl: res.thumbnailUrl,
      };
      try {
        const { data } = await updateUserDetails({
          address: normalizedAddress,
          userData: userProfile,
          gnosisOwnerAddress: undefined,
          siweFunction,
        });
        updateUserState(data);
        Message.success({ content: "Successfully uploaded banner image", key: "savingBanner", duration: 1.5 });
        trackUpdateProfileSettingsInProfileSettingsPage({
          userId: normalizedAddress,
        });
      } catch (e) {
        console.error(e);
      }
    },
    [
      normalizedAddress,
      siweFunction,
      trackUpdateProfileSettingsInProfileSettingsPage,
      updateUserState,
      userState,
      uuid,
    ],
  );

  const onchangeOfpfp = (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (file) {
      Message.loading({ content: "Uploading profile image..", key: "savingPfp", duration: 0 });
    }
  };

  const onchangeOfBanner = (e: React.ChangeEvent<any>) => {
    const file = e.target.files[0];
    if (file) {
      Message.loading({ content: "Uploading banner image..", key: "savingBanner", duration: 0 });
    }
  };

  return (
    <>
      <TrackPageView name={"Dashboard Settings Profile"} />
      <StyledRowMobile style={{ marginLeft: 0 }} gutter={[16, 16]}>
        <Col xl={{ span: 20, push: 0 }} lg={{ span: 20, push: 0 }} md={{ span: 20, push: 0 }}>
          <StyledRow style={{ marginLeft: isMobile ? "" : "24px" }} justify="start" gutter={20}>
            <Col style={{ paddingLeft: 0 }} xs={24} lg={16}>
              <SettingsText>
                <SettingsAngularIcon />
                Settings <span>Profile</span>
              </SettingsText>
            </Col>
            <SettingsMainNav />
            {account ? (
              <>
                <StyledCol xl={18} xxl={16}>
                  <TabsWrapper>
                    <StyledDivAlt>
                      <StyledTab to={"/settings/profile"} $active={pathname.endsWith("/profile")}>
                        Profile
                      </StyledTab>
                      <StyledTab to={"/settings/delegate"} $active={pathname.includes("delegate")}>
                        Delegation
                      </StyledTab>
                    </StyledDivAlt>
                  </TabsWrapper>
                </StyledCol>
                <Col
                  style={{
                    background: "white",
                    padding: 0,
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(25, 21, 64, 0.05)",
                    width: "100%",
                  }}
                  xl={18}
                  xxl={16}
                >
                  <>
                    <HeaderWrapper>
                      <StyledImg src={userState?.bannerUrl || `${process.env.PUBLIC_URL}/assets/ProfileBanner3.png`} />
                      <IKContext
                        publicKey={publicKey}
                        urlEndpoint={urlEndpoint}
                        authenticationEndpoint={`${authenticationEndpoint}&bypassCache=${Date.now()}`}
                      >
                        <IKUpload
                          onError={onUploadBannerError}
                          onSuccess={onUploadBannerSuccess}
                          style={{ display: "none" }}
                          id="uploadBanner"
                          onChange={onchangeOfBanner}
                        />
                      </IKContext>
                      <IconWrapper onClick={triggerBannerInput}>
                        <StyledCameraIcon />
                      </IconWrapper>
                    </HeaderWrapper>

                    <StyledRowContent gutter={[8, 8]} justify="end">
                      <Col span={24}>
                        <StyledDiv>
                          <ProfileImageWrapper>
                            <StyledProfileImg src={userState?.pfpUrl || `${process.env.PUBLIC_URL}/assets/pfp.png`} />
                            <IconWrapper onClick={triggerPfpInput}>
                              <StyledCameraIcon />
                            </IconWrapper>
                          </ProfileImageWrapper>
                          <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={`${authenticationEndpoint}&bypassCache=${Date.now()}`}
                          >
                            <IKUpload
                              onError={onUploadPfpError}
                              onSuccess={onUploadPfpSuccess}
                              style={{ display: "none" }}
                              id="uploadPfp"
                              onChange={onchangeOfpfp}
                            />
                          </IKContext>

                          <InputDiv>
                            <StyledLabel>Display Name</StyledLabel>
                            <StyledInput
                              placeholder="0x23....1234d"
                              value={inputState.username}
                              onChange={handleInputChange}
                              name="username"
                            />
                          </InputDiv>
                          <InputDiv>
                            <StyledLabel>ENS</StyledLabel>
                            <StyledInput
                              width="70%"
                              placeholder="Your ENS address"
                              value={inputState.ens}
                              onChange={handleInputChange}
                              name="ens"
                            />
                          </InputDiv>
                          <InputsContainer>
                            <div>
                              <Row gutter={[20, 8]} justify="end">
                                <Col span={24}>
                                  <StyledMDEditor
                                    placeholder="i.e. Bio, Services provided, Delegation pitch..."
                                    height={isMobile ? "60%" : 150}
                                    value={about}
                                    onChange={(value) => setAbout(value || "")}
                                    preview={preview}
                                  />
                                  <StyledAbout>About</StyledAbout>
                                  <StyledPreviewWrapper>
                                    <StyledSwitch defaultChecked={false} onChange={toggleMdEditorView} />
                                    <StyledPreviewLabel>{preview === "edit" ? "Preview" : "Edit"}</StyledPreviewLabel>
                                  </StyledPreviewWrapper>
                                </Col>
                              </Row>
                            </div>
                          </InputsContainer>
                          <InputDiv style={{ marginTop: "28px" }}>
                            <StyledLabel>Twitter</StyledLabel>
                            <Flex>
                              <StyledSpan>@</StyledSpan>
                              <StyledInput
                                width="90%"
                                placeholder="Your Twitter Handle"
                                value={inputState.twitter}
                                onChange={handleInputChange}
                                name="twitter"
                              />
                            </Flex>
                          </InputDiv>
                          <InputDiv>
                            <StyledLabel>Website</StyledLabel>
                            <StyledInput
                              placeholder="Your link here"
                              name="website"
                              value={inputState.website}
                              onChange={handleInputChange}
                            />
                          </InputDiv>
                          <StyledHeading>
                            <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                              Connect Your Email
                            </StyledHeadingText>
                            <StyledHeadingText size="16px" color={COLORS.primary.grayDarkLightest}>
                              Receive notifications from Boardroom
                            </StyledHeadingText>
                          </StyledHeading>

                          <InputDiv>
                            <StyledLabel>Email</StyledLabel>
                            <StyledInput
                              width="80%"
                              placeholder="email@address.com"
                              name="email"
                              value={inputState.email}
                              onChange={handleInputChange}
                            />
                          </InputDiv>
                        </StyledDiv>
                      </Col>
                    </StyledRowContent>
                    <StyledSaveButtonWrapper style={{ padding: "12px 20px 0" }}>
                      <StyledSaveButton disabled={isLoading || isSaved} $isSaved={isSaved} onClick={handleSubmit}>
                        {isLoading && <Loader size="xsmall" />}
                        <span>{!isLoading && !isSaved ? "Save Preferences" : isLoading ? "Saving" : "Saved"}</span>
                      </StyledSaveButton>
                    </StyledSaveButtonWrapper>
                  </>
                </Col>
              </>
            ) : (
              <Col sm={24} md={22} xl={16}>
                <Suspense
                  fallback={
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={450}
                      backgroundColor={COLORS.primary.grayLight}
                      foregroundColor={COLORS.primary.grayLighter}
                    >
                      <circle cx="30" cy="38" r="20" />
                      <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="114" r="20" />
                      <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="190" r="20" />
                      <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="266" r="20" />
                      <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="342" r="20" />
                      <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                    </ContentLoader>
                  }
                >
                  <UnconnectedWalletState
                    handleClick={wallet?.openWalletModal}
                    title="A Missed Connection"
                    subTitle={
                      <>
                        Want a more personalized experience? <br /> Connect that wallet to see more.
                      </>
                    }
                  />
                </Suspense>
              </Col>
            )}
          </StyledRow>
        </Col>
      </StyledRowMobile>
    </>
  );
};
export default React.memo(SettingsProfile);
