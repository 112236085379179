import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import styled from "styled-components";
import { Drawer } from "antd/es";

import { TrackPageView } from "../components/TrackPageView";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import MetaTags from "../components/common/MetaTags";
import { useOnboardWallet } from "../hooks/useOnboardWallet";
import UnconnectedWalletState from "../components/UnconnectedWalletState";
import { BackgroundForMobile, MainContainer } from "../components";
import { useSiwe } from "../hooks/useSiwe";
import { useGetApiSubscriptionDetails } from "../hooks/useGetApiMembership";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { CurrentSubscriptionDataContext } from "../reducers/SubscriptionData";
import { PaymentGate } from "../components/PaymentGate";
import { COLORS } from "../constants/colors";
import { Container, PaddingContainer, PageMax, HeaderText, HeaderWrapper, DevelopersMobileNav } from "./Billing";
import mediaQuery from "../media-query";
import { pageHeaderHeight } from "../constants/styles";
import { ThinCrossIcon } from "../components/icons";
import { useGetAddressWebhooks } from "../hooks/useGetAddressWebhooks";
import { CreateWebhookRow } from "../components/Webhooks/CreateWebhookRow";
import { ExistingWebhookRow } from "../components/Webhooks/ExistingWebhookRow";
import { Filters } from "../components/Webhooks/Filters";
import { useGetApiKey } from "../hooks/useApiKey";
import { CalendarSuspense } from "./DashboardPages/Calendar";

const StyledCreateButton = styled.button`
  background: #191540;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  border: none;
  width: 56%;
  max-width: 900px;
  position: fixed;
  bottom: 28px;
  height: 40px;
  cursor: pointer;
  transition: 0.3s all;
  :hover {
    background: ${COLORS.primary.accent};
  }
  :focus {
    background: ${COLORS.primary.accentDark};
  }
`;

export const StyledDrawer = styled(Drawer)`
  top: ${pageHeaderHeight};
  height: calc(100% - ${pageHeaderHeight});
  .ant-drawer-content {
    background: #fafafa;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  .ant-drawer-mask {
    opacity: 0.3 !important;
  }
  .ant-drawer-content-wrapper {
    width: calc(75% - 28px) !important;
    ${mediaQuery.lessThan("991px")`
      width: 100% !important;
    `}
  }

  .ant-drawer-header {
    display: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

export const DrawerHeader = styled.div`
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const CreateWebhookText = styled.span`
  color: ${COLORS.primary.grayDark};
  font-size: 20px;
  font-weight: 300;
  line-height: 16px;
`;

export const CreateCard = styled.div`
  border-radius: 8px;
  border: 1px solid ${COLORS.primary.grayLighter};
  margin-top: 28px;
  margin-left: 24px;
  width: 80%;
  position: relative;
  ${mediaQuery.lessThan("991px")`
    width: 90%;
  `}
`;

export const CreateCardHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  display: flex;
  align-items: center;
`;

export const CreateCardHeaderItem = styled.div`
  padding: 20px 16px 8px;
  border-right: 1px solid ${COLORS.primary.grayLighter};
  height: 58px;
`;

const WebhooksHeader = styled.div`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  padding: 8px 0;
  padding-left: 20px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: -20px;
  width: calc(100% + 40px);
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  > span {
    flex-shrink: 0;
  }
`;

const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;
  color: ${COLORS.primary.grayDarkLightest};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  gap: 16px;
  img {
    height: 100px;
  }
`;

const StyledDiv = styled("div")`
  padding: 20px;
  height: 100%;
  overflow: auto;
  max-height: 65vh;
  min-height: 40vh;
  overflow-x: hidden;
  ${mediaQuery.lessThan("991px")`
    overflow-x: auto;
  `}
`;

const EmptyWebhooks = () => {
  return (
    <EmptyWrapper>
      <img src={`${process.env.PUBLIC_URL}/assets/YourProposalsEmptyState.png`} />
      <span>No Webhooks Found</span>
    </EmptyWrapper>
  );
};

export const events = [
  {
    name: "Proposals",
    value: "proposalStateChanges",
  },
  {
    name: "Votes",
    value: "voteCastEvents",
  },
];

const WebhooksContent = () => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const { data: apiSubscriptionDetails, isLoading: isLoadingAPISubDetails } = useGetApiSubscriptionDetails(
    account,
    uuid,
  );
  const { isLoading } = useGetApiKey({ address: account, suspense: false });
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [webhookIdToEdit, setWebhookIdToEdit] = useState("");
  const [protocol, setProtocol] = useState("all");
  const [event, setEvent] = useState("all");
  const [status, setStatus] = useState("all");
  const webhooks = useGetAddressWebhooks({
    address: account,
    uuid,
  });

  const closeDrawer = useCallback(() => {
    setIsDrawerVisible(false);
    setWebhookIdToEdit("");
  }, []);

  const showDrawer = useCallback(() => {
    setIsDrawerVisible(true);
  }, []);

  const showDrawerWithEdit = useCallback((id: string) => {
    setIsDrawerVisible(true);
    setWebhookIdToEdit(id);
  }, []);

  const { dispatchSubscriptionData } = useContext(CurrentSubscriptionDataContext);

  useEffect(() => {
    if (apiSubscriptionDetails) {
      dispatchSubscriptionData({
        type: "SAVE_SUBSCRIPTION_DATA",
        data: apiSubscriptionDetails,
      });
    }
  }, [dispatchSubscriptionData, apiSubscriptionDetails]);

  const filteredWebhooks = useMemo(() => {
    if (!webhooks) {
      return [];
    }
    return webhooks.filter((webhook) => {
      if (protocol !== "all" && !webhook.protocols.split(",").includes(protocol)) {
        return false;
      }
      if (event !== "all" && webhook.type !== event) {
        return false;
      }
      if (status !== "all" && webhook.status !== status) {
        return false;
      }
      return true;
    });
  }, [event, protocol, status, webhooks]);

  if (isLoadingAPISubDetails || isLoading) {
    return (
      <div style={{ marginLeft: "-88px", width: "95%" }}>
        <CalendarSuspense />
      </div>
    );
  }
  if (!apiSubscriptionDetails?.status || apiSubscriptionDetails?.status === "cancelled") {
    return (
      <>
        <Col
          style={{
            background: "white",
            padding: 0,
            borderRadius: "8px",
            width: "100%",
            minHeight: "60vh",
            border: `1px solid ${COLORS.primary.grayLighter}`,
          }}
          xl={18}
          xxl={16}
        >
          <PaymentGate />
        </Col>
      </>
    );
  }

  return (
    <>
      <Col
        style={{
          background: "white",
          padding: 0,
          borderRadius: "8px",
          width: "100%",
          minHeight: "60vh",
          border: `1px solid ${COLORS.primary.grayLighter}`,
        }}
        xl={18}
        xxl={16}
      >
        <StyledDiv>
          <Filters status={status} setStatus={setStatus} setEvent={setEvent} setProtocol={setProtocol} />
          <WebhooksHeader>
            <span style={{ width: "110px" }}>ID</span>
            <span style={{ width: "136px" }}>Protocol</span>
            <span style={{ width: "75px" }}>Event</span>
            <span style={{ width: "56px" }}>Status</span>
            <span>Webhook URL</span>
          </WebhooksHeader>
          {filteredWebhooks?.map((webhook) => (
            <ExistingWebhookRow onClick={() => showDrawerWithEdit(webhook.id)} webhook={webhook} key={webhook.id} />
          ))}
          {!filteredWebhooks?.length && <EmptyWebhooks />}
        </StyledDiv>
        <StyledCreateButton onClick={showDrawer}>Create Webhook</StyledCreateButton>
      </Col>
      <StyledDrawer destroyOnClose zIndex={15} visible={isDrawerVisible} onClose={closeDrawer}>
        <DrawerHeader>
          <CreateWebhookText>
            <span style={{ fontWeight: "500" }}>{webhookIdToEdit ? "Edit" : "Create"}</span> Webhook
          </CreateWebhookText>
          <ThinCrossIcon
            style={{ cursor: "pointer" }}
            onClick={closeDrawer}
            color={COLORS.primary.grayDarkLightest}
            width={20}
            height={20}
          />
        </DrawerHeader>
        <CreateCard>
          <CreateCardHeader>
            <CreateCardHeaderItem style={{ width: "30%" }}>Protocol</CreateCardHeaderItem>
            <CreateCardHeaderItem style={{ width: "30%" }}>Event</CreateCardHeaderItem>
            <CreateCardHeaderItem style={{ width: "40%" }}>Webhook URL</CreateCardHeaderItem>
          </CreateCardHeader>
          <CreateWebhookRow closeDrawer={closeDrawer} webhookIdToEdit={webhookIdToEdit} />
        </CreateCard>
      </StyledDrawer>
    </>
  );
};

const Webhooks = () => {
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  useSiwe({});

  return (
    <>
      <TrackPageView name="Webhooks" />
      <MetaTags
        title="Webhooks | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <MainContainer $isDashboard>
        <BackgroundForMobile />
        <Container>
          <Row justify="start" gutter={[48, 48]} style={{ marginLeft: "0", marginRight: "0" }}>
            <Col lg={{ span: 20, offset: 4 }} xs={24}>
              <PaddingContainer>
                <PageMax>
                  <Row>
                    <Col style={{ marginBottom: "40px" }} span={24}>
                      <HeaderWrapper>
                        <HeaderText>Webhooks</HeaderText>
                      </HeaderWrapper>
                      <DevelopersMobileNav />

                      {account ? (
                        <WebhooksContent />
                      ) : (
                        <UnconnectedWalletState
                          handleClick={wallet?.openWalletModal}
                          title="A Missed Connection"
                          subTitle="Let’s fix that. Connect your wallet to access the Webhooks page and more."
                        />
                      )}
                    </Col>
                  </Row>
                </PageMax>
              </PaddingContainer>
            </Col>
          </Row>
        </Container>
      </MainContainer>
    </>
  );
};

export default Webhooks;
