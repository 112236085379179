import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import Tooltip from "antd/es/tooltip";

import { Table } from "../Table";
import { Modal } from "../Modal";
import { VoterNameColumn } from "../VoterNameColumn";
import { COLORS } from "../../constants/colors";
import { Text, Paragraph } from "../Typography";
import { useCurrentProtocol } from "../../hooks/useCurrentProtocol";
import { useProposal } from "../../hooks/useProposal";
import { useProposalVotes } from "../../hooks/useProposalVotes";
import { getProtocolPath } from "../../constants/protocols";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import formatValue from "../../utils/formatValue";
import { LightningIcon, LockAlternateIcon } from "../icons";
import media from "../../media-query";
import { displayVotedChoice } from "./VotedChoiceFormatting";
import { ProposalType } from "../../types";
import { useAdapterFramework } from "../../hooks/useAdapterFramework";
import { usePendingVotesByProposal } from "../../hooks/usePendingVotes";
import { PendingVoteDetailsContext } from "../../reducers/PendingVotes";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

interface Props {
  refId: string;
}

interface VoterDetails {
  address: string;
  protocol: string;
}

interface TableDataRow {
  voter: VoterDetails;
  key: string;
  balanceDetails: number;
  choice: any;
  reason?: string;
}

interface ProposalVoterProps {
  tableData?: Array<TableDataRow>;
}

const BalanceDetails = styled.div`
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

const VoteAmount = styled(Text)`
  width: 90px;
  text-align: left;
  margin-left: 8px;
  display: inline-block;
  color: #191540;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;
  ${media.lessThan("medium")`
      min-width: 30px;
  `}
`;

const VoteCount = styled.span<{ isVisible: boolean }>`
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: ${COLORS.primary.accent};
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.2s;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-weight: 300;
  font-size: 28px;
  line-height: 30px;
  color: ${COLORS.primary.grayDark};
  margin-bottom: 16px;
  ${media.lessThan("640px")`
    font-size: 24px;
  `}
`;

const TableWrapper = styled("div")`
  .currentUserVote {
    background-color: ${COLORS.primary.accentLighter};
  }
  .ant-table {
    overflow-x: hidden;
    ${media.lessThan("640px")`
    overflow-x: auto;
  `}
  }

  .ant-table-tbody > tr > td {
    padding: 32px 16px;
  }
  .ant-table-tbody > tr:hover {
    td {
      background: transparent !important;
    }
    cursor: pointer;
    box-shadow: 0px 0px 10px rgba(25, 21, 64, 0.1);
    background: linear-gradient(90deg, #ffffff 2%, ${COLORS.primary.grayLighter} 54%, #ffffff 99.99%);
    border-radius: 8px;
    .voter-name {
      color: ${COLORS.primary.accent};
    }
  }
`;

const TableFooter = styled("div")`
  margin-top: 20px;
  text-align: center;
`;

const ShowAllButton = styled("a")`
  padding: 10px 10px 0;
  display: block;

  &:hover {
    cursor: pointer;
  }
`;

const StyledParagraph = styled(Paragraph)`
  color: #191540;
  width: inherit;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SeeRationale = styled.button`
  background: transparent;
  border: none;
  outline: none;
  color: ${COLORS.primary.accent};
  padding: 0;
  cursor: pointer;
  font-weight: 400;
  font-size: 12px;
`;

const VoteRationaleModalHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #f0eff8;
  padding-bottom: 12px;
  margin-bottom: 16px;
  gap: 44px;
  ${media.lessThan("991px")`
  max-width: 20rem;
  gap: 16px;
  `}
`;

const Reason = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #7b7893;
  ${media.lessThan("991px")`
  max-width: 20rem;
  `}
`;

const ChoiceOnRationaleModal = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  color: #191540;
`;

const VoteRationale = ({
  reason,
  address,
  protocolPath,
  amountFormatted,
  choice,
}: {
  reason?: string;
  address: string;
  protocolPath: string;
  amountFormatted: string | number;
  choice: string;
}) => {
  const { width } = useWindowDimensions();
  const [isModalOpen, setIsModalOpen] = useState(false);
  if (!reason) {
    return null;
  }
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {reason && (
        <SeeRationale
          onClick={(e) => {
            e.stopPropagation();
            setIsModalOpen(true);
          }}
        >
          See Vote Rationale
        </SeeRationale>
      )}
      <Modal
        left={width <= 991 ? undefined : "45%"}
        customMinHeight="auto"
        zIndex={20}
        size="medium"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        customMinWidth={width <= 991 ? "auto" : undefined}
      >
        <div>
          <VoteRationaleModalHeader>
            <VoterNameColumn address={address} protocol={protocolPath} />
            <BalanceDetails>
              <LightningIcon width={12} height={16} color="#DBD8FA" />
              <VoteAmount>{amountFormatted.toLocaleString()}</VoteAmount>
            </BalanceDetails>
          </VoteRationaleModalHeader>
          <ChoiceOnRationaleModal>{choice}</ChoiceOnRationaleModal>
          <Reason>{reason}</Reason>
        </div>
      </Modal>
    </div>
  );
};

const columns = [
  {
    title: "Voter",
    key: "voter",
    dataIndex: "voter",
    width: "35%",
    render: function (voter: VoterDetails) {
      const { address, protocol } = voter;
      const protocolPath = getProtocolPath(protocol);
      return <VoterNameColumn address={address} protocol={protocolPath} />;
    },
  },
  {
    title: "Cast Power",
    key: "balanceDetails",
    dataIndex: "balanceDetails",
    width: "25%",
    align: "left" as const,
    render: function (power: number) {
      const amountFormatted = power < 1 ? power.toFixed(2) : formatValue(Math.round(power)) || 0;

      return (
        <BalanceDetails>
          <LightningIcon width={12} height={16} color="#DBD8FA" />
          <VoteAmount>{amountFormatted.toLocaleString()}</VoteAmount>
        </BalanceDetails>
      );
    },
  },
  {
    title: "Vote & Rationale",
    key: "choice",
    dataIndex: "choice",
    width: "40%",

    render: function (choice: string, rowData: TableDataRow) {
      const power = rowData.balanceDetails;
      const amountFormatted = power < 1 ? power.toFixed(2) : formatValue(Math.round(power)) || 0;
      const { address, protocol } = rowData.voter;
      const protocolPath = getProtocolPath(protocol);
      return (
        <div
          style={{
            width: "250px",
            ...(choice === "private-vote" ? { display: "flex", alignItems: "center", justifyContent: "center" } : {}),
          }}
        >
          {choice === "private-vote" ? (
            <Tooltip
              overlayInnerStyle={{ width: "25rem" }}
              title="This proposal has Shutter privacy enabled. All votes will be encrypted until the voting period has ended and the final score is calculated"
            >
              <LockAlternateIcon style={{ cursor: "help" }} width={24} height={24} color="#191540" />
            </Tooltip>
          ) : (
            <Tooltip title={choice}>
              <StyledParagraph ellipsis>{choice}</StyledParagraph>
            </Tooltip>
          )}
          <VoteRationale
            address={address}
            protocolPath={protocolPath}
            choice={choice}
            amountFormatted={amountFormatted}
            reason={rowData.reason}
          />
        </div>
      );
    },
  },
];

function ProposalVotersTable(props: Props) {
  const { refId } = props;
  const protocol = useCurrentProtocol();
  const { proposal } = useProposal({ refId });
  const { account } = useContext(CurrentAccountContext);
  const [isVisible, setIsVisible] = useState(false);
  const history = useHistory();
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;

  const { adapterFramework } = useAdapterFramework(protocol?.cname);

  const { votes, fetchNextPage, hasNextPage } = useProposalVotes({
    refId,
    limit: 50,
  });

  const pendingVotes = usePendingVotesByProposal({ refId, address: account });
  const { pendingVoteDetails, dispatchPendingVoteDetails } = useContext(PendingVoteDetailsContext);

  useEffect(() => {
    if (!pendingVoteDetails[refId] && pendingVotes) {
      dispatchPendingVoteDetails({
        type: "SAVE_PENDING_VOTE_DETAILS",
        data: pendingVotes,
      });
    }
  }, [pendingVotes, pendingVoteDetails, dispatchPendingVoteDetails, refId]);

  const data: TableDataRow[] = useMemo(
    () =>
      votes
        .map((vote) => ({
          voter: {
            address: vote.address,
            imgUrl: "",
            protocol: protocol?.cname || "",
          },
          key: vote.address,
          balanceDetails: vote.power,
          reason: vote.reason,
          choice:
            proposal && vote.choices !== undefined
              ? vote.privacy === "shutter" && ((vote.choice as any) === -1 || vote.choices === -1)
                ? "private-vote"
                : displayVotedChoice(
                    proposal?.type as ProposalType,
                    vote.choices || vote.choice,
                    proposal?.choices,
                    proposal.adapter,
                    adapterFramework,
                  )
              : "",
        }))
        .sort((a, b) => b.balanceDetails - a.balanceDetails)
        .filter((a) => !(a.voter.address.toLowerCase() === account?.toLowerCase())),
    [account, protocol, proposal, votes, adapterFramework],
  );

  const pendingUserVote: TableDataRow | null = useMemo(() => {
    if (pendingVoteDetails[refId]) {
      return {
        voter: {
          address: pendingVoteDetails[refId].address,
          imgUrl: "",
          protocol: protocol?.cname || "",
        },
        key: pendingVoteDetails[refId].address,
        balanceDetails: parseFloat(pendingVoteDetails[refId].power),
        choice:
          proposal && pendingVoteDetails[refId].choices !== undefined
            ? displayVotedChoice(
                proposal?.type as ProposalType,
                pendingVoteDetails[refId].choices,
                proposal?.choices,
                proposal.adapter,
                adapterFramework,
              )
            : "",
      };
    }
    return null;
  }, [pendingVoteDetails, refId, protocol?.cname, proposal, adapterFramework]);

  const userVote: TableDataRow[] = useMemo(
    () =>
      votes
        .map((vote) => ({
          voter: {
            address: vote.address,
            imgUrl: "",
            protocol: protocol?.cname || "",
          },
          key: vote.address,
          balanceDetails: vote.power,
          reason: vote.reason,
          choice:
            proposal && vote.choices !== undefined
              ? displayVotedChoice(
                  proposal?.type as ProposalType,
                  vote.choices || vote.choice,
                  proposal?.choices,
                  proposal.adapter,
                  adapterFramework,
                )
              : "",
        }))
        .sort((a, b) => b.balanceDetails - a.balanceDetails)
        .filter((a) => a.voter.address.toLowerCase() === account?.toLowerCase()),
    [account, protocol, proposal, votes, adapterFramework],
  );

  const aggregatedData = useMemo(() => {
    if (pendingUserVote) {
      return [pendingUserVote, ...data];
    }
    if (userVote.length) {
      return [...userVote, ...data];
    }
    return data;
  }, [userVote, data, pendingUserVote]);

  const rowClassName = (record: TableDataRow) => {
    if (record.key.toLowerCase() === account?.toLowerCase()) {
      return "currentUserVote";
    }
    return "";
  };

  const navToVoter = useCallback(
    (voter: TableDataRow) => {
      history.push({
        pathname: `/voter/${voter.voter.address}`,
        search: `?protocol=${protocol?.cname}`,
      });
    },
    [history, protocol],
  );

  return (
    <>
      <Title>
        Votes{" "}
        <VoteCount isVisible={!!aggregatedData?.length}>
          {(proposal?.totalVotes || 0) + (pendingVoteDetails[refId] && userVote.length === 0 ? 1 : 0)}
        </VoteCount>
      </Title>
      <TableWrapper>
        <Table
          onRow={(voter: TableDataRow) => {
            return {
              onClick: () => {
                navToVoter(voter);
              },
            };
          }}
          dataSource={aggregatedData?.slice(0, 5)}
          columns={columns}
          rowIsLink={true}
          inACardContainer={true}
          pagination={false}
          rowClassName={rowClassName}
          backgroundColor="#fafafa"
        />
      </TableWrapper>
      <Modal
        customMaxHeight={isMobile ? "80vh" : ""}
        zIndex={20}
        open={isVisible}
        onClose={() => setIsVisible(false)}
        size="large"
      >
        <Modal.Title>
          <Title>
            Votes
            <VoteCount isVisible={!!aggregatedData?.length}>
              {(proposal?.totalVotes || 0) + (pendingVoteDetails[refId] && userVote.length === 0 ? 1 : 0)}
            </VoteCount>
          </Title>
        </Modal.Title>

        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}
          initialLoad={false}
          threshold={1000}
          useWindow={false}
        >
          <Modal.Body>
            <TableWrapper>
              <Table
                onRow={(voter: TableDataRow) => {
                  return {
                    onClick: () => {
                      navToVoter(voter);
                    },
                  };
                }}
                dataSource={aggregatedData}
                columns={columns}
                showHeader={false}
                rowIsLink={true}
                inACardContainer={true}
                pagination={false}
                rowClassName={rowClassName}
              />
            </TableWrapper>
          </Modal.Body>
        </InfiniteScroll>
      </Modal>

      <TableFooter>
        {aggregatedData && aggregatedData?.length > 5 && (
          <ShowAllButton onClick={() => setIsVisible(true)}>SHOW MORE</ShowAllButton>
        )}
      </TableFooter>
    </>
  );
}

export default React.memo(ProposalVotersTable);

export type { ProposalVoterProps };
