import { getAddress } from "@ethersproject/address";
import { useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { isAddress } from "web3-utils";
import { useSdkWithoutSigner } from "./useSdkWithoutSigner";
import { checkAdapterFrameworkToUse } from "./useVotePowerAdapter";
import { GovernanceSDK } from "@boardroom/gov-sdk/dist";

export const getVotePowrByProtocolAndAddresses = async (
  protocol: string,
  normalizedAddresses: string[],
  sdk?: GovernanceSDK,
  adapterToUse?: string,
) => {
  const protocolInSdk = sdk?.getProtocol(protocol);
  const adapterFramework = protocolInSdk?.adapterInstances("votePower");

  const adapterFrameworkToBeUsed = adapterToUse ?? checkAdapterFrameworkToUse(adapterFramework);
  const chainId = await protocolInSdk?.adapter("votePower", adapterFrameworkToBeUsed).getChainId();
  const rpc = sdk?.transports.get("rpc").network(chainId || 1);
  const votePowerFrameWork = await protocolInSdk?.adapter("votePower", adapterFrameworkToBeUsed).getFramework();

  if (protocolInSdk?.hasAdapter("votePower", adapterFrameworkToBeUsed)) {
    try {
      let allScores;
      if (chainId === 5 || votePowerFrameWork === "openZeppelin") {
        const latestBlock = await rpc?.getBlockNumber();
        allScores = await protocolInSdk
          ?.adapter("votePower", adapterFrameworkToBeUsed)
          .getVotePower(normalizedAddresses, latestBlock ? latestBlock - 1 : latestBlock);
      } else {
        allScores = await protocolInSdk
          ?.adapter("votePower", adapterFrameworkToBeUsed)
          .getVotePower(normalizedAddresses);
      }

      const scoresByAddress = allScores.map((scores) => {
        const found = normalizedAddresses.find((address) => scores.address === address);
        if (found) {
          return scores.power;
        }
      });
      return scoresByAddress.reduce((acc, score) => {
        return (acc || 0) + (score || 0);
      }, 0);
    } catch (error) {
      console.error(error);
      if (adapterFrameworkToBeUsed === "onchain" && protocolInSdk?.hasAdapter("votePower", "snapshot")) {
        try {
          const allScores = await protocolInSdk?.adapter("votePower", "snapshot").getVotePower(normalizedAddresses);

          const scoresByAddress = allScores.map((scores) => {
            const found = normalizedAddresses.find((address) => scores.address === address);
            if (found) {
              return scores.power;
            }
          });
          return scoresByAddress.reduce((acc, score) => {
            return (acc || 0) + (score || 0);
          }, 0);
        } catch (error) {
          console.error(error);
          return "-";
        }
      }
    }
  }

  return "-";
};

export const useVotePower = (
  protocol: string,
  suspense: boolean = true,
  addresses?: string | string[],
  adapter?: string,
) => {
  const sdk = useSdkWithoutSigner();
  let normalizedAddresses: string[] = [];

  if (addresses && Array.isArray(addresses)) {
    normalizedAddresses = addresses
      .map((address) => (address && isAddress(address) ? getAddress(address) : ""))
      .filter(Boolean);
  } else if (addresses) {
    normalizedAddresses = addresses ? [isAddress(addresses) ? getAddress(addresses) : addresses] : [];
  }

  const enabled = !!normalizedAddresses?.length && !!protocol && !!sdk;

  const {
    data: power,
    isLoading,
    isError,
  } = useQuery<number | undefined | "-">(
    [`voterPower:${protocol}:${normalizedAddresses.toString()}:${adapter}`],
    async () => {
      return await getVotePowrByProtocolAndAddresses(protocol, normalizedAddresses, sdk, adapter);
    },
    {
      enabled,
      suspense,
      structuralSharing: false,
    },
  );

  return useMemo(() => {
    return typeof power !== "undefined"
      ? { power, isError, isLoading: isLoading && enabled }
      : { power: "-", isError, isLoading: isLoading && enabled };
  }, [enabled, isError, isLoading, power]);
};

export const useMultipleAddressVotePower = ({
  protocol,
  suspense = true,
  addresses,
}: {
  protocol?: string;
  suspense: boolean;
  addresses?: string[];
}) => {
  const queryClient = useQueryClient();
  const sdk = useSdkWithoutSigner();
  const normalizedAddresses = addresses
    ? addresses.map((address) => (isAddress(address) ? getAddress(address) : address))
    : [];

  const { data: power, isLoading } = useQuery<{ scoresMap: Map<string, number>; totalPower: number | undefined | "-" }>(
    [`voterPowerWithScoresMap:${protocol}:${normalizedAddresses.toString()}`],
    async () => {
      try {
        const existingData: { scoresMap: Map<string, number>; totalPower: number | undefined | "-" } | undefined =
          queryClient.getQueryData([`voterPowerWithScoresMap:${protocol}:${normalizedAddresses.toString()}`]);
        if (existingData) {
          return existingData;
        }
        if (!protocol) {
          throw new Error();
        }
        const protocolInSdk = sdk?.getProtocol(protocol);
        const adapterFramework = protocolInSdk?.adapterInstances("votePower");

        const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);

        if (protocolInSdk?.hasAdapter("votePower", adapterFrameworkToBeUsed)) {
          const allScores = await protocolInSdk
            ?.adapter("votePower", adapterFrameworkToBeUsed)
            .getVotePower(normalizedAddresses);
          const scoresMap = new Map(allScores.map((score) => [score.address, score.power]));

          const scoresByAddress = allScores.map((scores) => {
            const found = normalizedAddresses.includes(scores.address);
            if (found) {
              return scores.power;
            }
          });

          const totalPower = scoresByAddress.reduce((acc, score) => {
            return (acc || 0) + (score || 0);
          }, 0);

          return {
            totalPower,
            scoresMap,
          };
        }
      } catch (error) {
        console.error(error);
      }
      return {
        totalPower: "-",
        scoresMap: new Map(),
      };
    },
    {
      enabled: !!normalizedAddresses && !!protocol && !!sdk,
      suspense,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
      cacheTime: Infinity,
    },
  );

  const parsedPower = typeof power?.totalPower !== "undefined" ? power?.totalPower : "-";

  return {
    power: parsedPower,
    scoresMap: power?.scoresMap,
    isLoading,
  };
};

export const useHasVotePowerMultipleProtocols = (protocols: string[], address?: string) => {
  const sdk = useSdkWithoutSigner();
  const normalizedAddress = isAddress(address || "") ? getAddress(address || "") : address || "";
  const queryClient = useQueryClient();

  const { data, status } = useQuery<string[]>(
    [`voterPower:${protocols?.toString()}:${normalizedAddress}`],
    async () => {
      const promises = protocols.map(async (protocol) => {
        try {
          const protocolInSdk = sdk?.getProtocol(protocol);
          const adapterFramework = protocolInSdk?.adapterInstances("votePower");

          const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);

          const chainId = await protocolInSdk?.adapter("vote", adapterFrameworkToBeUsed).getChainId();

          if (protocolInSdk?.hasAdapter("votePower", adapterFrameworkToBeUsed)) {
            let scores;
            if (chainId === 5) {
              const jsonRpc = sdk?.transports.get("rpc").network(5);
              const latestBlock = await jsonRpc?.getBlockNumber();
              scores = await protocolInSdk
                ?.adapter("votePower", adapterFrameworkToBeUsed)
                .getVotePower([normalizedAddress], latestBlock ? latestBlock - 1 : latestBlock);
            } else {
              scores = await protocolInSdk
                ?.adapter("votePower", adapterFrameworkToBeUsed)
                .getVotePower([normalizedAddress]);
            }
            const found = scores?.find((s) => s.address === normalizedAddress);
            if (!!found && !!found.power) {
              queryClient.setQueryData([`voterPower:${protocol}:${normalizedAddress}`], found.power);
              return protocol;
            }
          }
        } catch (error) {
          console.error(error, "vote power");
        }
      });
      const awaitedPromises = (await Promise.all(promises)).filter(Boolean) as string[];
      return awaitedPromises;
    },
    {
      enabled: !!normalizedAddress && !!protocols.filter((protocol) => !!protocol)?.length && !!sdk,
      suspense: false,
    },
  );

  return useMemo(
    () => ({
      protocolsWithVotePower: Array.isArray(data) ? data : [],
      status,
    }),
    [data, status],
  );
};

export const useMultipleAddressVotePowerMultipleProtocols = ({
  protocols,
  addresses,
  isTeamView,
}: {
  protocols?: string[];
  addresses?: string[];
  isTeamView?: boolean;
}) => {
  const queryClient = useQueryClient();
  const sdk = useSdkWithoutSigner();
  const normalizedAddresses = addresses
    ? addresses.map((address) => (isAddress(address) ? getAddress(address) : address))
    : [];

  const { data: power } = useQuery(
    [`voterPowerWithScoresMapMultipleProtocols:${protocols?.toString()}:${normalizedAddresses.toString()}`],
    () => {
      return protocols?.map(async (protocol) => {
        try {
          if (!protocol) {
            throw new Error();
          }
          const protocolInSdk = sdk?.getProtocol(protocol);
          const adapterFramework = protocolInSdk?.adapterInstances("votePower");

          const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);

          if (protocolInSdk?.hasAdapter("votePower", adapterFrameworkToBeUsed)) {
            const allScores = await protocolInSdk
              ?.adapter("votePower", adapterFrameworkToBeUsed)
              .getVotePower(normalizedAddresses);
            const scoresMap = new Map(allScores.map((score) => [score.address, score.power]));

            const scoresByAddress = allScores.map((scores) => {
              const found = normalizedAddresses.includes(scores.address);
              if (found) {
                return scores.power;
              }
            });

            const totalPower = scoresByAddress.reduce((acc, score) => {
              return (acc || 0) + (score || 0);
            }, 0);
            queryClient.setQueryData([`voterPowerWithScoresMap:${protocol}:${normalizedAddresses.toString()}`], {
              totalPower,
              scoresMap,
            });
            return {
              totalPower,
              scoresMap,
            };
          }
        } catch (error) {
          console.error(error);
        }
        return {
          totalPower: "-",
          scoresMap: new Map(),
        };
      });
    },
    {
      enabled: !!normalizedAddresses && !!protocols?.length && !!sdk && isTeamView,
      suspense: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      keepPreviousData: true,
    },
  );

  return power;
};
