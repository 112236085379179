import moment, { Moment } from "moment";

import { CalendarEventTypes } from "../../types";

export function humanizeEventTypeText(text: CalendarEventTypes) {
  switch (text) {
    case "call":
      return "Event";
    case "event":
      return "Event";
    case "govWeekly":
      return "Meeting";
    case "meeting":
      return "Meeting";
    case "proposalEnd":
      return "Proposal Ends";
    case "proposalStart":
      return "Proposal Starts";
  }
}

export const isDateToday = (date: Moment) => moment(date).isSame(moment(), "day");

export const eventTypeColorCoding: Record<string, string> = {
  call: "#FCDA02",
  event: "#FCDA02",
  proposalEnd: "#EE3FD9",
  proposalStart: "#65DB52",
  govWeekly: "#35C9F8",
  meeting: "#35C9F8",
  discussion: "#35C9F8",
};

export const eventTypeColorCodingText: Record<string, string> = {
  call: "#D8A33D",
  event: "#D8A33D",
  proposalEnd: "#D225BE",
  proposalStart: "#108043",
  govWeekly: "#1487b8",
  meeting: "#1487b8",
  discussion: "#1487b8",
};

export const getColorIndexFromString = (str: string) => {
  const colors = ["#D8A33D", "#D8A33D", "#D225BE", "#108043", "#1487b8", "#1487b8", "#1487b8"];

  // Calculate a hash value from the string
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  // Map the hash value to an index within the range of colors array
  const index = Math.abs(hash % colors.length);

  return colors[index];
};
