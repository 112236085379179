import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";

import { useDelegationAdapter } from "./useVotePowerAdapter";
import { frameworkItems } from "../components/Delegates/DelegateFilterTab";
import { NetworkFilterFilterContext } from "../reducers/NetworkFilter";
import { apiClient } from "../utils/apiClient";

interface TopVpAndDelegationStatsForProtocol {
  noOfWalletsDelegated: number;
  protocol: string;
  adapter: string;
  address: string;
  votePower: number;
  timestamp: number | null;
  blockNumber: number;
  refId: string;
  votingStatus: string;
}

interface TopVpAndDelegationStatsForProtocolResponse {
  items: TopVpAndDelegationStatsForProtocol[];
  totalVp: string;
}

export const useDelegationCountStats = ({ protocol }: { protocol: string }) => {
  const adapter = useDelegationAdapter(protocol);
  const { framework } = useContext(NetworkFilterFilterContext);
  const forcedAdapter = frameworkItems[protocol] ? framework : null;
  const adapterToUse = forcedAdapter ?? adapter;
  const { data, isLoading } = useQuery(["delegationCountStats", protocol, adapterToUse], async () => {
    const data = await apiClient.delegationCountStatsForProtocol(protocol, adapterToUse);
    return data;
  });

  return {
    data,
    isLoading,
  };
};

export const useTopVpAndDelegationStats = ({ protocol }: { protocol: string }) => {
  const adapter = useDelegationAdapter(protocol);
  const { framework } = useContext(NetworkFilterFilterContext);
  const forcedAdapter = frameworkItems[protocol] ? framework : null;
  const adapterToUse = forcedAdapter ?? adapter;
  const { data, isLoading } = useQuery<TopVpAndDelegationStatsForProtocolResponse>(
    ["topVpAndDelegationStats", protocol, adapterToUse],
    async () => {
      const data = await apiClient.topVpAndDelegationStatsForProtocol(protocol, adapterToUse);
      return data as any;
    },
  );

  return {
    data,
    isLoading,
  };
};
