import { useState, useContext, useEffect } from "react";
import { JsonRpcProvider } from "@ethersproject/providers";
import { singletonHook } from "react-singleton-hook";
import { GovernanceSDK, NetworkTransportResolver, SignerTransport } from "@boardroom/gov-sdk";
import { CurrentAccountContext } from "../reducers/CurrentAccount";
import { CurrentWeb3Context } from "../reducers/CurrentWeb3Provider";
import { exportedProtocols } from "../utils/sdkProtocolImport";

export const useSdk = singletonHook(null, () => {
  const { account } = useContext(CurrentAccountContext);
  const { web3Instance } = useContext(CurrentWeb3Context);
  const [instance, setInstance] = useState<GovernanceSDK | null>(null);
  const signer = web3Instance?.getSigner();

  useEffect(() => {
    setInstance(null);
  }, [account]);

  useEffect(() => {
    if (signer && !instance) {
      const sdk = new GovernanceSDK({
        transports: {
          rpc: new NetworkTransportResolver({
            1: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_1),
            10: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_10),
            137: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_137),
            1284: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_1284),
            1285: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_1285),
            42161: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_42161),
            43114: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_43114),
            11155420: new JsonRpcProvider(process.env.REACT_APP_RPC_URL_11155420),
          }),
          signer: new SignerTransport(signer),
        },
        snapshotApiKey: process.env.REACT_APP_SNAPSHOT_API_KEY,
        protocols: exportedProtocols,
      });

      setInstance(sdk);
    }
  }, [account, signer, instance]);

  return instance;
});
