import ContentLoader from "react-content-loader";
import { ProposalDetails, VoteDetails } from "@boardroom/boardroom-api";
import React, { useState, useCallback, useContext, Suspense, useEffect, useRef, useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import notification from "antd/es/notification";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { Web3Provider } from "@ethersproject/providers";
import { useSetChain } from "@web3-onboard/react";
import moment from "moment";

import { COLORS } from "../../../constants/colors";
import { SuccessIcon, NoteIcon, Papers, ArrowRightIcon } from "../../icons";
import { NotificationsContext } from "../../../contexts";
import { useScore, useMixpanel } from "../../../hooks";
import { useCastVote } from "../../../hooks/useCastVote";
import { CurrentWeb3Context } from "../../../reducers/CurrentWeb3Provider";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import { useProposal } from "../../../hooks/useProposal";
import media from "../../../media-query";
import formatValue from "../../../utils/formatValue";
import { useVoterVotesForMultipleAddresses } from "../../../hooks/useVoterVotes";
import { CastVoteRefContext } from "../../../reducers/CastVoteRef";
import {
  BasicChoices,
  RankedChoices,
  WeightedChoices,
  ApprovalChoices,
  ApprovalVotingChoices,
} from "./VotingStyleChoices";
import { protocols } from "../../../constants/protocols";
import { CalendarEvent, ProposalType, ProtocolDescription } from "../../../types";
import { VotingTypeUI, votingTypes } from "./utils";
import { RenderHeader, RenderSuccessFooter, RenderWallet, RenderVote } from "./components";
import { displayVotedChoice } from "../VotedChoiceFormatting";
import { useAdapterFramework } from "../../../hooks/useAdapterFramework";
import NoteModal from "../../../features/Dashboard/NoteModal";
import { PendingVoteDetailsContext } from "../../../reducers/PendingVotes";
import { useVoteTxnHash } from "../../../reducers/VoteTxnHash";
import { useCurrentRefId } from "../../../reducers/CurrentRefId";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { useIsMultisigSignIn } from "../../../hooks/useIsMultisigSignIn";
import { useCurrentSplitViewIndex } from "../../../reducers/CurrentSplitViewIndex";
import { useIsEmailSignIn } from "../../../hooks/useIsEmailSignIn";
import { useIsAccountConnectedWallet } from "../../../hooks/useIsAccountConnectedWallet";
import { getProposalMainStatus } from "../../../utils/getProposalTimeAgoText";
import { RoutePaths } from "../../../constants/Routes";
import { getConnectedChainId } from "../../../utils/checkChain";
import mediaQuery from "../../../media-query";

const Wrapper = styled.div``;

const InfoText = styled.div`
  color: ${COLORS.secondary.pinkDark};
  text-align: center;
  margin-top: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  ${media.lessThan("640px")`
    width: 60%;
    margin: 0px auto 0;
  `}
`;

const Container = styled.div`
  border: 1px solid ${COLORS.primary.grayLighter};
  box-shadow: 0px 6px 14px rgba(7, 3, 40, 0.04);
  border-radius: 8px;
  background: #fff;
  width: 100%;
  min-height: 301px;
  margin: 40px 0px 28px;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 13;
`;

const SuccessContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  margin: auto;
  height: calc(78% - 64px);
  color: ${COLORS.primary.accent};
`;

const StyledSuccessTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  margin-top: 40px;
  white-space: nowrap;
`;

const StyledPendingVotesText = styled(Link)`
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  margin-top: 16px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  svg {
    margin-left: 8px;
  }
  ${mediaQuery.lessThan("991px")`
    white-space: normal;
    padding: 0 8px;
  `}
`;

const FlexItem = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  width: auto;
  margin-bottom: 40px;
  ${media.lessThan("640px")`
    justify-content: center;
    gap: 40px;
  `}
`;

const StyledSuccessText = styled.div`
  font-size: 14px;
  line-height: 16px;
  text-transform: capitalize;
  cursor: pointer;
  text-align: center;
`;

const StyledSuccessIcon = styled(SuccessIcon)`
  margin: 40px auto;
`;

const Divider = styled.div<{ $marginTop: number; $marginBottom?: number }>`
  width: 100%;
  border-top: 1px solid ${COLORS.primary.grayLighter};
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? $marginBottom : "40")}px;
  margin-top: ${({ $marginTop }) => $marginTop}px;
`;

const RenderSuccess = ({
  proposalId,
  protocol,
  protocolPath,
}: {
  proposalId: string;
  protocol: ProtocolDescription;
  protocolPath?: string;
}) => {
  const [noteModal, setNoteModal] = useState(false);
  const { trackClickNoteButton, trackClickViewVoteReceipt } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);
  const { voteTxnHash } = useVoteTxnHash();

  const handleClick = useCallback(() => {
    setNoteModal(true);
    trackClickNoteButton({
      proposalRefId: proposalId,
      userId: `${account}`,
    });
  }, [setNoteModal, trackClickNoteButton, account, proposalId]);

  const handleClickVoteReceipt = useCallback(() => {
    trackClickViewVoteReceipt({
      proposalRefId: proposalId,
      userId: `${account}`,
    });
  }, [trackClickViewVoteReceipt, account, proposalId]);

  return (
    <>
      <SuccessContainer>
        <StyledSuccessTitle>Success!</StyledSuccessTitle>
        {protocolPath && (
          <StyledPendingVotesText to={RoutePaths.feed}>
            Explore Your Feed to View Pending Votes <ArrowRightIcon />
          </StyledPendingVotesText>
        )}
        <StyledSuccessIcon height={96} width={96} color={COLORS.secondary.purple} />
        <FlexItem>
          {!!voteTxnHash.txnHash && (
            <StyledSuccessText onClick={handleClickVoteReceipt}>
              <a
                href={
                  !voteTxnHash.isSnapshotProposal
                    ? protocol.cname === "optimism"
                      ? `https://optimistic.etherscan.io/tx/${voteTxnHash.txnHash}`
                      : protocol.cname === "arbitrum"
                      ? `https://arbiscan.io/tx/${voteTxnHash.txnHash}`
                      : `https://etherscan.io/tx/${voteTxnHash.txnHash}`
                    : `https://ipfs.io/ipfs/${voteTxnHash.txnHash}`
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <Papers />
                &nbsp; View Receipt{" "}
              </a>
            </StyledSuccessText>
          )}
          <StyledSuccessText onClick={handleClick}>
            <NoteIcon />
            &nbsp; Add Note
          </StyledSuccessText>
        </FlexItem>
      </SuccessContainer>
      <NoteModal noteModal={noteModal} setNoteModal={setNoteModal} refId={proposalId} />
    </>
  );
};

interface Params {
  protocol: string;
}

const CastVote = ({
  refId,
  flatProposalEvents,
  proposalData,
  votesData,
}: {
  refId: string;
  flatProposalEvents?: CalendarEvent[];
  proposalData?: ProposalDetails[];
  votesData?: VoteDetails[];
}) => {
  const panelRef = useRef(null);
  const { protocol: protocolPath } = useParams<Params>();
  const { dispatchCastVoteRef } = useContext(CastVoteRefContext);
  const { account } = useContext(CurrentAccountContext);

  const {
    proposal,
  }: {
    proposal: (ProposalDetails & { privacy?: string }) | undefined;
  } = useProposal({ refId });
  const { score } = useScore(
    proposal?.protocol,
    account,
    proposal?.protocol === "makerdao"
      ? undefined
      : getProposalMainStatus(proposal?.currentState || "pending") === "pending"
      ? undefined
      : proposal?.blockNumber,
    proposal?.adapter,
  );

  const votingPower = parseFloat(score) || 0;
  const { web3Instance } = useContext(CurrentWeb3Context);
  const { setVoteTxnHash } = useVoteTxnHash();
  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { currentSplitViewIndex, setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const [{ connectedChain }, setChain] = useSetChain();
  const { castVote } = useCastVote(
    proposal?.protocol || "",
    proposal?.protocol === "makerdao" && proposal?.adapter === "onchain" ? proposal?.proposer : proposal?.id || "",
    proposal?.adapter as string,
    votingPower,
    refId,
    web3Instance as Web3Provider,
    proposal?.type as ProposalType,
    proposal?.privacy === "shutter",
  );
  const protocol = protocols[proposal?.protocol || ""];
  const { votes } = useVoterVotesForMultipleAddresses({
    protocol: proposal?.protocol,
    addresses: [account],
    limit: 100,
  });
  const { adapterFramework } = useAdapterFramework(protocol?.cname);

  const { dispatch } = useContext(NotificationsContext);
  const { trackStartCastVote, trackSubmitCastVote, trackClickChangeVote, trackClickNextProposal } = useMixpanel();
  const { pendingVoteDetails, dispatchPendingVoteDetails } = useContext(PendingVoteDetailsContext);

  const [chosen, setChosen] = useState<{ index: number; text: string }>();
  const [chosenText, setChosenText] = useState("");
  const [chosenArray, setChosenArray] = useState<number[]>([]);
  const [chosenObject, setChosenObject] = useState<{ [key: number]: number }>({});
  const [rankedChoices, setRankedChoices] = useState(proposal?.choices);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [changingVote, setChangingVote] = useState<boolean>(false);
  const finalRankedSelections: number[] = useMemo(() => [], []);
  const isSnapshotProposal =
    proposal?.adapter === "snapshot" ||
    (proposal?.adapter === "default" && adapterFramework === "snapshot") ||
    (proposal?.adapter === "archive" && adapterFramework === "snapshot");
  const isMultisigSignIn = useIsMultisigSignIn();
  const isEmailSignIn = useIsEmailSignIn();
  const isAccountConnectedWallet = useIsAccountConnectedWallet();

  useEffect(() => {
    for (const item in proposal?.choices) {
      if (!finalRankedSelections.includes(parseInt(item) + 1)) {
        finalRankedSelections.push(parseInt(item) + 1);
      }
    }
  }, [proposal?.choices, finalRankedSelections]);

  useEffect(() => {
    setSuccess(false);
    setChosen(undefined);
    setChosenArray([]);
    setChosenObject({});
    setChangingVote(false);
  }, [account, refId]);

  useEffect(() => {
    dispatchCastVoteRef({ type: "SAVE_CAST_VOTE_REF", data: panelRef });
  }, [panelRef, dispatchCastVoteRef]);

  useEffect(() => {
    const voteIndex = votes?.findIndex((vote) => vote?.proposalRefId === refId);
    const pendingVote = pendingVoteDetails[refId];
    if (
      !success &&
      !chosen &&
      !changingVote &&
      ((votes && voteIndex !== undefined && (voteIndex !== undefined ? voteIndex : -1) >= 0) || pendingVote)
    ) {
      let vote;
      setSuccess(true);
      if (pendingVote) {
        vote = pendingVote;
      } else if (votes && voteIndex) {
        vote = votes[voteIndex];
      }
      if (vote && proposal) {
        setChosenText(
          displayVotedChoice(
            proposal.type as ProposalType,
            vote.choices || (vote as any).choice,
            proposal.choices,
            proposal.adapter,
            adapterFramework,
          ) || "",
        );
      }
    }
  }, [votes, refId, proposal, success, chosen, changingVote, adapterFramework, pendingVoteDetails]);

  const handleSelectChoice = useCallback(
    (index: number, text: string) => {
      setChosen({ index, text });

      trackStartCastVote({
        protocol: proposal?.protocol || "",
        type: "offchain",
        proposalId: proposal?.id || "",
        userId: `${account}`,
      });
    },
    [account, proposal, trackStartCastVote],
  );

  const handleApprovalChoices = useCallback(
    (index: number) => {
      if (!chosenArray.includes(index + 1)) {
        setChosenArray([...chosenArray, index + 1]);
      } else {
        const filtered = chosenArray.filter((chosen: number) => chosen !== index + 1);
        setChosenArray([...filtered]);
      }

      trackStartCastVote({
        protocol: proposal?.protocol || "",
        type: "offchain",
        proposalId: proposal?.id || "",
        userId: `${account}`,
      });
    },
    [account, chosenArray, proposal?.id, proposal?.protocol, trackStartCastVote],
  );

  const handleApprovalVotingChoices = useCallback(
    (index: number) => {
      if (!chosenArray.includes(index)) {
        setChosenArray([...chosenArray, index]);
      } else {
        const filtered = chosenArray.filter((chosen: number) => chosen !== index);
        setChosenArray([...filtered]);
      }

      trackStartCastVote({
        protocol: proposal?.protocol || "",
        type: "offchain",
        proposalId: proposal?.id || "",
        userId: `${account}`,
      });
    },
    [account, chosenArray, proposal?.id, proposal?.protocol, trackStartCastVote],
  );

  const handleWeightedChoices = useCallback(
    (event: React.ChangeEvent<any>, index: number) => {
      const value = event.target.value.replace(/\D/g, "");
      if (value !== "") {
        setChosenObject({
          ...chosenObject,
          [index + 1]: +value,
        });
      } else {
        delete chosenObject[index + 1];
        setChosenObject({ ...chosenObject });
      }

      trackStartCastVote({
        protocol: proposal?.protocol || "",
        type: "offchain",
        proposalId: proposal?.id || "",
        userId: `${account}`,
      });
    },
    [account, chosenObject, proposal?.id, proposal?.protocol, trackStartCastVote],
  );

  const handleSubmit = useCallback(async () => {
    setSuccess(false);
    if (isMultisigSignIn) {
      setWarning("Please connect the multisig wallet to vote.");
      return;
    }
    if (isEmailSignIn && !isAccountConnectedWallet) {
      setWarning("Please connect the wallet to vote.");
      return;
    }
    const getSelected = {
      basic: isSnapshotProposal ? (chosen?.index as number) + 1 : (chosen?.index as number),
      "single-choice": isSnapshotProposal ? (chosen?.index as number) + 1 : (chosen?.index as number),
      approval: chosenArray.map(Number),
      "ranked-choice": finalRankedSelections,
      quadratic: chosenObject,
      weighted: chosenObject,
      approvalVoting: chosenArray.map(Number),
      optimisticApproval: 0,
    };

    if (loading) {
      setWarning("Your vote is being submitted, please wait a moment.");

      return;
    }

    // if (!votingPower) {
    //   setWarning(
    //     `Your voting power is ${formatValue(
    //       votingPower,
    //     )}. You need a voting power greater than 0 in your wallet to vote.`,
    //   );

    //   return;
    // }

    if (
      ((proposal?.type === "basic" || proposal?.type === "single-choice" || proposal?.type === null) &&
        chosen === undefined) ||
      ((proposal?.type === "approval" || proposal?.type === "approvalVoting") && chosenArray.length === 0) ||
      ((proposal?.type === "quadratic" || proposal?.type === "weighted") && Object.keys(chosenObject).length === 0)
    ) {
      setWarning("Please choose an option to vote for this poll.");
      return;
    }

    if (!proposal?.id) {
      setError("Something went wrong. Please refresh the page and try again.");

      return;
    }

    if (!web3Instance) {
      setWarning("Connecting to external provider, please wait a moment.");
      return;
    }
    if (account && !isSnapshotProposal && isAccountConnectedWallet) {
      const chainIdForProtocol = await getConnectedChainId({
        protocolCname: proposal?.protocol,
        adapterType: "vote",
        forcedFramework: proposal?.adapter,
      });
      if (chainIdForProtocol && chainIdForProtocol !== connectedChain?.id) {
        await setChain({ chainId: chainIdForProtocol });
        notification.success({
          message: "Pushed a request to your wallet... Please switch networks to delegate",
          placement: "topLeft",
          duration: 6,
        });
      }
    }

    setLoading(true);

    try {
      const chosenIndexSelection = isSnapshotProposal ? (chosen?.index as number) + 1 : (chosen?.index as number);
      if (castVote) {
        const ipfsHash = await castVote(getSelected[proposal?.type as ProposalType] || chosenIndexSelection);

        trackSubmitCastVote({
          protocol: proposal?.protocol || "",
          type: "offchain",
          proposalId: proposal?.id,
          userId: account,
        });

        if (ipfsHash) {
          setChosenText(
            displayVotedChoice(
              proposal?.type as ProposalType,
              getSelected[proposal?.type as ProposalType] || chosenIndexSelection,
              proposal?.choices || [],
              proposal?.adapter,
              adapterFramework,
            ) || "",
          );
          setSuccess(true);
          setVoteTxnHash({ isSnapshotProposal, txnHash: ipfsHash });

          dispatchPendingVoteDetails({
            type: "SAVE_PENDING_VOTE_DETAILS",
            data: [
              {
                proposalRefId: proposal?.refId,
                protocol: proposal?.protocol,
                adapter: isSnapshotProposal ? "snapshot" : "onchain",
                voteRefId: "placeholder",
                txnHash: ipfsHash,
                status: isSnapshotProposal ? "success" : "pending",
                choices: getSelected[proposal?.type as ProposalType] || chosenIndexSelection,
                address: account,
                power: votingPower,
              },
            ],
          });
          dispatch({
            type: !isSnapshotProposal ? "onInfo" : "onSuccess",
            payload: {
              confirmationNumber: ipfsHash,
              isOnChainVote: !isSnapshotProposal,
              chainId: isSnapshotProposal
                ? 1
                : proposal.protocol === "optimism"
                ? 10
                : proposal.protocol === "arbitrum"
                ? 42161
                : 1,
            },
          });
        }
      }
    } catch (e: any) {
      const voteIndex = votes?.findIndex((vote) => vote?.proposalRefId === refId);
      console.log("err", e);
      if (e.code === 4001) {
        if (votes && voteIndex !== undefined && (voteIndex !== undefined ? voteIndex : -1) >= 0) {
          setChangingVote(false);
          const vote = votes[voteIndex];
          if (vote && proposal) {
            setChosenText(
              displayVotedChoice(
                proposal.type as ProposalType,
                vote.choices,
                proposal.choices,
                proposal.adapter,
                adapterFramework,
              ) || "",
            );
          }
          setSuccess(true);
        }
        notification.error({
          message: "Canceled",
          duration: 10,
        });
      } else {
        dispatch({
          type: "onError",
          payload: {
            message:
              e.error === "unauthorized" && proposal?.protocol === "synthetix"
                ? "You need to have staked SNX a week before the council election to be able to signal"
                : typeof e.error === "string"
                ? e.error
                : "Something went wrong. Please try again.",
          },
        });
      }
    }

    setLoading(false);
  }, [
    account,
    castVote,
    chosen,
    dispatch,
    web3Instance,
    proposal,
    trackSubmitCastVote,
    votingPower,
    refId,
    votes,
    chosenArray,
    chosenObject,
    finalRankedSelections,
    adapterFramework,
    isSnapshotProposal,
    dispatchPendingVoteDetails,
    setVoteTxnHash,
    loading,
    isMultisigSignIn,
    connectedChain?.id,
    setChain,
    isEmailSignIn,
    isAccountConnectedWallet,
  ]);

  const handleClickSuccessFooter = useCallback(() => {
    if (protocolPath) {
      setChangingVote(true);
      setChosen(undefined);
      setChosenArray([]);
      setChosenObject({});
      setSuccess(false);
      trackClickChangeVote({
        proposalRefId: refId,
        userId: account,
      });
    } else {
      if (flatProposalEvents) {
        if (currentSplitViewIndex) {
          const nextProposal = flatProposalEvents[currentSplitViewIndex + 1];
          setCurrentRefId(nextProposal?.proposalRefId || "");
          setCurrentSplitViewIndex(currentSplitViewIndex + 1);
        } else {
          const currProposalIndex = flatProposalEvents.findIndex((event) => event.proposalRefId === currentRefId);
          const nextProposal = flatProposalEvents[currProposalIndex + 1];
          setCurrentRefId(nextProposal?.proposalRefId || "");
          setCurrentSplitViewIndex(currProposalIndex + 1);
        }
      } else if (proposalData) {
        const currProposalIndex = proposalData.findIndex((proposal) => proposal.refId === currentRefId);
        const nextProposal = proposalData[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.refId);
      } else if (votesData) {
        const currProposalIndex = votesData.findIndex((vote) => vote.proposalRefId === currentRefId);
        const nextProposal = votesData[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId);
      }
      trackClickNextProposal({
        proposalRefId: refId,
        userId: account,
      });
    }
  }, [
    setChangingVote,
    setChosen,
    setSuccess,
    setCurrentRefId,
    flatProposalEvents,
    currentSplitViewIndex,
    setCurrentSplitViewIndex,
    proposalData,
    currentRefId,
    protocolPath,
    votesData,
    trackClickChangeVote,
    trackClickNextProposal,
    account,
    refId,
  ]);

  const renderVotingUI: VotingTypeUI = {
    basic: (
      <BasicChoices
        choices={proposal?.choices as string[]}
        account={account}
        error={error}
        warning={warning}
        chosen={chosen}
        handleSelectChoice={handleSelectChoice}
        votePower={votingPower}
      />
    ),
    "single-choice": (
      <BasicChoices
        choices={proposal?.choices as string[]}
        account={account}
        error={error}
        warning={warning}
        chosen={chosen}
        handleSelectChoice={handleSelectChoice}
        votePower={votingPower}
      />
    ),
    approval: (
      <ApprovalChoices
        choices={proposal?.choices as string[]}
        account={account}
        handleApprovalChoices={handleApprovalChoices}
        chosenArray={chosenArray}
        votePower={votingPower}
      />
    ),
    approvalVoting: (
      <ApprovalVotingChoices
        choices={proposal?.choices as string[]}
        account={account}
        handleApprovalChoices={handleApprovalVotingChoices}
        chosenArray={chosenArray}
        votePower={votingPower}
      />
    ),
    weighted: (
      <WeightedChoices
        choices={proposal?.choices as string[]}
        account={account}
        handleWeightedChoices={handleWeightedChoices}
        chosenObject={chosenObject}
        setChosenObject={setChosenObject}
        votePower={votingPower}
      />
    ),
    "ranked-choice": <RankedChoices choices={rankedChoices as string[]} account={account} votePower={votingPower} />,
    quadratic: (
      <WeightedChoices
        choices={proposal?.choices as string[]}
        account={account}
        handleWeightedChoices={handleWeightedChoices}
        chosenObject={chosenObject}
        setChosenObject={setChosenObject}
        votePower={votingPower}
      />
    ),
    optimisticApproval: (
      <BasicChoices
        choices={proposal?.choices as string[]}
        account={account}
        error={error}
        warning={warning}
        chosen={chosen}
        handleSelectChoice={handleSelectChoice}
        votePower={votingPower}
      />
    ),
  };

  const currentDate = moment(new Date()).fromNow();
  const endsIn = moment((proposal?.endTimestamp || 0) * 1000).fromNow();

  const selectionValidation =
    !!(
      (proposal?.type === "basic" ||
        proposal?.type === "single-choice" ||
        proposal?.type === "optimisticApproval" ||
        proposal?.type === null) &&
      chosen
    ) ||
    ((proposal?.type === "approval" || proposal?.type === "approvalVoting") && chosenArray.length > 0) ||
    ((proposal?.type === "quadratic" || proposal?.type === "weighted") && Object.keys(chosenObject).length > 0) ||
    (proposal?.type === "ranked-choice" && finalRankedSelections.length > 0);

  const onDragEnd = useCallback(
    (DropResult: DropResult) => {
      const srcIndex = DropResult.source.index;
      const desIndex = DropResult.destination?.index;
      rankedChoices?.splice(desIndex as number, 0, rankedChoices.splice(srcIndex, 1)[0]);
      finalRankedSelections?.splice(desIndex as number, 0, finalRankedSelections.splice(srcIndex, 1)[0]);
      setRankedChoices([...rankedChoices!]);
    },
    [finalRankedSelections, rankedChoices],
  );

  if (
    proposal?.currentState === "closed" ||
    proposal?.currentState === "canceled" ||
    proposal?.currentState === "pending" ||
    (proposal?.currentState === "executed" && endsIn < currentDate) ||
    !proposal?.currentState
  ) {
    return null;
  }

  return (
    <>
      <Wrapper ref={panelRef}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Container>
            <RenderHeader
              title={votingTypes[proposal.type as ProposalType]?.title || ""}
              desc={votingTypes[proposal.type as ProposalType]?.desc || ""}
              protocolPath={protocolPath}
            />
            {success ? (
              <RenderSuccess proposalId={proposal.refId} protocol={protocol} protocolPath={protocolPath} />
            ) : (
              renderVotingUI[(proposal?.type as ProposalType) || "basic"]
            )}
            {!account && !success && <RenderWallet refId={proposal?.refId} />}
            {account && !success && (
              <RenderVote
                loading={loading}
                chosen={selectionValidation}
                votePower={votingPower}
                handleSubmit={handleSubmit}
              />
            )}
            {account && success ? (
              <RenderSuccessFooter
                text={chosenText}
                onClick={handleClickSuccessFooter}
                protocolPath={protocolPath}
                votePower={formatValue(votingPower)}
                isOnchainVote={!isSnapshotProposal}
              />
            ) : null}
          </Container>
        </DragDropContext>
        {account && (
          <InfoText>
            *Vote Power Set at Proposal Creation: Your voting power is determined at the creation of the proposal.
            Adding vote power afterward won&rsquo;t affect your ability to vote on this proposal.
          </InfoText>
        )}
      </Wrapper>
    </>
  );
};

export default React.memo(
  (props: {
    refId: string;
    removeDividers?: boolean;
    proposalData?: ProposalDetails[];
    flatProposalEvents?: CalendarEvent[];
    votesData?: VoteDetails[];
    teamWallets?: string[];
  }) => {
    const { width } = useWindowDimensions();
    const isMobile = width <= 640;
    const { proposal } = useProposal({ refId: props.refId });
    const currentDate = moment(new Date()).fromNow();
    const endsIn = moment((proposal?.endTimestamp || 0) * 1000).fromNow();
    if (
      !props.removeDividers &&
      (proposal?.currentState === "closed" ||
        proposal?.currentState === "canceled" ||
        proposal?.currentState === "pending" ||
        (proposal?.currentState === "executed" && endsIn < currentDate) ||
        !proposal?.currentState)
    ) {
      return <Divider $marginTop={0} />;
    }

    return (
      <>
        {!props.removeDividers && <Divider $marginTop={0} />}
        <Suspense
          fallback={
            <Container>
              <ContentLoader
                speed={2}
                width="100%"
                height={370}
                backgroundColor={COLORS.primary.grayLight}
                foregroundColor={COLORS.primary.grayLighter}
              >
                <rect x={isMobile ? "15" : "50"} y="40" rx="5" ry="5" width={isMobile ? "90%" : "85%"} height="64" />
                <rect x={isMobile ? "15" : "50"} y="130" rx="5" ry="5" width={isMobile ? "90%" : "85%"} height="64" />
                <rect x={isMobile ? "15" : "50"} y="220" rx="5" ry="5" width={isMobile ? "90%" : "85%"} height="64" />
                <rect x="0" y="310" rx="5" ry="5" width="100%" height="64" />
              </ContentLoader>
            </Container>
          }
        >
          <CastVote {...props} />
        </Suspense>
        {!props.removeDividers && <Divider $marginBottom={16} $marginTop={32} />}
      </>
    );
  },
);
