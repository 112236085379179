import { ProposalComment as ProposalCommentInterface } from "@boardroom/boardroom-api";
import notification from "antd/es/notification";
import React from "react";

import { editComment as apiEditComment } from "../../../utils/editComment";
import { createComment } from "../../../utils/createComment";
import { deleteComment } from "../../../utils/deleteComment";

interface RemoveProps {
  id: string,
  comments: ProposalCommentInterface[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  dispatchNotes: React.Dispatch<any>,
  siweFunction: any,
  sharedWithOrg?: boolean,
}

export const removeComment = async (
  {
    id, comments, setLoading, dispatchNotes, siweFunction, sharedWithOrg
  }: RemoveProps
) => {
  setLoading(true);
  const commentIndex = comments.findIndex((comment) => comment.commentId === id);
  if (commentIndex < 0) {
    setLoading(false);
    notification.error({
      message: "Something went wrong... Please try again later",
    });
    return;
  }

  try {
    await deleteComment({
      author: comments[commentIndex].author,
      commentId: comments[commentIndex].commentId,
      proposalId: comments[commentIndex].proposalId,
      sharedWithOrg,
      siweFunction
    });
    dispatchNotes({
      type: "DELETE_NOTE",
      data: comments[commentIndex],
    });
    notification.success({
      message: "Your note has been deleted",
      duration: 10,
    });
  } catch (error: any) {
    console.error(error);
    if (error?.response?.status === 401) {
      notification.error({
        message: "You need to sign in with wallet to delete a comment",
      });
    } else {
      notification.error({
        message: error?.message || "Something went wrong... Please try again later",
      });
    }
  } finally {
    setLoading(false);
  }
};

interface EditProps {
  id: string,
  comments: ProposalCommentInterface[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEditingId: React.Dispatch<React.SetStateAction<string>>,
  editorContent: string,
  setEditorContent: React.Dispatch<React.SetStateAction<string>>,
  dispatchNotes: React.Dispatch<any>,
  siweFunction: any,
  sharedWithOrg?: boolean,
}

export const editComment = async (
  {
    id, comments, setLoading, setEditingId, editorContent, setEditorContent, dispatchNotes, siweFunction, sharedWithOrg
  }: EditProps
) => {
  setLoading(true);
  const commentIndex = comments.findIndex((comment) => comment.commentId === id);
  if (commentIndex < 0) {
    setLoading(false);
    notification.error({
      message: "Something went wrong... Please try again later",
    });
    return;
  }

  try {
    const commentReponse = await apiEditComment({
      author: comments[commentIndex].author,
      commentId: comments[commentIndex].commentId,
      proposalId: comments[commentIndex].proposalId,
      newCommentBody: editorContent,
      sharedWithOrg,
      siweFunction
    });

    dispatchNotes({
      type: "SAVE_NOTE",
      data: commentReponse?.data,
    });

    notification.success({
      message: "Your comment has been edited",
      duration: 10,
    });
  } catch (error: any) {
    console.error(error);
    if (error?.response?.status === 401) {
      notification.error({
        message: "You need to sign in with wallet to edit a comment",
      });
    } else {
      notification.error({
        message: error?.message || "Something went wrong... Please try again later",
      });
    }
  } finally {
    setLoading(false);
    setEditingId("");
    setEditorContent("");
  }
};

interface SubmitProps {
  refId: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setEditingId: React.Dispatch<React.SetStateAction<string>>,
  editorContent: string,
  setEditorContent: React.Dispatch<React.SetStateAction<string>>,
  dispatchNotes: React.Dispatch<any>,
  account: string,
  protocol: string,
  siweFunction: any,
  sharedWithOrg?: boolean,
}

export const SubmitComment = async (
  {
    refId, setLoading, setEditingId, editorContent, setEditorContent, dispatchNotes, account, protocol, siweFunction, sharedWithOrg
  }: SubmitProps
) => {
  setLoading(true);

  try {
    const commentResponse = await createComment({
      proposalId: refId,
      author: account,
      protocol: protocol,
      parentId: "",
      commentBody: editorContent,
      sharedWithOrg,
      siweFunction
    });

    dispatchNotes({
      type: "SAVE_NOTE",
      data: commentResponse?.data,
    });
    notification.success({
      message: "Your note has been submitted",
      duration: 10,
    });
  } catch (error: any) {
    console.error(error);
    if (error?.response?.status === 401) {
      notification.error({
        message: "You need to sign in with wallet to submit a comment",
      });
    } else {
      notification.error({
        message: error?.message || "Something went wrong... Please try again later",
      });
    }
  } finally {
    setLoading(false);
    setEditingId("");
    setEditorContent("");
  }
};
