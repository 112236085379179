import React, { useState, useEffect, useContext, useMemo, useRef, useCallback, Suspense } from "react";
import { useQuery } from "@apollo/client";
import { User } from "@boardroom/boardroom-api";
import Col from "antd/es/col";
import Row from "antd/es/row";
import { useHistory, useLocation, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useCurrentWidth } from "react-socks";
import notification from "antd/es/notification";
import CopyToClipboard from "react-copy-to-clipboard";
import { isAddress } from "web3-utils";

import media from "../../media-query";
import { ProfileInfoCard, ProfileMainContent, ProfileHeader, ProfileBanner } from "../../components";
import { Loader } from "../../components/Loader";
import { useGetAddress, useGetEnsAvatar } from "../../hooks/useEns";
import { useGetEns } from "../../hooks/useEns";
import { protocols } from "../../constants/protocols";
import VotePowerCard from "../../components/VoterProfile/VotePowerCard";
import { TrackPageView } from "../../components/TrackPageView";
import ProtocolsDropdown from "../../components/VoterProfile/ProtocolsDropdown";
import ProfileShareButton from "../../components/VoterProfile/ProfileShareButton";
import ProfileFollowButton from "../../components/VoterProfile/ProfileFolllowButton";
import ProfileAvatar from "../../components/VoterProfile/ProfileAvatar";
import { VOTER_PROFILE_SCROLL_SHRINK, VOTER_PROFILE_SCROLL_SHRINK_MOBILE } from "../../constants/general";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import EditProfileModal from "../../components/VoterProfile/EditProfile";
import AboutSection from "../../components/VoterProfile/AboutSection";
import ProfileActiveProtocols from "../../components/VoterProfile/ProfileActiveProtocols";
import { useVoter } from "../../hooks/useVoter";
import { ArrowLeftIcon, CrossIcon } from "../../components/icons";
import { formatEns, toShortAddress } from "../../utils";
import { COLORS } from "../../constants/colors";
import { ThreeDotMenuIcon } from "../../components/icons";
import { Popover } from "../../components/Popover";
import TwitterShare from "../../components/common/TwitterShareButton";
import { useMixpanel } from "../../hooks";
import MetaTags from "../../components/common/MetaTags";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useUserDetails } from "../../hooks/useUserDetails";
import ErrorBoundary from "../../components/ErrorBoundary";
import { FULL_IDENTITY_QUERY } from "../../utils/cyberConnect";
import { useGnosisSafeOwners } from "../../hooks/useGnosisSafeOwners";
import { useDelegatesFromAddress } from "../../hooks/useDelegatesFromAddress";
import { useDelegatesForProtocols } from "../../hooks/useDelegateForProtocols";
import VoterProfileSkeleton from "./VoterProfileSkeleton";
import DelegationModal from "../../components/Voting/DelegationModal";
import { CopyDelegationLinkWrapper } from "../Team/TeamProfile";
import { StyledCopyIcon, StyledCopyText } from "../../components/DelegateMessageBoard/styles";
import { RoutePaths } from "../../constants/Routes";
import Custom404 from "../Custom404";
import { pageHeaderHeight } from "../../constants/styles";

interface Params {
  identifier: string;
}

interface Props {
  voterIdentifier?: string;
}

const Container = styled.div`
  position: relative;
  padding-bottom: 40px;
  margin: auto;
  overflow-y: scroll;
  ${media.greaterThan("991px")`
    height: calc(100vh - ${pageHeaderHeight});
  `}
  ${media.lessThan("991px")`
    margin-top: -7px;
    height: 100vh;
  `}
`;

const FlexItem = styled.div`
  display: flex;
  gap: 16px;
`;

const HeaderStickyWrapper = styled.div`
  position: sticky;
  width: 100%;
  z-index: 1;
  background-color: white;
  top: 0px;
  ${media.lessThan("991px")`
    position: relative;
  `}
`;

const MainContentWrapper = styled.div<{ isProfilePage: boolean }>`
  background-color: white;
  overflow-x: hidden;
  position: relative;
  ${(props) =>
    !props.isProfilePage &&
    css`
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
    `}
  box-shadow: 0px -4px 4px rgb(0 0 0 / 10%);
  ${media.greaterThan("991px")`
    height: calc(100% - 80px);
  `}
`;

const AbsoluteBgMobileHeader = styled.div`
  position: absolute;
  height: 68px;
  width: calc(100% + 32px);
  margin-left: 0px;
  border-top-right-radius: 0px;
  background: white;
  border-top-left-radius: 0px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 0;
`;

const StyledMobileNavContent = styled.div`
  margin-left: 80px;
  margin-top: 18px;
  ${media.lessThan("640px")`
    max-width: 14rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `}
`;

const StyledProfileName = styled.span`
  font-weight: 600;
  font-size: 20px;
  margin-left: 8px;
  color: ${COLORS.primary.grayDark};
`;

export const PageMax = styled.div`
  width: 100%;
  ${media.greaterThan("1400px")`
    width: 1400px;
    margin: 0 auto;
  `}
`;

const StyledBackButton = styled.div`
  position: absolute;
  z-index: 11;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 50px;
  left: 16px;
  top: 14px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: white;
  ${media.greaterThan("991px")`
    display: none;
  `}
`;

const StyledBackButtonDark = styled(StyledBackButton)`
  background: none;
  color: #4235e1;
`;

const StyledDropdown = styled(StyledBackButton)`
  transform: rotate(90deg);
  left: 0px;
  top: 0px;
`;

const StyledDropdownDark = styled(StyledBackButtonDark)`
  transform: rotate(90deg);
  left: 0px;
  top: 0px;
`;

const StyledRightAbsoluteElement = styled.div`
  position: absolute;
  z-index: 11;
  right: 16px;
  top: 14px;
  width: 40px;
  height: 40px;
  ${media.greaterThan("991px")`
    display: none;
  `}
`;

const ProfileTwitterShareButton = styled(TwitterShare)`
  vertical-align: text-top;
  display: inline-flex;
  height: 21px;
  overflow: hidden;
  color: ${COLORS.primary.accent};
`;

export const StyledVoterOverlay = styled("div")`
  z-index: 18;
  left: 0px;
  width: 100%;
  position: fixed;
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(25, 21, 64, 0.7);
  top: 0;
  ${media.lessThan("991px")`
    display: none;
  `}
`;

export const CloseIcon = styled(CrossIcon)`
  z-index: 12;
  :hover {
    cursor: pointer;
  }
`;

export const StyledCloseProfileTrigger = styled.div`
  text-align: right;
  color: white;
  padding-right: 40px;
  height: 80px;
  line-height: 80px;
  background: rgba(25, 21, 64, 0.01);
`;

export const StyledClosedText = styled.span`
  font-size: 24px;
  padding-right: 20px;
`;

function VoterProfile(props: Props) {
  const { identifier: paramIdentifier } = useParams<Params>();
  const identifier = paramIdentifier?.split("?")[0] || props.voterIdentifier?.split("?")[0] || "";

  const { pathname, search } = useLocation();
  const isMobile = useCurrentWidth() < 991;
  const isProfilePage = pathname.startsWith("/voter");
  const addressFromUrl = !identifier.includes(".eth") ? identifier.split("?")[0] : "";
  const ensFromUrl = identifier.includes(".eth") ? identifier.split("?")[0] : "";
  const [address, setAddress] = useState<string>(addressFromUrl);
  const [ens, setEns] = useState<string>(ensFromUrl);
  const resolvedAddress = useGetAddress(ens, address);
  const resolvedEns = useGetEns(address, ens);
  const [scrolledHeight, setScrolledHeight] = useState(0);
  const voteHistoryScrollRef = useRef<HTMLDivElement>(null);
  const query = useMemo(() => new URLSearchParams(search), [search]);
  const protocolOnUrl = query.get("protocol");
  const delegationModal = query.get("delegationModal");
  const [activeProtocol, setActiveProtocol] = useState<string>(protocolOnUrl || "");
  const [open, setOpen] = useState<boolean>(false);
  const [delegationModalVisible, setDelegationModalVisible] = useState<boolean>(Boolean(delegationModal));
  const { account } = useContext(CurrentAccountContext);
  const [isProfileOwner, setIsProfileOwner] = useState(account?.toLowerCase() === identifier?.toLowerCase());
  const { data: cyberConnectData } = useQuery(FULL_IDENTITY_QUERY, {
    variables: { address: resolvedAddress },
  });
  const [isDelegate, setIsDelegate] = useState<boolean>(query.get("isDelegate") === "true");
  const { dispatchUserDetails } = useContext(CurrentUserDetailsContext);
  const [userState, setUserState] = useState<User>({ address });
  const history = useHistory();
  const containerElement = useRef<HTMLDivElement>(null);
  const { trackShareVoterProfileOnTwitter, trackOpenEditProfileModalInProfilePage } = useMixpanel();
  const { user: userDetails } = useUserDetails({ address, suspense: false, byPassCache: true });
  const ensAvatar = useGetEnsAvatar(address, ens);
  const { gnosisOwners } = useGnosisSafeOwners(address);
  const { delegateFor } = useDelegatesForProtocols({ address, suspense: false });

  useEffect(() => {
    if (gnosisOwners?.map((address: string) => address?.toLowerCase()).includes(account?.toLowerCase())) {
      setIsProfileOwner(true);
    }
  }, [gnosisOwners, account]);

  useEffect(() => {
    setIsProfileOwner(account?.toLowerCase() === identifier?.toLowerCase());
  }, [account, identifier]);

  useEffect(() => {
    setOpen(false);
  }, [account]);

  useEffect(() => {
    const twitterInCyberConnect = cyberConnectData?.identity?.social?.twitter;
    if (twitterInCyberConnect) {
      setIsDelegate(true);
    }
  }, [cyberConnectData]);

  useEffect(() => {
    if (query.get("voter")) {
      history.push(`/voter/${query.get("voter")}`);
    }
  }, [history, isMobile, isProfilePage, query, address, ens]);

  useEffect(() => {
    if (userDetails) {
      setUserState(userDetails);
    }
  }, [userDetails, setUserState]);

  const { voter } = useVoter({ address, suspense: false });
  const votedProtocols = useMemo(
    () => voter?.protocols?.map((protocol) => protocol.protocol)?.filter((protocol) => protocols[protocol]?.isEnabled),
    [voter],
  );

  // identifier change means url param has changed
  // so one of the below should be reset
  useEffect(() => {
    if (identifier.includes(".eth")) {
      setEns(identifier);
      setAddress("");
    } else {
      setEns("");
      setAddress(`${identifier}`);
    }
  }, [identifier, pathname, query]);

  // Update this value whenever we have a new resolved address
  useEffect(() => {
    if (resolvedAddress && resolvedAddress !== address) {
      setAddress(resolvedAddress);
    }
  }, [resolvedAddress, address]);

  // Update this value whenever we have a new ens value
  useEffect(() => {
    if (resolvedEns !== ens && resolvedEns !== ensFromUrl && (resolvedEns || ensFromUrl)) {
      setEns(resolvedEns || ensFromUrl);
    }
  }, [resolvedEns, ensFromUrl, ens]);

  const { delegationsFromAddress } = useDelegatesFromAddress({ address });

  const delegateTo = useMemo(() => {
    const delegates = delegationsFromAddress?.map((delegate) => delegate.addressDelegatedTo);
    const delegatedTo: string[] = [];
    delegates?.forEach((delegate) => {
      if (!delegatedTo?.includes(delegate) && delegate.toLowerCase() !== address.toLowerCase()) {
        delegatedTo.push(delegate);
      }
    });
    return delegatedTo;
  }, [delegationsFromAddress, address]);

  const updateUserState = useCallback(
    (userDetails: User) => {
      setUserState(userDetails);
      dispatchUserDetails({ type: "SAVE_USER_DETAILS", data: userDetails });
    },
    [dispatchUserDetails],
  );

  const onScroll = useCallback(
    (e: any) => {
      const scrollShrinkHeight = isMobile ? VOTER_PROFILE_SCROLL_SHRINK_MOBILE : VOTER_PROFILE_SCROLL_SHRINK;
      if (e.target.scrollTop <= scrollShrinkHeight) {
        setScrolledHeight(e.target.scrollTop);
      } else if (e.target.scrollTop > scrollShrinkHeight && scrolledHeight < scrollShrinkHeight) {
        setScrolledHeight(scrollShrinkHeight);
      }
    },
    [isMobile, scrolledHeight],
  );

  const closeProfile = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      history.push(RoutePaths.feed);
    }
    return;
  };

  const onEditClick = useCallback(() => {
    setOpen(true);
    trackOpenEditProfileModalInProfilePage({
      userId: account,
    });
  }, [account, trackOpenEditProfileModalInProfilePage]);

  const onCopy = useCallback(() => {
    notification.success({
      message: "Copied!",
    });
  }, []);

  if (!isAddress(address) && !ens) {
    return <Custom404 isVoter />;
  }

  return (
    <ErrorBoundary>
      {!!protocolOnUrl && (
        <DelegationModal
          zIndex={19}
          visible={delegationModalVisible}
          setVisible={setDelegationModalVisible}
          address={address}
          protocolName={protocolOnUrl}
          disableSelfDelegation={true}
        />
      )}
      <MainContentWrapper isProfilePage={isProfilePage}>
        <div>
          <StyledBackButton
            onClick={closeProfile}
            style={{
              opacity: `${VOTER_PROFILE_SCROLL_SHRINK_MOBILE - scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}`,
            }}
          >
            <ArrowLeftIcon height={20} width={20} />
          </StyledBackButton>
          <StyledBackButtonDark
            onClick={closeProfile}
            style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}
          >
            <ArrowLeftIcon height={20} width={20} />
          </StyledBackButtonDark>
          <StyledRightAbsoluteElement ref={containerElement}>
            <Popover
              content={
                <ProfileTwitterShareButton
                  type="voter"
                  title="Check out this user profile on the @boardroom_info app"
                  clickCallBack={() => {
                    trackShareVoterProfileOnTwitter({
                      protocol: activeProtocol || "",
                      voterAddress: address || "",
                      userId: `${account}`,
                    });
                  }}
                  text={"Tweet This Profile"}
                  hashtags={[]}
                />
              }
              placement="leftBottom"
              trigger="click"
              zIndex={13}
              container={containerElement.current}
            >
              <div style={{ position: "relative" }}>
                <StyledDropdown
                  style={{
                    opacity: `${
                      VOTER_PROFILE_SCROLL_SHRINK_MOBILE - scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE
                    }`,
                  }}
                >
                  <ThreeDotMenuIcon height={20} width={20} />
                </StyledDropdown>
                <StyledDropdownDark style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}>
                  <ThreeDotMenuIcon height={20} width={20} />
                </StyledDropdownDark>
              </div>
            </Popover>
          </StyledRightAbsoluteElement>
          {isMobile && (
            <AbsoluteBgMobileHeader style={{ opacity: `${scrolledHeight / VOTER_PROFILE_SCROLL_SHRINK_MOBILE}` }}>
              <StyledMobileNavContent>
                <ProfileAvatar
                  isDelegate={isDelegate}
                  address={address}
                  size="xsmall"
                  pfp={userState.pfpUrl || ensAvatar}
                />
                <StyledProfileName>
                  {userState?.username || formatEns(ens) || toShortAddress(address)}
                </StyledProfileName>
              </StyledMobileNavContent>
            </AbsoluteBgMobileHeader>
          )}
          <Container ref={voteHistoryScrollRef} id="scrollElement" onScroll={onScroll}>
            <HeaderStickyWrapper style={{ top: isMobile ? "0px" : `-${scrolledHeight}px` }}>
              <ProfileBanner scrolledHeight={scrolledHeight} bannerImage={userState.bannerUrl} />
              <TrackPageView name={`Voter: ${ens || address} Profile`} />
              <MetaTags
                title="Delegate Profile"
                description="Checkout My Governance Delegate Profile"
                imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
              />
              <PageMax>
                <Col md={{ span: 20, push: 2 }} xs={{ span: 22, push: 1 }}>
                  <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
                    <Col md={2} xs={24}>
                      <ProfileAvatar
                        address={address}
                        size={isMobile ? "small" : "big"}
                        pfp={userState.pfpUrl || ensAvatar}
                        isDelegate={isDelegate}
                      />
                    </Col>
                    <Col md={13} xs={24}>
                      {address && (
                        <>
                          <ProfileHeader
                            address={address}
                            ens={ens}
                            scrolledHeight={isMobile ? scrolledHeight : 0}
                            twitter={userState?.twitter}
                            website={userState?.website}
                            username={userState?.username}
                          />
                          <ProfileActiveProtocols
                            activeProtocols={votedProtocols}
                            addressType={userState?.type}
                            isDelegate={isDelegate}
                          />
                        </>
                      )}
                    </Col>
                    <Col xs={9} md={8}>
                      {!isMobile && (
                        <FlexItem>
                          {isProfileOwner && (
                            <ProfileFollowButton
                              isOwnProfile={isProfileOwner}
                              scrolledHeight={isMobile ? scrolledHeight : 0}
                              onEditClick={onEditClick}
                            />
                          )}
                          <ProfileShareButton
                            ens={ens}
                            activeProtocol={activeProtocol}
                            address={address}
                            scrolledHeight={isMobile ? scrolledHeight : 0}
                          />
                        </FlexItem>
                      )}
                      {address && (
                        <EditProfileModal
                          open={open}
                          onClose={() => setOpen(false)}
                          address={address}
                          user={userState}
                          updateUserState={updateUserState}
                          isGnosisOwner={isProfileOwner && account !== identifier}
                        />
                      )}
                    </Col>
                  </Row>
                </Col>
              </PageMax>
            </HeaderStickyWrapper>
            <PageMax>
              <Row>
                <Col md={{ span: 20, push: 2 }} xs={{ span: 22, push: 1 }}>
                  <>
                    {address ? (
                      <Row gutter={[24, { xs: 12, sm: 12, md: 24 }]}>
                        <Col md={2}></Col>
                        <Col md={{ span: 7, push: 13 }} xs={24}>
                          {!isMobile && (
                            <>
                              <ProfileInfoCard
                                activeProtocol={activeProtocol}
                                address={address}
                                website={userState?.website}
                                ens={ens}
                                setActiveProtocol={setActiveProtocol}
                                delegateForProtocols={delegateFor}
                                delegateToAddresses={delegateTo}
                              />
                              {!!activeProtocol && (
                                <CopyToClipboard
                                  text={`https://boardroom.io${pathname}?protocol=${activeProtocol}&delegationModal=true`}
                                  onCopy={onCopy}
                                >
                                  <CopyDelegationLinkWrapper>
                                    <StyledCopyText style={{ marginBottom: 0 }}>
                                      <StyledCopyIcon />
                                      Copy 1-click delegation link
                                    </StyledCopyText>
                                  </CopyDelegationLinkWrapper>
                                </CopyToClipboard>
                              )}
                              <VotePowerCard address={address} />
                            </>
                          )}
                        </Col>
                        <Col md={{ span: 13, pull: 7 }} xs={24}>
                          <div>
                            <AboutSection
                              identifier={userState?.address}
                              about={userState?.about}
                              delegateForProtocols={delegateFor}
                            />
                            {isMobile && (
                              <>
                                <ProfileInfoCard
                                  activeProtocol={activeProtocol}
                                  address={address}
                                  website={userState?.website}
                                  ens={ens}
                                  setActiveProtocol={setActiveProtocol}
                                  delegateForProtocols={delegateFor}
                                  delegateToAddresses={delegateTo}
                                />
                                <VotePowerCard address={address} />
                              </>
                            )}
                            <ProtocolsDropdown
                              activeProtocol={activeProtocol}
                              setActiveProtocol={setActiveProtocol}
                              votedProtocols={votedProtocols}
                            />
                            <ProfileMainContent
                              key={activeProtocol}
                              activeProtocol={activeProtocol}
                              address={address}
                              ens={ens}
                              ref={voteHistoryScrollRef}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Loader pageAlign={true} />
                    )}
                  </>
                </Col>
              </Row>
            </PageMax>
          </Container>
        </div>
      </MainContentWrapper>
    </ErrorBoundary>
  );
}

export default () => {
  const history = useHistory();
  const closeProfileOverlay = () => {
    if (history.location.key) {
      history.goBack();
    } else {
      history.push(RoutePaths.feed);
    }
    return;
  };
  const isMobile = useCurrentWidth() < 991;

  return (
    <Suspense fallback={<VoterProfileSkeleton />}>
      {isMobile ? (
        <VoterProfile />
      ) : (
        <StyledVoterOverlay>
          <StyledCloseProfileTrigger>
            <StyledClosedText>Exit Profile</StyledClosedText>
            <CloseIcon height="16px" width="16px" color="#fff" onClick={closeProfileOverlay} />
          </StyledCloseProfileTrigger>
          <VoterProfile />
        </StyledVoterOverlay>
      )}
    </Suspense>
  );
};
