import React, { useState, useMemo, Suspense, useContext } from "react";
import Tooltip from "antd/es/tooltip";
import Button from "antd/es/button";
import styled from "styled-components";
import { useSelect } from "downshift";
import ContentLoader from "react-content-loader";
import { useCurrentWidth } from "react-socks";
import moment from "moment";

import { GridIcon, ListIcon, ExpandCollapseArrowThin, FunnelFilterIcon } from "../icons";
import { LeanDropdownItemList } from "../Dropdown/LeanDropdownItemList";
import { getSingleSelectConfig } from "../Dropdown/config";
import { COLORS } from "../../constants/colors";
import { ClosableTag } from "../Tags";
import { useMobileFilters } from "../../reducers/ShowMobileFilter";
import { DelegateFilterParams, DelegateFrameworkFilterParams } from "../../types";
import { DelegateSortByItems } from "../../constants/general";
import { DelegateFrameworkFilterContext } from "../../reducers/DelegateFilter";
import { NetworkFilterFilterContext } from "../../reducers/NetworkFilter";
import { useTopVpAndDelegationStats } from "../../hooks/useDelegationStats";
import { useCurrentProtocolCname } from "../../hooks";
import mediaQuery from "../../media-query";

interface FilterProps {
  layout: "grid" | "list";
  onClick: (layout: "grid" | "list") => void;
  setSortByFilter: React.Dispatch<React.SetStateAction<DelegateFilterParams>>;
  setTagFilter?: React.Dispatch<
    React.SetStateAction<"delegate" | "core-developers" | "service-provider" | "investor" | "">
  >;
  setFrameworkFilter: React.Dispatch<React.SetStateAction<DelegateFrameworkFilterParams>>;
}

const StyledWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  padding-bottom: 14px;
  width: 100%;
  transition: width 1s ease-out;
  ${mediaQuery.lessThan("991px")`
    margin-bottom: 24px;
    padding-bottom: 16px !important;
  `}
`;

const DropDownWrapper = styled.div`
  margin: auto 0;
  width: 100%;
  & [ant-click-animating-without-extra-node]:after {
    animation: 0s !important;
  }
`;

const StyledButton = styled(Button)`
  border: none;
  display: flex;
  color: ${COLORS.primary.accent};
  box-shadow: none;
  width: 100%;
  justify-content: space-between;
  padding: 0;
  align-items: center;
  background: inherit;
`;

const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  margin: auto 0;
  transform: rotate(${({ isOpen }) => (isOpen ? "180deg" : 0)});
  transition: transform 0.2s;
  padding-left: 8px;
`;

const StyledGridIcon = styled(GridIcon)`
  margin: auto 0;
  cursor: pointer;
`;

const StyledListIcon = styled(ListIcon)`
  margin: auto 0;
  cursor: pointer;
`;

const FiltersButton = styled(Button)`
  border-radius: 5px;
  border: 1px solid ${COLORS.primary.grayDarkLightest};
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  margin-left: auto;
`;

const FilterWrapper = styled.div`
  width: 170px;
  margin: auto 12px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

export interface Item {
  name: string;
  value: string;
}

interface DropdownProps {
  onChange: (a: any) => void;
  items: Item[];
  initialValue?: Item | undefined;
}

export const DropDown = ({ items, initialValue, onChange }: DropdownProps) => {
  const { isOpen, selectedItem, getMenuProps, getToggleButtonProps, getItemProps } = useSelect(
    getSingleSelectConfig(items, initialValue, onChange),
  );
  const buttonText = useMemo(() => {
    if (Array.isArray(selectedItem)) {
      return selectedItem.length > 0 ? selectedItem.map((item) => item.name).join(", ") : "";
    }

    return selectedItem?.name;
  }, [selectedItem]);

  return (
    <DropDownWrapper>
      <StyledButton {...getToggleButtonProps()}>
        {buttonText}
        <ArrowIconContainer isOpen={isOpen}>
          <ExpandCollapseArrowThin color={COLORS.primary.accent} width={10} height={8} />
        </ArrowIconContainer>
      </StyledButton>
      <LeanDropdownItemList
        items={items}
        isOpen={isOpen}
        selected={selectedItem}
        getMenuProps={getMenuProps}
        getItemProps={getItemProps}
      />
    </DropDownWrapper>
  );
};

export const tagsItems = [
  {
    name: "Select Type",
    value: "",
  },
  {
    name: "Delegate",
    value: "delegate",
  },
  {
    name: "Service Provider",
    value: "service-provider",
  },
  {
    name: "Investor",
    value: "investor",
  },
  {
    name: "Core Developers",
    value: "core-developers",
  },
];

export const frameworkItems: {
  [key: string]: Item[];
} = {
  moonwell: [
    { name: "Moonbeam", value: "onchain" },
    {
      name: "Moonriver",
      value: "onchain-secondary",
    },
  ],
};

const DelegateFilterTab = ({ layout, onClick, setSortByFilter, setTagFilter }: FilterProps) => {
  const { setFrameworkFilter } = useContext(DelegateFrameworkFilterContext);
  const [filterParamsClone, setClone] = useState<string[]>([]);
  const cname = useCurrentProtocolCname();

  const { setShowFilters } = useMobileFilters();

  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth < 991;

  const parsedFrameworkItems = frameworkItems[cname];

  return (
    <>
      <StyledWrapper>
        <Tooltip title="Tile View">
          <StyledGridIcon
            height={18}
            width={18}
            color={layout === "grid" ? COLORS.primary.accent : COLORS.primary.grayDarkLightest}
            opacity={layout === "grid" ? 1 : 0.4}
            onClick={() => onClick("grid")}
            style={{ marginRight: layout === "grid" && !isMobile ? "2px" : "32px" }}
          />
        </Tooltip>
        {layout === "grid" && !isMobile && (
          <FilterWrapper>
            <DropDown
              items={DelegateSortByItems}
              initialValue={DelegateSortByItems[0]}
              onChange={(value) => setSortByFilter(value?.value || "All Framework")}
            />
          </FilterWrapper>
        )}

        <Tooltip title="List View">
          <StyledListIcon
            height={24}
            width={24}
            color={layout === "list" ? COLORS.primary.accent : COLORS.primary.grayDarkLightest}
            opacity={layout === "list" ? 1 : 0.4}
            onClick={() => onClick("list")}
          />
        </Tooltip>

        {isMobile && (
          <>
            <FiltersButton onClick={() => setShowFilters(true)}>
              <FunnelFilterIcon color={COLORS.primary.grayDarkLightest} width={12} height={12} />
              <span style={{ marginLeft: "6px" }}>Filters</span>
            </FiltersButton>
          </>
        )}
        {!isMobile && (
          <>
            {!!setTagFilter && (
              <FilterWrapper
                style={{
                  marginLeft: "auto",
                }}
              >
                {parsedFrameworkItems && parsedFrameworkItems.length > 1 && (
                  <DropDown
                    items={parsedFrameworkItems}
                    initialValue={parsedFrameworkItems[0]}
                    onChange={(value) => setFrameworkFilter(value?.value || "")}
                  />
                )}
              </FilterWrapper>
            )}
            {filterParamsClone.length > 0
              ? filterParamsClone.map((filter) => (
                  <ClosableTag
                    closeable
                    label={filter}
                    key={filter}
                    onClose={() => setClone([...filterParamsClone.filter((item) => item !== filter)])}
                  />
                ))
              : ""}
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export const VotersFilterTab = () => {
  const { setShowFilters } = useMobileFilters();

  const currentWidth = useCurrentWidth();
  const isMobile = currentWidth < 991;
  return (
    <>
      <StyledWrapper style={{ paddingBottom: "0", marginTop: "-24px" }}>
        {isMobile && (
          <>
            <FiltersButton onClick={() => setShowFilters(true)}>
              <FunnelFilterIcon color={COLORS.primary.grayDarkLightest} width={12} height={12} />
              <span style={{ marginLeft: "6px" }}>Filters</span>
            </FiltersButton>
          </>
        )}
      </StyledWrapper>
    </>
  );
};

export const InsightsFilterTab = ({ protocol }: { protocol: string }) => {
  const { setFrameworkFilter } = useContext(NetworkFilterFilterContext);
  const { data: topVpData } = useTopVpAndDelegationStats({ protocol });

  const items = frameworkItems[protocol];

  const lastUpdated = topVpData?.items[0]?.timestamp
    ? moment(topVpData.items[0].timestamp * 1000).format("MMM DD, YYYY")
    : null;

  return (
    <StyledWrapper
      style={{ marginTop: "-24px", paddingBottom: "0", marginBottom: "16px", display: "flex", alignItems: "center" }}
    >
      {lastUpdated && (
        <span>
          Last Updated: <strong>{lastUpdated}</strong>
        </span>
      )}
      {items && items.length > 1 && (
        <>
          <FilterWrapper
            style={{
              marginLeft: "auto",
            }}
          >
            <DropDown
              items={items}
              initialValue={items[0]}
              onChange={(value) => setFrameworkFilter(value?.value || "")}
            />
          </FilterWrapper>
        </>
      )}
    </StyledWrapper>
  );
};

export default (props: FilterProps) => (
  <Suspense
    fallback={
      <>
        <ContentLoader
          speed={2}
          width="100%"
          height={340}
          viewBox="0 0 100% 250"
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <rect x="0" y="0" rx="5" ry="5" width="100%" height="76" />
        </ContentLoader>
      </>
    }
  >
    <DelegateFilterTab {...props} />
  </Suspense>
);
