import axios from "axios";

import { baseAPIUrl, BOARDROOM_API_KEY } from "../constants/general";
import { getUuid } from "./getUuid";

interface Props {
  commentId: string;
  proposalId: string;
  author: string;
  newCommentBody?: string;
  sharedWithOrg?: boolean;
  siweFunction: any;
}

export const editComment = async ({ author, commentId, proposalId, newCommentBody, sharedWithOrg, siweFunction }: Props) => {
  const uuid = getUuid(author);
  if (!uuid) {
    siweFunction();
    throw new Error("You need to sign in with wallet to edit a comment");
  }
  const parsedData = {
    commentId,
    proposalId,
    author,
    commentBody: newCommentBody,
    uuid,
  };

  if (sharedWithOrg) {
    return (await axios.post(`${baseAPIUrl}editOrgNote?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
  }

  return (await axios.post(`${baseAPIUrl}editComment?key=${BOARDROOM_API_KEY}`, parsedData))?.data;
};
