import { useContext, useCallback } from "react";
import { Dict } from "mixpanel-browser";

import { MixpanelContext } from "../contexts";
import { isStaging, isProduction } from "../utils";
import { CalendarEvent } from "../types";

interface TrackPageViewProps {
  page: string;
  protocol?: string;
  resourceType?: string;
  resourceName?: string;
  transactionId?: string;
}

interface UserIdOnly {
  userId: string;
}

interface ProposalOnly {
  proposalRefId: string;
}

interface UserIdAndProposal {
  userId: string;
  proposalRefId: string;
}

interface UserIdAndProtocol {
  userId: string;
  protocol: string;
}

interface UserIdAndProjectsFilter {
  userId: string;
  projectsFilter: "Your Projects" | "Your Wallet(s)" | "All Projects";
}

interface UserIdAndVotingSystemsFilter {
  userId: string;
  votingSystem: "All Voting Systems" | "Snapshot" | "Onchain";
}

interface UserIdAndPage {
  userId: string;
  page: string;
}

interface TrackSubmitClaimProfileProps {
  userId: string;
  description: string;
  employer: string;
  githubUnverified: string;
  name: string;
  twitterUnverified: string;
  website: string;
}

interface TrackStartDelegateProps {
  delegateAddress: string;
  userId: string;
}

interface TrackCancelDelegateProps {
  delegateAddress: string;
  userId: string;
}

interface TrackSubmitDelegationProps {
  protocol: string;
  to: string;
  userId: string;
  chainId: string;
}

interface TrackStartCastVoteProps {
  protocol: string;
  type: "onchain" | "offchain";
  proposalId: string;
  userId: string;
}

interface TrackCancelCastVoteProps {
  protocol: string;
  type: "onchain" | "offchain";
  proposalId: string;
  userId: string;
}

interface TrackSubmitCastVoteProps {
  protocol: string;
  type: "onchain" | "offchain";
  proposalId: string;
  userId: string;
  chainId?: string;
}

interface TrackSelectWalletProviderProps {
  walletProvider: string;
}

interface TrackSelectProjectProps {
  protocol: string;
  userId: string;
  context: string;
}

interface TrackSelectForumTopicProps {
  protocol: string;
  topicId: string;
  userId: string;
}

interface TrackOpenExternalForumTopicDiscussionProps {
  protocol: string;
  topicId: string;
  url: string;
  userId: string;
}

interface TrackSelectProposalProps {
  protocol: string;
  proposalId: string;
  userId: string;
  context: string;
}

interface TrackSelectSnapshotPollProps {
  protocol: string;
  proposalId: string;
  userId: string;
  context: string;
}

interface TrackSelectVoterProps {
  protocol: string;
  voterAddress: string;
  userId: string;
  context: string;
}

interface TrackSignDataProps {
  protocol: string;
  to: string;
  userId: string;
  chainId: string;
}

interface TrackCreateProposalProps {
  protocol: string;
  proposalId: string;
  userId: string;
}

interface TrackOpenExternalProfileEdit {
  userId: string;
  url: string;
}

interface TrackOpenBoardroomSocialLink {
  type: string;
  userId: string;
}

interface TrackShareProposalOnTwitter {
  userId: string;
  protocol: string;
  proposalId?: string;
}

interface TrackShareSnapshotPollOnTwitter {
  userId: string;
  protocol: string;
  proposalId?: string;
}

interface TrackShareVoterProfileOnTwitter {
  userId: string;
  protocol: string;
  voterAddress: string | null;
}

interface TrackShareProtocolcallPageOnTwitter {
  userId: string;
  protocol: string;
  callTitle: string;
}

/**
 * Dashboard
 */
interface TrackHoverCalendar {
  userId: string;
  date: string;
}
interface TrackClickCalendarEvent {
  userId: string;
  event: CalendarEvent;
  fromMini: boolean;
  fromWeekly: boolean;
  fromMonthly: boolean;
}

interface TrackProposalsListNextPage {
  userId: string;
  page: number;
}
interface TrackClickProposal {
  userId: string;
  protocol: string;
  proposalRefId: string;
}

interface TrackClickVoteHistoryItem {
  userId: string;
  protocol: string;
  proposalRefId: string;
}

/**
 * Landing
 */

interface TrackHowToCardClick {
  userId: string;
  title: string;
}

const useMixpanel = () => {
  const { mixpanel, ip } = useContext(MixpanelContext);
  const mixpanelTrack = useCallback(
    (eventName: string, props?: Dict) => {
      if (isStaging || isProduction) {
        mixpanel.track(eventName, {
          ...props,
          environment: process.env.REACT_APP_NODE_ENV,
          ip,
        });
      }
    },
    [mixpanel, ip],
  );

  /**
   * Navigation
   */
  const trackPageView = useCallback(
    (props: TrackPageViewProps) => {
      mixpanelTrack("Page View", {
        ...props,
        wallet: window.localStorage.getItem("selectedWallet"),
      });
    },
    [mixpanelTrack],
  );

  const trackNavigateToHome = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Navigate to Home", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToForum = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Navigate to Forum", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToProposals = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Navigate to Proposals", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToVoters = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Navigate to Voters", props);
    },
    [mixpanelTrack],
  );

  /**
   * Voter
   */

  const trackVoterSubmitDelegationPitch = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Submit Delegation Pitch", props);
    },
    [mixpanelTrack],
  );

  const trackStartClaimProfile = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Start Claim Profile", props);
    },
    [mixpanelTrack],
  );

  const trackCancelClaimProfile = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Cancel Claim Profile", props);
    },
    [mixpanelTrack],
  );

  const trackSubmitClaimProfile = useCallback(
    (props: TrackSubmitClaimProfileProps) => {
      mixpanelTrack("Submit Claim Profile", props);
    },
    [mixpanelTrack],
  );

  const trackStartSetupDelegation = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Start Setup Delegation", props);
    },
    [mixpanelTrack],
  );

  const trackCancelSetupDelegation = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Cancel Setup Delegation", props);
    },
    [mixpanelTrack],
  );

  const trackStartDelegate = useCallback(
    (props: TrackStartDelegateProps) => {
      mixpanelTrack("Start Delegate", props);
    },
    [mixpanelTrack],
  );

  const trackCancelDelegate = useCallback(
    (props: TrackCancelDelegateProps) => {
      mixpanelTrack("Cancel Delegate", props);
    },
    [mixpanelTrack],
  );

  const trackSubmitDelegation = useCallback(
    ({ protocol, userId, chainId, to }: TrackSubmitDelegationProps) => {
      mixpanelTrack("Submit Delegation", {
        protocol,
        userId,
        chainId,
        to,
      });
    },
    [mixpanelTrack],
  );

  const trackOpenPoliticiansModal = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Open Politicians Modal", props);
    },
    [mixpanelTrack],
  );

  /**
   * Voting
   */

  const trackStartCastVote = useCallback(
    (props: TrackStartCastVoteProps) => {
      mixpanelTrack("Start Cast Vote", props);
    },
    [mixpanelTrack],
  );

  const trackCancelCastVote = useCallback(
    (props: TrackCancelCastVoteProps) => {
      mixpanelTrack("Cancel Cast Vote", props);
    },
    [mixpanelTrack],
  );

  const trackSubmitCastVote = useCallback(
    (props: TrackSubmitCastVoteProps) => {
      mixpanelTrack("Submit Cast Vote", props);
    },
    [mixpanelTrack],
  );

  const trackOpenConnectWalletDrawer = useCallback(() => {
    mixpanelTrack("Open Connect Wallet Drawer");
  }, [mixpanelTrack]);

  const trackSelectWalletProvider = useCallback(
    (props: TrackSelectWalletProviderProps) => {
      mixpanelTrack("Select Wallet Provider", props);
    },
    [mixpanelTrack],
  );

  const trackSelectProject = useCallback(
    (props: TrackSelectProjectProps) => {
      mixpanelTrack("Select Project", props);
    },
    [mixpanelTrack],
  );

  const trackSelectForumTopic = useCallback(
    (props: TrackSelectForumTopicProps) => {
      mixpanelTrack("Select Forum Topic", props);
    },
    [mixpanelTrack],
  );

  const trackOpenExternalForumTopicDiscussion = useCallback(
    (props: TrackOpenExternalForumTopicDiscussionProps) => {
      mixpanelTrack("Open External Forum Topic Discussion", props);
    },
    [mixpanelTrack],
  );

  const trackSelectProposal = useCallback(
    (props: TrackSelectProposalProps) => {
      mixpanelTrack("Select Proposal", props);
    },
    [mixpanelTrack],
  );

  const trackSelectSnapshotPoll = useCallback(
    (props: TrackSelectSnapshotPollProps) => {
      mixpanelTrack("Select Snapshot Poll", props);
    },
    [mixpanelTrack],
  );

  const trackSelectVoter = useCallback(
    (props: TrackSelectVoterProps) => {
      mixpanelTrack("Select Voter", props);
    },
    [mixpanelTrack],
  );

  const trackSignData = useCallback(
    ({ protocol, userId, chainId, to }: TrackSignDataProps) => {
      mixpanelTrack("Sign Data", {
        protocol,
        userId,
        chainId,
        to,
      });
    },
    [mixpanelTrack],
  );

  const trackCreateProposal = useCallback(
    (props: TrackCreateProposalProps) => {
      mixpanelTrack("Create Proposal", props);
    },
    [mixpanelTrack],
  );

  const trackOpenExternalProfileEdit = useCallback(
    (props: TrackOpenExternalProfileEdit) => {
      mixpanelTrack("External Profile Edit", props);
    },
    [mixpanelTrack],
  );

  const trackOpenFeatureRequestModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open Feature Request Modal", props);
    },
    [mixpanelTrack],
  );

  const trackOpenBoardroomSocialLink = useCallback(
    (props: TrackOpenBoardroomSocialLink) => {
      mixpanelTrack("Open Boardroom Social Link", props);
    },
    [mixpanelTrack],
  );

  /**
   * Social Sharing
   */
  const trackShareProposalOnTwitter = useCallback(
    (props: TrackShareProposalOnTwitter) => {
      mixpanelTrack("Share Proposal on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareSnapshotPollOnTwitter = useCallback(
    (props: TrackShareSnapshotPollOnTwitter) => {
      mixpanelTrack("Share Snapshot Poll on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareVoterProfileOnTwitter = useCallback(
    (props: TrackShareVoterProfileOnTwitter) => {
      mixpanelTrack("Share Voter Profile on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareProtocolIdeationPageOnTwitter = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Share Protocol Ideation Page on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareProtocolcallsPageOnTwitter = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Share Calls Info Page on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareProtocolcallPageOnTwitter = useCallback(
    (props: TrackShareProtocolcallPageOnTwitter) => {
      mixpanelTrack("Share Call Info Page on Twitter", props);
    },
    [mixpanelTrack],
  );

  const trackShareProtocolsListPageOnTwitter = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Share Protocol Proposal List Page on Twitter", props);
    },
    [mixpanelTrack],
  );

  /**
   * Dashboard
   */
  const trackClickVoteHistoryItem = useCallback(
    (props: TrackClickVoteHistoryItem) => {
      mixpanelTrack("Dashboard Click Vote History Item", props);
    },
    [mixpanelTrack],
  );

  const trackClickProposal = useCallback(
    (props: TrackClickProposal) => {
      mixpanelTrack("Dashboard Click Proposal Item", props);
    },
    [mixpanelTrack],
  );

  const trackProposalsListNextPage = useCallback(
    (props: TrackProposalsListNextPage) => {
      mixpanelTrack("Dashboard Timeline Next Page", props);
    },
    [mixpanelTrack],
  );

  const trackExpandCalendar = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Dashboard Expand Calendar", props);
    },
    [mixpanelTrack],
  );

  const trackClickCalendarEvent = useCallback(
    (props: TrackClickCalendarEvent) => {
      mixpanelTrack("Click Calendar Event on grid view", props);
    },
    [mixpanelTrack],
  );

  const trackHoverCalendar = useCallback(
    (props: TrackHoverCalendar) => {
      mixpanelTrack("Hover Calendar Event", props);
    },
    [mixpanelTrack],
  );

  const trackUncaughtAppError = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Uncaught App Error", props);
    },
    [mixpanelTrack],
  );

  const trackTopDaosDropDownClick = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click Top Dao Dropdown", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToDashboardFromLanding = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Navigate to Dashboard from landing", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToProjectsFromLanding = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Navigate to projects from landing", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToDeveloperLandingFromLanding = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Navigate to api landing page from landing", props);
    },
    [mixpanelTrack],
  );

  const trackHowToCardClick = useCallback(
    (props: TrackHowToCardClick) => {
      mixpanelTrack("Click on how to card", props);
    },
    [mixpanelTrack],
  );

  const trackCategoryDropdownClick = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click Browse by Category Dropdown", props);
    },
    [mixpanelTrack],
  );

  const trackClickProposalSummaryButton = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click Proposal Summary Button in feed", props);
    },
    [mixpanelTrack],
  );
  const trackClickNoteButton = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click Note Button in feed", props);
    },
    [mixpanelTrack],
  );

  const trackClickFeedVoteButton = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click Vote Button in feed", props);
    },
    [mixpanelTrack],
  );

  const trackClickNotificationsSignUpButton = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click sign up button in notifications card", props);
    },
    [mixpanelTrack],
  );

  const trackClickConnectWalletOnDelegatesCard = useCallback(() => {
    mixpanelTrack("Click connect wallet button on portal delegates card");
  }, [mixpanelTrack]);

  const trackClickDocsLinkOnDelegatesCard = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click docs link in notifications card", props);
    },
    [mixpanelTrack],
  );

  const trackClickConnectWalletOnVotePowerCard = useCallback(() => {
    mixpanelTrack("Click connect wallet button on portal vote power card");
  }, [mixpanelTrack]);

  const trackClickEventOrMeetingOnPortal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click event or meeting link on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickShareProposalOnPortal = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click share proposal on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickBookmarkProposalOnFeed = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click bookmark proposal on feed", props);
    },
    [mixpanelTrack],
  );

  const trackClickSavedProposalFilterOnFeed = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click saved proposal filter on feed", props);
    },
    [mixpanelTrack],
  );

  const trackClickVotedProposalFilterOnFeed = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click voted proposal filter on feed", props);
    },
    [mixpanelTrack],
  );

  const trackClickCustomizeButtonOnDropdown = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click customize button on filters dropdown on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickEditPreferencesButtonOnDropdown = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click edit preferences button on filters dropdown on portal", props);
    },
    [mixpanelTrack],
  );

  const trackExpandProjectsFilterDropdown = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Expand projects filter dropdown", props);
    },
    [mixpanelTrack],
  );

  const trackExpandVotingSystemsFilterDropdown = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Expand voting systems filter dropdown", props);
    },
    [mixpanelTrack],
  );

  const trackClickProjectsFilterOption = useCallback(
    (props: UserIdAndProjectsFilter) => {
      mixpanelTrack("Click projects filter option", props);
    },
    [mixpanelTrack],
  );

  const trackClickVotingSystemsFilterOption = useCallback(
    (props: UserIdAndVotingSystemsFilter) => {
      mixpanelTrack("Click voting systems filter option", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateMonths = useCallback(
    (props: UserIdAndPage) => {
      mixpanelTrack("Navigate through months on calendar", props);
    },
    [mixpanelTrack],
  );

  const trackClickTodayButton = useCallback(
    (props: UserIdAndPage) => {
      mixpanelTrack("Click today button on calendar", props);
    },
    [mixpanelTrack],
  );

  const trackClickGridView = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click grid view button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickListView = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click list view button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickEndingSoonFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click ending soon proposals filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickAllFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click all filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickTodayFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click today filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickOpenFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click open filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickPendingFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click pending proposals filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackClickClosedFilter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click closed proposals filter button on portal", props);
    },
    [mixpanelTrack],
  );

  const trackExpandUpcomingEventsCurrentMonth = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Expand upcoming events on the current month", props);
    },
    [mixpanelTrack],
  );

  const trackExpandPastEventsCurrentMonth = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Expand past events on the current month", props);
    },
    [mixpanelTrack],
  );

  const trackExpandEventsOnAnotherMonth = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Expand events on another month", props);
    },
    [mixpanelTrack],
  );

  const trackClickCustomizeButtonOnNotificationsModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click customize button on notifications modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickCustomizeButtonOnPostModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click customize button on Post modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickCustomizeButtonOnCreatePage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click customize button on Create Page", props);
    },
    [mixpanelTrack],
  );

  const trackClickCustomizeButtonOnEmailPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click customize button on email settings page", props);
    },
    [mixpanelTrack],
  );

  const trackClickEditPreferencesButtonOnNotificationsModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click edit preferences button on notifications modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickPreferencesLinkOnNotificationsModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click preferences page link on notifications modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickConnectWalletOnNotificationsModal = useCallback(() => {
    mixpanelTrack("Click connect wallet on notifications modal");
  }, [mixpanelTrack]);

  const trackClickSavePreferencesOnNotificationsModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click save preferences on notiications modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickSavePreferencesOnNotificationsCard = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click save preferences on notifications card", props);
    },
    [mixpanelTrack],
  );

  const trackClickProposerLinkOnVotingModal = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click proposer profile link on voting modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickSeeFullProposalOnVotingModal = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click see full proposal on voting modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickConnectWalletOnVotingModal = useCallback(
    (props: ProposalOnly) => {
      mixpanelTrack("Click connect wallet on voting modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickViewVoteReceipt = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click view vote receipt", props);
    },
    [mixpanelTrack],
  );

  const trackClickChangeVote = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click change vote", props);
    },
    [mixpanelTrack],
  );

  const trackClickNextProposal = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click next proposal", props);
    },
    [mixpanelTrack],
  );

  const trackClickConnectWalletOnNotesModal = useCallback(
    (props: ProposalOnly) => {
      mixpanelTrack("Click connect wallet on notes modal", props);
    },
    [mixpanelTrack],
  );

  const trackClickPostNote = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click post note", props);
    },
    [mixpanelTrack],
  );

  const trackClickEditNote = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click edit note button", props);
    },
    [mixpanelTrack],
  );

  const trackClickDeleteNote = useCallback(
    (props: UserIdAndProposal) => {
      mixpanelTrack("Click delete note button", props);
    },
    [mixpanelTrack],
  );

  const trackClickSelectProtocolDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click select protocol in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickPostButtonDelegationTab = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click post button in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackOpenPostModalFromMessageBoard = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Open post modal from message board in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickSeeMoreMessagesDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click see more messages in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickSeeMoreDelegatorsDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click see more delegators in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickDelegatorDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click delegator in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickEditPitchButtonDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click edit pitch button in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickEditDelegationButtonDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click edit delegation button in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackClickVisitProfileButtonDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click visit profile button in delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackShareDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Share delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackShareDelegationTabPost = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Share delegation tab post", props);
    },
    [mixpanelTrack],
  );

  const trackCreatePostDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Create post delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackEditPostDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Edit post delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackOpenPostModalForEditingDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Open post modal for editing delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackDeletePostDelegationTab = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Delete post delegation tab", props);
    },
    [mixpanelTrack],
  );

  const trackUpdateDelegationPitch = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update delegation pitch", props);
    },
    [mixpanelTrack],
  );

  const trackUpdateProfileSettingsInProfileSettingsPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update profile settings in profile settings page", props);
    },
    [mixpanelTrack],
  );

  const trackUpdateTeamProfileSettingsInTeamProfileSettingsPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update team profile settings in team profile settings page", props);
    },
    [mixpanelTrack],
  );

  const trackClickProtocolInExplore = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Click protocol in explore protocols page", props);
    },
    [mixpanelTrack],
  );

  const trackSwitchToTeamView = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Switch to team view", props);
    },
    [mixpanelTrack],
  );
  const trackUpdateProfileSettingsInProfileModal = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update profile settings in profile modal", props);
    },
    [mixpanelTrack],
  );

  const trackSwitchTeamWalletOnDelegateTab = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Switch team wallet on delegate tab", props);
    },
    [mixpanelTrack],
  );

  const trackUpdateFeedPreferencesInFeedSettingsPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update feed preferences in feed settings page", props);
    },
    [mixpanelTrack],
  );

  const trackClickCsvDownloadButton = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click CSV download button", props);
    },
    [mixpanelTrack],
  );

  const trackUpdateEmailPreferencesInEmailSettingsPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Update email settings in email settings page", props);
    },
    [mixpanelTrack],
  );

  const trackOpenEditProfileModalInProfilePage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open edit profile modal in profile page", props);
    },
    [mixpanelTrack],
  );

  const trackClickProfileWebsite = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click profile website", props);
    },
    [mixpanelTrack],
  );

  const trackClickProfileTwitter = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click profile twitter", props);
    },
    [mixpanelTrack],
  );

  const trackClickCopyProfileLink = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click copy profile link", props);
    },
    [mixpanelTrack],
  );

  const trackClickCopyProfileAddress = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click copy profile address", props);
    },
    [mixpanelTrack],
  );

  const trackSwitchToDelegationPitchesInProfile = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Switch to delegation pitches in profile", props);
    },
    [mixpanelTrack],
  );

  const trackSwitchToAboutInProfile = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Switch to about in profile", props);
    },
    [mixpanelTrack],
  );

  const trackNavigateToProtocolFromActiveProtocolsInProfile = useCallback(
    (props: UserIdAndProtocol) => {
      mixpanelTrack("Navigate to protocol from active protocols", props);
    },
    [mixpanelTrack],
  );

  const trackClickSavePreferencesInNotificationsSettingsPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click save preferences in notifications settings page", props);
    },
    [mixpanelTrack],
  );

  const trackOpenWalletDrawerFromCastVote = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open wallet drawer from cast vote component", props);
    },
    [mixpanelTrack],
  );

  const trackOpenWalletDrawer = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open wallet drawer", props);
    },
    [mixpanelTrack],
  );

  const trackOpenConnectWalletModalFromWalletDrawer = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open connect wallet modal from wallet drawer", props);
    },
    [mixpanelTrack],
  );

  const trackClickGetStartedButtonInOnboardPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click Get Started button in Onboard page", props);
    },
    [mixpanelTrack],
  );

  const trackClickNextOnStep2InOnboardPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Click next button on step 2 in Onboard page", props);
    },
    [mixpanelTrack],
  );

  const trackOpenChargebeeModalInOnboardPage = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Open Chargebee modal in Onboard page", props);
    },
    [mixpanelTrack],
  );

  const trackSwitchToDelegatorsInProfile = useCallback(
    (props: UserIdOnly) => {
      mixpanelTrack("Switch to delegators in profile", props);
    },
    [mixpanelTrack],
  );

  return {
    trackPageView,
    trackNavigateToHome,
    trackNavigateToForum,
    trackNavigateToProposals,
    trackNavigateToVoters,
    trackStartClaimProfile,
    trackCancelClaimProfile,
    trackSubmitClaimProfile,
    trackStartSetupDelegation,
    trackCancelSetupDelegation,
    trackStartDelegate,
    trackCancelDelegate,
    trackSubmitDelegation,
    trackOpenPoliticiansModal,
    trackStartCastVote,
    trackCancelCastVote,
    trackSubmitCastVote,
    trackOpenConnectWalletDrawer,
    trackSelectWalletProvider,
    trackSelectProject,
    trackSelectForumTopic,
    trackOpenExternalForumTopicDiscussion,
    trackSelectProposal,
    trackCreateProposal,
    trackSelectSnapshotPoll,
    trackSelectVoter,
    trackSignData,
    trackOpenExternalProfileEdit,
    trackOpenFeatureRequestModal,
    trackOpenBoardroomSocialLink,
    trackShareProtocolIdeationPageOnTwitter,
    trackShareProposalOnTwitter,
    trackShareSnapshotPollOnTwitter,
    trackShareVoterProfileOnTwitter,
    trackShareProtocolcallsPageOnTwitter,
    trackShareProtocolcallPageOnTwitter,
    trackShareProtocolsListPageOnTwitter,
    trackClickVoteHistoryItem,
    trackClickProposal,
    trackProposalsListNextPage,
    trackExpandCalendar,
    trackClickCalendarEvent,
    trackHoverCalendar,
    trackVoterSubmitDelegationPitch,
    trackUncaughtAppError,
    trackTopDaosDropDownClick,
    trackNavigateToDashboardFromLanding,
    trackNavigateToProjectsFromLanding,
    trackNavigateToDeveloperLandingFromLanding,
    trackHowToCardClick,
    trackCategoryDropdownClick,
    trackClickProposalSummaryButton,
    trackClickNoteButton,
    trackClickFeedVoteButton,
    trackClickNotificationsSignUpButton,
    trackClickConnectWalletOnDelegatesCard,
    trackClickConnectWalletOnVotePowerCard,
    trackClickDocsLinkOnDelegatesCard,
    trackClickEventOrMeetingOnPortal,
    trackClickShareProposalOnPortal,
    trackClickCustomizeButtonOnDropdown,
    trackClickEditPreferencesButtonOnDropdown,
    trackClickProjectsFilterOption,
    trackClickVotingSystemsFilterOption,
    trackExpandProjectsFilterDropdown,
    trackExpandVotingSystemsFilterDropdown,
    trackNavigateMonths,
    trackClickTodayButton,
    trackClickGridView,
    trackClickListView,
    trackClickEndingSoonFilter,
    trackClickAllFilter,
    trackClickPendingFilter,
    trackClickClosedFilter,
    trackExpandPastEventsCurrentMonth,
    trackExpandUpcomingEventsCurrentMonth,
    trackExpandEventsOnAnotherMonth,
    trackClickCustomizeButtonOnNotificationsModal,
    trackClickSavePreferencesOnNotificationsModal,
    trackClickEditPreferencesButtonOnNotificationsModal,
    trackClickPreferencesLinkOnNotificationsModal,
    trackClickConnectWalletOnNotificationsModal,
    trackClickProposerLinkOnVotingModal,
    trackClickSeeFullProposalOnVotingModal,
    trackClickConnectWalletOnVotingModal,
    trackClickViewVoteReceipt,
    trackClickChangeVote,
    trackClickNextProposal,
    trackClickConnectWalletOnNotesModal,
    trackClickPostNote,
    trackClickEditNote,
    trackClickDeleteNote,
    trackClickTodayFilter,
    trackClickOpenFilter,
    trackClickCustomizeButtonOnEmailPage,
    trackClickSelectProtocolDelegationTab,
    trackClickPostButtonDelegationTab,
    trackClickSeeMoreMessagesDelegationTab,
    trackClickSeeMoreDelegatorsDelegationTab,
    trackClickDelegatorDelegationTab,
    trackClickEditPitchButtonDelegationTab,
    trackClickEditDelegationButtonDelegationTab,
    trackClickVisitProfileButtonDelegationTab,
    trackOpenPostModalFromMessageBoard,
    trackShareDelegationTab,
    trackShareDelegationTabPost,
    trackOpenPostModalForEditingDelegationTab,
    trackCreatePostDelegationTab,
    trackEditPostDelegationTab,
    trackDeletePostDelegationTab,
    trackClickCustomizeButtonOnPostModal,
    trackUpdateDelegationPitch,
    trackClickProtocolInExplore,
    trackSwitchToTeamView,
    trackSwitchTeamWalletOnDelegateTab,
    trackClickCsvDownloadButton,
    trackOpenEditProfileModalInProfilePage,
    trackClickProfileWebsite,
    trackNavigateToProtocolFromActiveProtocolsInProfile,
    trackClickProfileTwitter,
    trackClickCopyProfileLink,
    trackClickCopyProfileAddress,
    trackSwitchToDelegationPitchesInProfile,
    trackSwitchToAboutInProfile,
    trackUpdateProfileSettingsInProfileSettingsPage,
    trackUpdateProfileSettingsInProfileModal,
    trackUpdateFeedPreferencesInFeedSettingsPage,
    trackUpdateEmailPreferencesInEmailSettingsPage,
    trackClickSavePreferencesInNotificationsSettingsPage,
    trackUpdateTeamProfileSettingsInTeamProfileSettingsPage,
    trackClickCustomizeButtonOnCreatePage,
    trackClickSavePreferencesOnNotificationsCard,
    trackOpenWalletDrawerFromCastVote,
    trackOpenWalletDrawer,
    trackOpenConnectWalletModalFromWalletDrawer,
    trackClickBookmarkProposalOnFeed,
    trackClickSavedProposalFilterOnFeed,
    trackClickVotedProposalFilterOnFeed,
    trackClickGetStartedButtonInOnboardPage,
    trackClickNextOnStep2InOnboardPage,
    trackOpenChargebeeModalInOnboardPage,
    trackSwitchToDelegatorsInProfile
  };
};

export default useMixpanel;
