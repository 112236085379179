import React, { useCallback, useContext, useEffect, useState } from "react";
import Col from "antd/es/col";
import Row from "antd/es/row";
import notification from "antd/es/notification";
import { Link, useHistory } from "react-router-dom";
import styled, { css } from "styled-components";

import {
  FlagCircleIcon,
  LightBulbIcon,
  ThinCheckIcon,
  ThinChevronUpIcon,
  WalletVariationIcon,
} from "../../components/icons";
import { TrackPageView } from "../../components/TrackPageView";
import { BackgroundForMobile, Container, MainContainer, Modal } from "../../components";
import { PaddingContainer, PageMax } from "../Delegation/styles";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import mediaQuery from "../../media-query";
import { apiClient } from "../../utils/apiClient";
import { useSiwe } from "../../hooks/useSiwe";
import { useMixpanel } from "../../hooks";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import { RoutePaths } from "../../constants/Routes";

const NextButton = styled.button`
  background: #4235e1;
  border-radius: 8px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  height: 40px;
  width: 100%;
  border: none;
  cursor: pointer;
  outline: none;
  :hover {
    background: #291fa7;
  }
  :active {
    background: #191540;
  }
  :disabled {
    cursor: not-allowed;
    background: #7b7893;
  }
`;

const BackButton = styled.button`
  background: white;
  border-radius: 8px;
  color: #4235e1;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  height: 40px;
  width: 100%;
  border: 1px solid #4235e1;
  cursor: pointer;
  outline: none;
  :hover {
    border: 1px solid #291fa7;
    color: #291fa7;
  }
  :active {
    border: 1px solid #291fa7;
    color: #fff;
    background: #291fa7;
  }
  :disabled {
    border: 1px solid #7b7893;
    color: #7b7893;
    cursor: not-allowed;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  padding: 16px 0 20px;
  border-top: 1px solid #f0eff8;
  background: #fafafa;
`;

const ScrollCol = styled(Col)`
  height: calc(100vh - 128px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Step3Description = styled.p`
  margin-top: 12px;
  margin-bottom: 64px;
  color: #7b7893;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
`;

const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding-bottom: 24px;
  width: 100%;
`;

const GetStartedText = styled.span`
  color: #191540;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
`;

const NameBundle = styled.div`
  border-radius: 8px 8px 0px 0px;
  background: #f0eff8;
  color: #191540;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  gap: 12px;
`;

const LightBulbWrapper = styled.span`
  display: flex;
  align-items: center;
  color: #7b7893;
  font-size: 10px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 12px;
  margin-left: 40px;
  svg {
    margin-right: 4px;
  }
`;

const StyledGradientDiv = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    linear-gradient(79deg, #191540 0%, #4235e1 51.04%, #191540 100%);
  height: 100%;
  width: 43vw;
  position: fixed;
  left: 0;
  top: 64px;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top -22px right 0;
  }
  ${mediaQuery.lessThan("1100px")`
    display: none;
  `}
  ${mediaQuery.lessThan("1400px")`
    img {
      object-position: top 0 right 0;
    }
  `}
  ${mediaQuery.greaterThan("1460px")`
    img {
      object-position: top -32px right 0;
    }
  `}
`;

const BundleCard = styled.div<{ $isOpen?: boolean }>`
  border-radius: 4px;
  border: 1px solid ${({ $isOpen }) => ($isOpen ? "#4235e1" : "#f0eff8")};
  padding: 0;
  margin: 12px 0;
  width: 100%;
  background: #fff;
  :hover {
    border: 1px solid #4235e1;
  }
`;

const BundleCardInitialContent = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
  padding: 24px 12px 12px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  ${({ $isOpen }) =>
    $isOpen &&
    `
  background: #fafafa;
  border-bottom: 1px solid #f0eff8;
  `}
`;

const BundleCardTitle = styled.span`
  color: #191540;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
`;

const OptionalText = styled.span`
  color: #7b7893;
  font-style: italic;
  font-weight: 400;
`;

const BundleCardDescription = styled.span`
  color: #7b7893;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;

const BundleCardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 0 12px 0;
`;

const BundleCardInitialContentRightSide = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const ExpandCollapseBundleCard = styled(ThinChevronUpIcon)<{ $isOpen?: boolean }>`
  cursor: pointer;
  ${({ $isOpen }) => !$isOpen && "transform: rotate(180deg);"}
`;

const NumberOfAddedWallets = styled.span<{ $isOpen?: boolean }>`
  color: #7b7893;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 16px;
  border-radius: 8px;
  border: 1px solid #c8c8c8;
  background: #f6f6f6;
`;

const BundleNameWrapper = styled.div<{ $hasError?: boolean; $isFocused?: boolean }>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid #f0eff8;
  padding-bottom: 12px;
  position: relative;
  color: #7b7893;
  :hover {
    color: #4235e1;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

    input {
      border-bottom: 1px solid #4235e1;
    }
  }
  ${({ $isFocused }) =>
    $isFocused &&
    css`
      color: #4235e1;
      box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);

      input {
        border-bottom: 1px solid #4235e1;
      }
    `}
  ${({ $hasError }) =>
    $hasError &&
    css`
      color: #ff4141;
      input {
        border-bottom: 1px solid #ff4141;
      }
    `}
`;

const BundleNameLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 12px 0 0 44px;
`;

const BundleNameInput = styled.input`
  border: none;
  outline: none;
  color: #191540;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  padding: 4px 12px 8px 0;
  margin: 0 16px 0 44px;
  border-bottom: 1px solid #191540;
  background: transparent;
`;

const BundleNameInputInfo = styled.div`
  position: absolute;
  bottom: 20px;
  right: 16px;
  text-align: right;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
`;

const WalletWrapper = styled.div<{ $isAdmin?: boolean; $isFocused?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid #d8d8fa;
  padding: 8px;
  position: relative;
  color: #7b7893;
  ${({ $isAdmin }) =>
    !$isAdmin &&
    css`
      :hover {
        border: 1px solid #4235e1;
      }
    `}
  ${({ $isFocused }) => $isFocused && "border: 1px solid #4235e1;"}
  ${({ $isAdmin }) => $isAdmin && "background: #fafafa;"}
`;

const RemoveWalletButton = styled.span`
  color: #7b7893;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 16px;
  :hover {
    color: #4235e1;
  }
  :active {
    color: #291fa7;
  }
`;

const StepWrapper = styled.div`
  height: calc(100% - 98px);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ExitingModalTitle = styled.span`
  color: #191540;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 30px;
`;

const ExitingModalDescription = styled.p`
  color: #7b7893;
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  margin: 16px 0;
`;

const ExitingModalExitButton = styled(Link)`
  color: #7b7893;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 8px;
  border: 1px solid #7b7893;
  background: #fff;
  height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ExitingModalBackButton = styled.button`
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  border-radius: 8px;
  border: none;
  background: #4235e1;
  height: 40px;
  outline: none;
  cursor: pointer;
  width: 100%;
`;

const ModalButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 0 20px 0;
  position: absolute;
  bottom: 16px;
`;

const WalletInputContent = ({
  setWallets,
  index,
  content,
  wallet,
}: {
  setWallets: React.Dispatch<React.SetStateAction<string[]>>;
  index: number;
  content: string;
  wallet: string;
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <WalletWrapper $isFocused={isFocused}>
      <BundleNameLabel style={{ margin: 0, padding: 0 }}>{content}</BundleNameLabel>
      <BundleNameInput
        style={{ margin: 0, padding: 0, borderBottom: 0 }}
        value={wallet}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onChange={(e) => {
          const value = e.target.value;
          setWallets((curr) => {
            const newWallets = [...curr];
            newWallets[index] = value;
            return newWallets;
          });
        }}
      />
      <RemoveWalletButton
        onClick={() => {
          setWallets((curr) => {
            const newWallets = [...curr];
            newWallets.splice(index, 1);
            return newWallets;
          });
        }}
      >
        Remove {content?.includes("Wallet") ? "Wallet" : "User"}
      </RemoveWalletButton>
    </WalletWrapper>
  );
};

const RenderBundleCreate = ({
  bundleName,
  setBundleName,
  wallets,
  setWallets,
}: {
  bundleName: string;
  setBundleName: React.Dispatch<React.SetStateAction<string>>;
  setWallets: React.Dispatch<React.SetStateAction<string[]>>;
  wallets: string[];
}) => {
  const [hasError, setHasError] = useState(false);
  const [isNameFocused, setIsNameFocused] = useState(false);
  const [isWalletsOpen, setIsWalletsOpen] = useState(false);
  return (
    <StepWrapper>
      <GetStartedText>Add More Wallets</GetStartedText>
      <Step3Description style={{ marginBottom: "44px" }}>
        Add and track more addresses with a wallet bundle. You&apos;ll be able to view aggregated governance activity
        across 350+ DAOs for all additional wallets in a single, shareable profile view.
      </Step3Description>
      <BundleNameWrapper $isFocused={isNameFocused} $hasError={hasError}>
        <NameBundle>
          <FlagCircleIcon /> Name Your Wallet Bundle
        </NameBundle>
        <BundleNameLabel htmlFor="orgName">Wallet Bundle Name</BundleNameLabel>
        <BundleNameInput
          onFocus={() => {
            setHasError(false);
            setIsNameFocused(true);
          }}
          onBlur={() => {
            if (!bundleName) {
              setHasError(true);
            } else {
              setHasError(false);
            }
            setIsNameFocused(false);
          }}
          value={bundleName}
          onChange={(e) => setBundleName(e.target.value?.slice(0, 40))}
          type="text"
          name="orgName"
        />
        <BundleNameInputInfo>
          {bundleName?.length ? (
            <ThinCheckIcon width={16} height={16} color="#54C63F" />
          ) : (
            <span
              style={{ fontStyle: "italic", paddingRight: "4px", marginRight: "4px", borderRight: "1px solid #7b7893" }}
            >
              Required
            </span>
          )}{" "}
          <span>{bundleName?.length}/40</span>
        </BundleNameInputInfo>
      </BundleNameWrapper>
      <LightBulbWrapper>
        <LightBulbIcon color="#35C9F8" width={12} height={12} />
        You can edit your Bundle anytime in <span style={{ fontWeight: "500", marginLeft: "4px" }}>Settings</span>
      </LightBulbWrapper>
      <BundleCard $isOpen={isWalletsOpen} style={{ marginTop: "40px" }}>
        <BundleCardInitialContent $isOpen={isWalletsOpen} onClick={() => setIsWalletsOpen((curr) => !curr)}>
          <WalletVariationIcon color={isWalletsOpen ? "#4235e1" : "#191540"} width={20} height={20} />
          <BundleCardContent>
            <BundleCardTitle>
              Add Wallets to Your Bundle <OptionalText>(Optional)</OptionalText>
            </BundleCardTitle>
            <BundleCardDescription>Bundle multiple wallets to see everything in one place.</BundleCardDescription>
          </BundleCardContent>
          <BundleCardInitialContentRightSide>
            {!!wallets?.filter(Boolean)?.length && !isWalletsOpen && (
              <NumberOfAddedWallets>{wallets?.filter(Boolean)?.length}</NumberOfAddedWallets>
            )}
            <ExpandCollapseBundleCard $isOpen={isWalletsOpen} color="#4235e1" width={16} height={16} />
          </BundleCardInitialContentRightSide>
        </BundleCardInitialContent>
        {isWalletsOpen && (
          <>
            <div style={{ padding: "20px 12px 40px" }}>
              <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
                {wallets?.map((wallet, index) => (
                  <WalletInputContent
                    index={index}
                    wallet={wallet}
                    key={index}
                    setWallets={setWallets}
                    content={`Wallet Address ${index + 1}/${wallets?.length}`}
                  />
                ))}
              </div>
              <div
                style={{ marginTop: "16px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >
                <LightBulbWrapper style={{ marginLeft: "0", marginTop: "0" }}>
                  <LightBulbIcon color="#35C9F8" width={12} height={12} />
                  You can edit your Bundle anytime in{" "}
                  <span style={{ fontWeight: "500", marginLeft: "4px" }}>Settings</span>
                </LightBulbWrapper>
                <RemoveWalletButton
                  style={{ color: "#4235e1", position: "inherit" }}
                  onClick={() => {
                    setWallets((curr) => [...curr, ""]);
                  }}
                >
                  Add Another Wallet
                </RemoveWalletButton>
              </div>
            </div>
          </>
        )}
      </BundleCard>
      <LightBulbWrapper>
        <LightBulbIcon color="#35C9F8" width={12} height={12} />
        Bundle wallets now or skip and set up later in{" "}
        <span style={{ fontWeight: "500", marginLeft: "4px" }}>Settings</span>
      </LightBulbWrapper>
    </StepWrapper>
  );
};

const Onboard = () => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const wallet = useOnboardWallet();
  const [bundleName, setBundleName] = useState("");
  const [wallets, setWallets] = useState<string[]>([""]);
  const [exitingModal, setExitingModal] = useState(false);
  const history = useHistory();
  useSiwe({});
  const { trackClickNextOnStep2InOnboardPage } = useMixpanel();
  const siweFunction = useSiweFunction();

  useEffect(() => {
    if (userDetails?.orgMemberships) {
      history.push(RoutePaths.feed);
    }
  }, [history, userDetails?.orgMemberships]);

  const updateSubscriptionDataAndCreateOrg = useCallback(async () => {
    if (!uuid) {
      notification.error({
        message: "Canceled... You need to sign in with wallet to create bundle, please try again",
        duration: 10,
      });
      await siweFunction();
      return;
    }
    notification.info({ message: "Creating your bundle... Wait a few seconds" });
    try {
      await apiClient.createOrg({
        uuid,
        address: account,
        orgWallets: [...wallets.filter(Boolean)],
        orgName: bundleName,
        orgId: bundleName.toLowerCase().replace(/[^\w]/gi, ""),
      });
    } catch (error: any) {
      const errorText = await new Response(error.message).text();
      let message = typeof errorText === "string" ? errorText : "Error creating bundle";
      if (message === "Error. Organization with the specified orgId already exists") {
        message = "Bundle with the specified name already exists, please use a different name";
      }
      notification.error({
        message,
        placement: "topRight",
        duration: 6,
      });
      console.error(error);
      return;
    }

    notification.success({
      message: "Bundle created successfully, you will be redirected to your feed in a few seconds",
    });
    history.push(RoutePaths.feed);
  }, [account, bundleName, history, siweFunction, uuid, wallets]);

  const handleClickNext = useCallback(() => {
    if (!account) {
      notification.error({
        message: "Canceled... You need to connect wallet to continue, please try again",
        duration: 10,
      });
      wallet?.openWalletModal();
      return;
    } else if (!bundleName) {
      notification.error({ message: "Please fill the Bundle name field to continue" });
      return;
    } else {
      trackClickNextOnStep2InOnboardPage({
        userId: account,
      });
      updateSubscriptionDataAndCreateOrg();
    }
  }, [account, bundleName, trackClickNextOnStep2InOnboardPage, updateSubscriptionDataAndCreateOrg, wallet]);

  return (
    <>
      <TrackPageView name={"Onboard"} />
      <MainContainer $isDashboard>
        <BackgroundForMobile />
        <Container>
          <PaddingContainer>
            <PageMax>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col xs={0} lg={10}>
                      <StyledGradientDiv>
                        <img src={`${process.env.PUBLIC_URL}/assets/OnboardGraphic.svg`} />
                      </StyledGradientDiv>
                    </Col>
                    <ScrollCol xs={24} lg={12}>
                      <RenderBundleCreate
                        bundleName={bundleName}
                        setBundleName={setBundleName}
                        wallets={wallets}
                        setWallets={setWallets}
                      />
                      <FooterWrapper>
                        <ButtonWrapper>
                          <BackButton onClick={() => setExitingModal(true)}>Cancel</BackButton>
                          <NextButton disabled={!bundleName} onClick={handleClickNext}>
                            Create Bundle
                          </NextButton>
                        </ButtonWrapper>
                        <Modal
                          customMinWidth="auto"
                          open={exitingModal}
                          onClose={() => setExitingModal(false)}
                          zIndex={20}
                          size="medium"
                        >
                          <ModalWrapper>
                            <ExitingModalTitle>Are you sure you&apos;d like to leave?</ExitingModalTitle>
                            <ExitingModalDescription>
                              You’ll lose your progress, but you can always start over anytime. Visit Settings or click
                              on any Bundle button.
                            </ExitingModalDescription>
                            <ModalButtonsWrapper>
                              <ExitingModalExitButton to="/">I&apos;ll show myself out</ExitingModalExitButton>
                              <ExitingModalBackButton onClick={() => setExitingModal(false)}>
                                Back to Bundle setup
                              </ExitingModalBackButton>
                            </ModalButtonsWrapper>
                          </ModalWrapper>
                        </Modal>
                      </FooterWrapper>
                    </ScrollCol>
                  </Row>
                </Col>
              </Row>
            </PageMax>
          </PaddingContainer>
        </Container>
      </MainContainer>
    </>
  );
};

export default Onboard;
