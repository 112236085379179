import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ContentLoader from "react-content-loader";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { COLORS } from "../../constants/colors";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import mediaQuery from "../../media-query";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { useIsSidebarExpanded } from "../../reducers/IsSidebarExpanded";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useHover } from "use-events";

import {
  BoardroomLogo,
  ExpandNavRailIcon,
  ExploreIcon,
  ExploreTabOutlinedIcon,
  OutlinedDevelopersIcon,
  PortfolioFilledIcon,
  PortfolioOutlinedIcon,
  SettingsAngularIcon,
  ThinPlusIcon,
  UsersFilledIcon,
  UsersOutlinedIcon,
} from "../icons";

import { SettingsNavHub } from "./SettingsNavHub";
import { isTeamView as isTeamViewUtil } from "../../utils/teamUtils";
import { CurrentUserDetailsContext } from "../../reducers/CurrentUserDetails";
import { useGetTeam } from "../../hooks/useGetTeam";
import { Pfp } from "../Pfp/Pfp";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useMixpanel } from "../../hooks";
import { RoutePaths } from "../../constants/Routes";
import { CollapsedMainNavWidth, ExpandedMainNavWidth } from "../../constants/styles";
import { DevelopersNavHub } from "./DevelopersNavHub";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";
import { Modal } from "../Modal";
import { EmailLoginModal } from "../EmailLoginModal/EmailLoginModal";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

const Wrapper = styled.div<{ $isExpanded?: boolean }>`
  position: fixed;
  left: 0;
  width: ${({ $isExpanded }) => ($isExpanded ? ExpandedMainNavWidth : CollapsedMainNavWidth)};
  background: white;
  height: 100%;
  padding-top: 0px;
  display: flex;
  flex-direction: column;
  z-index: 12;
  display: ${standAloneProtocolCname ? "none" : "inherit"};
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const PageWrapper = styled(Link)<{
  $isActive: boolean;
  $isExpanded: boolean;
  $isDisabled?: boolean;
  $isLast?: boolean;
}>`
  color: ${({ $isActive }) => ($isActive ? "#291FA7" : "#191540")};
  cursor: pointer;
  padding: 8px 24px;
  margin: 0 4px 32px;
  border-radius: 4px;
  ${({ $isActive }) => $isActive && "background: rgba(123, 120, 147, 0.05);"}
  display: flex;
  align-items: center;
  font-weight: ${({ $isExpanded }) => ($isExpanded ? "500" : "400")};
  font-size: 12px;
  line-height: 16px;
  height: 58px;
  ${({ $isLast, $isExpanded }) => $isLast && !$isExpanded && "margin-bottom: 0;"}

  :hover {
    color: #4235e1;
    background: rgba(123, 120, 147, 0.05);
  }
  :active,
  :focus {
    color: #291fa7;
    background: rgba(123, 120, 147, 0.05);
  }
  svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }
  transition: 0.3s all;

  ${({ $isExpanded, $isActive }) =>
    !$isExpanded &&
    css`
      flex-direction: column;
      justify-content: center;
      svg {
        margin-right: 0;
      }
      span {
        ${$isActive ? "" : "opacity: 0;"}
        margin-top: 2px;
        display: flex;
      }
      :hover {
        span {
          opacity: 1;
        }
      }
      :active,
      :focus {
        span {
          opacity: 1;
        }
      }
    `}
  ${({ $isDisabled }) => $isDisabled && "cursor: not-allowed; opacity: 0.2; pointer-events: none;"}
`;

const ExpandWrapper = styled.div<{ $isExpanded?: boolean }>`
  width: 100%;
  display: flex;
  justify-content: end;
  padding: 12px 0px;
  margin-top: ${({ $isExpanded }) => ($isExpanded ? "auto" : $isExpanded ? "28px" : "8px")};
  border-top: 1px solid #f0eff8;
  ${({ $isExpanded }) => $isExpanded && "padding-left: 30px; justify-content: space-between;"}
  svg {
    cursor: pointer;
    ${({ $isExpanded }) => ($isExpanded ? "margin-right: 30px;" : "margin: 0 auto;")}
    ${({ $isExpanded }) => $isExpanded && "transform: rotate(180deg);"}
  }
`;

const NavRailWrapper = styled.div`
  display: flex;
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const CreateButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: white;
  border: 1px solid #191540;
  border-radius: 8px;
  color: #191540;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  transition: 0.3s all;
  :hover {
    background: #4235e1;
    color: white;
  }
  :active,
  :focus {
    background: #291fa7;
    color: white;
  }
`;

const CreateButtonWrapper = styled.div`
  padding-top: 24px;
  border-top: 1px solid #f0eff8;
  margin: 0 12px;
  width: calc(100% - 24px);
`;

const MiniCreateButton = styled(Link)<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 56px;
  background: white;
  border-radius: 8px;
  color: #191540;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  transition: 0.3s all;
  ${({ $isActive }) => $isActive && "color: #4235e1; background: rgba(123, 120, 147, 0.05);"}
  :hover {
    color: #4235e1;
    background: rgba(123, 120, 147, 0.05);
  }
  :active,
  :focus {
    color: #291fa7;
    background: rgba(123, 120, 147, 0.05);
  }
`;

const ActivePlusIconWrapper = styled.div`
  background: #191540;
  border-radius: 4px;
  width: 24px;
  height: 24px;
`;

const TeamTabbing = styled.div<{ $isExpanded: boolean }>`
  display: flex;
  width: 100%;
  height: 64px;
  margin-bottom: 32px;
  ${({ $isExpanded }) =>
    !$isExpanded &&
    "justify-content: center; align-items: start; flex-direction: column; height: auto; margin-top: 16px; gap: 24px; padding-bottom: 20px; border-bottom: 1px solid #f0eff8;"}
`;

const ViewSelector = styled.div<{ $isActive: boolean; $isExpanded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${({ $isExpanded, $isActive }) =>
    $isExpanded && $isActive && "border-bottom: 4px solid #4235e1; width: 136px; padding: 0 16px;"}
  ${({ $isExpanded, $isActive }) => $isExpanded && !$isActive && "width: 48px;"}
  ${({ $isExpanded }) =>
    !$isExpanded &&
    `
    flex-direction: column;
    width: 100%;
    background: inherit !important;
    .viewName {
      display: block !important;
      text-align: center;
    }
    `}
    border-left: 4px solid transparent;
  ${({ $isExpanded, $isActive }) => !$isExpanded && $isActive && "border-left: 4px solid #4235e1;"}
  .viewName {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: #291fa7;
    max-width: 4rem;
    ${({ $isExpanded }) => ($isExpanded ? "margin-left: 6px;" : "margin-left: -4px;")}
  }
  .ant-avatar,
  .blockie {
    ${({ $isExpanded }) => ($isExpanded ? "margin-left: 6px;" : "margin-left: -4px;")}
  }
  ${({ $isActive }) =>
    !$isActive &&
    css`
      background: #f6f6f6;
      filter: grayscale(1);
      opacity: 0.4;
      transition: 0.3s all;
      :hover {
        filter: grayscale(0);
        opacity: 0.75;
      }
      .viewName {
        display: none;
      }
    `}
`;

const StyledLink = styled(Link)<{ $isDisabled?: boolean }>`
  ${({ $isDisabled }) => $isDisabled && "cursor: not-allowed; opacity: 0.2; pointer-events: none;"}
`;

const SidebarBorderRight = styled.div<{ $isExpanded?: boolean }>`
  position: fixed;
  top: 0;
  left: ${({ $isExpanded }) => ($isExpanded ? ExpandedMainNavWidth : CollapsedMainNavWidth)};
  z-index: 18;
  width: ${({ $isExpanded }) => ($isExpanded ? "2px" : "1px")};
  height: 100%;
  background: linear-gradient(
    89.89deg,
    ${COLORS.primary.accent} 0.96%,
    ${COLORS.secondary.blue} 48.89%,
    ${COLORS.secondary.purple} 99.91%
  );
`;

const StyledLogoWrapper = styled.div`
  z-index: 10;
  cursor: pointer;
  ${mediaQuery.lessThan("991px")`
    display: none;
  `}
`;

const StyledLogoLink = styled(Link)`
  &:hover {
    color: ${COLORS.primary.accent};
  }
`;

const StyledBoardroomLogo = styled(BoardroomLogo)`
  width: 32px;
  height: 32px;
  transition: transform ease-in-out 0.3s;
  margin: 24px 0px 24px 24px;
  &:hover {
    transform: scale(1.1);
  }
`;

export const MainSidebarNav = () => {
  const { width } = useWindowDimensions();
  const { pathname, search } = useLocation();
  const { isSidebarExpanded, setIsSidebarExpanded } = useIsSidebarExpanded();
  const [isEmailLoginModalOpen, setIsEmailLoginModalOpen] = useState(false);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const isTeamView = isTeamViewUtil(pathname, search);
  const { userDetails } = useContext(CurrentUserDetailsContext);
  const { account } = useContext(CurrentAccountContext);
  const orgMemberships = userDetails?.orgMemberships;
  const teamDetails = useGetTeam(orgMemberships || "");
  const { trackSwitchToTeamView } = useMixpanel();
  const history = useHistory();
  const [isHovered, bind] = useHover();
  const isEmailSignIn = useIsEmailSignIn();

  useEffect(() => {
    setCurrentRefId("");
  }, [isTeamView, setCurrentRefId]);

  const toggleExpand = useCallback(() => {
    setIsSidebarExpanded(!isSidebarExpanded);
  }, [isSidebarExpanded, setIsSidebarExpanded]);

  const isSettingsPage = pathname.startsWith("/settings");
  const isDevelopersPage = [RoutePaths.billing, RoutePaths.webhooks, RoutePaths.api, RoutePaths.usage].includes(
    pathname,
  );

  const isMainPath = useMemo(() => {
    const mainPaths = [
      RoutePaths.feed,
      RoutePaths.discuss,
      RoutePaths.dailyBriefs,
      RoutePaths.projects,
      RoutePaths.create,
      RoutePaths.proxyFeed,
      RoutePaths.proxyDiscuss,
      RoutePaths.proxyBriefs,
    ];
    return mainPaths.includes(pathname);
  }, [pathname]);

  useEffect(() => {
    if ((isMainPath && width >= 1200) || width >= 2100) {
      setIsSidebarExpanded(true);
    } else {
      setIsSidebarExpanded(false);
    }
  }, [isMainPath, setIsSidebarExpanded, width]);

  const pathnameSplitted = pathname.split("/");

  const isProjectPage =
    pathnameSplitted[2] === "proposals" ||
    pathnameSplitted[2] === "proposal" ||
    pathnameSplitted[2] === "voters" ||
    pathnameSplitted[2] === "overview" ||
    pathnameSplitted[2] === "reports" ||
    pathnameSplitted[2] === "meetings" ||
    (pathnameSplitted[2] === "calendar" && pathnameSplitted[1] !== "feed") ||
    pathnameSplitted[2] === "delegates" ||
    pathnameSplitted[2] === "whitelist" ||
    pathnameSplitted[2] === "discussions" ||
    pathnameSplitted[2] === "contributors" ||
    pathnameSplitted[2] === "insights";

  const onOpenTeamProfile = useCallback(() => {
    if (!userDetails?.orgMemberships) {
      history.push("/onboard");
      return;
    }

    history.push({
      pathname: `/bundle/${userDetails?.orgMemberships}`,
      search: search || `bundle=${userDetails?.orgMemberships}`,
    });
    trackSwitchToTeamView({
      userId: account,
    });
  }, [account, history, search, trackSwitchToTeamView, userDetails?.orgMemberships]);

  const OnSwitchToConnectedWalletViewClick = useCallback(() => {
    if (!isTeamView) {
      return;
    }
    history.push("/");
  }, [history, isTeamView]);

  const isPortfolioActive = useMemo(
    () => pathname === RoutePaths.discuss || pathname === RoutePaths.feed || pathname === RoutePaths.dailyBriefs,
    [pathname],
  );

  const onOpen = useCallback(() => {
    setIsEmailLoginModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsEmailLoginModalOpen(false);
  }, []);

  return (
    <NavRailWrapper>
      <Modal customMinHeight="auto" zIndex={20} size="large" open={isEmailLoginModalOpen} onClose={onClose}>
        <EmailLoginModal setIsEmailLoginModalOpen={setIsEmailLoginModalOpen} />
      </Modal>
      <Wrapper style={{ zIndex: "12" }} $isExpanded={isSidebarExpanded && !currentRefId}>
        <StyledLogoLink to={RoutePaths.feed}>
          <StyledLogoWrapper>
            {!standAloneProtocolCname && <StyledBoardroomLogo color={COLORS.primary.accent} />}
          </StyledLogoWrapper>
        </StyledLogoLink>
        {account ? (
          <TeamTabbing $isExpanded={isSidebarExpanded && !currentRefId} {...bind}>
            <ViewSelector
              onClick={OnSwitchToConnectedWalletViewClick}
              $isActive={!isTeamView}
              $isExpanded={isSidebarExpanded && !currentRefId}
            >
              <Pfp noShrink address={account} size="small" pfpUrl={userDetails?.pfpUrl} />
              <span
                className="viewName"
                style={{ marginTop: width >= 991 && isSidebarExpanded && !currentRefId ? "0" : "6px" }}
              >
                {width >= 991 && isSidebarExpanded && !currentRefId ? "Connected " : ""}Wallet
              </span>
            </ViewSelector>
            <ViewSelector
              onClick={onOpenTeamProfile}
              $isActive={!!isTeamView}
              $isExpanded={isSidebarExpanded && !currentRefId}
            >
              {isHovered && !userDetails?.orgMemberships ? (
                <PlusCircleOutlined
                  style={{
                    fontSize: "30px",
                    color: COLORS.primary.accent,
                    marginLeft: isSidebarExpanded && !currentRefId ? "6px" : "-4px",
                  }}
                />
              ) : (
                <Pfp noShrink address={account} size="small" pfpUrl={teamDetails?.pfpUrl} />
              )}
              <span
                className="viewName"
                style={{ marginTop: width >= 991 && isSidebarExpanded && !currentRefId ? "0" : "6px" }}
              >
                Bundled View
              </span>
            </ViewSelector>
          </TeamTabbing>
        ) : null}
        <PageWrapper
          $isExpanded={isSidebarExpanded && !currentRefId}
          to={RoutePaths.projects}
          $isActive={pathname === RoutePaths.projects || isProjectPage}
        >
          {pathname === RoutePaths.projects || isProjectPage ? <ExploreIcon /> : <ExploreTabOutlinedIcon />}
          <span>
            <span>Projects</span>
          </span>
        </PageWrapper>
        <PageWrapper
          $isExpanded={isSidebarExpanded && !currentRefId}
          to={{
            search,
            pathname: isPortfolioActive ? pathname : RoutePaths.feed,
          }}
          $isActive={isPortfolioActive}
        >
          {isPortfolioActive ? <PortfolioFilledIcon /> : <PortfolioOutlinedIcon />}
          <span>Feed</span>
        </PageWrapper>
        <PageWrapper
          $isExpanded={isSidebarExpanded && !currentRefId}
          to={{
            search,
            pathname: RoutePaths.proxyFeed,
          }}
          $isActive={pathname.startsWith("/proxy")}
        >
          {pathname.startsWith("/proxy") ? <UsersFilledIcon /> : <UsersOutlinedIcon />}
          <span>Proxy</span>
        </PageWrapper>
        {!account && !isEmailSignIn ? (
          isSidebarExpanded && !currentRefId ? (
            <CreateButtonWrapper>
              <CreateButton
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
                as="button"
                onClick={onOpen}
              >
                Sign In
              </CreateButton>
            </CreateButtonWrapper>
          ) : (
            <CreateButtonWrapper style={{ paddingTop: "16px", paddingBottom: "16px" }}>
              <MiniCreateButton
                style={{
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={onOpen}
                as="button"
              >
                <ThinPlusIcon width={24} height={24} />
                <span>Sign In</span>
              </MiniCreateButton>
            </CreateButtonWrapper>
          )
        ) : null}
        {isSidebarExpanded && !currentRefId ? (
          <PageWrapper
            $isExpanded={isSidebarExpanded && !currentRefId}
            to={RoutePaths.billing}
            $isActive={isDevelopersPage}
            style={{ marginTop: "16px" }}
          >
            {isDevelopersPage ? <OutlinedDevelopersIcon /> : <OutlinedDevelopersIcon />}
            <span>
              <span>Developers</span>
            </span>
          </PageWrapper>
        ) : (
          <CreateButtonWrapper style={{ paddingTop: "24px" }}>
            <MiniCreateButton
              $isActive={isDevelopersPage}
              to={{
                search,
                pathname: RoutePaths.billing,
              }}
            >
              {isDevelopersPage ? (
                <ActivePlusIconWrapper style={{ background: COLORS.primary.accentDark }}>
                  <OutlinedDevelopersIcon width={24} height={24} color="white" />
                </ActivePlusIconWrapper>
              ) : (
                <OutlinedDevelopersIcon width={24} height={24} />
              )}
              <span>Developers</span>
            </MiniCreateButton>
          </CreateButtonWrapper>
        )}
        <div style={{ marginTop: "auto" }}>
          {(!isSidebarExpanded || currentRefId) && (
            <div style={{ cursor: isTeamView ? "not-allowed" : "default" }}>
              <PageWrapper
                style={{
                  marginBottom: 0,
                  marginTop: "16px",
                }}
                $isExpanded={false}
                $isActive={pathname.startsWith("/settings")}
                to="/settings/profile"
                $isDisabled={!!isTeamView}
              >
                <SettingsAngularIcon />
                <span>Settings</span>
              </PageWrapper>
            </div>
          )}
          {((isMainPath && width >= 1200) || width >= 1750) && (
            <ExpandWrapper $isExpanded={isSidebarExpanded && !currentRefId}>
              {isSidebarExpanded && !currentRefId && (
                <div style={{ cursor: isTeamView ? "not-allowed" : "default" }}>
                  <StyledLink $isDisabled={!!isTeamView} to="/settings/profile">
                    <SettingsAngularIcon color="#191540" width={24} height={24} />
                  </StyledLink>
                </div>
              )}
              <ExpandNavRailIcon width={24} height={24} color="#7B7893" onClick={toggleExpand} />
            </ExpandWrapper>
          )}
        </div>
      </Wrapper>
      <SidebarBorderRight $isExpanded={isSidebarExpanded && !currentRefId} />

      {isSettingsPage && (
        <Suspense
          fallback={
            <>
              <ContentLoader
                speed={2}
                width="216px"
                height={"100vh"}
                backgroundColor={COLORS.primary.grayLight}
                foregroundColor={COLORS.primary.grayLighter}
                style={{
                  marginLeft: isSidebarExpanded
                    ? ExpandedMainNavWidth
                    : standAloneProtocolCname
                    ? "0px"
                    : CollapsedMainNavWidth,
                  position: "absolute",
                }}
              >
                <rect y="30" width={"60%"} height="12" />
                <rect y="60" width={"60%"} height="12" />
                <rect y="90" width={"60%"} height="12" />
                <rect y="120" width={"60%"} height="12" />
                <rect y="150" width={"60%"} height="12" />
              </ContentLoader>
            </>
          }
        >
          <SettingsNavHub />
        </Suspense>
      )}
      {isDevelopersPage && <DevelopersNavHub />}
    </NavRailWrapper>
  );
};
