import React, { Suspense, useContext } from "react";
import ContentLoader from "react-content-loader";
import styled from "styled-components";

import { COLORS } from "../../constants/colors";
import { useHasDelegation } from "../../hooks/useHasDelegation";
import useScore from "../../hooks/useScore";
import formatValue from "../../utils/formatValue";
import { VoterPowerIcon, WalletIcon } from "../icons";
import InfoCard from "../InfoCard";
import media from "../../media-query";
import SetupDelegation from "../Voting/SetupDelegation";
import { useVotePowerAdapter } from "../../hooks/useVotePowerAdapter";
import { useVotePower } from "../../hooks/useVotePower";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { VotingPowerCardIsLoadingContext } from "../../reducers/VotingPowerCard";
import DelegationInfo from "./DelegationInfo";

interface Props {
  address?: string;
  blockNumber?: number;
  protocolCname: string;
  teamWallets?: string[];
}

const StyledVoterCardWrapper = styled.div`
  width: 100%;
  background: white;
  ${media.lessThan("640px")`
  margin-top: 16px;
  `};
`;

const StyledVoteInfoWrapper = styled.div`
  height: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledVotePower = styled.span<{ fontWeight?: number }>`
  color: ${COLORS.primary.accent};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 600)};
  font-size: 24px;
  line-height: 30px;
  padding-top: 1px;
  padding-left: 6px;
`;

const StyledDelegationWrapper = styled.div`
  width: 100%;
  background: white;
  color: ${COLORS.primary.accent};
  height: 48px;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
`;

const StyledConnectWalletWrapper = styled.div`
  width: 100%;
  background: white;
  color: ${COLORS.primary.accent};
  height: 48px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 48px;
  border-top: 1px solid ${COLORS.primary.grayLighter};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 12px;
  height: 84px;
  align-items: center;
  padding: 18px 0;
`;

const StyledText = styled.div`
  margin-left: 26px;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${COLORS.primary.grayDarkLightest};
`;

const ErrorTxt = styled.p`
  display: block;
  text-align: center;
  margin-top: 24px;
`;

const VotPowerDetailsForMaker = ({ address, protocolCname }: Props) => {
  const { score: pollingVp } = useScore(protocolCname, address, undefined, "onchain-secondary");
  const { score: executiveVp } = useScore(protocolCname, address, undefined, "onchain");

  const isM0 = protocolCname === "m0";
  const pollingText = isM0 ? "Zero Token" : "Polling";
  const executiveText = isM0 ? "Power Token" : "Executive";
  const pollingParsedVp = isM0 ? (Number(pollingVp) || 0) / 10 ** 6 : pollingVp;
  return (
    <Flex>
      <div style={{ alignItems: "center" }}>
        <StyledVoteInfoWrapper>
          <VoterPowerIcon height="18px" width="20px" color={COLORS.primary.accent} />
          <StyledVotePower>{formatValue(pollingParsedVp) || 0.0}</StyledVotePower>
        </StyledVoteInfoWrapper>
        <StyledText>{pollingText}</StyledText>
      </div>
      <StyledVoteInfoWrapper style={{ marginBottom: "auto" }}>
        <StyledVotePower fontWeight={100}>/</StyledVotePower>
      </StyledVoteInfoWrapper>
      <div style={{ alignItems: "center" }}>
        <StyledVoteInfoWrapper>
          <VoterPowerIcon height="18px" width="20px" color={COLORS.primary.accent} />
          <StyledVotePower>{formatValue(executiveVp) || 0.0}</StyledVotePower>
        </StyledVoteInfoWrapper>
        <StyledText>{executiveText}</StyledText>
      </div>
    </Flex>
  );
};

const VotePowerCardLoader = () => {
  return (
    <StyledVoterCardWrapper>
      <InfoCard title="VOTE POWER" $noPaddingBody={true}>
        <ContentLoader
          speed={2}
          width="100%"
          height={80}
          backgroundColor={COLORS.primary.grayLight}
          foregroundColor={COLORS.primary.grayLighter}
        >
          <rect x="39%" y="40" rx="5" ry="5" width="20" height="25" />
          <rect x="47%" y="40" rx="5" ry="5" width="20" height="25" />
          <rect x="55%" y="40" rx="5" ry="5" width="20" height="25" />
        </ContentLoader>
      </InfoCard>
    </StyledVoterCardWrapper>
  );
};

const VotingPowerCard = ({ address, blockNumber, protocolCname, teamWallets }: Props) => {
  const votePowerAdapter = useVotePowerAdapter(protocolCname);
  const { account } = useContext(CurrentAccountContext);
  const wallet = useOnboardWallet();
  const {
    power,
    isError: votePowerIsError,
    isLoading: votePowerIsLoading,
  } = useVotePower(protocolCname, false, teamWallets ? teamWallets : address);
  const votePower = power === "-" ? null : formatValue(power, 1, true);
  const {
    score,
    isError: scoreIsError,
    isLoading: scoreIsLoading,
  } = useScore(protocolCname, teamWallets ? teamWallets : address, blockNumber, votePowerAdapter);

  const hasDelegation = useHasDelegation(protocolCname);
  if (scoreIsError && votePowerIsError) {
    return (
      <StyledVoterCardWrapper>
        <InfoCard title="VOTE POWER" $noPaddingBody={true}>
          <ErrorTxt>Something went wrong</ErrorTxt>
        </InfoCard>
      </StyledVoterCardWrapper>
    );
  }

  if (votePowerIsLoading || scoreIsLoading) {
    return <VotePowerCardLoader />;
  }

  let vp: any = 0;

  if (votePower !== null) {
    vp = votePower;
  } else if (score) {
    vp = formatValue(score, 1, true);
  } else {
    vp = "N/A";
  }

  return (
    <StyledVoterCardWrapper>
      <InfoCard title="VOTE POWER" $noPaddingBody={true}>
        {protocolCname === "makerdao" || protocolCname === "m0" ? (
          <VotPowerDetailsForMaker
            address={address}
            blockNumber={blockNumber}
            protocolCname={protocolCname}
            teamWallets={teamWallets}
          />
        ) : (
          <Flex>
            <StyledVoteInfoWrapper>
              <VoterPowerIcon height="18px" width="20px" color={COLORS.primary.accent} />
              <StyledVotePower>{vp}</StyledVotePower>
            </StyledVoteInfoWrapper>
          </Flex>
        )}
        {hasDelegation && protocolCname && !teamWallets && !!account && (
          <>
            <DelegationInfo protocolCname={protocolCname} address={account} />
            <StyledDelegationWrapper>
              <SetupDelegation
                protocolName={protocolCname}
                address={address}
                triggerText="Manage Delegation"
                showDelegationsLink
              />
            </StyledDelegationWrapper>
          </>
        )}
        {!account && (
          <StyledConnectWalletWrapper onClick={wallet?.openWalletModal}>
            <WalletIcon height={20} width={20} /> <span>Connect Wallet</span>
          </StyledConnectWalletWrapper>
        )}
      </InfoCard>
    </StyledVoterCardWrapper>
  );
};

const VotingPowerCardSuspense = (props: Props) => {
  const { votingPowerCardIsLoading } = useContext(VotingPowerCardIsLoadingContext);

  if (votingPowerCardIsLoading) {
    return <VotePowerCardLoader />;
  }

  return (
    <Suspense
      fallback={
        <>
          <StyledVoterCardWrapper>
            <InfoCard title="VOTE POWER" $noPaddingBody={true}>
              <ContentLoader
                speed={2}
                width="100%"
                height={80}
                backgroundColor={COLORS.primary.grayLight}
                foregroundColor={COLORS.primary.grayLighter}
              >
                <rect x="39%" y="40" rx="5" ry="5" width="20" height="25" />
                <rect x="47%" y="40" rx="5" ry="5" width="20" height="25" />
                <rect x="55%" y="40" rx="5" ry="5" width="20" height="25" />
              </ContentLoader>
            </InfoCard>
          </StyledVoterCardWrapper>
        </>
      }
    >
      <VotingPowerCard {...props} />
    </Suspense>
  );
};

export default VotingPowerCardSuspense;
