import { useQuery } from "@tanstack/react-query";
import { Contract } from "@ethersproject/contracts";
import { JsonRpcProvider } from "@ethersproject/providers";
import lock from "@unlock-protocol/contracts/dist/abis/PublicLock/PublicLockV13.json";
import { useContext } from "react";
import { NftSubscriptionContext } from "../reducers/NftSubscription";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { useUser } from "@clerk/clerk-react";
const isProduction = false;

export const monthlySubscriptionLockAddress = isProduction
  ? "0x1756c6d0d80cbd4b82e85adae1468d0a60de81ee"
  : "0x87da72dc59674a17ad2154a25699246c51e25a57";
export const yearlySubscriptionLockAddress = isProduction
  ? "0x91c58cea6aad23b9d8d8b6772d7c158196b13b58"
  : "0xa9cbac78d812e9ca637dfa6d2c158b161d613f16";

export const networkId = isProduction ? 10 : 11155111;
const rpcUrl = isProduction ? process.env.REACT_APP_RPC_URL_10 : process.env.REACT_APP_RPC_URL_11155111;

export const checkNftKeysForSubscription = async (address: string): Promise<boolean> => {
  try {
    const monthlyContract = new Contract(monthlySubscriptionLockAddress, lock.abi, new JsonRpcProvider(rpcUrl));

    const yearlyContract = new Contract(yearlySubscriptionLockAddress, lock.abi, new JsonRpcProvider(rpcUrl));

    const hasValidKeyMonthlyPromise = monthlyContract.getHasValidKey(address);
    const hasValidKeyYearlyPromise = yearlyContract.getHasValidKey(address);
    const isValidKeyMonthly = await hasValidKeyMonthlyPromise;
    const isValidKeyYearly = await hasValidKeyYearlyPromise;
    const hasValidKey = isValidKeyMonthly || isValidKeyYearly;

    return hasValidKey;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const checkIfUserHasValidSubscription = async (address: string) => {
  try {
    const response = await fetch(
      `${baseAPIUrl}getUserSubscriptionDetails/isValidSubscription/${address}?key=${BOARDROOM_API_KEY}`,
    );
    const { data } = await response.json();
    return data.isValid;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const useHasValidSubscription = (address: string) => {
  const { dispatchNftSubscriptionIsValid } = useContext(NftSubscriptionContext);
  const clerkUser = useUser();
  let email = "";
  if (clerkUser && clerkUser.user && clerkUser.user.primaryEmailAddress) {
    email = clerkUser.user.primaryEmailAddress.emailAddress;
  }
  const { data } = useQuery<boolean, Error>(
    [`useHasValidSubscription:${address}${email}`],
    async () => {
      const identifier = address || email;
      try {
        const response = await fetch(
          `${baseAPIUrl}getUserSubscriptionDetails/isValidSubscription/${identifier}?key=${BOARDROOM_API_KEY}`,
        );
        const { data } = await response.json();
        dispatchNftSubscriptionIsValid({
          type: "NFT_SUBSCRIPTION_IS_VALID",
          data: data.isValid,
        });
        return data.isValid;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    {
      enabled: !!address || !!email,
      suspense: false,
    },
  );

  return data;
};
