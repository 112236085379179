import { useSdkWithoutSigner } from "./useSdkWithoutSigner";

export const checkAdapterFrameworkToUse = (adapterFramework: string[] | undefined) => {
  const adapterFrameworkToBeUsed = adapterFramework?.includes("onchain-upgrade")
    ? "onchain-upgrade"
    : adapterFramework?.includes("onchain")
    ? "onchain"
    : adapterFramework?.includes("onchain-secondary")
    ? "onchain-secondary"
    : adapterFramework?.includes("onchain-tertiary")
    ? "onchain-tertiary"
    : adapterFramework?.includes("onchain-optimism")
    ? "onchain-optimism"
    : adapterFramework?.includes("onchain-arbitrum")
    ? "onchain-arbitrum"
    : adapterFramework?.includes("snapshot")
    ? "snapshot"
    : "default";
  return adapterFrameworkToBeUsed;
};

export const useVotePowerAdapter = (protocolCname: string) => {
  const sdk = useSdkWithoutSigner();
  try {
    const protocolInSdk = sdk?.getProtocol(protocolCname);
    const adapterFramework = protocolInSdk?.adapterInstances("votePower");

    const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
    return adapterFrameworkToBeUsed;
  } catch (error) {
    console.error(error);
    return "default";
  }
};

export const useDelegationAdapter = (protocolCname: string) => {
  const sdk = useSdkWithoutSigner();
  try {
    const protocolInSdk = sdk?.getProtocol(protocolCname);
    const adapterFramework = protocolInSdk?.adapterInstances("delegation");

    const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
    return adapterFrameworkToBeUsed;
  } catch (error) {
    console.error(error);
    return "default";
  }
};

export const useProposalAdapter = (protocolCname: string) => {
  const sdk = useSdkWithoutSigner();
  try {
    const protocolInSdk = sdk?.getProtocol(protocolCname);
    const adapterFramework = protocolInSdk?.adapterInstances("proposals");

    const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
    return adapterFrameworkToBeUsed;
  } catch (error) {
    console.error(error);
    return "default";
  }
};
