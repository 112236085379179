import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import notification from "antd/es/notification";
import styled, { css } from "styled-components";
import { useQueryClient } from "@tanstack/react-query";

import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { CurrentUuidContext } from "../../reducers/CurrentUuid";
import { BOARDROOM_API_KEY, baseAPIUrl } from "../../constants/general";
import { COLORS } from "../../constants/colors";
import { Input } from "../Input";
import { Dropdown, MultiDropdown } from "../Dropdown";
import {
  CreateCard,
  CreateCardHeader,
  CreateCardHeaderItem,
  CreateWebhookText,
  DrawerHeader,
} from "../../pages/Webhooks";
import { Loader } from "../Loader";
import { Popover } from "../Popover";
import { PopoverItem, ThreeDotMenuWrapper } from "../Webhooks/ExistingWebhookRow";
import { EditIcon, ThinCrossIcon, ThreeDotMenuIcon, TrashIcon } from "../icons";
import { KeywordSearch, useGetKeywordSearchesByAddress } from "../../hooks/useGetAlerts";
import { protocols } from "../../constants/protocols";
import { useSiwe } from "../../hooks/useSiwe";
import { useSiweFunction } from "../../hooks/useSiweFunction";
import ProtocolIcon from "../ProtocolIcon";
import { Item } from "../Dropdown/types";
import { Text } from "../Typography";
import { RoutePaths } from "../../constants/Routes";

const CreateCardRow = styled.div`
  display: flex;
  align-items: center;
`;

const ErrorMessage = styled.div`
  color: ${COLORS.secondary.red};
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  margin-top: -16px;
`;

const StyledInput = styled(Input)`
  border: none;
  box-shadow: none;
  padding: 0;
  height: 36px;
  :hover,
  :focus {
    border: none;
    box-shadow: none;
  }
`;

const AddButton = styled.button<{
  $hasError?: boolean;
  $isSaved?: boolean;
}>`
  height: 40px;
  border-radius: 4px;
  background: ${COLORS.primary.accent};
  border: none;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  width: 100px;
  transition: 0.3s all;
  margin-top: -10px;
  :hover {
    background: ${COLORS.primary.accentDark};
  }
  :disabled {
    background: #f6f6f6;
    color: ${COLORS.primary.grayDarkLightest};
    cursor: not-allowed;
  }
  ${({ $isSaved }) =>
    $isSaved &&
    css`
      color: #fff;
      background: ${COLORS.primary.grayDarkLightest};
      border: 1px solid ${COLORS.primary.grayDarkLightest};
      cursor: not-allowed;
      :hover {
        color: #fff;
        background: ${COLORS.primary.grayDarkLightest};
      }
      :disabled {
        color: #fff;
        background: ${COLORS.primary.grayDarkLightest};
      }
    `}
  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${COLORS.secondary.red};
      background: #fff;
      color: ${COLORS.secondary.red};
      cursor: pointer;
      :disabled {
        background: #fff;
        color: ${COLORS.secondary.red};
        cursor: pointer;
      }
      :hover {
        background: #fff;
        color: ${COLORS.secondary.red};
      }
    `}
`;

const AddAnotherWebhookButton = styled.button`
  background: transparent;
  border: none;
  color: ${COLORS.primary.accent};
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
  border-top: 1px solid ${COLORS.primary.grayLighter};
  :hover {
    color: ${COLORS.primary.accentDark};
  }
`;

const protocolItems = [
  {
    name: "All Protocols",
    value: "all-protocols",
  },
  {
    value: "my-protocols",
    name: "My Protocols",
  },
];

export const CreateAlertRow = ({
  exists,
  alert,
  protocolsToAdd,
}: {
  exists: boolean;
  alert?: KeywordSearch[];
  protocolsToAdd: string[];
}) => {
  const { account } = useContext(CurrentAccountContext);
  const { uuid } = useContext(CurrentUuidContext);
  const queryClient = useQueryClient();
  useSiwe({});
  const siweFunction = useSiweFunction();

  const [protocol, setProtocol] = useState("all-protocols");
  const [keyword, setKeyword] = useState(exists ? (alert ? alert[0]?.keyword : "") : "");
  const [keywordError, setKeywordError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const allProtocols = useMemo(
    () =>
      Object.values(protocols)
        .filter((protocol) => !!protocol.discourseForum?.url)
        .map((protocol) => protocol.cname),
    [],
  );
  const [unselectedProtocols, setUnselectedProtocols] = useState<string[]>([]);

  const validateKeyword = useCallback(() => {
    if (keyword.length <= 2) {
      setKeywordError(true);
      return false;
    }
    setKeywordError(false);
    return true;
  }, [keyword]);

  useEffect(() => {
    setKeyword(exists ? (alert ? alert[0]?.keyword : "") : "");
  }, [alert, exists]);

  const submit = useCallback(async () => {
    if (!account || !uuid) {
      notification.error({
        message: "Error",
        description: "You need to sign in with wallet to update your alerts",
      });
      siweFunction();
      return;
    }
    const isValid = validateKeyword();
    if (!isValid) return;
    setIsLoading(true);
    try {
      const protocolToUse = protocol === "all-protocols" ? allProtocols : protocolsToAdd;
      const data = {
        address: account,
        protocols: protocolToUse,
        keyword,
        uuid,
      };
      if (!data?.protocols?.length) {
        notification.error({
          message: "Error",
          description: (
            <Text>
              Please select at least one protocol on your{" "}
              <a href={`https://boardroom.io${RoutePaths.settingsFeed}`}>Feed Preferences</a> to add alert with the My
              Projects filter selected
            </Text>
          ),
        });
        setIsLoading(false);
        return;
      }
      if (exists && alert) {
        await axios.post(`${baseAPIUrl}discourse/updateDiscourseKeywordToSearch?key=${BOARDROOM_API_KEY}`, {
          ...data,
          ids: alert
            ?.filter((singleAlert) => !unselectedProtocols.includes(singleAlert.protocol))
            .map((singleAlert) => singleAlert?.id),
        });
        const unselectedIds = alert
          ?.filter((singleAlert) => unselectedProtocols.includes(singleAlert.protocol))
          .map((singleAlert) => singleAlert?.id);
        if (unselectedIds?.length) {
          await axios.post(`${baseAPIUrl}discourse/deleteDiscourseKeywordToSearch?key=${BOARDROOM_API_KEY}`, {
            ids: unselectedIds,
            address: account,
            uuid,
          });
        }
        queryClient.invalidateQueries(["getAlertsByAddress", account]);
        notification.success({
          message: "Success",
          description: "Alert updated successfully",
        });
      } else {
        await axios.post(`${baseAPIUrl}discourse/createDiscourseKeywordToSearch?key=${BOARDROOM_API_KEY}`, data);
        queryClient.invalidateQueries(["getAlertsByAddress", account]);
        notification.success({
          message: "Success",
          description: "Alert created successfully",
        });
      }
      setIsSaved(true);
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error updating alert",
      });
    }
    setIsLoading(false);
  }, [
    account,
    alert,
    allProtocols,
    exists,
    keyword,
    protocol,
    protocolsToAdd,
    queryClient,
    siweFunction,
    unselectedProtocols,
    uuid,
    validateKeyword,
  ]);

  const deleteAlert = useCallback(async () => {
    if (!account || !uuid) {
      notification.error({
        message: "Error",
        description: "You need to sign in with wallet to update your alerts",
      });
      siweFunction();
      return;
    }
    setIsLoading(true);
    try {
      await axios.post(`${baseAPIUrl}discourse/deleteDiscourseKeywordToSearch?key=${BOARDROOM_API_KEY}`, {
        ids: alert?.map((singleAlert) => singleAlert?.id),
        address: account,
        uuid,
      });
      queryClient.invalidateQueries(["getAlertsByAddress", account]);
      notification.success({
        message: "Success",
        description: "Alert deleted successfully",
      });
    } catch (e) {
      notification.error({
        message: "Error",
        description: "Error deleting alert",
      });
    }
    setIsLoading(false);
  }, [account, alert, queryClient, siweFunction, uuid]);

  const edit = useCallback(() => {
    setIsEditing(true);
  }, []);

  const alertProtocolItems = useMemo(
    () =>
      alert?.map((singleAlert) => ({
        name: (
          <div>
            <ProtocolIcon protocol={protocols[singleAlert.protocol]} size="xsmall" />
            <span style={{ marginLeft: "8px" }}>{protocols[singleAlert.protocol]?.name}</span>
          </div>
        ),
        value: singleAlert.protocol,
      })) || [],
    [alert],
  );

  const selectedProtocols = useMemo(() => {
    return alertProtocolItems.map((item) => item.value).filter((protocol) => !unselectedProtocols.includes(protocol));
  }, [alertProtocolItems, unselectedProtocols]);

  const onAddProtocol = useCallback(
    (item: Item | null) => {
      setUnselectedProtocols((prev) => prev.filter((protocol) => protocol !== item?.value));
    },
    [setUnselectedProtocols],
  );

  const onRemoveProtocol = useCallback(
    (item: Item | null) => {
      setUnselectedProtocols((prev) => [...prev, item?.value || ""]);
    },
    [setUnselectedProtocols],
  );

  const onPopoverVisibleChange = useCallback(
    (visible: boolean) => {
      setIsPopoverVisible(visible);
    },
    [setIsPopoverVisible],
  );

  return (
    <CreateCardRow>
      <CreateCardHeaderItem style={{ width: "40%" }}>
        {!exists ? (
          <div
            style={{
              ...(isSaved ? { cursor: "not-allowed" } : {}),
            }}
          >
            <Dropdown
              buttonStyle={{
                height: "36px",
                border: "none",
                background: "transparent",
                boxShadow: "none",
                padding: "0",
                ...(isSaved ? { pointerEvents: "none" } : {}),
              }}
              itemListStyle={{
                width: "100%",
              }}
              initialValue={protocolItems.find((item) => item.value === protocol)}
              customArrowIconColor="#4235e1"
              items={protocolItems}
              onChange={(e) => {
                setProtocol(e!.value);
              }}
              placeholder="Select Protocol"
              label={null}
            />
          </div>
        ) : (
          <div
            style={{
              ...((!isEditing && exists) || isSaved ? { cursor: "not-allowed" } : {}),
            }}
          >
            <MultiDropdown
              buttonStyle={{
                height: "36px",
                border: "none",
                background: "transparent",
                boxShadow: "none",
                padding: "0",
                ...((!isEditing && exists) || isSaved ? { pointerEvents: "none" } : {}),
              }}
              itemListStyle={{
                width: "100%",
              }}
              initialValue={alertProtocolItems[0]}
              customArrowIconColor="#4235e1"
              items={alertProtocolItems}
              placeholder="Select Protocol"
              label={null}
              selectedItems={selectedProtocols}
              onAdd={onAddProtocol}
              onRemove={onRemoveProtocol}
            />
          </div>
        )}
      </CreateCardHeaderItem>
      <CreateCardHeaderItem
        style={{ width: "60%", display: "flex", alignItems: "center", justifyContent: "space-between" }}
      >
        <div>
          {keywordError && <ErrorMessage>Keyword must have at least 3 characters</ErrorMessage>}
          <StyledInput
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            onBlur={validateKeyword}
            placeholder="Enter Keyword"
            disabled={(exists && !isEditing) || isSaved}
          />
        </div>
        {exists && !isEditing ? (
          <Popover
            trigger="click"
            visible={isPopoverVisible}
            placement="bottom"
            onVisibleChange={onPopoverVisibleChange}
            overlayClassName="webhook-popover"
            zIndex={25}
            content={
              <div>
                <PopoverItem onClick={edit}>
                  <div className="content-wrapper">
                    <EditIcon width={16} height={16} />
                    <span>Edit</span>
                  </div>
                </PopoverItem>
                <PopoverItem onClick={deleteAlert}>
                  <div className="content-wrapper">
                    <TrashIcon width={16} height={16} />
                    <span>{isLoading ? "Deleting..." : "Delete"}</span>
                  </div>
                </PopoverItem>
              </div>
            }
          >
            <ThreeDotMenuWrapper>
              <ThreeDotMenuIcon width={16} height={16} />
            </ThreeDotMenuWrapper>
          </Popover>
        ) : (
          <AddButton $isSaved={isSaved} onClick={submit} disabled={keywordError || isLoading || isSaved}>
            {isLoading ? (
              <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Loader size="xsmall" />
                <span>{exists ? "Editing" : "Adding"}</span>
              </div>
            ) : exists ? (
              isSaved ? (
                "Edited"
              ) : (
                "Edit"
              )
            ) : isSaved ? (
              "Added"
            ) : (
              "Add"
            )}
          </AddButton>
        )}
      </CreateCardHeaderItem>
    </CreateCardRow>
  );
};

const Rows = ({ protocolsToAdd }: { protocolsToAdd: string[] }) => {
  const { account } = useContext(CurrentAccountContext);
  const { keywordSearches: alerts } = useGetKeywordSearchesByAddress({ address: account || "" });
  const groupedByKeyword = useMemo(
    () =>
      alerts?.reduce((acc, obj) => {
        const key = obj.keyword;

        acc.set(key, [...(acc.get(key) || []), obj]);

        return acc;
      }, new Map()) || new Map(),
    [alerts],
  );

  const parsedAlerts = useMemo(() => Array.from(groupedByKeyword.values()), [groupedByKeyword]);
  const [rows, setRows] = useState(parsedAlerts?.length || 0);

  useEffect(() => {
    setRows(parsedAlerts?.length || 0);
  }, [parsedAlerts]);

  const addRow = useCallback(() => {
    setRows((curr) => curr + 1);
  }, []);

  return (
    <>
      {Array.from(Array(rows).keys()).map((_, i) => (
        <CreateAlertRow
          key={i}
          exists={i < (parsedAlerts?.length || -1)}
          alert={parsedAlerts?.[i]}
          protocolsToAdd={protocolsToAdd}
        />
      ))}
      <AddAnotherWebhookButton onClick={addRow}>ADD ANOTHER ALERT</AddAnotherWebhookButton>
    </>
  );
};

export const AlertsModal = ({ closeDrawer, protocolsToAdd }: { closeDrawer: () => void; protocolsToAdd: string[] }) => {
  return (
    <>
      <DrawerHeader>
        <CreateWebhookText>
          <span style={{ fontWeight: "500" }}>Manage</span> Alerts
        </CreateWebhookText>
        <ThinCrossIcon
          style={{ cursor: "pointer" }}
          onClick={closeDrawer}
          color={COLORS.primary.grayDarkLightest}
          width={20}
          height={20}
        />
      </DrawerHeader>
      <CreateCard style={{ marginBottom: "40px" }}>
        <CreateCardHeader>
          <CreateCardHeaderItem style={{ width: "40%" }}>Projects</CreateCardHeaderItem>
          <CreateCardHeaderItem style={{ width: "60%" }}>Key Word</CreateCardHeaderItem>
        </CreateCardHeader>
        <Suspense fallback={<Loader />}>
          <Rows protocolsToAdd={protocolsToAdd} />
        </Suspense>
      </CreateCard>
    </>
  );
};
