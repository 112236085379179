import { useAuth, useUser } from "@clerk/clerk-react";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import Cookies from "universal-cookie";
import { useHistory } from "react-router";

import { BOARDROOM_API_KEY, baseAPIUrl } from "../constants/general";
import { CurrentUuidContext } from "../reducers/CurrentUuid";
import { CurrentAccountContext } from "../reducers/CurrentAccount";

const cookies = new Cookies();

export const useClerkSignIn = () => {
  const { account, dispatchAccount } = useContext(CurrentAccountContext);
  const { dispatchUuid } = useContext(CurrentUuidContext);
  const { getToken, isSignedIn } = useAuth();
  const { user } = useUser();
  const history = useHistory();

  const { data: token } = useQuery(
    ["clerkToken", isSignedIn],
    async () => {
      const token = await getToken({
        template: "Default",
      });
      return token;
    },
    {
      enabled: !!isSignedIn,
    },
  );
  const { data } = useQuery(
    ["clerkSignIn", token, isSignedIn],
    async () => {
      if (!token) {
        return {
          address: null,
          uuid: null,
        };
      }
      try {
        const response = await fetch(
          `${baseAPIUrl}clerkLogin/emailLoginClerk?key=${BOARDROOM_API_KEY}&email=${user?.primaryEmailAddress?.emailAddress}`,
          {
            method: "GET",
            headers: {
              authorization: token,
            },
          },
        );
        const data = await response.json();
        if (data?.data?.address && data?.data?.uuid) {
          dispatchAccount({
            type: "SAVE_ACCOUNT",
            data: data.data.address,
          });
          dispatchUuid({
            type: "SAVE_UUID",
            data: data.data.uuid,
          });
          cookies.set(`uuid:${data.data.address.toLowerCase()}`, data.data.uuid, { path: "/" });
        } else {
          history.push("/settings/email");
        }
        return data?.data;
      } catch (error) {
        console.error(error);
        history.push("/settings/email");
        return {
          address: null,
          uuid: null,
        };
      }
    },
    {
      enabled: !!token && !account,
    },
  );
  return data;
};

export const useCheckProxyConnection = () => {
  const { data } = useQuery(
    ["checkProxyConnection"],
    async () => {
      try {
        const response = await fetch(`${baseAPIUrl}clerkLogin/checkProxyConnection?key=${BOARDROOM_API_KEY}`, {
          method: "GET",
        });
        const data = await response.json();
        return data?.data || [];
      } catch (error) {
        console.error(error);
      }
    },
    {
      useErrorBoundary: false,
      suspense: false,
    },
  );

  return data?.proxyConnection;
};
