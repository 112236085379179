import React, { Suspense, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { TwitterShareButton } from "react-share";
import { useHover } from "use-events";
import ContentLoader from "react-content-loader";
import { ProposalDetails, VoteDetails } from "@boardroom/boardroom-api";
import moment from "moment";
import { ProposalState } from "@boardroom/gov-sdk";
import { Link, useLocation } from "react-router-dom";
import { useClickAway } from "react-use";
import notification from "antd/es/notification";
import List from "antd/es/list";
import parse from "html-react-parser";

import { useCurrentRefId } from "../../../reducers/CurrentRefId";
import { useGetEns } from "../../../hooks/useEns";
import { useAdapterFramework } from "../../../hooks/useAdapterFramework";
import { ProposalModal } from "../../ProposalModal";
import { getProposalMainStatus, getProposalTimeAgoText } from "../../../utils/getProposalTimeAgoText";
import SummaryModal from "../../../features/Dashboard/SummaryModal";
import NoteModal, { NoteModalContent } from "../../../features/Dashboard/NoteModal";
import { COLORS } from "../../../constants/colors";
import {
  NoteIcon,
  OnchainIcon,
  PaperOutlineIcon,
  SnapshotIcon,
  ThreeDotMenuIcon,
  ThinCheckIcon,
  ThinShareIcon,
  ThinCrossIcon,
  ArrowIcon,
  ThinChevronUpIcon,
  BookmarkIcon,
  BookmarkFilledIcon,
} from "../../icons";

import { SummaryButton, VoteInfo } from "../../VoteButtonOnCard/VoteButtonOnCard";
import { useMixpanel } from "../../../hooks";
import { insightsProtocols, partnerProtocols, protocols } from "../../../constants/protocols";
import ProtocolIcon from "../../ProtocolIcon";
import { humanizeEventTypeText } from "../../CategoryBadge/utils";
import { eventTypeColorCoding, eventTypeColorCodingText, isDateToday } from "../utils";
import { CalendarEvent, ProposalType, ProtocolDescription } from "../../../types";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import {
  BottomNavigationItemWrapper,
  CloseSplitViewWrapper,
  DefaultFlexDiv,
  DropdownItem,
  DropdownItemText,
  Event,
  EventColoredMarker,
  EventDate,
  EventDateValue,
  EventHour,
  EventMetaInfo,
  EventPadding,
  EventProtocolAndType,
  EventTitle,
  FlexDiv,
  FlexItem,
  GoVoteButton,
  MobileProtocolName,
  NextProposalHeaderWrapper,
  NotesCount,
  PopoverContentWrapper,
  PopoverMask,
  PopoverWrapper,
  ProtocolPageLink,
  ProtocolPageLinksWrapper,
  ShareText,
  SplitViewBottomNavigationWrapper,
  SplitViewContentWrapper,
  SplitViewHeaderNavigationWrapper,
  SplitViewHeaderWrapper,
  SplitViewProtocolDropdownToggleWrapper,
  SplitViewTab,
  SplitViewTabsWrapper,
  StackedRow,
  StatusContainer,
  StyledDrawer,
  StyledTimeAgo,
  SummaryTitle,
  ThreeDotMenuWrapper,
  TopText,
  VoteButton,
  VoteButtonDescriptionText,
  VoteButtonMainText,
  RedCircle,
} from "./styles";
import { PendingVoteDetailsContext } from "../../../reducers/PendingVotes";
import StatusTag from "../../StatusTag";
import { toShortAddress } from "../../../utils";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { CurrentPopoverOpenContext } from "../../../reducers/PopoverOpen";
import { useGetResourceFolders } from "../../../hooks/useGetResourceFolders";
import { useDiscourseData } from "../../../hooks/useDiscourseData";
import { useDelegatesByProtocol } from "../../../hooks/useDelegatesByProtocol";
import { NotesContext } from "../../../reducers/Comments";
import { useCurrentSplitViewIndex } from "../../../reducers/CurrentSplitViewIndex";
import { isTeamView } from "../../../utils/teamUtils";
import { CurrentUserDetailsContext } from "../../../reducers/CurrentUserDetails";
import { Text } from "../../Typography";
import { CurrentUuidContext } from "../../../reducers/CurrentUuid";
import { updateUserDetails } from "../../../utils/updateUserDetails";
import { useSiweFunction } from "../../../hooks/useSiweFunction";
import { useGetComments } from "../../../hooks/useGetComments";
import { displayVotedChoice } from "../../Proposals/VotedChoiceFormatting";

const PopoverContent = ({
  top,
  setOpen,
  handleClickNote,
  handleClickShare,
  handleClickSummary,
  protocol,
  event,
  content,
  proposal,
  voted,
}: {
  top: string;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleClickSummary: (e: any) => void;
  handleClickNote: (e: any) => void;
  handleClickShare: (e: any) => void;
  protocol: ProtocolDescription;
  event: CalendarEvent;
  content: any;
  proposal: ProposalDetails | undefined;
  voted: boolean;
}) => {
  const { setCurrentRefId } = useCurrentRefId();
  const contentWrapperRef = useRef(null);
  const { dispatchPopoverOpen } = useContext(CurrentPopoverOpenContext);
  useClickAway(contentWrapperRef, () => {
    setOpen(false);
    setTimeout(() => {
      dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: false });
    }, 300);
  });
  const proposalStatus = getProposalMainStatus(proposal?.currentState || "closed");

  const setProposal = useCallback(() => {
    setCurrentRefId(event.proposalRefId || "");
    setOpen(false);
    dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: false });
  }, [dispatchPopoverOpen, event.proposalRefId, setCurrentRefId, setOpen]);

  return (
    <PopoverWrapper>
      <PopoverMask />
      <PopoverContentWrapper $top={top} ref={contentWrapperRef}>
        {content && (
          <DropdownItem onClick={handleClickSummary}>
            <PaperOutlineIcon color="#4235E1" />
            <DropdownItemText>View Summary</DropdownItemText>
          </DropdownItem>
        )}
        <DropdownItem onClick={handleClickNote}>
          <NoteIcon color="#4235E1" />
          <DropdownItemText>Notes</DropdownItemText>
        </DropdownItem>
        <DropdownItem onClick={handleClickShare}>
          <TwitterShareButton
            url={`https://boardroom.io/${protocol?.path}/proposal/${event.proposalRefId}`}
            via={"boardroom_info"}
            title={`View this proposal from the ${protocol?.name} project:`}
          >
            <div style={{ display: "flex", alignItems: "center", marginLeft: "-2px" }}>
              <ThinShareIcon width={18} height={18} color="#4235E1" />
              <DropdownItemText>Share</DropdownItemText>
            </div>
          </TwitterShareButton>
        </DropdownItem>
        {proposalStatus === "active" && !voted ? (
          <GoVoteButton onClick={() => setProposal()}>
            <ThinCheckIcon width={20} height={20} />
            <span>Go vote</span>
          </GoVoteButton>
        ) : (
          <Link target="_blank" to={event.url}>
            {voted ? (
              <VoteButton>
                <ThinCheckIcon width={20} height={20} color="#191540" style={{ marginRight: "4px" }} />{" "}
                <VoteButtonMainText>Voted</VoteButtonMainText>
                <VoteButtonDescriptionText>: Visit Proposal</VoteButtonDescriptionText>
              </VoteButton>
            ) : (
              <VoteButton>
                <VoteButtonMainText>{proposalStatus === "pending" ? "Pending" : "Closed"}</VoteButtonMainText>
                <VoteButtonDescriptionText>: See Proposal</VoteButtonDescriptionText>
              </VoteButton>
            )}
          </Link>
        )}
      </PopoverContentWrapper>
    </PopoverWrapper>
  );
};

export const TimelineItemTopArea = ({
  proposalDetail,
  marginRight,
  hideStatus,
}: {
  proposalDetail: ProposalDetails;
  marginRight?: string;
  hideStatus?: boolean;
}) => {
  const { account } = useContext(CurrentAccountContext);
  const endsIn = moment(proposalDetail.endTimestamp * 1000).fromNow();
  const startsIn = moment(proposalDetail.startTimestamp * 1000).fromNow();
  const { trackClickProposerLinkOnVotingModal } = useMixpanel();
  const ens = useGetEns(proposalDetail?.proposer);
  const { adapterFramework } = useAdapterFramework(proposalDetail?.protocol);

  const handleClickProposerLink = useCallback(() => {
    trackClickProposerLinkOnVotingModal({
      userId: account,
      proposalRefId: proposalDetail?.refId,
    });
  }, [proposalDetail, account, trackClickProposerLinkOnVotingModal]);

  return (
    <StackedRow className="opacityForArchiving">
      {!hideStatus && (
        <StatusContainer>
          <StatusTag marginRight={marginRight} status={proposalDetail.currentState.toLowerCase() as ProposalState} />
        </StatusContainer>
      )}
      <TopText>
        <span onClick={(e) => e.stopPropagation()}>
          By{" "}
          <Link onClick={handleClickProposerLink} style={{ color: "#4235E1" }} to={`/voter/${proposalDetail.proposer}`}>
            {ens || toShortAddress(proposalDetail.proposer)}
          </Link>
        </span>
        <i>&#183;</i>
        <StyledTimeAgo $isCanceled={proposalDetail.currentState === "canceled"}>
          {getProposalTimeAgoText(proposalDetail.currentState, startsIn, endsIn, proposalDetail.endTimestamp)}
        </StyledTimeAgo>
        <i>&nbsp;&#183;&nbsp;</i>
        {proposalDetail.adapter === "snapshot" ||
        (proposalDetail.adapter === "default" && adapterFramework === "snapshot") ? (
          <span style={{ display: "flex" }}>
            <SnapshotIcon width={16} height={16} />
            &nbsp;<p style={{ margin: "auto 0" }}>Offchain</p>
          </span>
        ) : (
          <span style={{ display: "flex" }}>
            <OnchainIcon width={16} height={16} color={COLORS.secondary.blue} />
            &nbsp;<p style={{ margin: "auto 0" }}>Onchain</p>
          </span>
        )}
      </TopText>
    </StackedRow>
  );
};

interface EventRowProps {
  event: CalendarEvent;
  proposalData?: ProposalDetails[];
  votes?: (VoteDetails | undefined)[];
  isLastItem?: boolean;
  isFirstItem?: boolean;
  lastItemBorderWidth?: number;
  isToday?: boolean;
  setSplitViewTab: React.Dispatch<React.SetStateAction<"summary" | "vote" | "notes">>;
  bookmarkedProposals: string[];
  flatProposalEvents: CalendarEvent[];
}

export const EventRowSuspense = () => {
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={50}
      backgroundColor={COLORS.primary.grayLight}
      foregroundColor={COLORS.primary.grayLighter}
      style={{ padding: "0 8px" }}
    >
      <circle x={isMobile ? "10%" : "3%"} y="4" cx={20} cy={20} r={20} />
      <rect x={isMobile ? "30%" : "15%"} y="4" rx="5" ry="5" width={isMobile ? "50%" : "40%"} height="12" />
      <rect x={isMobile ? "30%" : "15%"} y="24" rx="5" ry="5" width="15%" height="12" />
      <rect x={isMobile ? "15%" : "7%"} y="4" rx="5" ry="5" width={isMobile ? "10%" : "6%"} height="12" />
      <rect x={isMobile ? "15%" : "7%"} y="24" rx="5" ry="5" width={isMobile ? "10%" : "6%"} height="12" />
    </ContentLoader>
  );
};

export const SplitViewContent = ({
  flatProposalEvents,
  proposal,
  splitViewTab,
  summary,
  setSplitViewTab,
}: {
  proposal: ProposalDetails & { summary?: string };
  splitViewTab: string;
  setSplitViewTab: React.Dispatch<React.SetStateAction<"summary" | "vote" | "notes">>;
  summary?: string;
  flatProposalEvents?: CalendarEvent[];
}) => {
  const protocolCname = proposal?.protocol;
  const [isSplitViewHeaderExpanded, setIsSplitViewHeaderExpanded] = useState(false);
  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const resourceFolders = useGetResourceFolders({ protocol: protocols[protocolCname]?.cname });
  const { trackClickNoteButton } = useMixpanel();
  const { account } = useContext(CurrentAccountContext);

  const { notes } = useContext(NotesContext);
  const queryParams = new URLSearchParams(useLocation().search);
  const isTeamView = queryParams.get("bundle");
  const { setCurrentSplitViewIndex, currentSplitViewIndex } = useCurrentSplitViewIndex();

  useEffect(() => {
    if (splitViewTab === "summary") {
      if (!(summary || proposal.summary)) {
        setSplitViewTab("vote");
      } else {
        setSplitViewTab("summary");
      }
    }
  }, [proposal, setSplitViewTab, summary, splitViewTab]);

  const { discourseData } = useDiscourseData({
    categories: [],
    category: "all",
    tag: "all",
    latestOrTop: "latest",
    topPeriod: "all",
    url: protocols[protocolCname]?.discourseForum?.url || "",
    suspense: false,
  });
  const { delegates } = useDelegatesByProtocol({
    protocol: protocolCname || "",
    limit: 24,
    suspense: false,
  });

  const handleClickNextProposal = useCallback(() => {
    setSplitViewTab("summary");
    if (flatProposalEvents) {
      if (currentSplitViewIndex) {
        const nextProposal = flatProposalEvents[currentSplitViewIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex + 1);
      } else {
        const currProposalIndex = flatProposalEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const nextProposal = flatProposalEvents[currProposalIndex + 1];
        setCurrentRefId(nextProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex + 1);
      }
    }
  }, [
    currentRefId,
    setCurrentRefId,
    setSplitViewTab,
    flatProposalEvents,
    currentSplitViewIndex,
    setCurrentSplitViewIndex,
  ]);

  const handleClickPreviousProposal = useCallback(() => {
    setSplitViewTab("summary");
    if (flatProposalEvents) {
      if (currentSplitViewIndex) {
        const previousProposal = flatProposalEvents[currentSplitViewIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currentSplitViewIndex - 1);
      } else {
        const currProposalIndex = flatProposalEvents.findIndex((event) => event.proposalRefId === currentRefId);
        const previousProposal = flatProposalEvents[currProposalIndex - 1];
        setCurrentRefId(previousProposal?.proposalRefId || "");
        setCurrentSplitViewIndex(currProposalIndex - 1);
      }
    }
  }, [
    currentRefId,
    setCurrentRefId,
    setSplitViewTab,
    flatProposalEvents,
    setCurrentSplitViewIndex,
    currentSplitViewIndex,
  ]);

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const toggleSplitViewHeader = useCallback(() => {
    setIsSplitViewHeaderExpanded((curr) => !curr);
  }, []);

  const proposalNotes = useMemo(() => {
    return notes
      ?.filter((comment) => comment?.proposalId === proposal.refId)
      ?.filter((comment) => {
        if (isTeamView) {
          return true;
        } else {
          return !comment.sharedWithOrg;
        }
      });
  }, [isTeamView, notes, proposal.refId]);

  const handleClickOpenNotes = useCallback(() => {
    setSplitViewTab("notes");
    trackClickNoteButton({
      proposalRefId: proposal.refId || "",
      userId: `${account}`,
    });
  }, [account, proposal.refId, setSplitViewTab, trackClickNoteButton]);

  return (
    <div>
      <SplitViewHeaderWrapper $isExpanded={isSplitViewHeaderExpanded}>
        <SplitViewHeaderNavigationWrapper>
          <NextProposalHeaderWrapper onClick={handleClickPreviousProposal} $type="previous">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
          <NextProposalHeaderWrapper onClick={handleClickNextProposal} $type="next">
            <ThinChevronUpIcon />
          </NextProposalHeaderWrapper>
        </SplitViewHeaderNavigationWrapper>
        <div style={{ width: "100%" }}>
          <SplitViewProtocolDropdownToggleWrapper
            onClick={toggleSplitViewHeader}
            $isExpanded={isSplitViewHeaderExpanded}
          >
            <ProtocolIcon size="small" protocol={protocols[protocolCname]} />
            <span>{protocols[protocolCname]?.name}</span>
            <ArrowIcon width={14} height={14} color="#4235e1" />
          </SplitViewProtocolDropdownToggleWrapper>
        </div>
        <div style={{ width: "112px" }}>
          <CloseSplitViewWrapper onClick={handleClose}>
            <ThinCrossIcon />
          </CloseSplitViewWrapper>
        </div>
      </SplitViewHeaderWrapper>
      {isSplitViewHeaderExpanded && (
        <ProtocolPageLinksWrapper>
          {insightsProtocols.includes(protocolCname) && (
            <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/insights`}>
              Insights
            </ProtocolPageLink>
          )}
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/proposals`}>
            Governance
          </ProtocolPageLink>
          {!!discourseData?.topics?.length && (
            <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/discussions`}>
              Discussions
            </ProtocolPageLink>
          )}
          <ProtocolPageLink
            target="_blank"
            to={
              delegates?.length
                ? `/${protocols[protocolCname]?.path}/delegates`
                : `/${protocols[protocolCname]?.path}/voters`
            }
          >
            Members
          </ProtocolPageLink>
          <ProtocolPageLink target="_blank" to={`/${protocols[protocolCname]?.path}/overview`}>
            Overview
          </ProtocolPageLink>
          {partnerProtocols.includes(protocolCname) && (
            <ProtocolPageLink
              target="_blank"
              to={
                resourceFolders !== undefined
                  ? `/${protocols[protocolCname]?.path}/reports/${resourceFolders?.[0]}`
                  : `/${protocols[protocolCname]?.path}/reports`
              }
            >
              Reports
            </ProtocolPageLink>
          )}
        </ProtocolPageLinksWrapper>
      )}
      <div style={{ marginTop: isSplitViewHeaderExpanded ? "122px" : "64px" }} />
      <SplitViewTabsWrapper>
        {(summary || proposal.summary) && (
          <SplitViewTab $isActive={splitViewTab === "summary"} onClick={() => setSplitViewTab("summary")}>
            Summary
          </SplitViewTab>
        )}
        <SplitViewTab $isActive={splitViewTab === "vote"} onClick={() => setSplitViewTab("vote")}>
          Vote
        </SplitViewTab>
        <SplitViewTab $isActive={splitViewTab === "notes"} onClick={handleClickOpenNotes}>
          Notes <NotesCount>{proposalNotes.length || 0}</NotesCount>
        </SplitViewTab>
        <TwitterShareButton
          url={`https://boardroom.io/${protocols[protocolCname]?.path}/proposal/${proposal.refId}`}
          via={"boardroom_info"}
          title={`View this proposal from the ${protocols[protocolCname]?.name} project:`}
          style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}
        >
          <ThinShareIcon width={16} height={16} color="#4235E1" />
          <ShareText>Share</ShareText>
        </TwitterShareButton>
      </SplitViewTabsWrapper>
      <div style={{ marginTop: "24px" }} />
      {splitViewTab === "vote" && proposal.refId === currentRefId && (
        <SplitViewContentWrapper>
          <TimelineItemTopArea marginRight="8px" proposalDetail={proposal} />
          <Suspense fallback={<></>}>
            <ProposalModal
              protocolCname={proposal.protocol}
              refId={currentRefId}
              flatProposalEvents={flatProposalEvents}
            />
          </Suspense>
        </SplitViewContentWrapper>
      )}
      {splitViewTab === "summary" && (summary || proposal.summary) && (
        <SplitViewContentWrapper>
          <SummaryTitle style={{ marginBottom: "16px" }}>{proposal.title}</SummaryTitle>
          <div className="markdown-body">
            {(summary || proposal.summary) && parse(summary || proposal.summary)}
            {!summary && !!proposal.summary && (
              <>
                <br />
                <span style={{ fontStyle: "italic", fontSize: "12px", textAlign: "right", display: "block" }}>
                  AI generated by Holdim.
                </span>
              </>
            )}
            {!summary && !proposal.summary && <List />}
          </div>
        </SplitViewContentWrapper>
      )}

      {splitViewTab === "notes" && (
        <SplitViewContentWrapper>
          <NoteModalContent refId={proposal.refId} isTeamView={!!isTeamView} />
        </SplitViewContentWrapper>
      )}
      <div style={{ marginTop: "96px" }} />
      <SplitViewBottomNavigationWrapper>
        <BottomNavigationItemWrapper $type="previous" onClick={handleClickPreviousProposal}>
          <ThinChevronUpIcon />
          <span>Previous Proposal</span>
        </BottomNavigationItemWrapper>
        <BottomNavigationItemWrapper onClick={handleClickNextProposal}>
          <ThinChevronUpIcon />
          <span>Next Proposal</span>
        </BottomNavigationItemWrapper>
      </SplitViewBottomNavigationWrapper>
    </div>
  );
};

const EventRow = ({
  event,
  proposalData,
  votes,
  isLastItem,
  isFirstItem,
  lastItemBorderWidth,
  setSplitViewTab,
  flatProposalEvents,
  bookmarkedProposals,
}: EventRowProps) => {
  const eventRef = useRef<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const protocol = protocols[event.protocolCname];
  const { adapterFramework } = useAdapterFramework(event?.protocolCname);
  const { account } = useContext(CurrentAccountContext);
  const currentDate = moment();
  const isProposal = event.type === "proposalEnd" || event.type === "proposalStart";
  const { pathname, search } = useLocation();
  const isOnTeamView = isTeamView(pathname, search);
  const isBookmarked = useMemo(
    () => bookmarkedProposals.includes(event?.proposalRefId || ""),
    [bookmarkedProposals, event?.proposalRefId],
  );

  const [memoModal, setMemoModal] = useState(false);
  const [noteModal, setNoteModal] = useState(false);
  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();
  const {
    trackClickProposalSummaryButton,
    trackClickNoteButton,
    trackClickFeedVoteButton,
    trackClickEventOrMeetingOnPortal,
    trackClickShareProposalOnPortal,
    trackClickBookmarkProposalOnFeed,
  } = useMixpanel();
  const { pendingVoteDetails } = useContext(PendingVoteDetailsContext);
  const { userDetails, dispatchUserDetails } = useContext(CurrentUserDetailsContext);
  const { uuid } = useContext(CurrentUuidContext);
  const siweFunction = useSiweFunction();
  const isVotePending = pendingVoteDetails[event.proposalRefId || ""] !== undefined;
  const [isHovered, bind] = useHover();
  const { width, height } = useWindowDimensions();
  const isMobile = width <= 991;
  const { popoverOpen, dispatchPopoverOpen } = useContext(CurrentPopoverOpenContext);
  const { comments: commentsData } = useGetComments({
    filterBy: "proposalId",
    author: account,
    uuid,
    includeOrgComments: !!isOnTeamView,
    proposalId: event.proposalRefId,
  });
  const { notes, dispatchNotes } = useContext(NotesContext);
  const filteredAndSortedNotes = useMemo(() => {
    return notes?.filter((comment) => comment?.proposalId === event?.proposalRefId) ?? commentsData;
  }, [commentsData, notes, event?.proposalRefId]);

  useEffect(() => {
    return () => {
      dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: false });
      setOpen(false);
    };
  }, [dispatchPopoverOpen]);

  useEffect(() => {
    if (commentsData) {
      dispatchNotes({
        type: "SAVE_NOTES",
        data: commentsData,
      });
    }
  }, [commentsData, dispatchNotes]);

  const vote = useMemo(
    () => votes?.find((vote) => vote?.proposalRefId === event.proposalRefId),
    [event?.proposalRefId, votes],
  );

  const proposal: (ProposalDetails & { summary?: string }) | undefined = useMemo(
    () => proposalData?.find((proposalInArray) => proposalInArray.refId === event.proposalRefId),
    [event?.proposalRefId, proposalData],
  );

  const eventIndex = flatProposalEvents?.findIndex(
    (eventInArray) => eventInArray.proposalRefId === event.proposalRefId && eventInArray.type === event.type,
  );

  const handleMemoClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      e.preventDefault();
      if (isMobile) {
        setMemoModal(true);
        setOpen(false);
        dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: false });
      } else {
        setSplitViewTab("summary");
        setCurrentRefId(event.proposalRefId || "");
        setCurrentSplitViewIndex(eventIndex > -1 ? eventIndex : undefined);
      }
      trackClickProposalSummaryButton({
        proposalRefId: event?.proposalRefId || "",
        userId: `${account}`,
      });
    },
    [
      isMobile,
      trackClickProposalSummaryButton,
      event.proposalRefId,
      account,
      dispatchPopoverOpen,
      setSplitViewTab,
      setCurrentRefId,
      setCurrentSplitViewIndex,
      eventIndex,
    ],
  );

  const handleNoteClick = useCallback(
    (e: any) => {
      if (isMobile) {
        setNoteModal(true);
        setOpen(false);
        dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: false });
      } else {
        setSplitViewTab("notes");
        setCurrentRefId(event.proposalRefId || "");
        setCurrentSplitViewIndex(eventIndex > -1 ? eventIndex : undefined);
      }
      e.stopPropagation();
      e.preventDefault();
      trackClickNoteButton({
        proposalRefId: event?.proposalRefId || "",
        userId: `${account}`,
      });
    },
    [
      isMobile,
      trackClickNoteButton,
      event.proposalRefId,
      account,
      dispatchPopoverOpen,
      setSplitViewTab,
      setCurrentRefId,
      setCurrentSplitViewIndex,
      eventIndex,
    ],
  );

  const handleShareClick = useCallback(
    (e: any) => {
      e?.stopPropagation();
      trackClickShareProposalOnPortal({
        proposalRefId: event?.proposalRefId || "",
        userId: `${account}`,
      });
    },
    [account, event?.proposalRefId, trackClickShareProposalOnPortal],
  );

  const handleBookmarkClick = useCallback(
    async (e: any) => {
      e?.stopPropagation();
      if (event?.proposalRefId) {
        const updatedBookmarkedProposals = isBookmarked
          ? bookmarkedProposals?.filter((refId) => refId !== event?.proposalRefId)
          : [...bookmarkedProposals, event?.proposalRefId];
        try {
          if (!account) {
            notification.error({
              message: "Error",
              description: <Text>Please connect your wallet to bookmark</Text>,
            });
            return;
          }
          const data = {
            address: account,
            ...userDetails,
            bookmarkedProposals: updatedBookmarkedProposals.join(","),
            uuid,
          };
          notification.info({
            message: "Loading...",
            description: <Text>Bookmarking</Text>,
            duration: 3,
          });
          await updateUserDetails({
            address: account,
            userData: data,
            siweFunction,
            siweErrorDescription: "You need to sign in with wallet to bookmark",
          });
          dispatchUserDetails({ type: "SAVE_USER_DETAILS", data });
          notification.success({
            message: "Success",
            description: <Text>Bookmarked</Text>,
            duration: 3,
          });
          trackClickBookmarkProposalOnFeed({
            proposalRefId: event?.proposalRefId || "",
            userId: `${account}`,
          });
        } catch (e: any) {
          console.error(e);
          if (e.message !== "SIWE Error") {
            notification.error({
              message: "Error",
              description: <Text>Something went wrong... Please try again later</Text>,
            });
          }
        }
      }
    },
    [
      event?.proposalRefId,
      isBookmarked,
      bookmarkedProposals,
      account,
      userDetails,
      uuid,
      siweFunction,
      dispatchUserDetails,
      trackClickBookmarkProposalOnFeed,
    ],
  );

  const handleClick = useCallback(() => {
    setSplitViewTab("vote");
    if (isMobile && popoverOpen) {
      return;
    }
    if (isMobile) {
      setOpen(true);
      dispatchPopoverOpen({ type: "SAVE_POPOVER_OPEN", data: true });
      return;
    }
    if (isProposal) {
      trackClickFeedVoteButton({
        proposalRefId: event.proposalRefId || "",
        userId: `${account}`,
      });
    } else {
      trackClickEventOrMeetingOnPortal({
        userId: `${account}`,
      });
    }

    setCurrentRefId(event.proposalRefId || "");
    setCurrentSplitViewIndex(eventIndex > -1 ? eventIndex : undefined);
  }, [
    account,
    event.proposalRefId,
    trackClickFeedVoteButton,
    setCurrentRefId,
    isProposal,
    trackClickEventOrMeetingOnPortal,
    isMobile,
    dispatchPopoverOpen,
    popoverOpen,
    setSplitViewTab,
    eventIndex,
    setCurrentSplitViewIndex,
  ]);

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const isActive = useMemo(() => currentRefId === event.proposalRefId, [currentRefId, event.proposalRefId]);
  return (
    <>
      <Event ref={eventRef} {...bind} key={event.id} onClick={handleClick}>
        <EventColoredMarker
          $isTodayAndIsFirstItem={isDateToday(moment(event.date)) && isFirstItem}
          $isTodayAndIsLastItem={isDateToday(moment(event.date)) && isLastItem}
          $color={eventTypeColorCoding[event.type]}
        />
        <EventPadding
          $isActive={isActive}
          $lastItem={isLastItem}
          $firstItem={isFirstItem}
          $lastItemBorderBottomWidth={lastItemBorderWidth}
        >
          <EventMetaInfo>
            <ProtocolIcon protocol={protocols[event.protocolCname]} />
            <EventDate>
              <EventHour $voted={!!vote} $inPast={currentDate.isAfter(event.date)}>
                {moment(event.date).format("HH:mm")}
              </EventHour>
              <EventDateValue>{moment(event.date).format("MMM DD")?.toUpperCase()}</EventDateValue>
            </EventDate>
          </EventMetaInfo>
          <FlexDiv $customMargins={isMobile}>
            <div className="opacityForArchiving">
              {isMobile && <MobileProtocolName>{protocols[event.protocolCname]?.name}</MobileProtocolName>}
              <EventTitle $voted={!!vote} $isHovered={isHovered && !currentRefId} $width={width} $isActive={isActive}>
                {event.title}
              </EventTitle>
              <EventProtocolAndType
                $inPast={currentDate.isAfter(event.date)}
                $color={eventTypeColorCodingText[event.type]}
                $voted={!!vote}
              >
                {!isMobile && protocols[event.protocolCname]?.name}
                {!isMobile && " · "}
                {humanizeEventTypeText(event.type, isMobile)}
                {proposal?.adapter === "snapshot" ||
                (proposal?.adapter === "default" && adapterFramework === "snapshot") ? (
                  <span style={{ display: "flex", color: "#7b7893", marginLeft: "8px" }}>
                    · <SnapshotIcon width={16} height={16} />
                    <p style={{ margin: "auto 0" }}>Offchain</p>
                  </span>
                ) : (
                  <span style={{ display: "flex", color: "#7b7893", marginLeft: "8px" }}>
                    · <OnchainIcon style={{ marginLeft: "4px" }} width={16} height={16} color={COLORS.secondary.blue} />
                    &nbsp;<p style={{ margin: "auto 0" }}>Onchain</p>
                  </span>
                )}
                {!!vote && !!vote.proposalInfo && (
                  <span
                    style={{
                      marginLeft: "4px",
                      color: COLORS.primary.grayDarkLighter,
                      display: "flex",
                    }}
                  >
                    · Voted:{" "}
                    {displayVotedChoice(
                      vote.proposalInfo.type as ProposalType,
                      vote.choices || vote.choice,
                      vote.proposalInfo.choices || [],
                      vote.adapter,
                    )}
                  </span>
                )}
              </EventProtocolAndType>
            </div>
            <DefaultFlexDiv>
              {isHovered && isProposal && !isMobile && !currentRefId ? (
                <>
                  {!!proposal?.summary && (
                    <SummaryButton $addMarginRight onClick={handleMemoClick}>
                      View Summary
                    </SummaryButton>
                  )}
                  <VoteInfo
                    voted={!!vote}
                    smallerSize
                    onClick={handleClick}
                    protocolName={event.protocolCname}
                    pendingVote={isVotePending}
                  />
                </>
              ) : null}
              {!isHovered && isVotePending && isProposal && !isMobile && !currentRefId && (
                <VoteInfo
                  voted={!!vote}
                  smallerSize
                  onClick={handleClick}
                  protocolName={event.protocolCname}
                  pendingVote={isVotePending}
                  hideReceipt
                />
              )}
            </DefaultFlexDiv>
          </FlexDiv>
          {isProposal && !currentRefId && (
            <ThreeDotMenuWrapper style={{ position: "relative" }} $isOpen={open} $width={width}>
              {isHovered && !isMobile ? (
                <>
                  <FlexItem
                    $svgMarginRight="4px"
                    style={{ marginRight: "28px", position: "relative" }}
                    onClick={handleNoteClick}
                  >
                    <NoteIcon width={16} height={16} />
                    {filteredAndSortedNotes?.length > 0 ? (
                      <RedCircle style={{ left: "20px" }}>{filteredAndSortedNotes?.length}</RedCircle>
                    ) : (
                      <span>0</span>
                    )}
                  </FlexItem>
                  {!isOnTeamView && (
                    <FlexItem onClick={handleBookmarkClick} $svgMarginRight="16px">
                      {isBookmarked ? (
                        <BookmarkFilledIcon color="#4235e1" width={16} height={16} />
                      ) : (
                        <BookmarkIcon width={16} height={16} />
                      )}
                    </FlexItem>
                  )}
                  <FlexItem onClick={handleShareClick} $svgMarginRight="0px">
                    <TwitterShareButton
                      url={`https://boardroom.io/${protocol?.path}/proposal/${event.proposalRefId}`}
                      via={"boardroom_info"}
                      title={`View this proposal from the ${protocol?.name} project:`}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ThinShareIcon width={18} height={18} color={COLORS.primary.grayDarkLightest} />
                      </div>
                    </TwitterShareButton>
                  </FlexItem>
                </>
              ) : (
                <>
                  {filteredAndSortedNotes?.length > 0 && (
                    <RedCircle style={{ left: "-32px" }}>{filteredAndSortedNotes?.length}</RedCircle>
                  )}
                  {!!vote && <ThinCheckIcon style={{ marginRight: "16px" }} color="#191540" width={16} height={16} />}
                  {!vote && !isVotePending && !isOnTeamView && !isMobile && (
                    <>
                      {isBookmarked ? (
                        <BookmarkFilledIcon
                          width={16}
                          height={16}
                          onClick={handleBookmarkClick}
                          style={{ marginRight: "20px" }}
                          color="#4235e1"
                        />
                      ) : (
                        <BookmarkIcon
                          width={16}
                          height={16}
                          onClick={handleBookmarkClick}
                          style={{ marginRight: "20px" }}
                        />
                      )}
                    </>
                  )}
                  <ThreeDotMenuIcon />
                </>
              )}
            </ThreeDotMenuWrapper>
          )}
        </EventPadding>
      </Event>
      {proposal && isMobile && (
        <StyledDrawer
          visible={currentRefId === event.proposalRefId}
          onClose={handleClose}
          zIndex={9}
          placement="bottom"
          size="large"
          maskStyle={{ backgroundColor: "transparent" }}
          height="calc(100% - 108px)"
          bodyStyle={{ padding: 16, paddingTop: 0 }}
          headerStyle={{ marginLeft: "auto" }}
        >
          <>
            <TimelineItemTopArea marginRight="16px" proposalDetail={proposal} />
            <Suspense fallback={<></>}>
              <ProposalModal protocolCname={proposal.protocol} refId={currentRefId} proposalData={proposalData} />
            </Suspense>
          </>
        </StyledDrawer>
      )}
      {proposal?.summary && (
        <SummaryModal
          memoModal={memoModal}
          setMemoModal={setMemoModal}
          summary={proposal?.summary}
          title={event.title}
        />
      )}
      {event.proposalRefId === currentRefId || (noteModal && event.proposalRefId) ? (
        <NoteModal noteModal={noteModal} setNoteModal={setNoteModal} refId={event.proposalRefId} />
      ) : null}
      {isMobile && open && (
        <PopoverContent
          handleClickNote={handleNoteClick}
          handleClickSummary={handleMemoClick}
          handleClickShare={handleShareClick}
          top={
            (eventRef?.current?.getBoundingClientRect()?.top || 0) + 200 > height
              ? `${height - 200}px`
              : (eventRef?.current?.getBoundingClientRect()?.top || 0) + 45 + "px"
          }
          setOpen={setOpen}
          protocol={protocol}
          event={event}
          content={proposal?.summary}
          proposal={proposal}
          voted={!!vote}
        />
      )}
    </>
  );
};

const SuspendedEventRow = (props: EventRowProps) => {
  return (
    <Suspense fallback={<EventRowSuspense />}>
      <EventRow {...props} />
    </Suspense>
  );
};

export default SuspendedEventRow;
