import React, { useState, useContext, useEffect, useCallback, useRef, Suspense, useMemo } from "react";
import { User } from "@boardroom/boardroom-api";
import Button from "antd/es/button";
import Col from "antd/es/col";
import Row from "antd/es/row";
import notification from "antd/es/notification";
import MDEditor from "@uiw/react-md-editor";
import Switch from "antd/es/switch";
import styled, { css } from "styled-components";
import { useClickAway } from "react-use";
import { Link, useLocation } from "react-router-dom";
import ContentLoader from "react-content-loader";

import { COLORS } from "../../../constants/colors";
import {
  SearchIcon,
  CircleAddIcon,
  CircleMinusIcon,
  TrashIcon,
  EditIcon,
  SettingsAngularIcon,
} from "../../../components/icons";
import { CurrentAccountContext } from "../../../reducers/CurrentAccount";
import ProtocolIcon from "../../../components/ProtocolIcon";
import { useProtocols } from "../../../hooks/useProtocols";
import { ProtocolDescription } from "../../../types";
import { DelegationPitch, useDelegationPitchesByAddress } from "../../../hooks/useDelegationPitchesByAuthor";
import { updateDelegationPitch } from "../../../utils/updateDelegationPitch";
import { useUserDetails } from "../../../hooks/useUserDetails";
import { TrackPageView } from "../../../components/TrackPageView";
import mediaQuery from "../../../media-query";
import { useOnboardWallet } from "../../../hooks/useOnboardWallet";
import UnconnectedWalletState from "../../../components/UnconnectedWalletState";
import { SettingsMainNav, SettingsText, StyledRowMobile } from "./SettingsProfile";
import { useMixpanel } from "../../../hooks";
import { StyledSaveButton, StyledSaveButtonWrapper } from "./SettingsFeed";
import { Loader } from "../../../components/Loader";
import { protocols as protocolsFromSdk } from "../../../constants/protocols";
import { useSiweFunction } from "../../../hooks/useSiweFunction";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

const standAloneProtocolCname = process.env.REACT_APP_STANDALONE_PROTOCOL;

export const ButtonGroup = styled.div<{ justify?: string; marginTop?: string; padding?: string }>`
  padding: ${({ padding }) => (padding ? padding : "24px")};
  display: flex;
  width: inherit;
  justify-content: ${({ justify }) => (justify ? justify : "center")};
  gap: 12px;
  margin-top: ${({ marginTop }) => (marginTop ? marginTop : "64px")};
`;

export const StyledPitchButton = styled(Button)`
  font-size: 14px;
  line-height: 16px;
  height: 32px;
  border-radius: 8px;
`;

export const PitchCancelButton = styled(StyledPitchButton)`
  color: #7b7893;
  font-weight: normal;
  border: none;
  width: 112px;
`;

export const PitchSaveButton = styled(StyledPitchButton)`
  font-weight: 600;
  color: #fff;
  background: ${COLORS.secondary.purple};
  width: 112px;
`;

export const PitchDeleteButton = styled(StyledPitchButton)`
  font-weight: 600;
  color: #fff;
  background: #ff0000;
  width: 112px;
`;

export const StyledDiv = styled("div")`
  padding: 0 20px;
`;

export const StyledText = styled.div`
  width: 100%;
  text-align: center;
  color: ${COLORS.primary.grayDarkLightest};
`;

export const StyledHeading = styled.div`
  margin-top: 28px;
`;
export const StyledHeadingText = styled.div<{ size: string; color: string }>`
  font-size: ${({ size }) => size};
  color: ${({ color }) => color};
`;

export const StyledSwitch = styled(Switch)`
  float: right;
  &.ant-switch-checked {
    background: #4235e1;
  }
`;

export const HelperText = styled.div<{ color: string; margin?: string; fontSize?: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "14px")};
  line-height: 18px;
  color: ${({ color }) => color};
  margin: ${({ margin }) => margin};
`;

export const InputDiv = styled.div<{ height?: string }>`
  margin-top: 14px;
  display: inline-block;
  border: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 4px 12px;
  width: 100%;
  border-radius: 4px;
  position: relative;
  height: ${({ height }) => (height ? height : "56px")};
  &:hover {
    border: 1px solid ${COLORS.primary.accent};
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

export const StyledInput = styled.input<{ width?: string }>`
  display: block;
  width: ${({ width }) => (width ? width : "100%")};
  outline: none;
  border: none;
`;

export const StyledSearchIcon = styled(SearchIcon)`
  color: inherit;
  float: right;
  width: 20px;
  height: 20px;
  margin: auto 0 auto auto;
`;

export const StyledInputDiv = styled(InputDiv)`
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  z-index: 5;
  height: 260px;
`;

export const List = styled.ul<{ isOpen: boolean }>`
  position: absolute;
  margin-top: 10px;
  padding: 10px 4px;
  width: 100%;
  list-style: none;
  background: #fff;
  height: 260px;
  overflow: auto;
  box-shadow: 0px 4px 8px rgba(7, 3, 40, 0.09);
  border-radius: 8px;
  text-align: left;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  display: ${({ isOpen }) => (isOpen ? "block" : "none")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: ${({ isOpen }) => (isOpen ? "all 0.2s" : "none")};
`;

export const FlexItem = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  cursor: pointer;
  padding: 8px 2px;
  color: ${COLORS.primary.grayDarkLightest};
  &:hover {
    color: ${COLORS.primary.accent};
    border-radius: 4px;
  }
  &:first-child {
    margin-top: 0px;
  }
`;

export const SearchText = styled.div<{ fontSize?: string; color?: string }>`
  font-size: 16px;
  line-height: 18px;
  margin: auto 0;
  fontsize: ${({ fontSize }) => fontSize};
  color: ${({ color }) => color};
`;

export const StyledFlex = styled.div<{ $customGap?: string }>`
  display: flex;
  margin-left: auto;
  align-items: center;
  gap: ${({ $customGap }) => ($customGap ? $customGap : "6px")};
  cursor: pointer;
`;

export const StyledFlexDiv = styled.div`
  width: 100%;
  margin-top: 8px;
  display: flex;
  cursor: pointer;
`;

export const PitchDiv = styled.div<{ showTextBox?: boolean }>`
  border-top: 1px solid ${COLORS.secondary.purpleLighter};
  padding: 10px 0 4px;
  width: 100%;
  position: relative;
  height: ${({ showTextBox }) => (showTextBox ? "auto" : "56px")};
  &:last-of-type {
    border-bottom: 1px solid ${COLORS.secondary.purpleLighter};
  }
  &:hover {
    label {
      color: ${COLORS.primary.accent};
    }
  }
`;

export const StyledRichMarkdownEditor = styled(MDEditor)<{ $isErrored: boolean }>`
  padding-bottom: 4px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  min-height: 80px;
  border-radius: 4px;
  transition: all 0.3s;
  width: 100%;
  margin-top: 16px;
  border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.secondary.purpleLighter}`)};
  &:hover {
    border: ${(props) => (props.$isErrored ? `1px solid ${COLORS.red}` : `1px solid ${COLORS.primary.accent}`)};
  }
  p {
    font-size: 14px;
    line-height: 18px;
    color: ${COLORS.primary.grayDarkLightest};
  }
  .w-md-editor-text-input {
    -webkit-text-fill-color: inherit;
  }
`;

export const SavedDelegationPitchText = styled("p")`
  margin-bottom: 24px;
  margin-top: 12px;
  margin-left: 28px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #191540;
  max-width: 35rem;
`;

export const SureToDeleteTitle = styled("span")`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 12px;
  color: #191540;
  max-width: 35rem;
  display: block;
  margin-left: 28px;
`;

export const SureToDeleteText = styled("p")`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 48px;
  margin-top: 12px;
  color: #191540;
  max-width: 35rem;
  margin-left: 28px;
`;

export const CancelText = styled.span`
  color: ${COLORS.primary.accent};
  margin-top: 14px;
  font-size: 16px;
  display: flex;
  justify-content: end;
  cursor: pointer;
`;

export const StyledRow = styled(Row)`
  margin-bottom: 120px;
  border-radius: 8px;
  padding-bottom: 48px;
`;

export const StyledDivAlt = styled.div`
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
  ${mediaQuery.lessThan("991px")`
    overflow-x: auto;
  `}
`;

export const StyledTab = styled(Link)<{ $active?: boolean }>`
  margin-right: 16px;
  color: ${({ $active }) => ($active ? COLORS.primary.grayDark : COLORS.primary.grayDarkLightest)};
  font-weight: ${({ $active }) => ($active ? "500" : "400")};
  font-size: 18px;
  line-height: 28px;
  display: inline-block;
  min-width: auto;
  ${mediaQuery.lessThan("991px")`
    line-height: 30px;
    font-size: 22px;
    min-width: auto;
  `}
  &:after {
    ${(props) =>
      props.$active &&
      css`
        height: 8px;
        display: block;
        border-bottom: 4px solid ${COLORS.primary.accent};
        content: "";
        ${mediaQuery.lessThan("640px")`
          height: 16px;
          width: calc(100% + 24px);
          font-weight: 400;
        `}
      `}
  }
`;

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  border-bottom: 1px solid ${COLORS.primary.grayLighter};
  position: relative;
  ${mediaQuery.lessThan("991px")`
      height: 42px;
      left: 0px;
      width: 100%;
      display: flex;
      margin-right: 0px;
    `}
  ${mediaQuery.lessThan("640px")`
      width: 100%;
      background: #fafafa;
      z-index: +2;
      padding-left: 2px;
      height: 64px;
      position: sticky;
      position: -webkit-sticky;
      top: 80px;
      display: inline-block;
      padding-top: 16px;
      white-space: nowrap;
    `}
`;

export const StyledCol = styled(Col)`
  background: white;
  padding-top: 12px;
  padding-left: 16px !important;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: 0px 2px 6px rgba(25, 21, 64, 0.05);
  width: 100%;
`;

export const ProtocolWrapper = ({
  protocol,
  handleRemoveProtocol,
}: {
  protocol: ProtocolDescription;
  handleRemoveProtocol: any;
}) => {
  return (
    <StyledInputDiv height="44px" key={protocol.cname}>
      <FlexItem>
        <ProtocolIcon protocol={protocol} size="xsmall" />
        <SearchText>{protocol.name}</SearchText>
        <StyledFlex onClick={() => handleRemoveProtocol(protocol)}>
          <CircleMinusIcon width={16} height={16} color={COLORS.primary.grayDarkLightest} />
          <SearchText fontSize="14px" color={COLORS.primary.grayDarkLightest}>
            Remove
          </SearchText>
        </StyledFlex>
      </FlexItem>
    </StyledInputDiv>
  );
};

const SettingsDelegate = () => {
  const { account } = useContext(CurrentAccountContext);
  const { user: userDetails } = useUserDetails({ address: account, suspense: false, byPassCache: true });
  const [userState, setUserState] = useState<User>({ address: account });
  const [query, setQuery] = useState<string>("");
  // protocols that have been saved in the DB
  const [showSearchInput, setShowSearchInput] = useState(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isSaved, setIsSaved] = useState<boolean>(false);
  const { protocols } = useProtocols();
  const [savedProtocols, setSavedProtocols] = useState<ProtocolDescription[]>(
    standAloneProtocolCname ? [protocolsFromSdk[standAloneProtocolCname]] : [],
  );
  const { delegationPitches: savedDelegationPitches } = useDelegationPitchesByAddress({ address: userState?.address });
  const [showTextBox, setShowTextBox] = useState<any>({});
  const [showDeleteBox, setShowDeleteBox] = useState<any>({});
  const [editorContent, setEditorContent] = useState<{ [T: string]: string }>({});
  const [isEditorEverFocused, setIsEditorEverFocused] = useState(false);
  const [newProtocols, setNewProtocols] = useState<ProtocolDescription[]>([]);
  const [protocolsToBeRemoved, setProtocolsToBeRemoved] = useState<ProtocolDescription[]>([]);
  const [delegationPitches, setDelegationPitches] = useState<DelegationPitch[]>([]);
  const { trackUpdateDelegationPitch } = useMixpanel();

  const [isSelectedProtocolsEdited, setIsSelectedProtocolsEdited] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const wallet = useOnboardWallet();
  const siweFunction = useSiweFunction();
  const { width } = useWindowDimensions();
  const isMobile = width < 991;

  useEffect(() => {
    if (userDetails) {
      setUserState(userDetails);
    }
  }, [userDetails, setUserState]);

  useEffect(() => {
    if (savedDelegationPitches) {
      setDelegationPitches(savedDelegationPitches);
    }
  }, [savedDelegationPitches]);

  //initial loading of protocols that have already been saved to the DB
  useEffect(() => {
    if (
      savedDelegationPitches &&
      savedDelegationPitches.length &&
      savedProtocols.length === 0 &&
      !isSelectedProtocolsEdited
    ) {
      const protocolsPitchedDetails = Object.values(protocols)?.filter((protocol) =>
        savedDelegationPitches?.map((delegationPitch) => delegationPitch.protocol).includes(protocol?.cname),
      );
      if (standAloneProtocolCname) {
        if (!protocolsPitchedDetails.find((protocolPitches) => protocolPitches.cname === standAloneProtocolCname)) {
          protocolsPitchedDetails.push(protocols[standAloneProtocolCname]);
        }
      }

      setSavedProtocols([...protocolsPitchedDetails]);
    }
  }, [userState, protocols, savedProtocols, savedDelegationPitches, isSelectedProtocolsEdited, account]);

  const allCategories = useMemo(
    () =>
      Object.values(protocols)
        .filter((a) => a.isEnabled)
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        .filter((a) => a.name.toLowerCase().includes(query.toLowerCase())),
    [protocols, query],
  );

  const handleShowTextBox = useCallback(
    (cname: string, value: boolean, savedDelegationPitch?: DelegationPitch) => {
      setShowTextBox({ ...showTextBox, [cname]: value });
      setShowDeleteBox((current: any) => ({ ...current, [cname]: false }));
      if (savedDelegationPitch) {
        setEditorContent((currentValue) => ({ ...currentValue, [cname]: savedDelegationPitch?.delegationPitch }));
      }
    },
    [showTextBox],
  );

  const handleShowDeleteBox = useCallback((cname: string, value: boolean) => {
    setShowDeleteBox((current: any) => ({ ...current, [cname]: value }));
    setShowTextBox((current: any) => ({ ...current, [cname]: false }));
  }, []);

  const handleEditorContent = useCallback(
    (value: string, cname: string) => {
      setEditorContent({ ...editorContent, [cname]: value });
    },
    [editorContent],
  );

  //initial loading of delegate pitches from DB into the content editors
  useEffect(() => {
    savedDelegationPitches?.map((pitch) => {
      setEditorContent((currentValue) => ({ ...currentValue, [pitch.protocol]: pitch.delegationPitch }));
    });
  }, [savedDelegationPitches]);

  const updateProfile = useCallback(() => {
    setLoading(true);
    const newProtocolsWithoutPitch =
      delegationPitches !== undefined
        ? newProtocols?.filter((protocol: any) => {
            return !delegationPitches?.some((pitch) => pitch.protocol === protocol.cname);
          })
        : [...newProtocols];

    if (!newProtocolsWithoutPitch.length && !protocolsToBeRemoved?.length) {
      notification.info({
        message: "Successful",
        description: "All your delegation selections and pitches have already been saved.",
      });
      setLoading(false);
      return;
    }

    newProtocolsWithoutPitch.map(async (protocol) => {
      try {
        await updateDelegationPitch({
          address: account,
          delegationPitch: "Delegate",
          protocol: protocol.cname,
          siweFunction,
          trackUpdateDelegationPitch,
        });
        notification.success({
          message: "Successful",
          description: `Delegation Pitch Updated for - ${protocols[protocol.cname].name}`,
        });
        handleShowTextBox(protocol.cname, false);
      } catch (error: any) {
        console.error(error);
        if (error?.message === "SIWE Error") {
          setLoading(false);
          setIsSaved(false);
        }
      }
    });

    // this will delete these pitches in the database
    protocolsToBeRemoved?.map(async (protocol) => {
      try {
        await updateDelegationPitch({
          address: account,
          delegationPitch: "",
          protocol: protocol.cname,
          siweFunction,
          trackUpdateDelegationPitch,
        });
        notification.success({
          message: "Successful",
          description: `You have removed - ${protocols[protocol.cname].name} from your selections`,
        });
        handleShowTextBox(protocol.cname, false);
      } catch (error: any) {
        console.error(error);
        if (error?.message === "SIWE Error") {
          setLoading(false);
          setIsSaved(false);
        }
      }
    });

    setDelegationPitches((current) =>
      [
        ...(current || []),
        ...newProtocolsWithoutPitch.map((protocol: any) => ({
          address: account,
          delegationPitch: "Delegate",
          protocol: protocol.cname,
        })),
      ].filter((pitch) => !protocolsToBeRemoved?.some((protocol) => protocol.cname === pitch.protocol)),
    );
    setSavedProtocols((prevState) =>
      Array.from(new Set([...prevState, ...newProtocols].map((protocol) => protocols[protocol.cname]))),
    );
    setNewProtocols([]);
    setProtocolsToBeRemoved([]);
    setIsSaved(true);
  }, [
    account,
    delegationPitches,
    handleShowTextBox,
    newProtocols,
    protocols,
    protocolsToBeRemoved,
    siweFunction,
    trackUpdateDelegationPitch,
  ]);

  const handleAddNewProtocol = useCallback(
    (item: ProtocolDescription) => {
      setNewProtocols((prevState) =>
        Array.from(new Set([...prevState, item].map((protocol) => protocols[protocol.cname]))),
      );
      setProtocolsToBeRemoved((current) => current.filter((protocol) => protocol.cname !== item.cname));
      setIsSelectedProtocolsEdited(true);
      setQuery("");
      setShowSearchInput(false);
    },
    [protocols],
  );

  const handleRemoveProtocol = useCallback((item: ProtocolDescription) => {
    setProtocolsToBeRemoved((prevState) => Array.from(new Set([...prevState, item])));
    setSavedProtocols((current) => current.filter((protocol) => protocol.cname !== item.cname));
    setNewProtocols((current) => current.filter((protocol) => protocol.cname !== item.cname));
    setIsSelectedProtocolsEdited(true);
  }, []);

  const handleUpdateDelegationPitch = useCallback(
    async ({ address, delegationPitch, protocol }: DelegationPitch) => {
      setLoading(true);
      try {
        await updateDelegationPitch({
          address,
          delegationPitch,
          protocol,
          siweFunction,
          trackUpdateDelegationPitch,
        });
        notification.success({
          message: "Successful",
          description: delegationPitch
            ? `Delegation Pitch Updated for - ${protocols[protocol].name}`
            : `Delegation Pitch Deleted for - ${protocols[protocol].name}`,
        });
        if (delegationPitch) {
          setDelegationPitches([
            ...(delegationPitches?.filter((pitch) => pitch.protocol !== protocol) || []),
            {
              address,
              delegationPitch,
              protocol,
            },
          ]);
        } else {
          setDelegationPitches([...(delegationPitches?.filter((pitch) => pitch.protocol !== protocol) || [])]);
          setSavedProtocols((current) => [
            ...(current?.filter((protocolDescription) => protocolDescription.cname !== protocol) || []),
          ]);
          if (!standAloneProtocolCname) {
            setNewProtocols((current) => [
              ...(current?.filter((protocolDescription) => protocolDescription.cname !== protocol) || []),
            ]);
          }
          setIsSelectedProtocolsEdited(true);
        }
        handleShowTextBox(protocol, false);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [delegationPitches, handleShowTextBox, protocols, siweFunction, trackUpdateDelegationPitch],
  );

  useClickAway(containerRef, () => {
    setIsInputFocused(false);
  });

  const allProtocols = useMemo(() => savedProtocols.concat(newProtocols), [newProtocols, savedProtocols]);

  return (
    <>
      <TrackPageView name={"Dashboard Settings Delegate"} />
      <StyledRowMobile style={{ marginLeft: 0 }} gutter={[16, 16]}>
        <Col xl={{ span: 20, push: 0 }} lg={{ span: 20, push: 0 }} md={{ span: 20, push: 0 }}>
          <StyledRow style={{ marginLeft: isMobile ? "" : "24px" }} justify="start" gutter={20}>
            <Col style={{ paddingLeft: 0 }} xs={24} lg={16}>
              <SettingsText>
                <SettingsAngularIcon />
                Settings <span>Profile</span>
              </SettingsText>
            </Col>
            <SettingsMainNav />
            {account ? (
              <>
                <StyledCol xl={18} xxl={16}>
                  <TabsWrapper>
                    <StyledDivAlt>
                      <StyledTab to={"/settings/profile"} $active={pathname.endsWith("/profile")}>
                        Profile
                      </StyledTab>
                      <StyledTab to={"/settings/delegate"} $active={pathname.includes("delegate")}>
                        Delegation
                      </StyledTab>
                    </StyledDivAlt>
                  </TabsWrapper>
                </StyledCol>
                <Col
                  style={{
                    background: "white",
                    padding: 0,
                    borderBottomLeftRadius: "8px",
                    borderBottomRightRadius: "8px",
                    boxShadow: "0px 4px 6px rgba(25, 21, 64, 0.05)",
                    width: "100%",
                  }}
                  xl={18}
                  xxl={16}
                >
                  <StyledDiv>
                    <StyledText>
                      If you’d like to represent voters on their behalf, you may opt-in to become a <br />
                      delegate. You can also choose to delegate to yourself on any project.
                    </StyledText>

                    {!standAloneProtocolCname && (
                      <StyledHeading>
                        <StyledSwitch size="small" defaultChecked={false} checked={allProtocols.length >= 1} />
                        <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                          Become a Project Delegate
                        </StyledHeadingText>
                        {allProtocols.map((protocol) => (
                          <ProtocolWrapper
                            protocol={protocol}
                            key={protocol.cname}
                            handleRemoveProtocol={handleRemoveProtocol}
                          />
                        ))}
                        {!showSearchInput && (
                          <StyledFlexDiv>
                            <StyledFlex onClick={() => setShowSearchInput(true)}>
                              <CircleAddIcon width={16} height={16} color={COLORS.primary.accent} />
                              <SearchText fontSize="14px" color={COLORS.primary.accent}>
                                Add Another Project
                              </SearchText>
                            </StyledFlex>
                          </StyledFlexDiv>
                        )}
                        <div ref={containerRef}>
                          {showSearchInput && (
                            <>
                              <CancelText onClick={() => setShowSearchInput(false)}>Cancel</CancelText>
                              <StyledInputDiv height="44px">
                                <StyledInput
                                  width="85%"
                                  placeholder="Search Projects"
                                  value={query}
                                  onChange={(e) => setQuery(e.target.value)}
                                  onFocus={() => setIsInputFocused(true)}
                                />
                                <StyledSearchIcon />
                              </StyledInputDiv>
                            </>
                          )}
                          {showSearchInput && isInputFocused && (
                            <Container>
                              <List isOpen={true}>
                                {allCategories.map((protocol) => (
                                  <FlexItem key={protocol.cname} onClick={() => handleAddNewProtocol(protocol)}>
                                    <ProtocolIcon protocol={protocol} size="xsmall" />
                                    <SearchText>{protocol.name}</SearchText>
                                  </FlexItem>
                                ))}
                              </List>
                            </Container>
                          )}
                        </div>
                      </StyledHeading>
                    )}
                    <StyledHeading>
                      <StyledSwitch size="small" defaultChecked={false} checked={allProtocols.length >= 1} />
                      <StyledHeadingText size="18px" color={COLORS.primary.grayDark}>
                        Add a delegation pitch
                      </StyledHeadingText>
                      <HelperText color={COLORS.primary.grayDarkLightest} margin="0 0 24px 0">
                        Help voters understand why they should delegate their votes to you.
                      </HelperText>

                      {allProtocols.map((protocol) => {
                        const savedDelegationPitch = delegationPitches?.find(
                          (pitch) => pitch.protocol === protocol.cname,
                        );
                        return (
                          <PitchDiv
                            showTextBox={
                              showTextBox[protocol.cname as keyof typeof showTextBox] || !!savedDelegationPitch
                            }
                            key={protocol.cname}
                          >
                            <FlexItem>
                              <ProtocolIcon protocol={protocol} size="xsmall" />
                              <SearchText color={COLORS.primary.grayDark}>{protocol.name}</SearchText>
                              {!showTextBox[protocol.cname] &&
                                !showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                                (!savedDelegationPitch ? (
                                  <StyledFlex onClick={() => handleShowTextBox(protocol.cname, true)}>
                                    <CircleAddIcon width={16} height={16} color={COLORS.primary.accent} />
                                    <SearchText fontSize="14px" color={COLORS.primary.accent}>
                                      Add Pitch
                                    </SearchText>
                                  </StyledFlex>
                                ) : (
                                  <StyledFlex $customGap="24px">
                                    <TrashIcon
                                      onClick={() => handleShowDeleteBox(protocol.cname, true)}
                                      width={16}
                                      height={16}
                                      color="#FF0000"
                                    />
                                    <EditIcon
                                      onClick={() => handleShowTextBox(protocol.cname, true)}
                                      width={20}
                                      height={20}
                                      color="#4235E1"
                                    />
                                  </StyledFlex>
                                ))}
                            </FlexItem>
                            {!showTextBox[protocol.cname as keyof typeof showTextBox] &&
                              !showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                              savedDelegationPitch && (
                                <SavedDelegationPitchText>
                                  {savedDelegationPitch?.delegationPitch}
                                </SavedDelegationPitchText>
                              )}
                            {showDeleteBox[protocol.cname as keyof typeof showTextBox] &&
                              !showTextBox[protocol.cname as keyof typeof showTextBox] && (
                                <>
                                  <SureToDeleteTitle>
                                    Are you sure you want to delete this delegation pitch?
                                  </SureToDeleteTitle>
                                  <SureToDeleteText>
                                    This delegation pitch will be deleted immediately and cannot be recovered once you
                                    confirm delete.
                                  </SureToDeleteText>
                                  <ButtonGroup justify="flex-end" marginTop="8px" padding="8px 0 24px 0">
                                    <PitchCancelButton onClick={() => handleShowDeleteBox(protocol.cname, false)}>
                                      Cancel
                                    </PitchCancelButton>
                                    <PitchDeleteButton
                                      disabled={loading}
                                      onClick={() =>
                                        handleUpdateDelegationPitch({
                                          address: account,
                                          delegationPitch: "",
                                          protocol: protocol.cname,
                                        })
                                      }
                                    >
                                      {loading ? "Loading" : "Delete"}
                                    </PitchDeleteButton>
                                  </ButtonGroup>
                                </>
                              )}
                            {showTextBox[protocol.cname as keyof typeof showTextBox] && (
                              <>
                                <StyledRichMarkdownEditor
                                  onChange={(value) => handleEditorContent(value as string, protocol.cname)}
                                  $isErrored={
                                    isEditorEverFocused &&
                                    !(
                                      Array.from(
                                        editorContent[protocol.cname as keyof typeof editorContent]?.matchAll(
                                          /[a-zA-Z0-9áàâãéèêíïóôõöúçñ]/g,
                                        ) || [],
                                      ).length > 0
                                    )
                                  }
                                  onFocus={() => setIsEditorEverFocused(true)}
                                  value={editorContent[protocol.cname]}
                                  placeholder="Your delegation pitch..."
                                />
                                <ButtonGroup justify="flex-end" marginTop="8px" padding="8px 0 24px 0">
                                  <PitchCancelButton
                                    onClick={() => handleShowTextBox(protocol.cname, false, savedDelegationPitch)}
                                  >
                                    Cancel
                                  </PitchCancelButton>
                                  <PitchSaveButton
                                    disabled={loading}
                                    onClick={() =>
                                      handleUpdateDelegationPitch({
                                        address: account,
                                        delegationPitch: editorContent[protocol.cname as keyof typeof editorContent],
                                        protocol: protocol.cname,
                                      })
                                    }
                                  >
                                    {loading ? "Loading" : "Save"}
                                  </PitchSaveButton>
                                </ButtonGroup>
                              </>
                            )}
                          </PitchDiv>
                        );
                      })}
                    </StyledHeading>

                    <StyledSaveButtonWrapper>
                      <StyledSaveButton
                        disabled={loading || isSaved}
                        $isSaved={isSaved}
                        onClick={() => {
                          updateProfile();
                          setTimeout(() => {
                            setLoading(false);
                          }, 1000);
                        }}
                      >
                        {loading && <Loader size="xsmall" />}
                        <span>{!loading && !isSaved ? "Save Preferences" : loading ? "Saving" : "Saved"}</span>
                      </StyledSaveButton>
                    </StyledSaveButtonWrapper>
                  </StyledDiv>
                </Col>
              </>
            ) : (
              <Col sm={24} md={22} xl={16}>
                <Suspense
                  fallback={
                    <ContentLoader
                      speed={2}
                      width="100%"
                      height={450}
                      backgroundColor={COLORS.primary.grayLight}
                      foregroundColor={COLORS.primary.grayLighter}
                    >
                      <circle cx="30" cy="38" r="20" />
                      <rect x="60" y="23" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="43" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="114" r="20" />
                      <rect x="60" y="99" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="119" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="190" r="20" />
                      <rect x="60" y="175" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="195" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="266" r="20" />
                      <rect x="60" y="251" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="271" rx="5" ry="5" width="70%" height="12" />

                      <circle cx="30" cy="342" r="20" />
                      <rect x="60" y="327" rx="5" ry="5" width="50%" height="12" />
                      <rect x="60" y="347" rx="5" ry="5" width="70%" height="12" />
                    </ContentLoader>
                  }
                >
                  <UnconnectedWalletState
                    handleClick={wallet?.openWalletModal}
                    title="A Missed Connection"
                    subTitle={
                      <>
                        Want a more personalized experience? <br /> Connect that wallet to see more.
                      </>
                    }
                  />
                </Suspense>
              </Col>
            )}
          </StyledRow>
        </Col>
      </StyledRowMobile>
    </>
  );
};
export default React.memo(SettingsDelegate);
