import Col from "antd/es/col";
import React, { useEffect, useState, useRef, useCallback, useContext, useMemo } from "react";
import Row from "antd/es/row";
import { useHistory, useLocation } from "react-router";
import moment from "moment";

import { MainContainer, BackgroundForMobile, Modal } from "../../components";
import { COLORS } from "../../constants/colors";
import { ArrowUpIcon, EyeIcon } from "../../components/icons";
import MetaTags from "../../components/common/MetaTags";
import {
  Container,
  PageMax,
  BackToTopSection,
  BackToTopText,
  StyledRowNoMargins,
  PaddingContainer,
  MobileBgWrapper,
} from "../Dashboard/styles";
import { useCurrentRefId } from "../../reducers/CurrentRefId";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { TrackPageView } from "../../components/TrackPageView";
import { SettingsNotificationsModal } from "../DashboardPages/Settings/SettingsNotifications";
import {
  FeedFooter,
  JoinBannerText,
  MintBanner,
  StickyDiv,
  StyledCol,
  StyledColSplitView,
  StyledTeamTxt,
  TeamFeedText,
  ViewOnlyWrapper,
} from "../DashboardPages/Calendar/Calendar";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useGetTeam } from "../../hooks/useGetTeam";
import SuspendedEventRow, { SplitViewContent } from "../../components/DailyBriefs/DailyBriefsEventRow";
import { CalendarEventTypes } from "../../types";
import {
  EventsWrapper,
  SeeMoreText,
  SplitViewDrawer,
  Today,
  TodayWrapper,
} from "../../components/Calendar/ListView/styles";
import { isTeamView } from "../../utils/teamUtils";
import { WalletDrawerContext } from "../../reducers/WalletDrawer";
import { useCurrentSplitViewIndex } from "../../reducers/CurrentSplitViewIndex";
import { DiscussFiltersContent } from "../../components/ProposalsFilters/ProposalsFilters";
import { savedProtocolsToArray } from "../../utils/savedProtocolsToArray";
import { NoProposalsFoundFeed } from "../../components/NoProposalsFound/NoProposalsFound";
import { mainGridOffset, mainGridStrucutre } from "../../constants/styles";
import { useGetDailyBriefsByProtocols } from "../../hooks/useGetDailyBriefs";
import { protocols } from "../../constants/protocols";
import { useUserDetails } from "../../hooks/useUserDetails";
import { useGetProtocolsToAdd } from "../../hooks/useGetProtocolsToAdd";
import { NotificationsCard } from "../../components/MoreResourcesCard/MoreResourcesCard";
import { CurrentProjectsFilterContext } from "../../reducers/CurrentProjectsFilter";
import { useHasValidSubscription } from "../../hooks/useHasValidSubscription";
import { MainTabsWrapper, StyledMainTab } from "../DashboardPages/Settings/SettingsProfile";
import { RoutePaths } from "../../constants/Routes";
import { useProxyAddress } from "../../hooks/useVoterVotes";
import { useDelegateVotingPowerByAddress } from "../../hooks/useDelegateVotingPowerByAddress";

export const PortfolioMobileNav = ({ $hasMintBanner }: { $hasMintBanner?: boolean }) => {
  const { pathname } = useLocation();
  const { account } = useContext(CurrentAccountContext);
  const isProxy = pathname.startsWith("/proxy");
  const feedPath = isProxy ? RoutePaths.proxyFeed : RoutePaths.feed;
  const discussPath = isProxy ? RoutePaths.proxyDiscuss : RoutePaths.discuss;
  const dailyBriefsPath = isProxy ? RoutePaths.proxyBriefs : RoutePaths.dailyBriefs;
  const hasProxy = useProxyAddress(account);
  const disabled = !hasProxy && isProxy;

  return (
    <MainTabsWrapper style={{ paddingTop: $hasMintBanner ? "56px" : "" }}>
      {disabled ? null : (
        <>
          <StyledMainTab to={feedPath} $active={pathname === feedPath}>
            Proposals
          </StyledMainTab>
          <StyledMainTab to={discussPath} $active={pathname === discussPath}>
            Discussions
          </StyledMainTab>
          <StyledMainTab to={dailyBriefsPath} $active={pathname === dailyBriefsPath}>
            Briefs
          </StyledMainTab>
        </>
      )}
    </MainTabsWrapper>
  );
};

function DailyBriefs() {
  const { account } = useContext(CurrentAccountContext);
  const { search, pathname } = useLocation();
  const isProxyPage = pathname.startsWith("/proxy");
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");
  const { user: userDetails } = useUserDetails({
    address: account,
  });
  const isOnTeamView = isTeamView(pathname, search);
  const { isWalletDrawerOpen } = useContext(WalletDrawerContext);
  const savedProtocolsArray = savedProtocolsToArray(userDetails?.savedProtocols);
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const history = useHistory();

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  const dashboardSection = useRef<HTMLDivElement>(null);
  const splitViewRef = useRef<HTMLDivElement>(null);
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const allProtocols = Object.keys(protocols);
  const protocolsToAdd = useGetProtocolsToAdd();
  const proxyAddress = useProxyAddress(account);
  const { delegateVotingPower } = useDelegateVotingPowerByAddress({
    address: proxyAddress || "",
  });
  const proxyProtocols = useMemo(() => {
    return delegateVotingPower?.map((data) => data.protocol) || [];
  }, [delegateVotingPower]);
  const { dailyBriefs, hasNextPage, fetchNextPage } = useGetDailyBriefsByProtocols({
    protocols:
      projectsFilter === "all"
        ? allProtocols
        : projectsFilter === "custom"
        ? isOnTeamView
          ? teamSavedProtocolsArray
          : savedProtocolsArray
        : projectsFilter === "proxy" && isProxyPage && proxyAddress
        ? proxyProtocols
        : protocolsToAdd,
  });
  const hasValidKey = useHasValidSubscription(account);

  const [showButton, setShowButton] = useState(false);

  const { currentRefId, setCurrentRefId } = useCurrentRefId();
  const { setCurrentSplitViewIndex } = useCurrentSplitViewIndex();

  const handleCloseModal = useCallback(() => {
    setIsNotificationsModalOpen(false);
  }, []);

  const handleOpenModal = useCallback(() => {
    setIsNotificationsModalOpen(true);
  }, []);

  useEffect(() => {
    if (!hasValidKey && dailyBriefs?.length && !(isProxyPage && proxyAddress)) {
      setCurrentRefId && setCurrentRefId(dailyBriefs[0]?.id);
    }
  }, [dailyBriefs, hasValidKey, isProxyPage, proxyAddress, setCurrentRefId]);

  useEffect(() => {
    const handleScroll = (e: any) => {
      if (e.srcElement.classList?.contains("DashboardContainer")) {
        const containerScrollFromTop = e.srcElement.scrollTop;
        if (containerScrollFromTop > 1500) {
          setShowButton(true);
        } else {
          setShowButton(false);
        }
      }
    };

    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [showButton]);

  const scrollToTop = () => {
    if (dashboardSection.current) {
      dashboardSection.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const todayBriefEvents = useMemo(() => {
    const events = dailyBriefs
      ?.filter((brief) => moment(brief?.timestamp * 1000).isAfter(moment().subtract(24, "hours")))
      .map((brief) => ({
        title: `${protocols[brief?.protocol]?.name} - ${moment(brief?.timestamp * 1000)
          .utcOffset(0)
          .format("MMM DD, YYYY")}`,
        date: brief?.timestamp * 1000,
        protocolCname: brief?.protocol,
        type: "discussion" as CalendarEventTypes,
        id: brief?.id,
        proposalRefId: brief?.id,
        url: "",
      }));

    return events || [];
  }, [dailyBriefs]);

  const previousBriefEvents = useMemo(() => {
    const events = dailyBriefs
      ?.filter((brief) => moment(brief?.timestamp * 1000).isBefore(moment().subtract(24, "hours")))
      .map((brief) => ({
        title: `${protocols[brief?.protocol]?.name} - ${moment(brief?.timestamp * 1000)
          .utcOffset(0)
          .format("MMM DD, YYYY")}`,
        date: brief?.timestamp * 1000,
        protocolCname: brief?.protocol,
        type: "discussion" as CalendarEventTypes,
        id: brief?.id,
        proposalRefId: brief?.id,
        url: "",
      }));

    return events || [];
  }, [dailyBriefs]);

  const currentBrief = useMemo(
    () => dailyBriefs?.find((brief) => brief?.id === currentRefId),
    [currentRefId, dailyBriefs],
  );

  const handleClose = useCallback(() => {
    setCurrentRefId("");
    setCurrentSplitViewIndex(undefined);
  }, [setCurrentRefId, setCurrentSplitViewIndex]);

  const allBriefEvents = useMemo(
    () => [...todayBriefEvents, ...previousBriefEvents],

    [todayBriefEvents, previousBriefEvents],
  );

  const briefsPath = isProxyPage ? RoutePaths.proxyBriefs : RoutePaths.dailyBriefs;

  const navigateToProxy = useCallback(() => {
    history.push(`/voter/${proxyAddress}`);
  }, [history, proxyAddress]);

  return (
    <>
      <TrackPageView name={"Briefs"} />
      <MetaTags
        title="Briefs | Boardroom"
        description="Boardroom is a web3 governance management portal helping users make faster, smarter, and more informed decisions"
        imageUrl="https://uploads-ssl.webflow.com/5fea803f5ceaedd1ffd21e65/602447d1a489bc75f4f2478a_Twitter%20Banner%203.png"
      />
      <MainContainer $isDashboard>
        <MobileBgWrapper>
          <BackgroundForMobile />
        </MobileBgWrapper>
        <Container
          className="DashboardContainer"
          ref={dashboardSection}
          id="scrollElement"
          isSettings={false}
          $isCalendar={location.pathname === briefsPath}
          $isSplitView={!!currentRefId}
          $width={width}
        >
          <PaddingContainer>
            <PageMax>
              <StyledRowNoMargins justify={"start"} align="middle">
                <Col style={{ position: currentRefId ? "static" : "relative" }} span={24}>
                  <>
                    {!!teamDetails && !!account ? (
                      <StyledTeamTxt>
                        {teamDetails.orgName} <TeamFeedText>Feed</TeamFeedText>{" "}
                        <ViewOnlyWrapper>
                          <EyeIcon width={20} height={20} /> View Only
                        </ViewOnlyWrapper>
                      </StyledTeamTxt>
                    ) : (
                      <></>
                    )}
                    {account && proxyAddress && isProxyPage ? (
                      <MintBanner onClick={navigateToProxy}>
                        <JoinBannerText className="joinText">Proxy View: {proxyAddress}</JoinBannerText>
                      </MintBanner>
                    ) : (
                      <></>
                    )}
                    <Row style={{ marginLeft: "0", marginRight: "0" }} justify="start" gutter={[48, 48]}>
                      <StyledColSplitView
                        xs={24}
                        lg={{ span: mainGridStrucutre(!!currentRefId), offset: mainGridOffset(!!currentRefId) }}
                        ref={splitViewRef}
                        $width={width}
                        $isSplitView={!!currentRefId}
                      >
                        <PortfolioMobileNav />
                        <StickyDiv
                          $hasMintBanner={account && proxyAddress && isProxyPage}
                          $isTeamView={!!teamDetails && !!account}
                        >
                          <DiscussFiltersContent />
                        </StickyDiv>
                        <div style={{ marginTop: "20px" }} />
                        <TodayWrapper>
                          <Today>Today</Today>
                        </TodayWrapper>
                        <EventsWrapper $isToday>
                          {todayBriefEvents?.map((brief) => (
                            <SuspendedEventRow key={brief?.proposalRefId} briefEvents={allBriefEvents} event={brief} />
                          ))}
                          {hasNextPage && <SeeMoreText onClick={() => fetchNextPage()}>SEE MORE BRIEFS</SeeMoreText>}
                        </EventsWrapper>
                        {!todayBriefEvents.length && <NoProposalsFoundFeed />}
                        <div style={{ marginTop: "20px" }} />
                        <TodayWrapper>
                          <Today>Past Briefs</Today>
                        </TodayWrapper>
                        <EventsWrapper $isToday>
                          {previousBriefEvents?.map((brief) => (
                            <SuspendedEventRow key={brief?.proposalRefId} briefEvents={allBriefEvents} event={brief} />
                          ))}
                          {hasNextPage && <SeeMoreText onClick={() => fetchNextPage()}>SEE MORE BRIEFS</SeeMoreText>}
                        </EventsWrapper>
                        {!previousBriefEvents.length && <NoProposalsFoundFeed />}
                        <div style={{ marginTop: "40px" }} />
                        {currentBrief && (
                          <SplitViewDrawer
                            zIndex={currentRefId ? 16 : -1}
                            visible={!!currentRefId}
                            onClose={handleClose}
                            placement="right"
                            contentWrapperStyle={{ width: "100%" }}
                            destroyOnClose
                            $isTeamView={!!isOnTeamView && !!account}
                            $isWalletDrawerOpen={isWalletDrawerOpen}
                            $width={width}
                            bodyStyle={{ zIndex: 0 }}
                          >
                            <SplitViewContent brief={currentBrief} briefEvents={allBriefEvents} />
                          </SplitViewDrawer>
                        )}
                      </StyledColSplitView>
                      <StyledCol
                        $hasMintBanner={account && proxyAddress && isProxyPage}
                        $isTeamView={!!teamDetails && !!account}
                        $isSplitView={!!currentRefId}
                        xs={24}
                        lg={8}
                        style={{ paddingTop: !!teamDetails && !!account ? "136px" : "76px", zIndex: "8" }}
                      >
                        {!teamId ? (
                          <>
                            <NotificationsCard protocolsToAdd={protocolsToAdd} onClick={handleOpenModal} />
                          </>
                        ) : (
                          <></>
                        )}
                        <div style={{ marginTop: "32px" }} />
                        <FeedFooter />
                      </StyledCol>
                    </Row>
                    <Modal
                      customMinWidth={isMobile ? "0" : undefined}
                      customMaxHeight={isMobile ? "650px" : undefined}
                      zIndex={20}
                      removePaddingBottom
                      size="large"
                      open={isNotificationsModalOpen}
                      onClose={handleCloseModal}
                    >
                      <SettingsNotificationsModal />
                    </Modal>
                  </>
                  {showButton && (
                    <BackToTopSection onClick={scrollToTop}>
                      <ArrowUpIcon color={COLORS.primary.accent} width={48} height={48} />
                      <BackToTopText>Back to Top</BackToTopText>
                    </BackToTopSection>
                  )}
                </Col>
              </StyledRowNoMargins>
            </PageMax>
          </PaddingContainer>
        </Container>
      </MainContainer>
    </>
  );
}

export default React.memo(DailyBriefs);
