import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from "react";
import ContentLoader from "react-content-loader";
import { useLocation } from "react-router";
import { ErrorBoundary } from "react-error-boundary";

import MultipleVotePowerRow from "../../../components/VoterProfile/MultipleVotePowerRow";
import { COLORS } from "../../../constants/colors";
import { useProtocols } from "../../../hooks/useProtocols";
import { useBalancesInAddresses } from "../../../hooks/useUserProtocols";
import { CardTitle, StyledInfoCard, VotingPowerRowsWrapper } from "../../../components/VoterProfile/VotePowerCard";
import ExpandCollapseRows from "../../../components/common/ExpandCollapseRows";
import { useMultipleAddressesVoter } from "../../../hooks/useVoter";
import { getProtocolCnameFromContract } from "./utils";
import { useDelegateProtocolsByAddresses } from "../../../hooks/useDelegateVotingPowerByAddress";
import { useMultipleAddressVotePowerMultipleProtocols } from "../../../hooks/useVotePower";
import { isTeamView as isOnTeamViewUtil } from "../../../utils/teamUtils";
import { useGetTeam } from "../../../hooks/useGetTeam";
import { savedProtocolsToArray } from "../../../utils/savedProtocolsToArray";
import { CurrentProjectsFilterContext } from "../../../reducers/CurrentProjectsFilter";
import { useVotePowerFromApi } from "../../../hooks/useVotePowerFromApi";
import { RoutePaths } from "../../../constants/Routes";

interface Props {
  wallets: string[];
  removeMarginTop?: boolean;
}

function MultipleAddressVotePowerCard(props: Props) {
  const { wallets } = props;
  const [areResultsExpanded, setAreResultsExpanded] = useState(false);
  const { protocols } = useProtocols();
  const protocolsArray = useMemo(() => Object.values(protocols), [protocols]);
  const protocolsInWallet = useBalancesInAddresses(wallets);
  const { data: votesData } = useMultipleAddressesVoter({ addresses: wallets, suspense: false });
  const { protocols: delegateProtocols } = useDelegateProtocolsByAddresses({ addresses: wallets, suspense: false });
  const { pathname, search } = useLocation();
  const isTeamView = isOnTeamViewUtil(pathname, search);
  const { projectsFilter } = useContext(CurrentProjectsFilterContext);
  const query = new URLSearchParams(search);
  const teamId = query.get("bundle");
  const teamDetails = useGetTeam(teamId || "");
  const teamSavedProtocolsArray = savedProtocolsToArray(teamDetails?.savedProtocols);
  const [nullChildren, setNullChildren] = useState<string[]>([]);
  const votePowerFromApi = useVotePowerFromApi({
    addresses: wallets,
  });
  const protocolsFromVotePowerApi = useMemo(
    () => votePowerFromApi?.map((vp) => vp?.protocol) || [],
    [votePowerFromApi],
  );

  useEffect(() => {
    setNullChildren([]);
  }, [wallets]);

  const protocolsSupportedWithVoteHistory: string[] = useMemo(() => {
    if (votesData?.votesByAddress) {
      const voterAddresses = Object.keys(votesData.votesByAddress);
      const ProtocolsByAddress = voterAddresses.reduce((accProtocols, address) => {
        const sortedProtocolsObjByAddress =
          votesData.votesByAddress[address]?.protocols
            .filter((protocolObj) => protocols[protocolObj.protocol] && protocols[protocolObj.protocol]?.isEnabled)
            .sort(
              (a: { totalPowerCast: number }, b: { totalPowerCast: number }) => b.totalPowerCast - a.totalPowerCast,
            ) || [];
        const sortedProtocolCnames = sortedProtocolsObjByAddress.map((protocolObj) => protocolObj.protocol);
        return [...accProtocols, ...sortedProtocolCnames];
      }, [] as string[]);
      return Array.from(new Set(ProtocolsByAddress.map((protocol) => protocol)));
    }
    return [];
  }, [protocols, votesData?.votesByAddress]);

  const protocolFilteredWithVoteHistoryFromBalance = useMemo(() => {
    const allTokenContractsFromBalances = Object.keys(protocolsInWallet);
    const allProtocolsFromBalances = allTokenContractsFromBalances.map((contractAddress) =>
      getProtocolCnameFromContract(protocolsArray, contractAddress),
    );
    const restOfProtocolsFromHistory =
      allProtocolsFromBalances.filter((protocolInbalance) => {
        const index = protocolsSupportedWithVoteHistory?.findIndex((protocolWithVoteHostory) => {
          return protocolWithVoteHostory === protocolInbalance;
        });

        return index !== undefined ? index < 0 : true;
      }) || [];

    return restOfProtocolsFromHistory;
  }, [protocolsArray, protocolsInWallet, protocolsSupportedWithVoteHistory]);

  const delegateProtocolsToShow = useMemo(() => {
    const otherProtocols = [
      ...(protocolsSupportedWithVoteHistory || []),
      ...protocolFilteredWithVoteHistoryFromBalance,
    ];
    const filteredProtocols = Array.from(
      new Set(delegateProtocols?.filter((protocol: string) => !otherProtocols.includes(protocol))),
    );
    return filteredProtocols || [];
  }, [protocolFilteredWithVoteHistoryFromBalance, protocolsSupportedWithVoteHistory, delegateProtocols]);

  // used to start loading data for all protocols in background
  useMultipleAddressVotePowerMultipleProtocols({
    protocols: [
      ...protocolsSupportedWithVoteHistory,
      ...delegateProtocolsToShow,
      ...protocolFilteredWithVoteHistoryFromBalance,
    ],
    addresses: wallets,
    isTeamView: !!isTeamView,
  });

  const allProtocols = useMemo(
    () =>
      Array.from(
        new Set([
          ...protocolsSupportedWithVoteHistory,
          ...delegateProtocolsToShow,
          ...protocolFilteredWithVoteHistoryFromBalance,
          ...protocolsFromVotePowerApi,
        ]),
      )
        .filter((protocol) => {
          if (projectsFilter === "custom" && pathname === RoutePaths.feed) {
            return teamSavedProtocolsArray.includes(protocol);
          } else {
            return true;
          }
        })
        .sort(),
    [
      delegateProtocolsToShow,
      pathname,
      projectsFilter,
      protocolFilteredWithVoteHistoryFromBalance,
      protocolsFromVotePowerApi,
      protocolsSupportedWithVoteHistory,
      teamSavedProtocolsArray,
    ],
  );

  const toggleResultsExpandCollapse = useCallback(() => {
    setAreResultsExpanded(!areResultsExpanded);
    setNullChildren((cur) => cur.filter((p) => allProtocols.slice(0, 3).includes(p)));
  }, [allProtocols, areResultsExpanded]);

  return (
    <StyledInfoCard
      $removeMarginTop={props.removeMarginTop}
      height="100%"
      title={<CardTitle>Vote Power</CardTitle>}
      $noPaddingBody={true}
    >
      <VotingPowerRowsWrapper $isExpanded={areResultsExpanded}>
        <>
          <ExpandCollapseRows
            isExpanded={areResultsExpanded}
            totalRows={allProtocols?.length}
            onToggle={toggleResultsExpandCollapse}
          >
            {allProtocols.map((protocol, index) => {
              return (
                <ExpandCollapseRows.Row
                  index={index}
                  cutoffIndex={3 + nullChildren?.length}
                  isExpanded={areResultsExpanded}
                  key={`${index}-${protocol}`}
                >
                  <Suspense fallback={<></>}>
                    <MultipleVotePowerRow setNullChildren={setNullChildren} protocol={protocol} addresses={wallets} />
                  </Suspense>
                </ExpandCollapseRows.Row>
              );
            })}
          </ExpandCollapseRows>
        </>
      </VotingPowerRowsWrapper>
    </StyledInfoCard>
  );
}

function VotePowerCardWrapper(props: Props) {
  return (
    <Suspense
      fallback={
        <StyledInfoCard height="100%" title="Voting Power" $noPaddingBody={true}>
          <ContentLoader
            speed={2}
            width="100%"
            height={115}
            backgroundColor={COLORS.primary.grayLight}
            foregroundColor={COLORS.primary.grayLighter}
          >
            <circle cx="36" cy="41" r="12" />
            <rect x="65" y="37" rx="4" ry="4" width="92" height="5" />
            <rect x="272" y="35" rx="4" ry="4" width="27" height="8" />

            <circle cx="36" cy="99" r="12" />
            <rect x="65" y="95" rx="4" ry="4" width="92" height="5" />
            <rect x="272" y="93" rx="4" ry="4" width="27" height="8" />
          </ContentLoader>
        </StyledInfoCard>
      }
    >
      <MultipleAddressVotePowerCard {...props} />
    </Suspense>
  );
}

function VoterPowerErrorBoundary(props: Props) {
  return (
    <ErrorBoundary fallback={<></>}>
      <VotePowerCardWrapper {...props} />
    </ErrorBoundary>
  );
}

export default React.memo(VoterPowerErrorBoundary);
