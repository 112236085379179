import React, { useMemo } from "react";
import styled from "styled-components";
import { PropGetters } from "downshift";
import { useHover } from "use-events";
import { ThinChevronUpIcon } from "../icons";
import { COLORS } from "../../constants/colors";
import { Item } from "./types";
import { protocols } from "../../constants/protocols";
import ProtocolIcon from "../ProtocolIcon";
import { Popover } from "../Popover";

interface Props {
  selected: Item | Item[] | null;
  isOpen: boolean;
  placeholder?: React.ReactNode;
  prefix?: React.ReactNode;
  icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  getButtonProps: PropGetters<Item>["getToggleButtonProps"];
  customArrowIconColor?: string;
  buttonStyle?: React.CSSProperties;
  isProtocols?: boolean;
}

export const Button = styled.button<{ isOpen: boolean }>`
  padding: 13px 15px;
  width: 100%;
  height: 44px;
  display: flex;
  align-items: center;
  text-align: left;
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ isOpen }) => (isOpen ? COLORS.primary.accent : COLORS.primary.grayLight)};
  border-radius: 6px;
  box-shadow: 0px 2px 6px rgba(7, 3, 40, 0.05);
  font-size: 14px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  color: ${({ isOpen }) => (isOpen ? COLORS.primary.grayDarkLight : COLORS.primary.grayDarkLightest)};
  outline: 0;
  transition: all 0.2s;

  :hover {
    border-color: ${COLORS.primary.accent};
    box-shadow: 0px 4px 7px rgba(37, 26, 171, 0.1);
    color: ${COLORS.primary.grayDarkLight};
    cursor: pointer;
  }
`;

export const ButtonText = styled.span`
  margin-right: auto;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputIconContainer = styled.span`
  margin-right: 8px;
  line-height: 0;
`;

export const ArrowIconContainer = styled.div<{ isOpen: boolean }>`
  margin-top: ${({ isOpen }) => (isOpen ? 0 : "-2px")};
  transform: rotate(${({ isOpen }) => (isOpen ? "0" : "180deg")});
  transition: transform 0.2s;
  margin-left: 5px;
`;

export const CustomProtocolsPopoverContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DropdownButton = ({
  selected,
  isOpen,
  placeholder = "",
  prefix = "",
  icon: Icon,
  getButtonProps,
  customArrowIconColor,
  buttonStyle,
  isProtocols,
}: Props) => {
  const [isHovered, bind] = useHover();

  const buttonText = useMemo(() => {
    if (Array.isArray(selected) && selected?.length && isProtocols) {
      return (
        <div style={{ display: "flex" }}>
          {selected?.map((protocol, i) => {
            const protocolInfo = protocols[protocol.value];
            return (
              <div
                key={protocolInfo?.cname}
                style={{
                  ...(i > 0 && { marginLeft: "-4px" }),
                }}
              >
                <ProtocolIcon size="xsmall" protocol={protocolInfo} />
              </div>
            );
          })}
        </div>
      );
    }
    if (Array.isArray(selected)) {
      return selected.length > 0 ? selected.map((item) => item.name).join(", ") : placeholder;
    }

    return selected?.name || placeholder;
  }, [isProtocols, placeholder, selected]);

  const inputIconColor = isOpen || isHovered ? COLORS.primary.accent : COLORS.primary.grayDarkLightest;
  const arrowIconColor = customArrowIconColor
    ? customArrowIconColor
    : isOpen || isHovered
    ? COLORS.primary.accent
    : COLORS.primary.grayLight;

  const customText = Array.isArray(selected) && selected?.length && isProtocols;

  return (
    <Button style={buttonStyle} isOpen={isOpen} {...getButtonProps()} {...bind}>
      {Icon && (
        <InputIconContainer>
          <Icon color={inputIconColor} width={19} height={19} />
        </InputIconContainer>
      )}

      <ButtonText>
        {prefix}
        {customText ? (
          <Popover
            trigger="hover"
            zIndex={20}
            content={
              <CustomProtocolsPopoverContentWrapper>
                {selected?.map((protocol) => {
                  const protocolInfo = protocols[protocol.value];
                  if (!protocolInfo) {
                    return null;
                  }
                  return (
                    <div key={protocolInfo.cname}>
                      <ProtocolIcon protocol={protocolInfo} size="xsmall" />
                      <span style={{ marginLeft: "8px" }}>{protocolInfo.name}</span>
                    </div>
                  );
                })}
              </CustomProtocolsPopoverContentWrapper>
            }
          >
            {buttonText}
          </Popover>
        ) : (
          buttonText
        )}
      </ButtonText>

      <ArrowIconContainer isOpen={isOpen}>
        <ThinChevronUpIcon width={16} height={16} color={arrowIconColor} />
      </ArrowIconContainer>
    </Button>
  );
};
