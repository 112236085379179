import { useInfiniteQuery } from "@tanstack/react-query";
import { GetProposalsResponse } from "@boardroom/boardroom-api";
import { apiClient } from "../utils/apiClient";
import { ProtocolDescription } from "../types";
import { useMemo } from "react";
import { excludedProposals } from "../constants/general";

interface Props {
  protocol: ProtocolDescription | null;
  limit?: number;
  status?: "pending" | "active" | "closed";
  adapter?: string;
  adapters?: string[];
}

export const useProposals = ({ protocol, limit, status, adapter = "all", adapters }: Props) => {
  const { data, fetchNextPage, hasNextPage } = useInfiniteQuery<GetProposalsResponse, Error>(
    [`proposals:${protocol?.cname}:${status || "all"}:${adapter}:${limit}:${adapters?.toString()}}`],
    ({ pageParam: cursor }) =>
      apiClient.getProtocolProposals(protocol?.cname || "", {
        limit,
        ...(cursor ? { cursor } : {}),
        status,
        ...(adapter === "all" ? {} : { adapter }),
        ...(adapters ? { adapters: JSON.stringify(adapters) as any as string[] } : {}),
      }),
    {
      enabled: !!protocol?.cname,
      getNextPageParam: (params) => params?.nextCursor,
    },
  );

  /*
    Implementation below is weird but works, it's a fix for this issue: https://discord.com/channels/733391663774564443/1001134729971638403/1051976001300729876
    Need to figure out a better fix for this later, it also comes wrongly sorted from the API so the issue exists there too
  */

  const activeProposals = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d.data)
      .flat()
      .filter((proposal) => proposal.currentState === "active")
      .reverse();
  }, [data]);

  const otherProposals = useMemo(() => {
    return data?.pages
      .flat()
      .map((d) => d.data)
      .flat()
      .filter((proposal) => proposal.currentState !== "active");
  }, [data]);

  const allProposals = useMemo(
    () =>
      [...(activeProposals || []), ...(otherProposals || [])].filter(
        (proposal) => !excludedProposals.includes(proposal.refId),
      ),
    [activeProposals, otherProposals],
  );

  return { proposals: allProposals || [], fetchNextPage, hasNextPage };
};
