import React, { FormEvent, useCallback, useContext, useState } from "react";
import { SignIn as SignInClerk } from "@clerk/clerk-react";
import WalletOutlined from "@ant-design/icons/WalletOutlined";
import styled from "styled-components";
import TextArea from "antd/es/input/TextArea";
import { useLocation } from "react-router";

import { Input } from "../Input";
import { StyledButton as ConnectWalletButton } from "../NavBar/Wallet";
import { Button, DividerLine, DividerRow } from "../EmailLoginModal/EmailLoginModal";
import { useOnboardWallet } from "../../hooks/useOnboardWallet";
import { CurrentAccountContext } from "../../reducers/CurrentAccount";
import { useIsEmailSignIn } from "../../hooks/useIsEmailSignIn";
import { ThinLogOutIcon } from "../icons";
import mediaQuery from "../../media-query";
import { COLORS } from "../../constants/colors";
import { RoutePaths } from "../../constants/Routes";

const StyledTitle = styled.h2`
  font-size: 28px;
  margin-bottom: 12px;
  margin-top: 24px;
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const StyledButton = styled.button`
  padding: 8px;
  background-color: ${COLORS.primary.accent};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s;
  margin-top: 24px;
  :hover {
    background-color: ${COLORS.primary.accentDark};
  }
  :disabled {
    background-color: ${COLORS.primary.accentLight};
    cursor: not-allowed;
  }
`;

const StyledLabel = styled.label`
  color: #191540;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SignInWrapper = styled("div")`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const InfoParagraph = styled("p")`
  margin-bottom: 20px;
`;

const DividerText = styled("span")`
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin: 16px;
`;

const FirstSectionWrapper = styled("div")`
  margin-bottom: 28px;
  ${mediaQuery.lessThan("991px")`
    margin-bottom: 16px;
  `}
`;

const FORMSPARK_ACTION_URL = "https://submit-form.com/82jujAHbh";

export default function SignIn() {
  const [submitting, setSubmitting] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [success, setSuccess] = useState(false);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const wallet = useOnboardWallet();
  const { account } = useContext(CurrentAccountContext);
  const isEmailSignIn = useIsEmailSignIn();
  const { pathname } = useLocation();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();

      try {
        setSubmitting(true);
        await fetch(FORMSPARK_ACTION_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            message,
            email: emailAddress,
            name,
          }),
        });
        setSuccess(true);
      } catch (err: any) {
        console.error(JSON.stringify(err, null, 2));
      } finally {
        setSubmitting(false);
      }
    },
    [emailAddress, message, name],
  );

  return (
    <>
      <StyledTitle as="h2">Access Your Proxy Dashboard</StyledTitle>
      <InfoParagraph>
        You must be signed up to the Boardroom&apos;s Proxy Services to access this page. Please browse{" "}
        <a href="https://home.boardroom.io/dashboard">this page</a> or contact us using the form below for more
        information.
      </InfoParagraph>
      <FirstSectionWrapper>
        {isEmailSignIn || account ? (
          <Button onClick={wallet?.walletReset}>
            <ThinLogOutIcon width={20} height={20} />
            <span>Switch to account that has proxy</span>
          </Button>
        ) : (
          <SignInWrapper>
            <SignInClerk
              appearance={{
                elements: {
                  card: {
                    marginRight: "0px",
                  },
                },
              }}
              afterSignInUrl={pathname}
              afterSignUpUrl={RoutePaths.settingsEmail}
            />
            <DividerText>or</DividerText>
            <ConnectWalletButton onClick={wallet?.openWalletModal}>
              <WalletOutlined />
              <span>Connect Wallet</span>
            </ConnectWalletButton>
          </SignInWrapper>
        )}
      </FirstSectionWrapper>
      <DividerRow>
        <DividerLine />
        <DividerText>or</DividerText>
        <DividerLine />
      </DividerRow>
      <StyledTitle>Contact Us</StyledTitle>
      <StyledForm style={{ paddingBottom: "40px" }} onSubmit={handleSubmit}>
        <InputWrapper>
          <StyledLabel htmlFor="name">Your Name</StyledLabel>
          <Input id="name" name="name" value={name} onChange={(e) => setName(e.target.value)} />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor="email">Your Work Email</StyledLabel>
          <Input
            id="email"
            type="email"
            name="email"
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </InputWrapper>
        <InputWrapper>
          <StyledLabel htmlFor="message">Message</StyledLabel>
          <TextArea id="message" name="message" value={message} onChange={(e) => setMessage(e.target.value)} rows={4} />
        </InputWrapper>
        <StyledButton disabled={submitting || success || !emailAddress || !name} onClick={handleSubmit} type="submit">
          {submitting ? "Submitting..." : success ? "Contact Sent!" : "Submit"}
        </StyledButton>
      </StyledForm>
    </>
  );
}
