import React from "react";
import { useSdk } from "../../hooks/useSdk";
import { checkAdapterFrameworkToUse } from "../../hooks/useVotePowerAdapter";
import { useGetDelegatedAddress } from "../../hooks/useHasDelegation";
import toShortAddress from "../../utils/toShortAddress";
import Link from "../Typography/Link";
import styled from "styled-components";
import { useGetEns, useGetEnsAvatar } from "../../hooks/useEns";
import { useUserDetails } from "../../hooks/useUserDetails";
import ProfileAvatar from "../VoterProfile/ProfileAvatar";

interface Props {
  protocolCname: string;
  address?: string;
}

const StyledInfo = styled.p`
  display: inline-block;
  max-width: calc(100% - 100px);
  margin-bottom: 0px;
`;

const StyledInfoWrapper = styled.div`
  text-align: center;
  padding: 8px 0px;
  border-top: 1px solid #f0eff8;
`;

const StyledPfp = styled(Link)`
  margin-top: 0px;
  margin-right: 8px;
`;

const StyledInfoTxt = styled.p`
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function DelegationInfo(props: Props) {
  const { protocolCname, address } = props;
  const sdk = useSdk();
  const protocolInSdk = sdk?.getProtocol(protocolCname);
  const adapterFramework = protocolInSdk?.adapterInstances("delegation");
  const adapterFrameworkToBeUsed = checkAdapterFrameworkToUse(adapterFramework);
  const delegationAdapter = protocolInSdk?.adapter("delegation", adapterFrameworkToBeUsed);
  const delegationAddress = useGetDelegatedAddress(delegationAdapter, address);
  const ens = useGetEns(delegationAddress);
  const ensAvatar = useGetEnsAvatar(address, ens);

  const { user: userDetails } = useUserDetails({ address: delegationAddress, suspense: false });

  if (!delegationAddress || delegationAddress === "0x0000000000000000000000000000000000000000") return null;

  return (
    <StyledInfoWrapper>
      <StyledPfp to={`/voter/${delegationAddress}`}>
        <ProfileAvatar
          address={address}
          size="xsmall"
          pfp={userDetails?.pfpUrl || ensAvatar}
          style={{ float: "none", verticalAlign: "bottom" }}
        />
      </StyledPfp>
      <StyledInfo>
        {address === delegationAddress ? (
          <StyledInfoTxt>Delegating to self</StyledInfoTxt>
        ) : (
          <StyledInfoTxt>Delegating to {ens || toShortAddress(delegationAddress)}</StyledInfoTxt>
        )}
      </StyledInfo>
    </StyledInfoWrapper>
  );
}

export default DelegationInfo;
