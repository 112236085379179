import React from "react";
import Row from "antd/es/row";
import Col from "antd/es/col";
import Comment from "antd/es/comment";
import styled from "styled-components";
import parse from "html-react-parser";
import moment from "moment";
import { TwitterShareButton } from "react-share";

import { AuthorWrapper, PostedByText } from "../../components/Proposals/ProposalComments/ProposalComment";
import { LinkStyles } from "../../components/Typography/Link";
import { ArrowRightIcon, ThinShareIcon } from "../../components/icons";
import media from "../../media-query";
import { protocols } from "../../constants/protocols";
import { COLORS } from "../../constants/colors";
import { GhostWebsiteUrl } from "../../constants/Routes";
import { GhostPost } from "../../hooks/useGetGhostPosts";

const TopicPost = styled(Comment)<{ $removePadding?: boolean }>`
  .ant-comment-content-detail p,
  .ant-comment-content-detail ul {
    &:not(:last-child) {
      padding-bottom: 40px;
    }
  }
  .ant-comment-inner {
    img:not(.emoji, .thumbnail, .avatar, .bannerImage) {
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
    }
    aside.quote {
      font-style: italic;
      color: #7b7893;
      border-left: 4px solid #f0eff8;
      padding-left: 16px;
    }
    aside.onebox {
      border: 1px solid #f0eff8;
      padding: 16px;
      background-color: #fafafa;
      margin-bottom: 16px;
      .source {
        font-size: 12px;
        line-height: 16px;
        color: #7b7893;
        margin-bottom: 8px;
        display: block;
        img.site-icon {
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
      }
      .aspect-image {
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .ant-comment-avatar img {
    height: 40px !important;
    width: 40px !important;
    border-radius: 50%;
  }
  .ant-comment-avatar {
    position: absolute;
  }
  .ant-comment-content-author {
    flex-direction: column;
    margin-bottom: 20px;
    margin-left: 48px;
    .ant-comment-content-author-name {
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #7b7893;
    }
    .ant-comment-content-author-time {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #7b7893;
    }
  }
  p {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .source {
    img {
      width: 20px;
      height: 20px;
    }
  }
  .mention {
    pointer-events: none;
  }
  .emoji {
    width: 20px;
    height: 20px;
  }
  .poll {
    display: none;
  }
  .meta {
    display: none;
  }
  ${({ $removePadding }) => $removePadding && ".ant-comment-inner { padding: 0; }"}
`;

const StyledTitle = styled("h2")`
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  color: #191540;
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const AuthorLink = styled("span")`
  ${LinkStyles}
`;

const FullArticleLink = styled.a`
  color: ${COLORS.primary.accent};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  display: block;
  margin 16px auto 28px;
`;

const DrawerBottomSection = styled("div")`
  position: fixed;
  bottom: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  width: 100%;
  max-width: 674px;
  margin-left: -36px;
`;

const ArrowLeftIcon = styled(ArrowRightIcon)`
  transform: rotate(180deg);
`;

const DrawerHeaderSection = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #f0eff8;
`;

const AllNewsArticles = styled.span`
  color: ${COLORS.primary.accent};
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
`;

const GhostAtrticleLink = styled("a")`
  color: ${COLORS.primary.grayDarkLightest};
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  gap: 4px;
  :hover {
    color: ${COLORS.primary.accent};
  }
`;

const ShareDiv = styled("div")`
  cursor: pointer;
  color: ${COLORS.primary.grayDarkLightest};
  display: flex;
  :hover {
    color: ${COLORS.primary.accent};
  }
`;

const FlexDiv = styled("div")`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const DrawerRow = styled(Row)`
  margin: 0 24px !important;
  ${media.lessThan("991px")`
    margin: 0 12px !important;
  `}
`;

const BannerImage = styled.img`
  width: 50%;
  height: auto;
  object-fit: cover;
  margin: auto;
  display: block;
  margin-bottom: 12px;
`;

export interface NewsArticleData {
  id: string;
  refId: string;
  protocol: string;
  imageUrl: string;
  title: string;
  tags: string;
  postsCount: number;
  views: number;
  replyCount: number;
  likeCount: number;
  categoryId: string;
  pinned: boolean;
  visible: boolean;
  closed: boolean;
  archived: boolean;
  createdAt: string;
  bumpedAt: string;
  slug: string;
}

const DiscourseAvatar = ({ src, id }: { src: string; id: number }) => {
  return <img src={src} key={id} />;
};

export const NewsArticleTopicDrawer = ({
  topicData,
  handleClose,
}: {
  topicData: GhostPost;
  handleClose: () => void;
}) => {
  const protocolInfo = protocols[topicData?.protocolCname || "aave"];

  return (
    <DrawerRow gutter={[24, { xs: 12, sm: 12, md: 24 }]} justify="space-around">
      <Col span={24}>
        <DrawerHeaderSection>
          <AllNewsArticles onClick={handleClose}>
            <ArrowLeftIcon /> <span>All News Articles</span>
          </AllNewsArticles>
          <FlexDiv>
            <GhostAtrticleLink href={`${GhostWebsiteUrl}/${topicData?.slug}`} target="_blank" rel="noopener">
              <span>Article</span>
            </GhostAtrticleLink>
            <TwitterShareButton
              url={`${GhostWebsiteUrl}/${topicData?.slug}`}
              via={"boardroom_info"}
              title={`Read this article from the ${protocolInfo?.name} project:`}
            >
              <ShareDiv>
                <ThinShareIcon width={20} height={20} />
              </ShareDiv>
            </TwitterShareButton>
          </FlexDiv>
        </DrawerHeaderSection>
      </Col>
      <Col span={24}>
        <StyledTitle style={{ marginTop: "-12px" }}>{topicData?.title} </StyledTitle>
      </Col>
      <Col span={24}>
        <>
          <>
            <TopicPost
              $removePadding
              author={
                <AuthorWrapper>
                  <PostedByText style={{ marginRight: "4px" }}>Posted by</PostedByText>
                  <AuthorLink>{topicData?.primary_author.name}</AuthorLink>
                </AuthorWrapper>
              }
              avatar={
                <DiscourseAvatar
                  src={topicData?.primary_author.profile_image}
                  id={Number(topicData?.primary_author.id)}
                />
              }
              content={
                <>
                  <BannerImage className="bannerImage" src={topicData.feature_image} alt={topicData.title} />
                  {parse(topicData.html)}
                </>
              }
              datetime={<span>{moment(topicData.published_at).format("D MMMM YYYY hh:mm")} </span>}
            />
            {<div style={{ marginTop: "64px" }} />}
          </>
          <DrawerBottomSection>
            <FullArticleLink target="_blank" rel="noopener noreferrer" href={`${GhostWebsiteUrl}/${topicData.slug}`}>
              SEE FULL ARTICLE <ArrowRightIcon />
            </FullArticleLink>
          </DrawerBottomSection>
        </>
      </Col>
    </DrawerRow>
  );
};
